import sk from "vuetify/lib/locale/sk";

export default {

  $vuetify: {
    ...sk,
  },

  menu: {
    dashboard: "Informačný panel",
    console: "Konzola",
    consoleOsm: "Konzola OSM",
    subheaderOperational: "Prevádzkové údaje",
    order: "Objednávky",
    account: "Firmy",
    driverVehicle: "Pridelené vozidlá",
    vehicleDriver: "Zoznam vozidiel",
    vehicleMaintenance: "Údržba vozidiel",
    place: "Miesta",
    reports: "Reporty",
    reportOverview: "Prehľad",
    reportSubheaderStatus: "Stav a počet objednávok",
    reportStatusMonth: "Stavy podľa mesiacov",
    reportStatusDay: "Stavy podľa dní",
    reportStatusHours: "Stavy podľa hodín",
    reportStatusMonthPerSource: "Stavy podľa zdroja",
    reportDailyVehicles: "Počty vozidiel podľa dní",
    reportSubheaderSales: "Financie a firemné jazdy",
    reportSalesDriver: "Tržby vodičov",
    reportSalesCompany: "Firemné jazdy",
    reportSubheaderWorkshift: "Pracovné zmeny",
    reportWorkshift: "Pracovné zmeny",
    reportWorkshiftSummary: "Prehľad pracovných zmen",
    reportSubheaderUsers: "Používatelia",
    reportDispatcherAccessLog: "Prihlásenia dispečerov",
    subheaderUsers: "Používatelia a vozidlá",
    driver: "Vodiči",
    vehicle: "Vozidlá",
    dispatcher: "Dispečeri",
    subheaderSettings: "Nastavenia",
    locality: "Lokality",
    pricelist: "Cenníky",
    settings: "Nastavenia",
    taxiserviceStatus: "Stav taxislužby",
    taxiservice: "Taxislužby",
    subheaderOther: "Iné",
    roadmap: "Roadmap",
    servicePlan: "Program služieb",
    system: "Systém",
    messageDispatch: "Oznámenia dispečingu",
    feedback: "Feedback",
    about: "O aplikácii",
    signout: "Ukončiť prácu",
    versionChange: "Prepnutie verzie",
    versionBeta: "Vyškúšajte Beta ",
    versionProd: "Späť na Prod",
  },

  page_title: {
    dashboard: "Informačný panel",
    console: "Dispečerská konzola",
    consoleOsm: "Dispečerská konzola OSM",
    orderList: "Zoznam objednávok",
    orderDetail: "Detail objednávky",
    accountList: "Firemní klienti",
    accountDetail: "Detail firmy",
    driverVehicleList: "Pridelené vozidlá",
    vehicleDriverList: "Zoznam vozidiel",
    vehicleMaintenanceList: "Údržba vozidiel",
    placeList: "Zoznam miest",
    reportOverview: "Prehľad",
    reportStatusMonth: "Stavy objednávok podľa mesiacov",
    reportStatusDay: "Stavy vytvorených objednávok podľa dní",
    reportStatusMonthPerSource: "Stavy objednávok podľa zdroja",
    reportDailyVehicles: "Počty vozidiel podľa dní",
    reportSalesDriver: "Tržby vodičov",
    reportSalesCompany: "Firemné jazdy",
    reportWorkshift: "Pracovné zmeny",
    reportWorkshiftSummary: "Sumarizácia pracovných zmien",
    reportDispatcherAccessLog: "Záznamy o prihlásení dispečerov",
    driverList: "Vodiči",
    vehicleList: "Správa vozidiel",
    vehicleDetail: "Detail vozidla",
    dispatcherList: "Dispečeri",
    localityList: "Lokality",
    pricelistList: "Správa cenníkov",
    priceList: "Cenník",
    settings: "Nastavenia",
    taxiserviceList: "Správa taxislužieb",
    taxiserviceDetail: "Detail taxislužby",
    taxiserviceStatus: "Stav taxislužby",
    roadmap: "Roadmap",
    servicePlan: "Program služieb",
    messageDispatchList: "Oznámenia",
    feedbackList: "Feedback",
    about: "O aplikácii",
    personalSetting: "Osobné nastavenia",
  },

  // Common
  btnCreate: "Vytvoriť",
  btnSave: "Uložiť",
  btnSubmit: "Odoslať",
  btnCancel: "Zrušiť",
  btnClose: "Zavrieť",
  btnEdit: "Upraviť",
  btnAdd: "Pridat",
  btnAssign: "Prideliť",
  btnReload: "Obnoviť",
  btnUpdate: "Aktualizovať",
  btnRemove: "Odstrániť",
  btnDelete: "Vymazať",
  btnChange: "Zmeniť",
  btnConfirm: "Potvrdiť",
  btnDisable: "Zablokovať",
  btnEnable: "Povoliť",
  btnExportData: "Exportovať",
  btnImportData: "Importovať",
  btnRedirectHttps: "Presmerovať na HTTPS",
  DateRangeSelector: "Vyberte rozsah dátumov",
  //POST request
  msgCreateSuccess: "Záznam bol úspešne vytvorený",
  msgCreateError: "Záznam sa nepodarilo vytvoriť",
  //PUT request
  msgUpdateSuccess: "Záznam bol úspešne aktualizovaný",
  msgUpdateError: "Záznam sa nepodarilo aktualizovať",
  //GET request
  msgGetDataError: "Och, chyba.... Niečo sa pokazilo pri načítaní dát",
  //DELETE request
  msgDeleteSuccess: "Záznam bol úspešne vymazaný",
  msgDeleteError: "Záznam sa nepodarilo vymazať",
  //General error response
  msgResponseError: "Och, vyskytla sa chyba...",
  msgDataMissing: "Chýbajúce údaje",
  msgDataValidation: "Chyba validácie údajov",
  msgConfirmSlider: "Potvrďte posunutím slideru doprava", //Confirm with slider (move right)
  //Validation rules
  msgRequiredField: "Toto pole je povinné",
  phone_hint: "Medzinárodný formát: +421123456789",
  phone_placeholder: "+421123456789",
  msgPasswordMinLength: "Heslo musí mať aspoň 6 znakov",
  msgPasswordLowerCase: "Heslo musí obsahovať aspoň jedno malé písmeno",
  msgPasswordNumber: "Heslo musí obsahovať aspoň jedno číslo",
  //modules
  msgGetModulesError: "Nepodarilo sa nacitat nastavenia modulov...",
  msgModuleWorklogNotEnabled: "Modul Pracovnej zmeny (Worklog) nie je povolený",
  msgModuleReportNotEnabled: "Modul Reporty nie je povolený",
  //HTTPS
  msgHttps: "Pre používanie tejto aplikácie je potrebné zmeniť protokol URL adresy na zabezpečený HTTPS",

  Main: {
    showBetaDialog: {
      title: "Beta aplikácia",
      text1: "Prajete si prepnúť sa na beta verziu aplikácie?",
      text2: "Aplikácia je pretestovaná, ale môže napriek tomu obsahovať chyby. Má však najnovšiu funkčnosť. Aj opačne, beta verzia už môže obsahovať opravu chyby, ktorá sa mohla vyskytnúť v produkčnej verzii.",
      text3: "Vyskúšaním beta verzie pomôžete skôršiemu odladeniu funkčnosti a jej produkčnému nasadeniu.",
      text4: "Tiež nás zaujíma vaša spätná väzba na nové funkčnosti, aby budúce verzie vyhovovali Vám, aj ostatným používateľom.",
      text5: "Kedykoľvek sa môžete rozhodnúť prepnúť späť na produkčnú verziu.",
      switchButton: "Prepnúť na Beta",
    },

    showProdDialog: {
      title: "Produkčná verzia aplikácie",
      text1: "Prajete si prepnúť sa späť na produkčnú verziu aplikácie?",
      text2: "Ak bola v beta verzii aplikácie chyba, alebo máte pripomienky k novej funkčnosti, dajte nám prosím vedieť na adresu info@taxximo.com",
      switchButton: "Prepnúť na Produkčnú verziu",
    },
    language: {
      SK: "Slovensky",
      CS: "Česky",
      EN: "English (Anglicky)",
    },
    showLogoutDialog: {
      title: "Ukončenie práce a odhlásenie sa",
      text1: "Prajete si odhlásiť sa z aplikácie??",
      //text1: "Prajete si ukončiť pracovnú zmenu a odhlásiť sa z aplikácie??",
      logoutButton: "Odhlásiť sa",
      signoutButton: "Ukončiť prácu a odhlásiť sa",
    },
    msgGetTaxiservicListForPickerError: "Nepodarilo sa načítať zoznam taxislužieb pre picker",
    msgChangeTaxiserviceError: "Nepodarilo sa zmeniť taxislužbu",
    msgChangeTaxiserviceSuccess: "Taxislužba bola úspešne zmenená. Aktualizujte si načítané údaje",
    msgSignOutError: "Nepodarilo sa odhlásiť z pracovnej zmeny!",
  },
  AutocompleteDriverRemote: {
    prependIconTitle: "Aktualizovať zoznam vodičov",
    iconAppendOuterTitle: "Prepínanie usporiadania zoznamu podľa: Označenia vozidla | Označenia vodiča | Priezviska vodiča",
  },
  AutocompleteAccountRemote: {
    placeholder: "Začnite písať názov firmy...",
  },
  AutocompleteAddressOsm: {
    placeholder: "Začnite písať adresu...",
    //title: "Pre pridanie novej adresy do databázy kliknite na ikonu pred políčkom.",
    title: "Vyhľadajte adresu v službe Open Street Map",
  },
  AutocompleteAddressGeocode: {
    placeholder: "Začnite písať adresu...",
    //title: "Pre pridanie novej adresy do databázy kliknite na ikonu pred políčkom.",
    title: "Vyhľadajte adresu v službe Geocode Earth",
    iconAppendOuterTitle: "Prepínanie filtra vyhľadávania podľa: Blízkych adries | Iba na viditeľnej mape",
    iconAppendOuterTitleBoundary: "Prepínanie filtra vyhľadávania podľa: Blízkych adries | >Iba na viditeľnej mape<",
    iconAppendOuterTitleFocus: "Prepínanie filtra vyhľadávania podľa: >Blízkych adries< | Iba na viditeľnej mape",
  },
  AutocompleteAddressRemote: {
    placeholder: "Začnite písať adresu...",
    title: "Pre pridanie novej adresy do databázy kliknite na ikonu pred políčkom.",
    prependIconTitle: "Vyhľadať a pridať novú adresu do databázy",
    dialogTitle: "Pridanie novej adresy do databázy",
    dialogInputPlaceholder: "Zadajte adresu...",
    btnClose: "Zavrieť",
    btnAdd: "Pridať",
    msgAddSuccess: "Adresa bola úspešne pridaná do databázy",
    msgAddErrorEmpty: "Adresu je potrebné najprv vyhľadať a vybrať z ponúkaných možností",
    msgAddErrorDuplicated: "Adresu sa nepodarilo pridať do databázy",
    msgAddErrorDuplicatedAlert: "Duplicitný záznam. Prosím vyhľadajte adresu normálnym spôsobom, nevyhľadávajte ju cez Pridanie adresy.",
    msgAddErrorDuplicatedBan: "Často vyhľadávate adresy, ktoré sa už nachádzajú v databáze. Prekročili ste maximálny počet takýchto adries. Funkcia na pridávanie nových adries je pre Vás dočasne zablokovaná.",
    msgNoAddress: "Nie je dostupný detail adresy", //No details available for input
    msgAddAddressNotSearched: "Adresu je potrebné pokúsiť sa najprv vyhľadať",
  },
  AutocompleteAddressRemoteGeocode: {
    placeholder: "Začnite písať adresu...",
    title: "Pre pridanie novej adresy do databázy kliknite na ikonu pred políčkom.",
    prependIconTitle: "Vyhľadať a pridať novú adresu do databázy cez službu Geocode Earth",
    dialogTitle: "Pridanie novej adresy do databázy",
    autocompleteAddLabel: "Nová adresa",
    btnClose: "Zavrieť",
    btnAdd: "Pridať",
    msgAddSuccess: "Adresa bola úspešne pridaná do databázy",
    msgAddErrorDuplicated: "Adresu sa nepodarilo pridať do databázy",
    msgAddErrorDuplicatedAlert: "Duplicitný záznam. Prosím vyhľadajte adresu normálnym spôsobom, nevyhľadávajte ju cez Pridanie adresy.",
    msgAddErrorDuplicatedBan: "Často vyhľadávate adresy, ktoré sa už nachádzajú v databáze. Prekročili ste maximálny počet takýchto adries. Funkcia na pridávanie nových adries je pre Vás dočasne zablokovaná.",
    msgNoAddress: "Nie je dostupný detail adresy", //No details available for input
    msgAddAddressNotSearched: "Adresu je potrebné pokúsiť sa najprv vyhľadať",
  },
  AutocompleteCityRemote: {
    placeholder: "Začnite písať mesto...",
    msgSelectCountry: "Pre vyhľadanie mesta je potrebné najprv vybrať Krajinu.",
  },
  WeatherForecast: {
    title: "Počasie",
    msgGetWeatherError: "Nepodarilo sa načítať počasie",
    temperature: "Teplota",
    wind: "Vietor",
    humidity: "Vlhkosť",
    pressure: "Tlak",
    feelsLike: "Pocitová teplota",
    probabilityOfPrecipitation: "Pravdepodobnosť zrážok",
  },
  //https://openweathermap.org/weather-conditions
  WeatherEvent: {
    200: "Búrka", //Thunderstorm
    300: "Mrholenie", //Drizzle
    500: "Dážď",//Rain
    600: "Sneh",//Snow
    700: "Atmosferické podmienky",//  Atmosphere
    800: "Jasno",//Clear
    801: "Mierne zamračené",// Mierne zamračené / Few clouds
    802: "Polojasno",// Rozptýlené oblaky / Scattered clouds
    803: "Zamračené",// Rozbité oblaky / Broken clouds
    804: "Zamračené",// Zamračené / Overcast clouds
  },

  ExportData: {
    title: "Exportovať údaje",
    formatDatetime_label: "Formát dátumu a času",
    formatDecimal_label: "Oddeľovač desatinných čísel",
    btnExcel: "Excel",
    btnCsv: "CSV",
    btnRawData: "Raw data",
  },

  Login: {
    page_title: "Prihlásenie do systému Taxximo",
    email: "Email",
    password: "Heslo",
    btnLogin: "Prihlásiť sa",
    btnForgottenPassword: "Zabudli ste heslo?",
    btnDriverApp: "Stiahnuť aplikáciu vodiča",
    btnDriverAppBeta: "Zapojiť sa do beta testovania aplikácie vodiča",
    btnSimpleLogin: "Zjednodušené prihlásenie",
    dialogTitle: "Zabudnuté heslo",
    msgWrongFormatEmail: "Zadali ste nesprávny formát emailu",
    msgWrongCredentials: "Zadali ste nesprávny email alebo heslo",
    msgWrongCredentials2: "Zadané údaje sú nesprávne",
    msgModuleError: "Nepodarilo sa načítať nastavenia modulov... Kontaktujte administrátora systému alebo obchodného zástupcu Taxximo.",
    msgResetSuccess: "Reset link bol zaslaný na tvoj email",
    msgRecommendedChrome: "Pre najlepší výkon aplikácie odporúčame používať prehliadač Google Chrome",
    msgRecommendedChromeUnsupported: "Pre najlepší výkon aplikácie odporúčame používať prehliadač Google Chrome. Váš prehliadač nie je podporovaný. Niektoré funkcie môžu byť nedostupné.",
    //zobrazenie objednavok z pamate zariadenia pri vypadku spojenia
    dialogOrderListTitle: "Neaktuálny zoznam objednávok z pamäte zariadenia - technický výpis",
    btnCheckConnectivity: "Skontrolovať pripojenie",
    btnOrderList: "Zobraziť zoznam objednávok",
    msgOrderList: "Zobrazujú sa posledné údaje z pamäte zariadenia, nie zo servera. Sú zrejme neaktuálne. Objednávky sa nedajú aktualizovať ani zrušiť. Zoznam je iba informatívny.",
    msgConnectivity404: "Pripojenie na server zlyhalo.",
    msgConnectivity200: "Pripojenie na server je OK.",
    msgConnectivity302: "Pripojenie na server je pravdepodobne OK.",
    msgConnectivityOffline: "Zariadenie nie je pripojené na internet. Zobrazujú sa posledné zistené údaje. Objednávky sa nedajú aktualizovať ani zrušiť. Skúste sa pripojiť alebo počkajte kým bude dostupné internetové pripojenie.",
    msgConnectivityServerDown: "Údaje zo servera nie sú dostupné. Možno máte výpadok internetu alebo je výpadok v datacentre. Ak si zobrazíte zoznam objednávok, bude obsahovať len posledné zistené údaje. Objednávky sa nedajú aktualizovať ani zrušiť. Počkajte kým bude server opäť dostupný alebo bude dostupné internetové pripojenie.",
    msgConnectivityNoInternet: "Nemáte dostupný internet. Možno máte výpadok poskytovateľa internetových služieb alebo je problém v routeri. Ak si zobrazíte zoznam objednávok, bude obsahovať len posledné zistené údaje. Objednávky sa nedajú aktualizovať ani zrušiť.",
    tableHeaders: {
      id: "ID",
      status: "Stav",
      type: "Typ",
      createdAt: "Čas vytvorenia",
      requestedTime: "Požadovaný čas",
      address1Type: "Typ 1",
      address1: "Adresa 1",
      address2Type: "Typ 2",
      address2: "Adresa 2",
      driverFirstname: "Meno vodiča",
      driverLastname: "Priezvisko vodiča",
      vehicleInternalName: "Názov vozidla",
      preferredDriverLastname: "Preferovaný vodič",
      note: "Poznámka",
    },
  },

  PersonalSetting: {
    page_title: "Osobné nastavenia",
    passwordTitle: "Heslo",
    passwordSubtitle: "Nastavenia hesla",
    btnLogout: "Odhlásiť sa",
    dashboardSelectedItems: "Položky v informačnom paneli",
    dialogLogoutTitle: "Odhlásenie z aplikácie",
    logoutText: "Prajete si odhlásiť sa z aplikácie?",
    DialogPasswordChangeTitle: "Zmena hesla",
    passwordOld: "Aktuálne heslo",
    passwordNew: "Nové heslo",
    passwordNew2: "Potvrdenie nového hesla",
    msgPasswordsNotMatch: "Heslá sa nezhodujú",
    msgDashboardSelectedItemsSuccesfullySaved: "Položky v informačnom paneli boli úspešne uložené",
  },

  Dashboard: {
    page_title: "Informačný panel",
    cardMessageDispatch: "Oznámenia",
    cardDdriverOnBreak: "Vodiči na prestávke",
    cardDdriverOnBreakSubtitle: "Zoznam vodičov na prestávke",
    cardWorklogRequest: "Žiadosti vodičov",
    cardWorklogRequestSubtitle: "Zoznam žiadostí vodičov o prestávku a ukončenie pracovnej zmeny",
    cardDriverOnline: "Aktívni vodiči",
    cardDriverOnlineSubtitle: "Zoznam vodičov, ktorí sú voľní alebo vykonávajú jazdu",
    cardPriceLocality: "Cenníkové lokality",
    cardPriceLocalitySubtitle: "Mapa oblastí, ktoré sa využívajú na výpočet cien",
    cardWeatherForecast: "Počasie",
    cardWeatherForecastSubtitle: "Predpoveď počasia na najbližšíe hodiny",
    cardPaymentDue: "Evidujeme platby po dátume splatnosti",
    service: "Služba",
    tableHeadersWorklog: {
      type: "Typ",
      status: "Stav",
      driverFirstName: "Meno",
      driverLastName: "Priezvisko",
      createdAt: "Vytvorené",
    },
    tableHeadersDriverOnBreak: {
      id: "ID",
      firstName: "Meno",
      lastName: "Priezvisko",
    },
    tableHeadersDriverOnline: {
      id: "ID",
      firstName: "Meno",
      lastName: "Priezvisko",
      silence: "Tichý režim",
    },
    silenceEnabledTitle: "Tichý režim zapnutý",
    silenceDisabledTitle: "Tichý režim vypnutý",
    dashcardUtilizationRatio: {
      title: "Vyťaženosť vodičov",
      actionText: "Percentuálna vyťaženosť vodičov: Percento zaneprázdnených voči aktívnym vodičom. " +
        "\nIdeálna hodnota pre pružné vybavovanie zákazníkov je do 80%." +
        "\nOptimálna pre efektivitu taxislužby je 100%",
    },
    dashcardLoadRatio: {
      title: "Zaťaženie taxislužby",
      actionText: "Index záťaže taxislužby: Pomer Aktívnych objednávok (realizované + čakajúce)  / Aktívnych vodičov." +
        "\nIdeálna hodnota pre vybavovanie zákazníkov je 1 a menej." +
        "\nHodnota nad 1 spôsobuje, že zákazníci dlhšie čakajú na pridelenie vozidla." +
        "\nOptimálna pre efektivitu (rýchle vyťažovanie vodičov) taxislužby je od 1 do 1,4. " +
        "\nNad 2 je preťažená taxislužba. Vtedy je vhodné uvažovať nad odmietaním nových objednávok.",
    },
    dashcardImmediateAvailabilityRatio: {
      title: "Okamžitá dostupnosť",
      actionText: "Index okamžitej dostupnosti: Pomer objednávok na pridelenie a voľných vodičov." +
        "\nIdeálna hodnota pre vybavovanie zákazníkov 1 a menej.",
    },
    dashcardCounterPENDING: {
      title: "Nepriradené objednávky",
      actionText: "Objednávky čakajúce na priradenie vodičom.",
    },
    dashcardSumPerformedOrders: {
      title: "Realizované objednávky",
      actionText: "Objednávky pridelené vodičom a práve realizované.",
    },
    dashcardSumActiveDrivers: {
      title: "Aktívni vodiči",
      actionText: "Voľní a zaneprázdnení vodiči s objednávkami.",
    },
    dashcardSumBusyDrivers: {
      title: "Zaneprázdnení vodiči",
      actionText: "Vodiči s pridelenými objednávkami.",
    },
    dashcardCounterOnlineFREE: {
      title: "Voľní vodiči",
      actionText: "Voľní vodiči bez objednávok.",
    },
    dashcardCounterONBREAK: {
      title: "Vodiči na prestávke",
      actionText: "Vodiči aktuálne na prestávke.",
    },
  },

  orderStatus: {
    PREREGISTERED: "Predregistrovaná",
    TRANSFERED: "Presmerovaná",
    NEW: "Nová",
    PLANNED: "Plánovaná",
    PENDING: "Čakajúca",
    ACCEPTED: "Akceptovaná",
    WAITING: "Na mieste",
    IN_PROGRESS: "Vo vozidle",
    FINISHED: "Ukončená",
    CHARGED: "Naúčtovaná",
    SETTLED: "Zúčtovaná",
    ARCHIVED: "Archivovaná",
    CANCELLED_PREREGISTERED: "Odregistrovaná",
    CANCELLED_NEW: "Zneplatnená",
    CANCELLED_PLANNED: "Nerealizovaná",
    CANCELLED_PENDING: "Odvolaná",
    CANCELLED_ACCEPTED: "Odmietnutá",
    CANCELLED_WAITING: "Zrušená",
    CANCELLED_IN_PROGRESS: "Zamietnutá",
    CANCELLED_FINISHED: "Nedokončená",
    CANCELLED_CHARGED: "Nenaúčtovaná",
    CANCELLED_SETTLED: "Nezaplatená",
    CANCELLED_ALL: "Všetky ZRUŠENÉ",
    UNDEFINED: "",
  },

  orderStatusSimple: {
    PREREGISTERED: "Predregistrovaná",
    TRANSFERED: "Presmerovaná",
    NEW: "Nová",
    PLANNED: "Plánovaná",
    PENDING: "Čakajúca",
    ACCEPTED: "Akceptovaná",
    WAITING: "Na mieste",
    IN_PROGRESS: "Vo vozidle",
    FINISHED: "Ukončená",
    CHARGED: "Naúčtovaná",
    SETTLED: "Zúčtovaná",
    ARCHIVED: "Archivovaná",
    CANCELLED_PREREGISTERED: "Zrušená",
    CANCELLED_NEW: "Zrušená",
    CANCELLED_PLANNED: "Zrušená",
    CANCELLED_PENDING: "Zrušená",
    CANCELLED_ACCEPTED: "Zrušená",
    CANCELLED_WAITING: "Zrušená",
    CANCELLED_IN_PROGRESS: "Zrušená",
    CANCELLED_FINISHED: "Zrušená",
    CANCELLED_CHARGED: "Zrušená",
    CANCELLED_SETTLED: "Zrušená",
    UNDEFINED: "",
  },
  orderStatusExt: {
    UNDEFINED: "",
    CANCELLED_SUM: "Zrušené",
  },
  orderStatus_tooltip: {
    PREREGISTERED: "Predregistrovaná objednávka",
    TRANSFERED: "Presmerovaná objednávka",
    NEW: "Nová objednávka",
    PLANNED: "Plánovaná objednávka",
    PENDING: "Čakajúca objednávka",
    ACCEPTED: "Akceptovaná objednávka",
    WAITING: "Vodič čaká na mieste vyzdvihnutia",
    IN_PROGRESS: "Vodič prepravuje zákazníka",
    FINISHED: "Ukončená objednávka",
    CHARGED: "Naúčtovaná objednávka",
    SETTLED: "Zúčtovaná objednávka",
    ARCHIVED: "Archivovaná objednávka",
    CANCELLED_PREREGISTERED: "Zrušená Pregistrovaná objednávka",
    CANCELLED_NEW: "Zrušená Nová objednávka",
    CANCELLED_PLANNED: "Zrušená Plánovaná objednávka",
    CANCELLED_PENDING: "Zrušená Čakajúca objednávka",
    CANCELLED_ACCEPTED: "Zrušená Akceptovaná objednávka",
    CANCELLED_WAITING: "Zrušená objednávka Na mieste",
    CANCELLED_IN_PROGRESS: "Zrušená objednávka Vo vozidle",
    CANCELLED_FINISHED: "Zrušená Ukončená objednávka",
    CANCELLED_CHARGED: "Zrušená Naúčtovaná objednávka",
    CANCELLED_SETTLED: "Zrušená Zúčtovaná objednávka",
    UNDEFINED: "",
  },
  orderStatus_help: {
    PREREGISTERED: "Predregistrovaná objednávka",
    TRANSFERED: "Presmerovaná objednávka",
    NEW: "Objednávka bola vytvorená zákazníkom a čaká na akceptáciu taxislužbou.",
    PLANNED: "Objednávka nie je určená na okamžité pridelenie vodičovi a nie je zatiaľ prideľovaná ani automatom. Pravdepodobne bola vytvorená vopred na požadovaný čas.",
    PENDING: "Objednávka čaká na okamžité pridelenie vodičovi. Ak je zapnutý automat, objednávka bude pridelená automaticky.",
    ACCEPTED: "Objednávka bola pridelená vodičovi, ktorý ju akceptoval. Ak má vodič iba túto objednávku, pravdepodobne je na ceste k zákazníkovi.",
    WAITING: "Vodič čaká na zákazníka na mieste požadovaného vyzdvihnutia",
    IN_PROGRESS: "Vodič prepravuje zákazníka po požadovanej trase",
    FINISHED: "Ukončená objednávka, zákazník je prepravený do požadovaného cieľa cesty. Vodič pripravuje účet.",
    CHARGED: "Objednávka bola naúčtovaná, prebieha platba kartou, kreditom alebo bude zaplatená faktúrou pri firemnej jazde.",
    SETTLED: "Objednávka bola zaplatená a účet uzavretý.",
    ARCHIVED: "Archivovaná objednávka",
    CANCELLED_PREREGISTERED: "Zrušená Pregistrovaná objednávka",
    CANCELLED_NEW: "Zrušená Nová objednávka",
    CANCELLED_PLANNED: "Zrušená Plánovaná objednávka",
    CANCELLED_PENDING: "Zrušená Čakajúca objednávka",
    CANCELLED_ACCEPTED: "Zrušená Akceptovaná objednávka",
    CANCELLED_WAITING: "Zrušená objednávka Na mieste",
    CANCELLED_IN_PROGRESS: "Zrušená objednávka Vo vozidle",
    CANCELLED_FINISHED: "Zrušená Ukončená objednávka",
    CANCELLED_CHARGED: "Zrušená Naúčtovaná objednávka",
    CANCELLED_SETTLED: "Zrušená Zúčtovaná objednávka",
    UNDEFINED: "",
  },
  orderStatusArray:
    [
      {
        "text": "Predregistrovaná",
        "value": "PREREGISTERED",
      },
      {
        "text": "Presmerovaná",
        "value": "TRANSFERED",
      },
      {
        "text": "Nová",
        "value": "NEW",
      },
      {
        "text": "Plánovaná",
        "value": "PLANNED",
      },
      {
        "text": "Čakajúca",
        "value": "PENDING",
      },
      {
        "text": "Akceptovaná",
        "value": "ACCEPTED",
      },
      {
        "text": "Na mieste",
        "value": "WAITING",
      },
      {
        "text": "Vo vozidle",
        "value": "IN_PROGRESS",
      },
      {
        "text": "Ukončená",
        "value": "FINISHED",
      },
      {
        "text": "Naúčtovaná",
        "value": "CHARGED",
      },
      {
        "text": "Zúčtovaná",
        "value": "SETTLED",
      },
      {
        "text": "Archivovaná",
        "value": "ARCHIVED",
      },
      {
        "text": "Odregistrovaná",
        "value": "CANCELLED_PREREGISTERED",
      },
      {
        "text": "Zneplatnená",
        "value": "CANCELLED_NEW",
      },
      {
        "text": "Nerealizovaná",
        "value": "CANCELLED_PLANNED",
      },
      {
        "text": "Odvolaná",
        "value": "CANCELLED_PENDING",
      },
      {
        "text": "Odmietnutá",
        "value": "CANCELLED_ACCEPTED",
      },
      {
        "text": "Zrušená",
        "value": "CANCELLED_WAITING",
      },
      {
        "text": "Zamietnutá",
        "value": "CANCELLED_IN_PROGRESS",
      },
      {
        "text": "Nedokončená",
        "value": "CANCELLED_FINISHED",
      },
      {
        "text": "Nenaúčtovaná",
        "value": "CANCELLED_CHARGED",
      },
      {
        "text": "Nezaplatená",
        "value": "CANCELLED_SETTLED",
      },
      {
        "text": "",
        "value": "UNDEFINED",
      }
    ],

  orderType: {
    INSTANT: "Okamžitá",
    TIME: "Časovka",
    STREET: "Z ulice",
    DIRECT: "Priama",
    REPEATED: "Opakovaná",
    AIRPORT: "Letisková",
    UNDEFINED: "",
  },

  orderType_tooltip: {
    INSTANT: "Objednávka na okamžitú realizáciu",
    TIME: "Objednávka na presný čas",
    STREET: "Objednávka z ulice",
    DIRECT: "Priama objednávka",
    REPEATED: "Opakovaná objednávka",
    AIRPORT: "Objednávka s vyzdvihnutím na letisku",
    UNDEFINED: "",
  },

  orderTypeArray:
    [
      {
        "text": "Okamžitá",
        "value": "INSTANT",
      },
      {
        "text": "Časovka",
        "value": "TIME",
      },
      {
        "text": "Z ulice",
        "value": "STREET",
      },
      {
        "text": "Priama",
        "value": "DIRECT",
      },
      {
        "text": "Opakovaná",
        "value": "REPEATED",
      },
      {
        "text": "Letisková",
        "value": "AIRPORT",
      },
      {
        "text": "",
        "value": "UNDEFINED",
      }
    ],

  source: {
    DISPATCH_TAXXIMO: "Dispečing Txx.",
    DISPATCH_TAXISERVICE: "Dispečing",
    DISPATCH_BROKER: "Dispečing B.",
    CUSTOMER_ANDROID: "Zákazník AD",
    CUSTOMER_IOS: "Zákazník iOS",
    BRAND_ANDROID: "Zákazník AD B.",
    BRAND_IOS: "Zákazník iOS B.",
    DRIVER_ANDROID: "Vodič",
    PBX: "Ústredňa",
    UNDEFINED: "",
    undefined: "",
  },
  source_short: {
    DISPATCH_TAXXIMO: "DispečX",
    DISPATCH_TAXISERVICE: "Dispeč",
    DISPATCH_BROKER: "DispečB",
    CUSTOMER_ANDROID: "Zákazník",
    CUSTOMER_IOS: "Zákazník",
    BRAND_ANDROID: "Zákazník",
    BRAND_IOS: "Zákazník",
    DRIVER_ANDROID: "Vodič",
    PBX: "Ústredňa",
    undefined: "",
  },
  source_tooltip: {
    DISPATCH_TAXXIMO: "Dispečing Taxximo",
    DISPATCH_TAXISERVICE: "Dispečing taxislužby",
    DISPATCH_BROKER: "Spoločný dispečing brokera",
    CUSTOMER_ANDROID: "Zákaznícka aplikácia Taxximo, systém Android",
    CUSTOMER_IOS: "Zákaznícka aplikácia Taxximo, systém iOS",
    BRAND_ANDROID: "Zákaznícka brandovaná aplikácia Brokera, systém Android",
    BRAND_IOS: "Zákaznícka brandovaná aplikácia Brokera, systém iOS",
    DRIVER_ANDROID: "Aplikácia vodiča, systém Android",
    PBX: "Telefónna ústredňa",
    undefined: "",
    UNDEFINED: "",
  },
  sourceArray:
    [
      { "text": "Dispečing Txx.", "value": "DISPATCH_TAXXIMO" },
      { "text": "Dispečing", "value": "DISPATCH_TAXISERVICE" },
      { "text": "Dispečing B.", "value": "DISPATCH_BROKER" },
      { "text": "Zákazník AD", "value": "CUSTOMER_ANDROID" },
      { "text": "Zákazník iOS", "value": "CUSTOMER_IOS" },
      { "text": "Zákazník AD B.", "value": "BRAND_ANDROID" },
      { "text": "Zákazník iOS B.", "value": "BRAND_IOS" },
      { "text": "Vodič", "value": "DRIVER_ANDROID" },
      { "text": "Ústredňa", "value": "PBX" },
      { "text": "", "value": "UNDEFINED" }
    ],
  paymentType: {
    CASH: "Hotovosť",
    CARD: "EFTPOS",
    VIAMO: "VIAMO",
    COUPON: "Poukážka",
    CARD_ONLINE: "Kartou",
    PREPAID: "Kredit",
    POSTPAID: "Faktúra",
    INVOICE: "Faktúra Broker",
    UNDEFINED: "",
    undefined: "",
  },
  paymentType_tooltip: {
    CASH: "Platba v hotovosti",
    CARD: "Platba kartou z EFT POS terminálu vo vozidle",
    VIAMO: "Platba cez VIAMO",
    COUPON: "Platba poukážkou",
    CARD_ONLINE: "Platba cez platobnú bránu",
    PREPAID: "Platba z predplateného kreditu",
    POSTPAID: "Platba na faktúru účtovaná od Taxislužby",
    INVOICE: "Platba na faktúru preúčtovaná cez Brokera",
    undefined: "",
  },
  paymentTypeArray:
    [
      { "text": "Hotovosť", "value": "CASH" },
      { "text": "EFTPOS", "value": "CARD" },
      { "text": "VIAMO", "value": "VIAMO" },
      { "text": "Poukážka", "value": "COUPON" },
      { "text": "Kartou", "value": "CARD_ONLINE" },
      { "text": "Kredit", "value": "PREPAID" },
      { "text": "Faktúra", "value": "POSTPAID" },
      { "text": "Faktúra Broker", "value": "INVOICE" },
      { "text": "", "value": "undefined" }
    ],
  workStatus: {
    OFFLINE: "Voľno",
    ONLINE: "V práci",
    ON_BREAK: "Prestávka",
    NONE: "",
    UNDEFINED: "",
  },
  activeTaxiserviceSelection: {
    ACTIVE: "Vybratá",
    DISABLED: "Nevybratá",
    UNDEFINED: "",
  },

  worklogRequestType: {
    OFFLINE: "Ukončenie zmeny",
    ONLINE: "Začatie zmeny",
    ON_BREAK: "Prestávka",
    NONE: "",
    UNDEFINED: "",
  },
  worklogRequestStatus: {
    NEW: "Nové",
    CONFIRMED: "Schválené",
    DECLINED: "Odmietnuté",
    DONE: "Zrealizované",
    CANCELLED: "Zrušené",
    UNDEFINED: "",
  },
  dashboardItems: [
    { "text": "Vyťaženosť vodičov", "value": "utilizationRatio" },
    { "text": "Zaťaženie taxislužby", "value": "loadRatio" },
    { "text": "Okamžitá dostupnosť", "value": "immediateAvailabilityRatio" },
    { "text": "Nepriradené objednávky", "value": "counterPENDING" },
    { "text": "Realizované objednávky", "value": "sumPerformedOrders" },
    { "text": "Aktívni vodiči", "value": "sumActiveDrivers" },
    { "text": "Zaneprázdnení vodiči", "value": "sumBusyDrivers" },
    { "text": "Voľní vodiči", "value": "counterOnlineFREE" },
    { "text": "Vodiči na prestávke", "value": "counterON_BREAK" },
    { "text": "Cenníkové lokality - mapa", "value": "priceLocalityList" },
    { "text": "Aktívni vodiči - zoznam", "value": "driverOnlineItems" },
    { "text": "Vodiči na prestávke - zoznam", "value": "driverOnBreakItems" },
    { "text": "Schvaľovanie pracovných zmien - zoznam", "value": "worklogRequestItems" },
  ],
  OrderListView: {
    page_title: "Zoznam objednávok",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    actions: "Akcie",
    Id: "Č. objednávky",
    Id_tooltip: 'Technické číslo objednávky {msg}',
    Status: "Stav",
    Type: "Typ",
    Source: "Zdroj",
    PaymentType: "Platba",
    Note: "Poznámka",
    CreatedAt: "Vytvorené",
    AcceptedAt: "Akceptované",
    StartedAt: "Začaté",
    FinishedAt: "Ukončené",
    DriverId: "Č. vodiča",
    DriverName: "Meno vodiča",
    DriverFirstName: "Meno vodiča",
    DriverLastName: "Priezvisko vodiča",
    ClientId: "Č. zákazníka",
    ClientNickname: "Meno zákazníka",
    ClientPhone: "Telefón zákazníka",
    VehicleLpn: "ŠPZ vozidla",
    VehicleBrand: "Značka vozidla",
    VehicleModel: "Model vozidla",
    VehicleInternalName: "Interný názov vozidla",
    boardAddress: "Vyzdvihnutie",
    destinationAddress: "Cieľ",
    EstimatedDistance: "Predpokladaná vzdialenosť",
    EstimatedTime: "Predpokladaný čas",
    FinalDistance: "Finálna vzdialenosť",
    FinalTime: "Finálny čas",
    InfoDistance: "Informatívna vzdialenosť",
    InfoTime: "Informatívny čas",
    InfoPriceTotal: "Informatívna celková cena",
    EstimatedPriceTotal: "Predpokladaná celková cena",
    FinalPriceStart: "Finálna cena za vyzdvihnutie",
    FinalPriceDrive: "Finálna cena za jazdu",
    FinalPriceWaiting: "Finálna cena za čakanie",
    FinalPriceJourney: "Finálna cena za jazdu",
    FinalPriceExtra: "Finálny príplatok",
    FinalPriceDiscount: "Finálna zľava",
    FinalPriceTotal: "Finálna celková cena",
    OrdersCount: "Počet objednávok",
    OrderCounter: "Počet objednávok",
    BreakCounter: "Počet prestávok",
    BreakDuration: "Trvanie prestávok",
    MileagesStart: "Začiatočný stav kilometrov",
    MileagesFinish: "Koncový stav kilometrov",
    Mileages: "Najazdené kilometre",
    Duration: "Trvanie",
    Distance: "Najazdená vzdialenosť",
    NetDuration: "Čas bez prestávok",
    AccountId: "Č. firmy",
    AccountName: "Názov firmy",
    filterLabel: {
      actions: "Akcie",
      Id: "č. objednávky",
      Status: "Stav",
      Type: "Typ",
      Source: "Zdroj",
      PaymentType: "Platba",
      Note: "Poznámka",
      CreatedAt: "Vytvorené",
      AcceptedAt: "Akceptované",
      DriverId: "Č. vodiča",
      DriverName: "Meno vodiča",
      DriverFirstName: "Meno vodiča",
      DriverLastName: "Priezvisko vodiča",
      VehicleLpn: "ŠPZ",
      boardAddress: "Vyzdvihnutie",
      destinationAddress: "Cieľ",
      AccountName: "Názov firmy",
      AccountId: "ID firmy",
    },
    RefreshData: "Aktualizovať",
    vizualizeAddressOnMapDialog_title: "Vizualizácia adries na mape",
    btnVizualizeAddresses: "Vizualizácia adries",
    btnVizualizeAddresses_title: "Vizualizovať miesta vyzdvihnutia a cieľov objednávok na mape cez heatmap zobrazenie",
    btnVizualizeBoardAddresses: "Vizual. vyzdvihnutia",
    btnVizualizeBoardAddresses_title: "Vizualizovať miesta vyzdvihnutia objednávok na mape cez heatmap zobrazenie",
    btnVizualizeDestinationAddresses: "Vizual. ciele",
    btnVizualizeDestinationAddresses_title: "Vizualizovať miesta cieľov objednávok na mape cez heatmap zobrazenie",
    detailDialog_title: "Detail objednávky",
  },

  OrderView: {
    page_title: "Detail objednávky",
    reducePositions_title: "Redukovať počet markerov, ak je ich veľa na mape",
    tabInfo: "Info",
    tabRoute: "Trasa",
    tabTimeline: "Záznamy",
    tabPrice: "Cena",
    //Tab Info
    headerBasicData: "Základné údaje o objednávke",
    id: "Číslo objednávky",
    status: "Stav",
    source: "Zdroj",
    type: "Typ",
    paymentType: "Typ platby",
    preferredDriverFirstName: "Meno preferovaného vodiča",
    preferredDriverLastName: "Priezvisko preferovaného vodiča",
    requestedTime: "Požadovaný čas",
    presendTime: "Čas zaslanie vopred (minúty)",
    preferredDriverId: "ID preferovaného vodiča",
    note: "Poznámka",
    headerClient: "Klient",
    nickname: "Meno",
    clientPhone: "Telefón",
    mark: "Typ",
    clientNote: "Poznámka",
    clientRating: "Hodnotenie",
    clientDriveCounter: "Počet jazd",
    clientRateCount: "Počet hodnotení",
    clientRateSum: "Súčet bodov hodnotení",
    headerAccount: "Firemná jazda",
    accountName: "Názov firmy",
    accountStatus: "Stav firmy",
    accountId: "ID firmy",
    accountNone: "Žiadna", //No data
    headerDriver: "Vodič",
    driverFirstName: "Meno",
    driverLastName: "Priezvisko",
    driverPhone: "Telefón",
    driverNickname: "Označenie",
    driverRating: "Hodnotenie",
    driverDriveCounter: "Počet jázd",
    driverRateCount: "Počet hodnotení",
    driverRateSum: "Súčet bodov hodnotení",
    driverNone: "Žiadny", //No data
    driverId: "ID vodiča",
    orderDriverRating: "Hodnotenie od klienta",
    orderClientRating: "Hodnotenie od vodiča",
    headerOrderFilter: "Požiadavky na jazdu",
    headerDisplayAndSend: "Zobrazenie a zaslanie objednávky",
    showPhone_short: "Telefón zákazníka",
    showDriverPhoneButton_short: "Tlačidlo telefonovania",
    showAllAddresses_short: "Všetky adresy",
    onlyManualSending_short: "Manuálne zasielanie",
    forcedPickupRadius_short: "Rádius vyzdvihnutia",
    showPrice: "Zobrazenie ceny vodičovi a zákazníkovi",
    showPhone: "Vodičovi zobraziť telefónne číslo zákazníka",
    showDriverPhoneButton: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses: "Vodičovi zobraziť všetky adresy",
    onlyManualSending: "Iba manuálne zaslanie objednávky",
    forcedPickupRadius: "Vynútenie vyzdvihnutia v okruhu (0 = vypnuté)",
    headerWaypointList: "Trasa",
    headerChat: "Chat",
    headerOrderSentLogs: "Záznamy o zaslaní objednávky na vodičov",
    cardFinalPriceWaiting: "Čakanie",
    cardFinalPriceJourney: "Jazda",
    cardFinalPriceExtra: "Extra",
    cardFinalPriceDiscount: "Zľava",
    cardFinalPriceTotal: "Celkom",
    subheaderPrice: "Analýza ceny",
    displayCalculationSwitchHide: "Skryť výpočet",
    displayCalculationSwitchShow: "Zobraziť výpočet",
    tblHeaderPriceName: "Názov",
    tblHeaderPriceEstimated: "Očakávaná",
    tblHeaderPriceFinal: "Finálna",
    tblHeaderPriceInfo: "Informatívna",
    tblPrice: {
      locFrom: "Lokalita vyzdvihnutia",
      locTo: "Lokalita cieľa cesty",
      distance: "Vzdialenosť prepravy",
      time: "Čas prepravy",
      priceWaiting: "Cena za čakanie pre začatím prepravy zákazníka",
      priceStart: "Cena za vyzdvihnutie",
      priceDriveDistance: "Cena za vzdialenosť prepravy",
      priceDriveTime: "Cena za čas prepravy",
      priceDrive: "Cena za prepravu",
      priceJourney: "Cena za jazdu",
      priceExtra: "Príplatky",
      priceDiscount: "Zľavy",
      priceTotal: "Celková cena objednávky",
    },
    subheaderPriceItems: "Cenové položky",
    tblPriceItems: {
      type: "Typ",
      name: "Názov",
      active: "Aktívny",
      cancelPriceFixation: "Zrušiť fixáciu",
      resetToDefualtPrice: "Reset do Default Ceny",
      unitPrice: "Cena",
      quantity: "Množstvo",
      unit: "Jednotka",
      price: "Celková cena",
    },
    addressMarker_BOARD: "Miesto vyzdvihnutia",
    addressMarker_DESTINATION: "Cieľ cesty",
    addressMarker_TRANSIT1: "1. medzizastávka",
    addressMarker_TRANSIT2: "2. medzizastávka",
    addressMarker_TRANSIT3: "3. medzizastávka",
    addressMarker_TRANSIT4: "4. medzizastávka",
    addressMarker_TRANSIT: "Medzizastávka",
    //position marker title
    markerTitleId: "ID bodu",
    markerTitleOrderStatus: "Stav objednávky",
    markerTitleDriverId: "Vodič",
    markerTitleSpeed: "Rýchlosť",
    markerTitleCreatedAt: "Vytvorené (UTC)",
    markerTitleRecordedAt: "Zaznamenané (UTC)",
    timeline: {
      //VYTVORENIE OBJEDNAVKY
      createdAt_title: "Vytvorená objednávka",
      createdAt_text: "Objednávka vytvorená v systéme",
      //ZMENY STAVOV OBJEDNAVKY
      plannedAt_title: "Naplánovaná objednávka",
      plannedAt_text: "Objednávka naplánovaná",
      pendingAt_title: "Objednávka pripravená na pridelenie vodičovi",
      pendingAt_text: "Objednávka ",
      acceptedAt_title: "Objednávka akceptovaná vodičom",
      acceptedAt_text: "Vodič akceptoval objednávku. Ak nemá inú prebiehajúcu jazdu, tak by mal byť na ceste k zákazníkovi.",
      waitingAt_title: "Vodič čaká na zákazníka",
      waitingAt_text: "Vodič dorazil na miesto vyzdvihnutia zákazníka",
      progressAt_title: "Vodič vezie zákazníka ",
      progressAt_text: "Vodič začal prepravu zákazníka ",
      finishedAt_title: "Vodič ukončil jazdu",
      finishedAt_text: "Vodič doviezol zákazníka do požavaného cieľa cesty.",
      chargedAt_title: "Vodič pripravil účet",
      chargedAt_text: "Vodič spracoval finálny účet. V prípade, ak má takú možnosť povolenú, tak mohol upraviť cenu za jazdu alebo príplatky.",
      settledAt_title: "Ukončené vyúčtovanie jazdy",
      settledAt_text: "Objednávka bola zaplatená.",
      interruptedAt_title: "Zrušená objednávka",
      interruptedAt_text: "Objednávka bola zrušená.",
      //POZADOVANY CAS VYZDVIHNUTIA
      requestedTimeInstant_title: "Požadovaný čas vyzdvihnutia - okamžite",
      requestedTimeInstant_text: "Čas vyzdvihnutia je pri časovej objednávke v budúcnosti, pri okamžitej jazde sa zhruba zhoduje s časom vytvorenia.",
      orderSentLogsTime_title: "Požadovaný čas vyzdvihnutia",
      orderSentLogsTime_text: "Čas vyzdvihnutia pri časovej objednávke",
      //CHAT
      chatList_title: "Správy / Chat",
      //WAYPOINT
      waypointIssue_title: "Neskorý príchod na miesto vyzdvihnutia zákazníka",
      waypointIssue1_text: "Vodič prišiel na miesto o ",
      waypointIssue2_text: " minút neskôr ako bol požadovaný čas vyzdvihnutia.",
      waypointIssue_detail: "Požadovaný čas: ",
      waypointListBoard_title: "Miesto vyzdvihnutia",
      waypointListDestination_title: "Cieľ cesty",
      waypointListTransit_title: "Medzizastávka",
      //WAITING
      waitingListStartedAt_title: "Zapnutie čakačky",
      waitingListStartedAt_text: "Vodič zapol čakačku",
      waitingListFinishedAt_title: "Vypnutie čakačky",
      waitingListFinishedAt1_text: "Vodič vypol čakačku v trvaní ",
      waitingListFinishedAt2_text: "min. (presná hodnota ",
      //ORDER LOGS
      orderLogList_title: "Log záznamy",
      //ORDER SEND LOGS
      orderSentLogs_title: "Zaslanie objednávky",
      orderSentLogs1_text: "Objednávka bola zaslaná (",
      orderSentLogs2_text: ") vodičovi ",
      orderSentLogs3_text: " ktorý bol od miesta vyzdvihnutia vzdialený  ",
      orderSentLogs4_text: "",
    },
  },

  Console: {
    panelActions: "Aktivity",
    panelActions_title: "Minimalizovať/maximalizovať Panel aktivít",
    tabNew: "Nová",
    tabDetail: "Detail",
    tabMessages: "Správy",
    tabSettings: "Nastavenia",
    tabNew_title: "Nová objednávka",
    tabDetail_title: "Detail objednávky",
    tabMessages_title: "Správy / Chat",
    tabSettings_title: "Nastavenia",
    panelInfo: "Informácie",
    infoUtilizationRatio_title: "Vyťaženosť vodičov",
    infoLoadRatio_title: "Index záťaže taxislužby",
    infoImmediateAvailabilityRatio_title: "Index okamžitej dostupnosti",
    infoCountDriversInStand_title: "Počet voľných taxíkov na stanovištiach",
    infoListOfDriversPerStand_title: "Zoznam voľných vodičov na stanovištiach",
    infoListOfDriversWorkRequests_title: "Zoznam vodičov s požiadavkami na prestávky a ukončenie práce",
    infoCounterPENDING_title: "Objednávky na priradenie",
    infoSumPerformedOrders_title: "Realizované objednávky",
    infoSumBusyDrivers_title: "Počet zaneprázdnených vodičov / Vodiči s viac ako 1 objednávkou",
    infoCounterOnlineFREE_title: "Počet online voľných vodičov",
    infoCounterON_BREAK_title: "Počet vodičov na prestávke",
    msgTestTimeProblem: "Nesprávny čas na PC. Skontrolujte či máte na PC nastavený správny čas a časovú zónu, prípadne letný/zimný čas.",
    msgInternetProblemDevice: "Zariadenie nie je pripojené na internet. Zobrazujú sa posledné zistené údaje. Objednávky sa nedajú aktualizovať ani zrušiť. Skúste sa pripojiť alebo počkajte kým bude dostupné internetové pripojenie.",
    msgInternetProblemServer: "Údaje zo servera nie sú dostupné. Možno máte výpadok internetu alebo je výpadok v datacentre. Zobrazujú sa len posledné zistené údaje. Objednávky sa nedajú aktualizovať ani zrušiť. Počkajte kým bude server opäť dostupný alebo bude dostupné internetové pripojenie.",
    msgInternetProblemConnection: "Nemáte dostupný internet. Možno máte výpadok poskytovateľa internetových služieb alebo je problém v routeri. Zobrazujú sa len posledné zistené údaje. Objednávky sa nedajú aktualizovať ani zrušiť.",
    msgInternetBackOnline: "Internetové pripojenie je opäť dostupné.",
    msgNoWorklogRequests: "Žiadne požiadavky na prestávky alebo ukončenie práce.",
    msgWorklogRequestNotConfirmedTooLong: "Požiadavka na prestávku alebo ukončenie práce vodiča nebola potvrdená už niekoľko minút.",
    btnChatOk: "OK",
    btnChatCalling: "Volám",

    //Zalozka Settings
    settings: {
      onlineStatus_subtitle: "Online stav taxislužby",
      utilizationRatio: "Vyťaženosť vodičov",
      utilizationRatio_title: "Percento zaneprázdnených voči aktívnym vodičom.",
      utilizationRatio_sumActiveDrivers: "Všetci",
      utilizationRatio_sumBusyDrivers: "Obsadení",
      utilizationRatio_counterOnlineFREE: "Voľní",
      utilizationRatio_counterON_BREAK: "na prestávke",
      loadRatio: "Index záťaže taxislužby",
      loadRatio_title: "Pomer Aktívnych objednávok (realizované + čakajúce)  / Aktívnych vodičov.",
      loadRatio_sumActiveOrders: "Všetky jazdy",
      loadRatio_counterPENDING: "Na priradenie",
      loadRatio_sumPerformedOrders: "Realizované",
      immediateAvailabilityRatio: "Index okamžitej dostupnosti",
      immediateAvailabilityRatio_title: "Pomer objednávok na pridelenie a voľných vodičov.",
      immediateAvailabilityRatio_counterOnlineFREE: "Voľní vodiči",
      immediateAvailabilityRatio_counterON_BREAK: "Na prestávke",
      immediateAvailabilityRatio_counterDriversMoreThanOneOrder: "Obsadení viac obj.",
      settings_subtitle: "Nastavenia",
      darkGui: "Tmavý režim rozhrania",
      darkMap: "Tmavý režim mapy",
      playNotificationSound: "Zvuk chat notifikácií",
      playNotificationSoundFile: "Zvučka notifikácií",
      ignoreSystemMessages: "Nezobrazovať systémové notifikácie",
      showInfoPanel: "Infomačný panel",
      driverSpeed: "Rýchlosť vozidiel",
      showPendingOrders: "Vyzdvihnutia čakajúcich objednávok",
      pathDriver: "Čiary trás vozidiel",
      iconDriver: "Ikony vozidiel",
      iconDriverLabel: "Označenie vozidiel",
      btnAutomatSwitch: "Automat",
      btnAutomatSwitch_title: "Prepnúť automat",
      btnPbxSwitch: "Ústredňa",
      btnPbxSwitch_title: "Prepnúť telefónnu ústredňu",
      btnDatacenterStatus: "Cloud",
      btnDatacenterStatus_title: "Zistiť stav datacentra",
      btnHelp_title: "Nápoveda ku konzole",
      msgGetAutomatStatusError: "Chyba načítania informácií o nastaveniach automatu",
      msgChangeAutomatSuccess: "Nastavenia automatu boli úspešne zmenené",
      msgChangePbxSuccess: "Mód telefónnej ústredne bol úspešne zmenený",
      msgChangePbxUnchanged: "Mód telefónnej ústredne sa nezmenil",
      msgChangePbxError: "Chyba pri zmene módu telefónnej ústredne",
      msgChangePbxModuleDisabled: "Modul bezdispečerskej telefónnej ústredne je vypnutý",
      pathDriverSettings: [
        { "text": "Nezobrazovať", "value": "NEVER" },
        { "text": "Posledných 5 min. prepravy", "value": "INPROGRESS05MIN" },
        { "text": "Posledných 10 min. prepravy", "value": "INPROGRESS10MIN" },
        { "text": "Stále počas prepravy", "value": "INPROGRESS" },
        { "text": "Počas čakania a prepravy", "value": "WAITING" },
        { "text": "Vždy zobrazovať", "value": "ALWAYS" },
      ],
      showPendingOrdersSettings: [
        { "text": "Nezobrazovať", "value": "NEVER" },
        { "text": "Špendlík", "value": "PIN" },
        { "text": "Špendlík a trasa", "value": "PINLINE" },
        { "text": "Špendlík a červená trasa", "value": "PINLINERED" },
      ],
      iconDriverSettings: [
        { "text": "Špendlík", "value": "PIN" },
        {
          "text": "Autíčko",
          "value": "SIMPLECAR",
        },
        {
          "text": "Kruh",
          "value": "CIRCLE"
        },
      ],
      iconDriverSettingsOSM: [
        { "text": "Značka", "value": "TAG" },
        //{text: "3D autíčko",value: "SVGCAR",},
      ],
      iconDriverLabelSettings: [
        { "text": "Interné označenie vozidla", "value": "VEHICLEINTERNAL" },
        {
          "text": "Interné označenie vodiča",
          "value": "DRIVERINTERNAL",
        },
        {
          "text": "Iniciály vodiča",
          "value": "DRIVERNAME"
        },
      ],
      notificationSoundSettings: [
        { "text": "Zvuk 1 (default)", "value": "got-it-done.mp3" },
        { "text": "Zvuk 2", "value": "imo_sms_tone_2016.mp3" },
        { "text": "Zvuk 3", "value": "message_sound.mp3" },
        { "text": "Zvuk 4", "value": "nice_sms.mp3" },
        { "text": "Zvuk 5", "value": "short_alert.mp3" },
      ],
    },
    automatEnableDialog: {
      title: "Prajete si zapnúť automat?",
      text: "Objednávky budú automaticky zasielané vodičom podľa nastavených pravidiel automatu.",
      btnYes: "Zapnúť automat",
    },
    automatDisableDialog: {
      title: "Prajete si vypnúť automat?",
      text: "Objednávky budú môcť byť zasielané vodičom iba manuálne.",
      btnYes: "Vypnúť automat",
    },
    pbxDialog: {
      title: "Prajete si zmeniť režim telefónnej ústredne?",
      text: "Telefonáty môžu byť smerované na dispečerov alebo vodičov.",
      btnPbxDispatching: "Dispečing",
      btnPbxNonDispatching: "Vodiči",
      btnPbxOffline: "Vypnúť",
    },
  },

  ConsoleMap: {
    markerTitle_2Active: "2 aktívne objednávky",
    markerTitle_3Active: "3 aktívne objednávky",
    markerTitle_4Active: "4 aktívne objednávky",
    markerTitle_timeTypeWarning: "Vodič má ďalšiu objednávku, ktorá je časovka",
    markerTitle_silenceEnabled: "Tichý režim zapnutý",
    markerTitle_preferredDriverInconsistencyActual: "Aktuálna jazda je priradená inému vodičovi ako preferovaný",
    markerTitle_preferredDriverInconsistencyOther: "Iná jazda je priradená inému vodičovi ako preferovaný",
    markerTitle_worklogRequestOnbreakNew: "Nová požiadavka na prestávku",
    markerTitle_worklogRequestOnbreakConfirmed: "Schválená požiadavka na prestávku",
    markerTitle_worklogRequestOfflineNew: "Nová požiadavka na ukončenie práce",
    markerTitle_worklogRequestOfflineConfirmed: "Schválená požiadavka na ukončenie práce",
    markerTitle_outdatedPosition: "Dlhší čas nemáme aktuálnu polohu",
    markerTitle_timeToDestination: "Vyloženie zákazníka za",
    markerTitle_driverSpeed: "Rýchlosť vozidla",
    markerTitle_timeFromLastOrder: "Čas od ukončenia poslednej jazdy",
    markerTitle_streetOrder: "Jazda z ulice",
    markerTitlePlace_board: "Miesto vyzdvihnutia zákazníka",
    markerTitlePlace_destination: "Cieľ cesty",
    markerTitlePlace_transit1: "1. medzizastávka",
    markerTitlePlace_transit2: "2. medzizastávka",
    markerTitlePlace_transit3: "3. medzizastávka",
    markerTitlePlace_transit4: "4. medzizastávka",
    infoWindow_btnSetDriver: "Nastaviť tohto vodiča v poli na záložke DETAIL objednávky",
    infoWindow_btnMessageDriver: "Poslať vodičovi správu",
    infoWindow_btnDetail: "Zobraziť detail objednávky",
    infoWindow_btnMessageDriverClient: "Poslať správu Zákazníkovi a Vodičovi k objednávke",
    msgFindDriverOnMap1: "Vodič s ID",
    msgFindDriverOnMap2: "sa nenachádza na mape.",
    msgSetDriverToDetailFromMapSuccess: "Vodič pridaný do detailu objednávky...",
    msgSetDriverToDetailFromMapError: "Nenájdený žiadny vhodný voľný blízky vodič...",
    markerOrderTitle_durationSinceTryingAssign: "Dĺžka prideľovania",
    markerOrderTitle_addresses: "Adresy",
    markerOrderTitle_customer: "Zákazník",
    markerOrderTitle_driver: "Vodič",
    markerOrderTitle_pbx: "Ústredňa",
    markerOrderTitle_preferredDriver: "Preferovaný vodič",
    markerOrderTitle_dispatch: "Dispečing",
    markerOrderTitle_time: "Časovka",
    markerOrderTitle_instant: "Okamžitá",
    defaultStand: "Predvolené stanovište",
    stand: "Stanovište",
    capacity: "Kapacita",
    radius: "Rádius",
  },

  ConsoleOrderNew: {
    //niektor tlacidla su duplicitne oproti standardnym tlacidlam, lebo konzola môže mať iné popisky a by boli priestorovo efektivnejsie
    btnCreate: "Vytvoriť",
    btnClear: "Vyčistiť",
    phone: "Telefónne číslo",
    phoneRules: "Telefónne číslo musí byť v tvare +421XXXXXXXXX", //Phone number has to be numbers only with + prefix
    btnGetClientByPhone_title: "Vyhľadať podľa telefónneho čísla",
    btnGetClientByExtension_title: "Vyhľadať podľa prebiehajúceho hovoru na ústredni",
    extension: "Linka/klapka",
    extensionExpand: "Rozbalit klapky na tlačidlá",
    extensionCollapse: "Skryť tlačidlá s klapkami",
    nickname: "Meno zákazníka",
    btnOpenEditClientDialog_title: "Upraviť údaje o zákazníkovi",
    btnOpenInfoClientDialog_title: "Informácie o zákazníkovi",
    btnOpenHistoryClientDialog: "História objednávok zákazníka",
    clientInfo: "Informácie o zákazníkovi",
    clientMoreAccounts: "ďalšie", //more
    clientNote: "Poznámka o zákazníkovi",
    countryCode: "Krajina",
    countryCode_title: "Krajina",
    countryCode_prepend_title: "Vyčistite polia kajiny a mesta",
    city: "Mesto",
    city_title: "Mesto",
    boardAddress: "Adresa vyzdvihnutia",
    transitAddresses: "Medzizastávky",
    transit1Address: "1. Adresa medzizastávky",
    transit2Address: "2. Adresa medzizastávky",
    transit3Address: "3. Adresa medzizastávky",
    transit4Address: "4. Adresa medzizastávky",
    destinationAddress: "Adresa cieľa",
    radioBtnInstant: "Okamžite",
    radioBtnTime: "Neskôr",
    preferredDriverId: "Preferovaný vodič",
    preferredDriverId_title: "Vodič, ktorý bude mať prednosť pri priradení objednávky, ale nie je to garantované",
    radioBtnClient: "Osobne",
    radioBtnAccount: "Firma",
    accountId: "Firma",
    paymentType: "Spôsob platby",
    requestedTime: "Požadovaný čas",
    presendTime: "Zasielať vopred",
    btnAddRequestedTimeToNote: "Pridať požadovaný čas do poznámky",
    btnAddClientPhoneToNote: "Pridať telefónne číslo do poznámky v objednávke",
    note: "Poznámka",
    noteInsertTime: "Čas",
    noteInsertAccount: "Firma",
    noteInsertClientPhone: "Tel",
    orderFilterHeader: "Požiadavky na jazdu",
    orderFilter: {
      animal: "Zviera",
      airconditioning: "Klimatizácia",
      luggage: "Batožina",
      autonomous: "Autonomná jazda",
      paymentTerminal: "Platobný terminál",
      childrenChair: "Preprava dieťaťa",
      driverRating: "Hodnotenie vodiča",
      economy: "Eko jazda",
      maxPerson: "Počet cestujúcich",
      specialTransport: "Špeciálna preprava",
    },
    onlyManualSending: "Zasielanie objednávky",
    createdClientDialog_title: "Nový zákazník",
    editClientDialog_title: "Upraviť zákazníka",
    infoClientDialog_title: "Informácie o zákazníkovi",
    historyClientDialog_title: "História objednávok zákazníka",
    createOrderDialog_title: "Vytvoriť novú objednávku",
    successCreateOrderDialog_title: "Úspešne vytvorená nová objednávka",
    setAccountIdFromClientDialog_title: "Nastaviť firmu zákazníka",
    showOrderDetailDialog_title: "Detail objednávky",
    showPhoneHistoryDialog_title: "História posledných telefónnych čísiel",
    mark: "Označenie",
    notification: "Notifikácie",
    virtualAssistant: "Virtuálny asistent",
    rating: "Hodnotenie",
    driveCounter: "Počet jazd",
    accounts: "Firmy",
    accountName: "Názov firmy",
    accountClientStatus: "Stav používateľa vo firme",
    msgPhoneCrountryAlert1: " Telefónne číslo je pravdepodobne z INEJ KRAJINY (",
    msgPhoneCrountryAlert2: "). Prosím, nastavte notifikáciu, či chcete zasielať SMS.",
    msgPhoneLandlineAlert: "Telefónne číslo je pravdepodobne PEVNÁ LINKA. Prosím, nastavte notifikáciu, či chcete zasielať SMS.",
    msgOrderCreateSuccess: "Objednávka bola úspešne vytvorená.",
    msgOrderCreateText: "Chcete ponechať dáta formulára (Zavrieť), vytvoriť novú čistú objednávku alebo načítať nový hovor pre novú čistú objednávku?",
    autoHideSuccessCreateOrderDialogSwitch: "Automaticky skryť a vyčistiť formulár pre novú objednávku po 2 sekunách",
    btnNewOrder: "Nová objednávka",
    btnGetClientByExtension: "Načítať hovor",
    btnShowOrderDetail_title: "Detail práve vytvorenej novej objednávky",
    msgSetAccountIdText: "Klient má povolené firemné jazdy! Chcete vytvoriť klientovi objednávku s predvolenou firmou?",
    alwaysShowAccountAlertDialog_label: "Zobrazovať upozornenie",
    alwaysShowAccountAlertDialog_title: "Zobrazovať popup okno s upozornením, že klient má povolené firemné jazdy",
    btnPaymentPrivate: "Súkromná objednávka",
    btnPaymentAccount: "Firemná objednávka",
    msgShowOrderDetailText: "Klient má prebiehajúcu objednávku! Chcete zobraziť detail objednávky?",
    alwaysShowOrderDetailAlertDialog_label: "Zobrazovať upozornenie",
    alwaysShowOrderDetailAlertDialog_title: "Zobrazovať popup okno s upozornením, že klient má prebiehajúcu objednávku",
    btnShowOrderDetail: "Detail objednávky",
    rulesPresendTime1: "Min. 1min", //Time should be above 1 minute
    rulesPresendTime2: "Max. 240min", //Max should not be above 240 minutes (4 hours)
    presendTimeChip_title: "Kliknete na nastavenie zasielania vopred",
    rulesNote: "Poznámka môže mať maximálne 80 znakov", //Max 80 characters
    btnActionHideText: "Skryť",
    btnActionShowText: "Zobraziť",
    msgActionShowFullForm: "Chcete skúsiť ROZŠÍRENÉ MOŽNOSTI okna Novej objednávky?",
    msgReverseGeoAlertNoResults: "Nenašli sa žiadne výsledky",
    msgNoExtensionSelected: "Nebola vybraná žiadna Klapka",
    msgNoCallOnExtension: "Na klapke nie je žiadny hovor",
    msgCreateNewCustomer: "Zaevidujte nového klienta!",
    msgPhoneGateError: "Chyba pri komunikácii s telefónnou ústredňou!",
    msgPhoneGateErrorOther: "Chyba pri komunikácii s ústredňou!",
    msgPhoneNumberMissing: "Chýba telefónne číslo!",
    msgPhoneNumberMissingPlus: "Telefónne číslo musí začínať znakom +",
    msgPhoneNumberShort: "Telefónne číslo je príliš krátke, napríklad použite medzinárodný formát a aspoň 8 číslic!",
    msgPhoneNumberNotNumbers: "Telefónne číslo môže obsahovať po znaku + iba čísla!",
    msgPhoneNumberSecondNotNumber: "Telefónne číslo je neplatné!",
    msgDisabledModuelDispatchingCreateOrder: "Modul vytvárania objednávok je vypnutý!",
    msgMissingClientOrNumber: "Nie je vyhladany klient alebo telefónne číslo nie je platné.",
    msgClientPhoneNotMatch: "Telefónne číslo klienta sa nezhoduje s vyhľadaným telefónnym číslom. Možno ste ho prepísali a nevyhľadali ste klienta podľa nového čísla.",
    msgMissingClient: "Nie je vyhľadany klient.",
    msgClientNoteNotChanged: "Poznámka klienta sa nezmenila.",
    msgMissingBoardDestinAddress: "Nemáte vyhľadnú adresu vyzdvihnutia alebo cieľa cesty.",
    msgGetPaymentListError: "Nastala chyba pri načítavaní možností platby. Skontrolujte či sú nakonfigurované platobné metódy.",
    msgFilterPaymentTerminalYes: "Pridaná požiadavka na platobný terminál vo vozidle",
    msgFilterPaymentTerminalAny: "Zrušená požiadavka na platobný terminál vo vozidle",
    msgClientPendingOnAccount: "Firma bola pridaná, ale zákazník ešte nebol v danej firme schválený.",
    msgDefaultAccountIsNotValid: "Prednastavená firma nie je platná. Vyberte si prosím firmu z ponuky a oznámte manažérovi taxislužby, aby skontroloval firemného zákazníka.",
    msgAccountNotSelected: "Vybrali ste platbu na firmu, ale nevybrali ste firmu.",
    msgShortDistanceAddresses: "Vzdialenosť medzi bodmi je príliš malá! Vzdialenosť musí byť aspoň 10m.",
    msgTimeInPastError: "Čas vyzdvihnutia nemôže byť v minulosti!",
    msgTimeTooSoonError: "Čas vyzdvihnutia je príliš skoro! Zadajte neskorší čas (aspoň o 5 minút)!",
    msgTimeTooLateError: "Zadaný čas je viac ako 2 týždne v budúcnosti!",
    msgCreateOrderError: "Nastala chyba pri vytváraní objednávky!",
    msgDuplicateOrder: "Objednávka pre daného zákazníka už existuje!",
    msgAddReverseGeoLimitBan: "Často vyhľadávate adresy priamo z mapy. Prekročili ste maximálny počet takýchto adries. Funkcia na pridávanie nových adries je pre Vás dočasne zablokovaná.",
    msgClientBlocked: "Klient je blokovaný!",
    msgClientDebtor: "Klient je dlžník!",
    msgClientVip: "Klient je VIP!",
    msgUpdateClientSuccess: "Úspešne aktualizované!",
    msgUpdateClientError: "Nastala chyba pri aktualizovaní!",
    msgPresendTimeNotSufficient: "Odporúčaný čas zasielania vopred je dlhší ako predvolený čas. Zvážte ho upraviť!",
    msgBoardAddressFarFromStand: "Adresa vyzdvihnutia je ďalej ako zvyčajne!",
  },

  onlyManualSendingArray: [
    {
      "text": "Východiskové nastavenie (default)",
      "value": null,
    }, {
      "text": "Iba manuálne zasielanie",
      "value": true,
    }, {
      "text": "Zasielanie automatom (ak je zapnutý)",
      "value": false,
    },

  ],

  ConsoleOrderList: {
    panelOrderList: "Objednávky",
    panelOrderList_title: "Minimalizovať/maximalizovať Panel s objednávkami",
    searchField: "Hľadať",
    searchField_title: "Vyhľadávanie v zozname objednávok potvrďte stlačením klávesy Enter.",
    showOnlyActiveOrder_title: "Zobraziť iba Aktívne objednávky",
    showPlannedOrder_title: "Zobraziť všetky Plánované objednávky",
    hidePlannedOrder_title: "Skryť Plánované objednávky (zobrazené budú iba časovky prideľované do 30 minút)",
    onlyNotApprovedSwitch_title: "Zobraziť iba Adresy určené na schválenie",
  },

  ConsoleOrderListItem: {
    preferredDriver: "Preferovaný vodič",
    requestedTime: "Požadovaný čas vyzdvihnutia",
    onlyManualSending_true: "Zobraziť detail objednávky a manuálne prideliť vodičovi",
    onlyManualSending_false: "Zobraziť detail objednávky",
    changeStatusDialog_title: "Zmena stavu objednávky",
    changeStatusDialog_text: "Zmena stavu objednávky môžete urobiť iba pre Nové objednávky.",
    btnAccept: "Prijať objednávku",
    btnAbort: "Zrušiť objednávku",
  },

  ConsoleOrderWaypointEdit: {
    btnEditWaypoint: "Adresy",
    btnEditWaypoint_title: "Úprava adries a prejazdových bodov objednávky",
    dialogTitle: "Upraviť adresy",
    id_short: "ID",
    status: "Stav",
    createdAt: "Vytvorená",
    boardAddress: "Adresa vyzdvihnutia",
    transit1Address: "Adresa prejazdového bodu 1",
    transit2Address: "Adresa prejazdového bodu 2",
    transit3Address: "Adresa prejazdového bodu 3",
    transit4Address: "Adresa prejazdového bodu 4",
    destAddress: "Adresa cieľa cesty",
    msgPriceUpdated: "Cena bola úspešne prepočítaná.",
    msgPriceNotUpdated: "Cenu sa nepodarilo prepočítať.",
  },

  ConsoleOrderEdit: {
    btnEditOrder: "Upraviť",
    btnEditOrder_title: "Upraviť objednávku",
    dialogTitle: "Úprava objednávky",
    id_short: "ID",
    status: "Stav",
    createdAt: "Vytvorená",
    msgSuccess: "Objednávka bola úspešne aktualizovaná",
    msgError: "Objednávku sa nepodarilo aktualizovať!",
    msgUnavailable: "Pre tento typ alebo stav objednávky nie je možné upravovať objednávku!",
    msgInvalidDataInForm: "Údaje v formulári sú neplatné!",
    list: {
      subheaderTime: "Čas objednávky",
      updateToInstantDialog: "Zmeniť na okamžitú jazdu",
      updateRequestedTimeDialog: "Upraviť čas vyzdvihnutia",
      updateRequestedTimeDialog_toTime: "Zmeniť na časovku",
      updatePresendTimeDialog: "Upraviť čas zaslanie vopred",
      subheaderStatus: "Stav objednávky",
      abortOrderDialog: "Zrušiť jazdu",
      updateSendingActivateDialog: "Aktivovať na odosielanie",
      updateSendingDeactivateDialog: "Deaktivovať odosielanie",
      clearDriverDialog: "Odobrať jazdu",
      subheaderSettings: "Nastavenia objednávky",
      updateNoteDialog: "Upraviť poznámku",
      updatePaymentDialog: "Upraviť spôsob platby alebo firemný účet",
      updateFilterDialog: "Upraviť filtre/požiadavky na jazdu",
      updateSettingsDialog: "Upraviť nastavenia zobrazenia a zasielania objednávky",
      updatePriceDialog: "Prepočítať cenu",
      confirmNewDialog: "Potvrdiť novú objednávku",
    },
    //zrusenie
    abortOrderDialog_title: "Prajete si Zrušiť objednávku?",
    abortOrderDialog_text: "Pri zrušením objednávky nebude objednávky doručovaná na vodičov. Ak o jazdu nepožiadal zákazník, oznámte mu zrušenie jeho objednávky.",
    abortOrderDialog_btnAbort: "Zrušiť objednávku",
    abortedReason: "Dôvod zrušenia",
    abortNote: "Poznámka",
    //aktivovanie
    updateSendingActivateDialog_title: "Prajete si Aktivovať objednávku na odosielanie?",
    updateSendingActivateDialog_text: "Pri aktivovaní objednávky bude objednávka doručovaná na vodičov. (automatom alebo ručne podľa nastavení)",
    updateSendingActivateDialog_btnActivate: "Aktivovať na odosielanie",
    //deaktivovanie
    updateSendingDeactivateDialog_title: "Prajete si Deaktivovať objednávku na odosielanie?",
    updateSendingDeactivateDialog_text: "Pri deaktivovaní objednávky nebude objednávka doručovaná na vodičov. Zmení sa jej stav na neskoršie odosielanie. Podľa nastavenia automatu sa môže stať, že ju opäť aktivuje na odosielanie.",
    updateSendingDeactivateDialog_btnDeactivate: "Deaktivovať odosielanie",
    //na okamzitu
    updateToInstantDialog_title: "Prajete si zmeniť objednávku na okamžitú jazdu?",
    updateToInstantDialog_text: "Pri zmene objednávky na okamžitú jazdu bude objednávka doručovaná na vodičov IHNEĎ.",
    updateToInstantDialog_btnToInstant: "Zmeniť na okamžitú jazdu",
    //na casovku
    updateRequestedTimeDialog_title: " Prajete si zmeniť objednávku na časovku alebo zmeniť čas vyzdvihnutia?",
    updateRequestedTimeDialog_text: "Pri zmene objednávky na časovku bude objednávka doručovaná na vodičov v čase, ktorý ste zadali. Pre odosielanie vopred nastavte aj čas zasielania vopred v minutách.",
    updateRequestedTimeDialog_btnToTime: "Zmeniť",
    requestedTime: "Požadovaný čas vyzdvihnutia",
    msgRequestedTimePastError: "Čas vyzdvihnutia musí byť v budúcnosti!",
    msgRequestedTimeFarFutureError: "Čas vyzdvihnutia musí byť do 14 dní!",
    //cas zaslanie vopred
    updatePresendTimeDialog_title: "Prajete si zmeniť čas, kedy sa má objednávka začat vopred odosielať?",
    updatePresendTimeDialog_text: "Objednávka bude doručovaná zvolený počet minút vopred.",
    updatePresendTimeDialog_btnUpdate: "Zmeniť",
    presendTime: "Čas zaslanie vopred",
    rulesPresendTime1: "Čas musí byť viac ako 1 minúta", //Time should be above 1 minute
    rulesPresendTime2: "Čas musí byť menej ako 240 minút (4 hodiny)", //Max should not be above 240 minutes (4 hours)
    msgPresendTimeNullError: "Čas zaslanie vopred musí byť vyplnený!", //Presend time must be filled
    //Odobratie objednavky od vodica
    clearDriverDialog_title: "Prajete si odobrať objednávku od vodiča?",
    clearDriverDialog_text: "Pri odobratí objednávky od vodiča sa objednávka vráti do stavu odosielanie. ",
    clearDriverDialog_btnClear: "Odobrať objednávku",
    //poznamka  
    updateNoteDialog_title: "Praje si upraviť poznámku?",
    updateNoteDialog_text: "Pri zmene poznámky sa zmení poznámka v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updateNoteDialog_btnUpdate: "Upraviť poznámku",
    note: "Poznámka",
    rulesNote: "Poznámka môže mať maximálne 80 znakov", //Max 80 characters
    //platba
    updatePaymentDialog_title: "Prajete si upraviť platobné informácie?",
    updatePaymentDialog_text: "Pri zmene platobných informácií sa zmení platobná metóda a firemný účet v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updatePaymentDialog_btnUpdate: "Upraviť platbu",
    paymentType: "Spôsob platby",
    accountId: "Firemný účet",
    //poziadavky na jazdu
    updateFilterDialog_title: "Prajete si upraviť Požiadavky na jazdu?",
    updateFilterDialog_text: "Pri zmene požiadaviek na jazdu sa zmení požiadavky na jazdu v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updateFilterDialog_btnUpdate: "Upraviť požiadavky",
    animal: "Zviera",
    airconditioning: "Klimatizácia",
    luggage: "Batožina",
    autonomous: "Autonomná jazda",
    paymentTerminal: "Platobný terminál",
    childrenChair: "Preprava dieťaťa",
    driverRating: "Hodnotenie vodiča",
    economy: "Eko jazda",
    maxPerson: "Počet cestujúcich",
    specialTransport: "Špeciálna preprava",
    //nastavenia
    updateSettingsDialog_title: "Prajete si upraviť nastavenia na zasielanie objednávky a zobrazenie informácií v aplikácii vodiča?",
    updateSettingsDialog_text: "Pri zmene nastavení sa zmení nastavenie v objednávke. U vodiča sa zmena neprejaví hneď, ale napríklad pri zmene stavu objednávky alebo reštarte aplikácie.",
    updateSettingsDialog_btnUpdate: "Upraviť nastavenia",
    showPhone: "Vodičovi zobraziť telefónne číslo zákazníka",
    showDriverPhoneButton: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses: "Vodičovi zobraziť všetky adresy",
    onlyManualSending: "Iba manuálne zaslanie objednávky",
    forcedPickupRadius: "Vynútenie vyzdvihnutia v okruhu (0 = vypnuté)",
    showPhone_title: "Na zobrazenie telefónneho čísla je potrebné, aby si vodič reštartoval aplikáciu alebo zmenil stav objednávky.",
    showDriverPhoneButton_title: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses_title: "Na zobrazenie telefónneho čísla je potrebné, aby si vodič reštartoval aplikáciu alebo zmeni stav objednávky",
    onlyManualSending_title: "Používa sa pre automat, aby neposielal objednávku automaticky",
    forcedPickupRadius_title: "Ak je 0, vodič môže kdekoľvek zmeniť stav objednávky, že je na mieste vyzdvihnutia. Ak je napr. 200, vodič pri príchode musí byť maximálne 200 metrov od polohy adresy vyzdvihnutia.",
    rulesForcedPickupRadius1: "Musí byť 0 alebo viac", //Should be 0 or more
    rulesForcedPickupRadius2: "Maximálne 1000m", //Max 1000m
    //cena
    updatePriceDialog_title: "Prajete si prepočítať cenu objednávky?",
    updatePriceDialog_text: "Pri prepočítaní ceny môžu vzniknúť negatívne dopady na zákazníka. Preto dobre zvážte, či chcete prepočítať cenu objednávky. Po zmene ceny je potrebné novú očakávanú cenu oznámiť primo zákazníkovi, či s tým súhlasí.",
    updatePriceDialog_btnUpdate: "Prepočítať cenu",
    msgPriceUpdated: "Cena bola úspešne prepočítaná.",
    msgPriceNotUpdated: "Cenu sa nepodarilo prepočítať.",
    //potvrdenie Novej objednavky
    confirmNewDialog_title: "Prajete si potvrdiť novú objednávku?",
    confirmNewDialog_text: "Pri vytvorení objednávky spavidla z aplikácie Zákazníka, ak nie je nastavené automatické schválenie, tak je potrebné objednávku Schváliť. Ak objednávku neschválite alebo odmietnete, nebude posielaná na vodičov.",
    confirmNewDialog_btnConfirmNew: "Schváliť",
    confirmNewDialog_btnAbortNew: "Odmietnuť a zrušiť",
  },

  ConsoleOrderDetail: {
    id: "Č. objednávky",
    id_short: "ID",
    status: "Stav",
    presendTime: "Zaslanie vopred:",
    requestedTime: "",
    clientNone: " - žiadny zákazník - ",
    clientId: "Č. zákazníka:",
    nicknameClient: "",
    phoneClient: "",
    mark: "",
    ratingClient: "",
    noteClient: "",
    driverNone: " - žiadny vodič - ",
    driverId: "Č. vodiča:",
    firstName: "",
    lastName: "",
    nicknameDriver: "",
    phoneDriver: "",
    ratingDriver: "",
    driveCounter: "",
    vehicleId: "Č. vozidla:",
    brand: "",
    model: "",
    lpn: "",
    color: "",
    vehicleType: "",
    internalName: "",
    preferredDriverFirstName: "Pref: ",
    preferredDriverLastName: "",
    paymentType: "Platba:",
    account: "Firemný zákazník:",
    note: "Poznámka:",
    orderFilter: "Požiadavky na jazdu",
    displayAndSend: "Zobrazenie a zaslanie objednávky",
    timeChanged: "Čas zmeny stavu objednávky",
    createdAt: "Vytvorená",
    plannedAt: "Naplánovaná",
    pendingAt: "Čaká na priradenie",
    acceptedAt: "Akceptovaná",
    waitingAt: "Čaká na zákazníka",
    progressAt: "Začatá preprava",
    finishedAt: "Ukončená preprava",
    chargedAt: "Pripravený účet",
    settledAt: "Zaplatená objednávka",
    interruptedAt: "Zrušená",
    archivedAt: "Archivovaná",
    showPrice: "Zobrazenie ceny vodičovi a zákazníkovi",
    showPhone: "Vodičovi zobraziť telefónne číslo zákazníka",
    showDriverPhoneButton: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses: "Vodičovi zobraziť všetky adresy",
    onlyManualSending: "Iba manuálne zaslanie objednávky",
    forcedPickupRadius: "Vynútenie vyzdvihnutia v okruhu (0 = vypnuté)",
    btnSendOrderToDriver: "Poslať",
    btnUpdatePreferreDriver: "Preferovať",
    btnFindDriverOnMap: "Nájsť",
    btnShowEditDriverWorkShiftDialog: "Práca",
    btnShowMessage: "Chat",
    btnDetailRefresh: "Aktual.",
    btnAutocompleteDriverRemote: "Vodič",
    btnAutocompleteDriverRemote_placeholder: "Začnite písať...",
    //btnEditOrder: "Upraviť",
    //btnEditOrderWaypoint: "Adresy",
    price: "Cena",
    priceName: "Názov",
    priceEstimated: "Predpoklad",
    priceFinal: "Final",
    priceInfo: "Info",
    distance: "Vzdialenosť",
    time: "Čas",
    priceStart: "Za vyzdvihnutie",
    priceWaiting: "Za čakanie pred jazdou",
    priceDriveDistance: "Za vzdialenosť prepravy",
    priceDriveTime: "Za čas prepravy",
    priceDrive: "Za prepravu",
    priceJourney: "Za jazdu",
    priceExtra: "Príplatky",
    priceDiscount: "Zľava",
    priceTotal: "Celková cena",
    sentOrderLogList: "Zasielanie objednávok na vodičov",
    tbl_driver: "Vodič",
    tbl_sent: "Odoslané",
    tbl_sent_short: "Odosl.",
    tbl_distance: "Vzdialenosť",
    tbl_distance_short: "Vzd.",
    tbl_time: "Čas",
    waitingList: "Čakanie na zákazníka",
    tbl_startedAt: "Začiatok",
    tbl_finishedAt: "Koniec",
    tbl_timeMin: "Trvanie (v min.)",
    tbl_timeSeconds: "Trvanie (v sek.)",
    tbl_distanceMeters: "Vzdialenosť (v m)",
    editDriverWorkShiftDialog_title: "Upraviť stavy pracovnej zmeny",
    workBreak: "Prestávka v práci",
    workBreakApprove: "Schváliť prestávku",
    workBreakReject: "Zamietnuť prestávku",
    workEndShift: "Ukončenie pracovnej zmeny",
    workEndShiftApprove: "Schváliť ukončenie pracovnej zmeny",
    workEndShiftReject: "Zamietnuť ukončenie pracovnej zmeny",
    workShiftRequestListDialog_title: "Zoznam otvorených požiadaviek na úpravu pracovnej zmeny",
    msgUpdateWorkShiftMissingData: "Chýbajú údaje pre úpravu pracovnej zmeny", //Missing data for changing workshift.
    msgUpdateWorkShiftMissingRequest: "Nebola nájdená požiadavka vodiča na prestávku alebo ukončenie pracovnej zmeny.",
    msgUpdateStatusError: "Chyba pri aktualizácii stavu objednávky",
    msgUpdateStatusSuccess: "Stav objednávky bol úspešne aktualizovaný",
    msgDriverNotSelected: "Vodič nie je vybraný",
    msgWorkShiftDriverNoRequest: "Vodič nemá žiadnu požiadavku k pracovovnej zmene!",
    msgGetOrderDetailError: "Chyba pri získavaní detailu objednávky",
    msgCopyText: "Skopírované do schránky",
    msgPbxCallNumberMissing: "Telefónne číslo nie je k dispozícii. Skontrolujte či je načítaný detail objednávky alebo či je v objednávke zadané číslo zákazníka",
    msgPbxCallLineMissing: "Nebola vybraná žiadna klapka na záložke s novou objednávkou!",
    msgPbxCallSuccess: "Volanie bolo úspešne zahájené",
    msgPbxCallError: "Volanie nebolo zahájené!",
    msgSendOrderDriverNotSelected: "Vodič nie je vybraný",
    msgSendOrderOrderNotSelected: "Objednávka nie je vybraná",
    msgSendOrderSuccess: "Objednávka bola úspešne zaslaná vodičovi.",
    msgSendOrderError: "Objednávka nebola zaslaná vodičovi!",
    msgSendOrderDriverNotPreferred: "Chystáte sa poslať objednávku nepreferovanému vodičovi. Stlate OK, aby ste mu napriek tomu poslali Objednávku.",
    msgPreferredDriverSetSuccess: "Preferovaný vodič je nastavený",
    msgPreferredDriverSetError: "Preferovaný vodič nie je nastavený",
    msgFindDriverNotSet: "Pre zobrazenie konkrétneho vodiča na mape, najprv vyberte vodiča zo zoznamu...",
    msgFindPositionError: "Lokalita je bez koordinátov...",
    msgMessageStatusCannotSend: "Správu nie je možné odoslať, lebo jej stav je uzavretý",
    confirmPbxCallDialog_title: "Volania zákazníkovi a vodičovi k objednávke",
    confirmPbxCallDialog_text: "Niektoré funkcie závisia od vašej ústredne alebo používaného zariadenia, nemusia byť dostupné.",
    subheaderPbxCall: "Volania cez ústredňu",
    btnPbxCallClient: "Vytvoriť hovor zákazníkovi z klapky",
    btnPbxCallDriver: "Vytvoriť hovor vodičovi z klapky",
    btnPbxCallBetweenDriverClient: "Vytvoriť hovor od Vodiča Zákazníkovi",
    subheaderPhoneCopy: "Skopírovať telefónne číslo",
    btnPhoneClientCopy: "Skopírovať telefónne číslo 'Zákazníka'",
    btnPhoneDriverCopy: "Skopírovať telefónne číslo 'Vodiča'",
    subheaderPhoneHref: "Otvoriť odkaz na telefón v telefónnej aplikácii",
    btnPhoneClientHref: "Otvoriť odkaz na telefón Zákazníka",
    btnPhoneDriverHref: "Otvoriť odkaz na telefón Vodiča",
    msgPbxCallOrderMissing: "Nie je zvolená žiadna objednávka!",
    chatMsgSuccessPbxDriverClientCall: "Dispečer vytvoril hovor medzi vodičom a zákazníkom",
    editDriverSilenceDialog_title: "Upraviť tichý režim vodiča",
    editDriverSilenceDialog_text: "Prajete si zapnúť alebo vypnúť Tichý režim vodiča?",
    btnSilenceOn: "Zapnúť Tichý režim",
    btnSilenceOff: "Vypnúť Tichý režim",
    msgSilenceOnSuccess: "Tichý režim vodiča je zapnutý",
    msgSilenceOffSuccess: "Tichý režim vodiča je vypnutý",
    msgSilenceError: "Tichý režim vodiča sa nepodarilo nastaviť",
    historyClientDialog_title: "História objednávok zákazníka",
    btnOpenHistoryClientDialog: "História objednávok",
    btnOpenHistoryClientDialog_title: "Zobraziť zoznam s históriou objednávok zákazníka",
    msgMissingClient: "Nie je dostupný klient.",
    msgClientHistoryEmpty: "História objednávok tohto klienta je prázdna."
  },

  ConsoleOrderDetail_tooltip: {
    copyToClipboard: "Skopírovať do schránky",
    createdAtChip: "Dátum a čas vytvorenia objednávky (UTC): ",
    changeStatusAtChip: "Kliknite pre zmenu stavu objednávky",
    presendTime: "Pri zasielaní automatom zaslať objednávku v nastavený čas vopred",
    requestedTime: "Pri časovej objednávke požadovaný čas vyzdvihnutia",
    paymentType: "Spôsob platby | Kliknite pre zmenu spôsobu platby",
    account: "Firemný zákazník",
    note: "Poznámka k objednávke | Kliknite pre zmenu poznámky",
    client: "Zákazník",
    clientId: "Identifikačné číslo zákazníka",
    nicknameClient: "Meno zákazníka",
    phoneClient: "Telefónne číslo",
    phoneClientHref: "Otvoriť odkaz na telefón v aplikácii",
    phoneClientCopy: "Skopírovať telefónne číslo zákazníka",
    phonePbxCall: "Vytvoriť hovor",
    mark: "Označenie alebo typ zákazníka",
    ratingClient: "Hodnotenie zákazníka",
    noteClient: "Poznámka k zákazníkovi",
    driver: "Vodič",
    driverId: "Identifikačné číslo vodiča:",
    firstName: "Meno",
    lastName: "Priezvisko",
    nicknameDriver: "Označenie/prezývka",
    phoneDriver: "Telefón na vodiča",
    phoneDriverHref: "Otvoriť odkaz na telefón v aplikácii",
    phoneDriverCopy: "Skopírovať telefónne číslo vodiča",
    ratingDriver: "Hodnotenie vodiča",
    driveCounter: "Celkový počet jázd vodiča",
    vehicleId: "Identifikačné číslo vozidla:",
    brand: "Značka vozidla",
    model: "Model vozidla",
    lpn: "ŠPZ",
    color: "Farba",
    vehicleType: "Typ vozidla",
    internalName: "Interné označenie",
    preferredDriverFirstName: "Meno preferovaného vodiča",
    preferredDriverLastName: "Priezvisko preferovaného vodiča",
    orderFilter: "Požiadavky na jazdu",
    displayAndSend: "Zobrazenie a zaslanie objednávky",
    btnSendOrderToDriver: "Zaslanie objednavky vodičovi",
    btnUpdatePreferreDriver: "Nastavenie preferovaného vodiča",
    btnFindDriverOnMap: "Vyhľadanie vodiča na mape",
    btnChangeDriverSilence: "Zmena tichého režimu vodiča",
    btnShowEditDriverWorkShiftDialog: "Schvaľovanie stavov pracovnej zmeny",
    btnShowMessage: "Zobrazenie chat správ k objednávke",
    btnDetailRefresh: "Znovunačítať detailné informácie o objednávke",
    btnAutocompleteDriverRemote: "Vyberte vodiča. Kliknutím na ľavú ikonu aktualizujete zoznam vodičov.",
    price: "Cena objednávky",
    //btnEditOrder: "Úprava objednávky",
    //btnEditOrderWaypoint: "Úprava adries a prejazdových bodov objednávky",
    timeChanged: "Čas zmeny stavu objednávky",
    createdAt: "Objednávka bola vytvorená",
    plannedAt: "Bola naplánovaná jazda, pri časovke v požadovanom čase",
    pendingAt: "Objednávka čaká na priradenie vodičovi",
    acceptedAt: "Vodič prijal objednávku a presúva sa na miesto vyzdvihnutia zákazníka",
    waitingAt: "Vodič prišiel na miesto vyzdvihnutia zákazníka a čaká na zákazníka",
    progressAt: "Vodič začal prepravovať zákazníka",
    finishedAt: "Vodič doviezol zákazníka do cieľa a pripravuje účet",
    settledAt: "Objednávka bola zaplatená a uzavretá",
    chargedAt: "Objednávka bola vyúčtovaná, ak bola zvolená platoba kartou, platbu vykoná platobná brána",
    interruptedAt: "Objednávka bola zrušená alebo jej zasielanie vodičovi bolo pozastavené",
    archivedAt: "Objednávka je archivovaná",
    sentOrderLogList: "Zasielanie objednávok na vodičov",
    waitingList: "Čakanie na zákazníka",
  },

  ConsoleOrderMessage: {
    tabOrder: "Objednávka",
    tabDriver: "Vodič",
    tabDispatch: "Dispečing",
    tabPublic_title: "Správy na všetkých vodičov",
    orderMessageField: "Správa vodičovi aj zákazníkovi",
    dispDriverMessageField: "Správa vodičovi",
    btnWorkShift: "Schvaľovanie stavov pracovnej zmeny (prestávka, ukončenie zmeny)",
    dispatchingMessageField: "Správa všetkým dispečerom",
    publicMessageField: "Správa na všetkých vodičov",
    btnAutocompleteDriverRemote: "Vodič",
    btnAutocompleteDriverRemote_placeholder: "Začnite písať...",
    btnAutocompleteDriverRemote_title: "Vyberte vodiča. Prípadne kliknutím na ľavú ikonu aktualizujete zoznam vodičov",
    copyToTextField: "Klikni na nakopírovanie textu do poľa so správou",
    editDriverWorkShiftDialog_title: "Upraviť stavy pracovnej zmeny",
    workBreak: "Prestávka v práci",
    workBreakApprove: "Schváliť prestávku",
    workBreakReject: "Zamietnuť prestávku",
    workEndShift: "Ukončenie pracovnej zmeny",
    workEndShiftApprove: "Schváliť ukončenie pracovnej zmeny",
    workEndShiftReject: "Zamietnuť ukončenie pracovnej zmeny",
    msgWorkShiftDriverNoRequest: "Vodič nemá žiadnu požiadavku k pracovnej zmene!",
    msgWorkShiftDriverNotSelected: "Nie je vybratý vodič!",
    msgWorkShiftMissingData: "Chýbajú údaje pre úpravu pracovnej zmeny", //Missing data for changing workshift.
    msgWorkShiftMissingRequest: "Nebola nájdená požiadavka vodiča na prestávku alebo ukončenie pracovnej zmeny.",
    msgWorkShiftUpdateError: "Chyba pri aktualizácii stavu",
    msgWorkShiftUpdateSuccess: "Stav bol úspešne aktualizovaný",
    msgGetOrderDetailError: "Chyba pri získavaní detailu objednávky",
    msgOrderMessageCountSystem: "V objednávke sa vyskytlo viacero správ s upozorneniami. Je možné, že priveľa vodičov ignorovalo túto objednávku. Prideľte ju ručne alebo prijmite primerané opatrenia.",
    sendMessageSuccess: "Správa bola úspešne odoslaná",
    getPublicMessagesError: "Chyba pri načítaní verených správ na všetkých vodičov",
    sendOrderMessageError: "Nie je zvolená objednávka alebo chýba text správy!",
    sendDispDriverMessageError: "Nie je vybratý vodič alebo nie je zadaný text správy!",
    sendDispatchingMessageError: "Nie je zadaný text správy!",
    sendPublicMessageError: "Nie je zadaný text správy!", //Text is missing!
    dispDriverMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Dobre",
      good_text: "Dobre",
      thankyou: "Ďakujem",
      thankyou_text: "Ďakujem",
      calling: "Volám",
      calling_text: "Volám",
      going: "Ide",
      going_text: "Zákazník už ide",
      whereareyou: "Kde si?",
      whereareyou_text: "Kde sa prosím nachádzaš?",
      cancelled: "Zrušené",
      cancelled_text: "Objednávka je zrušená",
      "1min": "1",
      "1min_text": "Zákazník príde o 1 minútu",
      "2min": "2",
      "2min_text": "Zákazník príde o 2 minúty",
      "5min": "5",
      "5min_text": "Zákazník príde o 5 minút",
    },
    orderMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Dobre",
      good_text: "Dobre",
      thankyou: "Ďakujem",
      thankyou_text: "Ďakujem",
      calling: "Volám",
      calling_text: "Volám",
      going: "Ide",
      going_text: "Zákazník už ide",
      cancelled: "Zrušené",
      cancelled_text: "Objednávka je zrušená",
      "1min": "1",
      "1min_text": "Zákazník príde o 1 minútu",
      "2min": "2",
      "2min_text": "Zákazník príde o 2 minúty",
      "5min": "5",
      "5min_text": "Zákazník príde o 5 minút",
      clientPhone: "Telefón",
      clientPhone_text: "Telefón na zákazníka: ",
    },
    dispatchingMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Dobre",
      good_text: "Dobre",
      thankyou: "Ďakujem",
      thankyou_text: "Ďakujem",
    },
  },

  showDriverPhoneButton: {
    NONE: "Žiadne",
    CUSTOMER: "Telefón na zákazníka",
    DISPATCH: "Telefón na dispečing",
    CUSTOMER_DISPATCH: "Telefón na zákazníka a dispečing",
    PBX: "Telefón na zákazníka cez ústredňu",
    PBX_DISPATCH: "Telefón na zákazníka cez ústredňu a dispečing",
  },
  showDriverPhoneButtonShort: {
    NONE: "-",
    CUSTOMER: "Z",
    DISPATCH: "D",
    CUSTOMER_DISPATCH: "Z&D",
    PBX: "U",
    PBX_DISPATCH: "U&D",
  },
  showDriverPhoneButtonArray: [
    {
      "text": "Žiadne",
      "value": "NONE",
    }, {
      "text": "Telefón na zákazníka",
      "value": "CUSTOMER",
    }, {
      "text": "Telefón na dispečing",
      "value": "DISPATCH",
    }, {
      "text": "Telefón na zákazníka a dispečing",
      "value": "CUSTOMER_DISPATCH",
    }, {
      "text": "Telefón na zákazníka cez ústredňu",
      "value": "PBX",
    }, {
      "text": "Telefón na zákazníka cez ústredňu a dispečing",
      "value": "PBX_DISPATCH",
    },
  ],
  showPrice: {
    ALL: "Zobraziť všetkým",
    ONLY_DRIVER: "Zobraziť iba vodičovi",
    HIDDEN: "Nezobraziť nikomu",
  },
  showPriceShort: {
    ALL: "Všetci",
    ONLY_DRIVER: "Iba vodič",
    HIDDEN: "Nezobraziť",
  },
  showPriceArray: [
    {
      "text": "Zobraziť všetkým",
      "value": "ALL",
    }, {
      "text": "Zobraziť iba vodičovi",
      "value": "ONLY_DRIVER",
    }, {
      "text": "Nezobraziť nikomu",
      "value": "HIDDEN",
    },
  ],

  orderFilter: {
    airconditioning: "Klimatizácia",
    animal: "Zviera",
    autonomous: "Autonómne vozidlo",
    childrenChair: "Preprava dieťaťa",
    driverRating: "Hodnotenie vodiča",
    eco: "Ekologický",
    luggage: "Batožina",
    maxPerson: "Počet cestujúcich",
    paymentTerminal: "Platobný terminál",
    smoking: "Fajčenie vo vozidle",
    specialTransport: "Špeciálna preprava",
  },

  orderFilterSelect: {
    airconditioning: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    animal: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Malé do 10kg",
        "value": "SMALL",
      }, {
        "text": "Stredné do 20kg",
        "value": "MEDIUM",
      }, {
        "text": "Veľké do 50kg",
        "value": "BIG",
      }, {
        "text": "Iba bez zvierat",
        "value": "NONE",
      },
    ],
    autonomous: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Autonomné vozidlo",
        "value": "AUTONOMOUS",
      }, {
        "text": "Neautonomné vozidlo",
        "value": "NONAUTONOMOUS",
      },
    ],
    paymentTerminal: [
      {
        "text": "Nepožadovaný",
        "value": "ANY",
      }, {
        "text": "Požadovaný",
        "value": "YES",
      },
    ],
    childrenChair: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Podsedák",
        "value": "BOOSTER",
      }, {
        "text": "Detská sedačka",
        "value": "SEAT",
      },
    ],
    driverRating: [
      {
        "text": "Nepožadované",
        "value": 0,
      }, {
        "text": "1",
        "value": 1,
      }, {
        "text": "2",
        "value": 2,
      }, {
        "text": "3",
        "value": 3,
      }, {
        "text": "4",
        "value": 4,
      }, {
        "text": "5",
        "value": 5,
      },
    ],
    eco: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Ekologické vozidlo",
        "value": "ECO",
      }, {
        "text": "Elektrické vozidlo",
        "value": "ELECTRIC",
      },
    ],
    luggage: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Malá batožina",
        "value": "SMALL",
      }, {
        "text": "Stredná batožina",
        "value": "MEDIUM",
      }, {
        "text": "Veľká batožina",
        "value": "BIG",
      },
    ],
    maxPerson: [
      {
        "text": "Nepožadované",
        "value": 0,
      }, {
        "text": "1",
        "value": 1,
      }, {
        "text": "2",
        "value": 2,
      }, {
        "text": "3",
        "value": 3,
      }, {
        "text": "4",
        "value": 4,
      }, {
        "text": "5",
        "value": 5,
      }, {
        "text": "6",
        "value": 6,
      }, {
        "text": "7",
        "value": 7,
      }, {
        "text": "8",
        "value": 8,
      }, {
        "text": "9",
        "value": 9,
      },
    ],
    smoking: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Fajčiarské vozidlo",
        "value": "SMOKING",
      },
    ],
    specialTransport: [
      {
        "text": "Nepožadované",
        "value": "ANY",
      }, {
        "text": "Preprava ľudí na vozíku",
        "value": "CHAIR",
      }, {
        "text": "Starší a vyžadujúci asistenciu",
        "value": "OLD",
      },
    ],
  },

  vehicleSelect: {
    airconditioning: [
      {
        "text": "Nie",
        "value": "NO",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    animal: [
      {
        "text": "Malé",
        "value": "SMALL",
      }, {
        "text": "Stredné",
        "value": "MEDIUM",
      }, {
        "text": "Veľké",
        "value": "BIG",
      }, {
        "text": "Žiadne",
        "value": "NONE",
      },
    ],
    autonomous: [
      {
        "text": "Bez autonómnych funkcií",
        "value": "L0",
      }, {
        "text": "Úroveň 1 - Adaptívny tempomat",
        "value": "L1",
      }, {
        "text": "Úroveň 2 - Udržiavanie jazdného pruhu",
        "value": "L2",
      }, {
        "text": "Úroveň 3 - Čiastočné autonómne riadenie",
        "value": "L3",
      }, {
        "text": "Úroveň 4 - Takmer autonómne riadenie",
        "value": "L4",
      }, {
        "text": "Úroveň 5 - Riadenie plne bez zásahu vodiča",
        "value": "L5",
      },
    ],
    paymentTerminal: [
      {
        "text": "Nie",
        "value": "NO",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    childrenChair: [
      {
        "text": "Bez detskej sedačky",
        "value": "NONE",
      }, {
        "text": "Podsedák",
        "value": "BOOSTER",
      }, {
        "text": "Sedačka",
        "value": "SEAT",
      }, {
        "text": "Sedačka aj podsedák",
        "value": "ALL",
      },
    ],
    eco: [
      {
        "text": "Nafta",
        "value": "DIESEL",
      }, {
        "text": "Benzín",
        "value": "GASOLINE",
      }, {
        "text": "Hybrid Electric",
        "value": "HYBRID",
      }, {
        "text": "Plug-in Hybrid Electric",
        "value": "PLUGIN",
      }, {
        "text": "CNG",
        "value": "CNG",
      }, {
        "text": "LPG",
        "value": "LPG",
      }, {
        "text": "Elektrické",
        "value": "ELECTRIC",
      }, {
        "text": "Vodík",
        "value": "HYDROGEN",
      },
    ],
    luggage: [
      {
        "text": "Malá",
        "value": "SMALL",
      }, {
        "text": "Stredná",
        "value": "MEDIUM",
      }, {
        "text": "Veľká",
        "value": "BIG",
      },
    ],
    smoking: [
      {
        "text": "Nie",
        "value": "NO",
      }, {
        "text": "Áno",
        "value": "YES",
      },
    ],
    specialTransport: [
      {
        "text": "Nie",
        "value": "NONE",
      }, {
        "text": "Preprava ľudí na vozíku",
        "value": "CHAIR",
      }, {
        "text": "Preprava starších ľudí a vyžadujúcich pomoc",
        "value": "OLD",
      }, {
        "text": "Starší aj imobilní ľudia na vozíku",
        "value": "ALL",
      },
    ],
  },

  DateRangeSelect:
    [
      {
        "text": "Dnes",
        "value": "today",
      }, {
        "text": "Včera",
        "value": "yesterday",
      }, {
        "text": "Posledné 2 dni",
        "value": "last_2_days",
      }, {
        "text": "Tento týždeň",
        "value": "this_week",
      }, {
        "text": "Tento mesiac",
        "value": "this_month",
      }, {
        "text": "Minulý mesiac",
        "value": "last_month",
      },
      /*{
        "text": "Posledné 3 mesiace",
        "value": "last_3_months",
      }, {
        "text": "Posledných 6 mesiacov",
        "value": "last_6_months",
      },*/
      {
        "text": "Vlastný rozsah",
        "value": "custom",
      },
    ],

  clientMark: {
    NONE: "Nový zákazník",
    NEW: "Nový zákazník",
    REGULAR: "Stály zákazník",
    PRIORITY: "Prioritný zákazník",
    FAMOUS: "Známa osobnosť",
    VIP: "VIP",
    COLLEAGUE: "Kolega z taxislužby",
    STUDENT: "Študent",
    CHILD: "Dieťa",
    JUNIOR: "Junior",
    SENIOR: "Senior",
    DEBTOR: "Dlžník",
    BLOCKED: "Blokovaný",
  },

  clientMarkSelect:
    [
      {
        "text": "Nový zákazník",
        "value": "NEW",
      }, {
        "text": "Stály zákazník",
        "value": "REGULAR",
      }, {
        "text": "Prioritný zákazník",
        "value": "PRIORITY",
      }, {
        "text": "Známa osobnosť",
        "value": "FAMOUS",
      }, {
        "text": "VIP",
        "value": "VIP",
      }, {
        "text": "Kolega z taxislužby",
        "value": "COLLEAGUE",
      }, {
        "text": "Študent",
        "value": "STUDENT",
      }, {
        "text": "Dieťa",
        "value": "CHILD",
      }, {
        "text": "Junior",
        "value": "JUNIOR",
      }, {
        "text": "Senior",
        "value": "SENIOR",
      }, {
        "text": "Dlžník",
        "value": "DEBTOR",
      }, {
        "text": "Blokovaný",
        "value": "BLOCKED",
      },
    ],

  clientNotificationSelect:
    [
      {
        "text": " - Žiadne - ",
        "value": "NONE",
      },
      /*{
        "text": "Push správy",
        "value": "PUSH",
      },*/
      {
        "text": "SMS",
        "value": "SMS",
      },
      /* {
        "text": "Email",
        "value": "EMAIL",
      }, {
        "text": "Telefonická komunikácia",
        "value": "CALL",
      }, {
        "text": "Chat",
        "value": "CHAT",
      }, {
        "text": "Viber",
        "value": "VIBER",
      }, {
        "text": "WhatsApp",
        "value": "WHATSAPP",
      },*/
    ],
  CountryCodeConsoleShortList:
    [
      { "text": "Všetky krajiny", "value": null, },
      { "text": "Slovensko", "value": "SK", },
      { "text": "Česko", "value": "CZ", },
      { "text": "Rakúsko", "value": "AT", },
      { "text": "Maďarsko", "value": "HU", },
      { "text": "Poľsko", "value": "PL", },
      { "text": "Ukrajina", "value": "UA" },
    ],
  CountryCodeShortList:
    [
      { "text": "Slovensko", "value": "SK", },
      { "text": "Česko", "value": "CZ", },
      { "text": "Rakúsko", "value": "AT", },
      { "text": "Maďarsko", "value": "HU", },
      { "text": "Poľsko", "value": "PL", },
      { "text": "Ukrajina", "value": "UA" },
    ],
  CountryCodeList:
    [
      { "text": "Slovensko", "value": "SK", },
      { "text": "Česko", "value": "CZ", },
      { "text": "Rakúsko", "value": "AT", },
      { "text": "Maďarsko", "value": "HU", },
      { "text": "Poľsko", "value": "PL", },
      { "text": "Ukrajina", "value": "UA" },
      { "value": "AD", "text": "Andorra" },
      { "value": "AE", "text": "Spojené arabské emiráty" },
      { "value": "AF", "text": "Afganistan" },
      { "value": "AG", "text": "Antigua a Barbuda" },
      { "value": "AI", "text": "Anguilla" },
      { "value": "AL", "text": "Albánsko" },
      { "value": "AM", "text": "Arménsko" },
      { "value": "AO", "text": "Angola" },
      { "value": "AQ", "text": "Antarktída" },
      { "value": "AR", "text": "Argentína" },
      { "value": "AS", "text": "Americká Samoa" },
      { "value": "AU", "text": "Austrália" },
      { "value": "AW", "text": "Aruba" },
      { "value": "AX", "text": "Alandské ostrovy" },
      { "value": "AZ", "text": "Azerbajdžan" },
      { "value": "BA", "text": "Bosna a Hercegovina" },
      { "value": "BB", "text": "Barbados" },
      { "value": "BD", "text": "Bangladéš" },
      { "value": "BE", "text": "Belgicko" },
      { "value": "BF", "text": "Burkina Faso" },
      { "value": "BG", "text": "Bulharsko" },
      { "value": "BH", "text": "Bahrajn" },
      { "value": "BI", "text": "Burundi" },
      { "value": "BJ", "text": "Benin" },
      { "value": "BL", "text": "Svätý Bartolomej" },
      { "value": "BM", "text": "Bermudy" },
      { "value": "BN", "text": "Brunej" },
      { "value": "BO", "text": "Bolívia, mnohonárodnostný štát" },
      { "value": "BQ", "text": "Bonaire, Sint Eustatius a Saba" },
      { "value": "BR", "text": "Brazília" },
      { "value": "BS", "text": "Bahamy" },
      { "value": "BT", "text": "Bhután" },
      { "value": "BV", "text": "Bouvetov ostrov" },
      { "value": "BW", "text": "Botswana" },
      { "value": "BY", "text": "Bielorusko" },
      { "value": "BZ", "text": "Belize" },
      { "value": "CA", "text": "Kanada" },
      { "value": "CC", "text": "Kokosové ostrovy" },
      { "value": "CD", "text": "Konžská demokratická republika" },
      { "value": "CF", "text": "Stredoafrická republika" },
      { "value": "CG", "text": "Kongo" },
      { "value": "CH", "text": "Švajčiarsko" },
      { "value": "CI", "text": "Pobrežie Slonoviny" },
      { "value": "CK", "text": "Cookove ostrovy" },
      { "value": "CL", "text": "Čile" },
      { "value": "CM", "text": "Kamerun" },
      { "value": "CN", "text": "Čína" },
      { "value": "CO", "text": "Kolumbia" },
      { "value": "CR", "text": "Kostarika" },
      { "value": "CU", "text": "Kuba" },
      { "value": "CV", "text": "Kapverdy" },
      { "value": "CW", "text": "Curaçao" },
      { "value": "CX", "text": "Vianočný ostrov" },
      { "value": "CY", "text": "Cyprus" },
      { "value": "DE", "text": "Nemecko" },
      { "value": "DJ", "text": "Džibutsko" },
      { "value": "DK", "text": "Dánsko" },
      { "value": "DM", "text": "Dominika" },
      { "value": "DO", "text": "Dominikánska republika" },
      { "value": "DZ", "text": "Alžírsko" },
      { "value": "EC", "text": "Ekvádor" },
      { "value": "EE", "text": "Estónsko" },
      { "value": "EG", "text": "Egypt" },
      { "value": "EH", "text": "Západná Sahara" },
      { "value": "ER", "text": "Eritrea" },
      { "value": "ES", "text": "Španielsko" },
      { "value": "ET", "text": "Etiópia" },
      { "value": "FI", "text": "Fínsko" },
      { "value": "FJ", "text": "Fidži" },
      { "value": "FK", "text": "Falklandy (Malvíny)" },
      { "value": "FM", "text": "Mikronézia, federatívne štáty" },
      { "value": "FO", "text": "Faerské ostrovy" },
      { "value": "FR", "text": "Francúzsko" },
      { "value": "GA", "text": "Gabon" },
      { "value": "GB", "text": "Spojené kráľovstvo Veľkej Británie a Severného Írska" },
      { "value": "GD", "text": "Grenada" },
      { "value": "GE", "text": "Gruzínsko" },
      { "value": "GF", "text": "Francúzska Guayana" },
      { "value": "GG", "text": "Guernsey" },
      { "value": "GH", "text": "Ghana" },
      { "value": "GI", "text": "Gibraltár" },
      { "value": "GL", "text": "Grónsko" },
      { "value": "GM", "text": "Gambia" },
      { "value": "GN", "text": "Guinea" },
      { "value": "GP", "text": "Guadeloupe" },
      { "value": "GQ", "text": "Rovníková Guinea" },
      { "value": "GR", "text": "Grécko" },
      { "value": "GS", "text": "Južná Georgia a Južné Sandwichove ostrovy" },
      { "value": "GT", "text": "Guatemala" },
      { "value": "GU", "text": "Guam" },
      { "value": "GW", "text": "Guinea-Bissau" },
      { "value": "GY", "text": "Guyana" },
      { "value": "HK", "text": "Hongkong" },
      { "value": "HM", "text": "Heardov ostrov a McDonaldove ostrovy" },
      { "value": "HN", "text": "Honduras" },
      { "value": "HR", "text": "Chorvátsko" },
      { "value": "HT", "text": "Haiti" },
      { "value": "ID", "text": "Indonézia" },
      { "value": "IE", "text": "Írsko" },
      { "value": "IL", "text": "Izrael" },
      { "value": "IM", "text": "Ostrov Man" },
      { "value": "IN", "text": "India" },
      { "value": "IO", "text": "Britské indickooceánske územie" },
      { "value": "IQ", "text": "Irak" },
      { "value": "IR", "text": "Irán, Islamská republika" },
      { "value": "IS", "text": "Island" },
      { "value": "IT", "text": "Taliansko" },
      { "value": "JE", "text": "Jersey" },
      { "value": "JM", "text": "Jamajka" },
      { "value": "JO", "text": "Jordánsko" },
      { "value": "JP", "text": "Japonsko" },
      { "value": "KE", "text": "Keňa" },
      { "value": "KG", "text": "Kirgizsko" },
      { "value": "KH", "text": "Kambodža" },
      { "value": "KI", "text": "Kiribati" },
      { "value": "KM", "text": "Komory" },
      { "value": "KN", "text": "Svätý Krištof a Nevis" },
      { "value": "KP", "text": "Kórejská ľudovodemokratická republika" },
      { "value": "KR", "text": "Kórejská republika" },
      { "value": "KW", "text": "Kuvajt" },
      { "value": "KY", "text": "Kajmanie ostrovy" },
      { "value": "KZ", "text": "Kazachstan" },
      { "value": "LA", "text": "Laoská ľudovodemokratická republika" },
      { "value": "LB", "text": "Libanon" },
      { "value": "LC", "text": "Svätá Lucia" },
      { "value": "LI", "text": "Lichtenštajnsko" },
      { "value": "LK", "text": "Srí Lanka" },
      { "value": "LR", "text": "Libéria" },
      { "value": "LS", "text": "Lesotho" },
      { "value": "LT", "text": "Litva" },
      { "value": "LU", "text": "Luxembursko" },
      { "value": "LV", "text": "Lotyšsko" },
      { "value": "LY", "text": "Líbya" },
      { "value": "MA", "text": "Maroko" },
      { "value": "MC", "text": "Monako" },
      { "value": "MD", "text": "Moldavská republika" },
      { "value": "ME", "text": "Čierna Hora" },
      { "value": "MF", "text": "Svätý Martin (francúzska časť)" },
      { "value": "MG", "text": "Madagaskar" },
      { "value": "MH", "text": "Marshallove ostrovy" },
      { "value": "MK", "text": "Severné Macedónsko" },
      { "value": "ML", "text": "Mali" },
      { "value": "MM", "text": "Mjanmarsko" },
      { "value": "MN", "text": "Mongolsko" },
      { "value": "MO", "text": "Macao" },
      { "value": "MP", "text": "Severné Mariány" },
      { "value": "MQ", "text": "Martinik" },
      { "value": "MR", "text": "Mauritánia" },
      { "value": "MS", "text": "Montserrat" },
      { "value": "MT", "text": "Malta" },
      { "value": "MU", "text": "Maurícius" },
      { "value": "MV", "text": "Maldivy" },
      { "value": "MW", "text": "Malawi" },
      { "value": "MX", "text": "Mexiko" },
      { "value": "MY", "text": "Malajzia" },
      { "value": "MZ", "text": "Mozambik" },
      { "value": "NA", "text": "Namíbia" },
      { "value": "NC", "text": "Nová Kaledónia" },
      { "value": "NE", "text": "Niger" },
      { "value": "NF", "text": "Norfolkov ostrov" },
      { "value": "NG", "text": "Nigéria" },
      { "value": "NI", "text": "Nikaragua" },
      { "value": "NL", "text": "Holandsko" },
      { "value": "NO", "text": "Nórsko" },
      { "value": "NP", "text": "Nepál" },
      { "value": "NR", "text": "Nauru" },
      { "value": "NU", "text": "Niue" },
      { "value": "NZ", "text": "Nový Zéland" },
      { "value": "OM", "text": "Omán" },
      { "value": "PA", "text": "Panama" },
      { "value": "PE", "text": "Peru" },
      { "value": "PF", "text": "Francúzska Polynézia" },
      { "value": "PG", "text": "Papua-Nová Guinea" },
      { "value": "PH", "text": "Filipíny" },
      { "value": "PK", "text": "Pakistan" },
      { "value": "PM", "text": "Saint Pierre a Miquelon" },
      { "value": "PN", "text": "Pitcairnove ostrovy" },
      { "value": "PR", "text": "Portoriko" },
      { "value": "PS", "text": "Palestína, štát" },
      { "value": "PT", "text": "Portugalsko" },
      { "value": "PW", "text": "Palau" },
      { "value": "PY", "text": "Paraguaj" },
      { "value": "QA", "text": "Katar" },
      { "value": "RE", "text": "Réunion" },
      { "value": "RO", "text": "Rumunsko" },
      { "value": "RS", "text": "Srbsko" },
      { "value": "RU", "text": "Rusko" },
      { "value": "RW", "text": "Rwanda" },
      { "value": "SA", "text": "Saudská Arábia" },
      { "value": "SB", "text": "Šalamúnove ostrovy" },
      { "value": "SC", "text": "Seychely" },
      { "value": "SD", "text": "Sudán" },
      { "value": "SE", "text": "Švédsko" },
      { "value": "SG", "text": "Singapur" },
      { "value": "SH", "text": "Svätá Helena" },
      { "value": "SI", "text": "Slovinsko" },
      { "value": "SJ", "text": "Svalbard a Jan Mayen" },
      { "value": "SL", "text": "Sierra Leone" },
      { "value": "SM", "text": "San Maríno" },
      { "value": "SN", "text": "Senegal" },
      { "value": "SO", "text": "Somálsko" },
      { "value": "SR", "text": "Surinam" },
      { "value": "SS", "text": "Južný Sudán" },
      { "value": "ST", "text": "Svätý Tomáš a Princov ostrov" },
      { "value": "SV", "text": "Salvádor" },
      { "value": "SX", "text": "Sint Maarten" },
      { "value": "SY", "text": "Sýria" },
      { "value": "SZ", "text": "Svazijsko" },
      { "value": "TC", "text": "Turks a Caicos" },
      { "value": "TD", "text": "Čad" },
      { "value": "TF", "text": "Francúzske južné a antarktické územia" },
      { "value": "TG", "text": "Togo" },
      { "value": "TH", "text": "Thajsko" },
      { "value": "TJ", "text": "Tadžikistan" },
      { "value": "TK", "text": "Tokelau" },
      { "value": "TL", "text": "Východný Timor" },
      { "value": "TM", "text": "Turkménsko" },
      { "value": "TN", "text": "Tunisko" },
      { "value": "TO", "text": "Tonga" },
      { "value": "TR", "text": "Turecko" },
      { "value": "TT", "text": "Trinidad a Tobago" },
      { "value": "TV", "text": "Tuvalu" },
      { "value": "TW", "text": "Taiwan, provincie Čínskej republiky" },
      { "value": "TZ", "text": "Tanzánia, zjednotená republika" },
      { "value": "UG", "text": "Uganda" },
      { "value": "UM", "text": "Menšie odľahlé ostrovy Spojených štátov" },
      { "value": "US", "text": "Spojené štáty americké" },
      { "value": "UY", "text": "Uruguaj" },
      { "value": "UZ", "text": "Uzbekistan" },
      { "value": "VA", "text": "Svätá stolica" },
      { "value": "VC", "text": "Svätý Vincent a Grenadiny" },
      { "value": "VE", "text": "Venezuela, Bolívarovská republika" },
      { "value": "VG", "text": "Britské panenské ostrovy" },
      { "value": "VI", "text": "Americké panenské ostrovy" },
      { "value": "VN", "text": "Vietnam" },
      { "value": "VU", "text": "Vanuatu" },
      { "value": "WF", "text": "Wallis a Futuna" },
      { "value": "WS", "text": "Samoa" },
      { "value": "YE", "text": "Jemen" },
      { "value": "YT", "text": "Mayotte" },
      { "value": "ZA", "text": "Južná Afrika" },
      { "value": "ZM", "text": "Zambia" },
      { "value": "ZW", "text": "Zimbabwe" }
    ],

  orderAbortedReasonArray:
    [
      { "text": "Zákazník zrušil objednávku", "value": "CLIENT_CANCELLED_ORDER" },
      { "text": "Zákazník neprišiel", "value": "CLIENT_NOT_COME" },
      { "text": "Duplicitná objednávka", "value": "DUPLICATED" },
      { "text": "Nesprávna objednávka", "value": "INCORRECT_ORDER" },
      { "text": "Iné", "value": "OTHER" },
    ],
  messageType: {
    SYSTEM: "Systémová",
    PUBLIC: "Verejná",
    DISP_DRIVER: "S vodičom",
    ORDER: "Objednávka",
    DISPATCHING: "Medzi Dispečermi",
    DIRECT: "Priama",
  },
  messageType_short: {
    SYSTEM: "SYS",
    PUBLIC: "ALL",
    DISP_DRIVER: "Vodič",
    ORDER: "OBJ",
    DISPATCHING: "DISP",
    DIRECT: "Priama",
  },
  messageType_tooltip: {
    SYSTEM: "Správa vygenerovaná systémom",
    PUBLIC: "Správa distribuovaná na všetkých vodičov bez možnosti odpovedať na správu",
    DISP_DRIVER: "Správa medzi vodičom a dispečermi",
    ORDER: "Správa v rámci objednávky",
    DISPATCHING: "Správa medzi dispečermi",
    DIRECT: "Priama správa medzi dvoma používateľmi",
  },
  messageFromToType: {
    SYSTEM: "Systém",
    PUBLIC: "Všetci",
    DRIVER: "Vodič",
    CLIENT: "Zákazník",
    DISPATCHER: "Dispečer",
  },
  messageFromToType_short: {
    SYSTEM: "Systém",
    PUBLIC: "Všetci",
    DRIVER: "Vodič",
    CLIENT: "Zákazník",
    DISPATCHER: "Dispečer",
  },
  messageFromToType_tooltip: {
    SYSTEM: "Systém",
    PUBLIC: "Všetci",
    DRIVER: "Vodič",
    CLIENT: "Zákazník",
    DISPATCHER: "Dispečer",
  },
  gender:
    [
      {
        "text": "Muž",
        "value": "MALE",
      }, {
        "text": "Žena",
        "value": "FEMALE",
      }, {
        "text": "Iné",
        "value": "OTHER",
      },
    ],
  driverTag:
    [
      {
        "text": "-",
        "value": null,
      }, {
        "text": "Nepočujúci alebo nedoslýchavý",
        "value": "DEAF",
      }, {
        "text": "Pohybovo zdravotne postihnutý",
        "value": "IMMOBILE",
      },
    ],
  language: [
    { "text": "Slovensky", "value": "sk" },
    { "text": "Česky", "value": "cs" },
    { "text": "Anglicky", "value": "en" },
    { "text": "Ukrajinsky", "value": "uk" },
    { "text": "Maďarsky", "value": "hu" },
    { "text": "Nemecky", "value": "de" },
    { "text": "Francúzsky", "value": "fr" },
    { "text": "Španielsky", "value": "es" },
    { "text": "Rusky", "value": "ru" },
    { "text": "Turecky", "value": "tr" },
    { "text": "Poľsky", "value": "pl" },
    { "text": "Čínsky", "value": "zh" },
    { "text": "Chorvátsky", "value": "hr" },
    { "text": "Slovinsky", "value": "sl" },
    { "text": "Taliansky", "value": "tl" },
    { "text": "Srbsky", "value": "sr" },
  ],

  vehicleColor_short: {
    WHITE: "Biela", // biela
    BLACK: "Čierna", // Čierna
    SILVER: "Strieborná", // Strieborná
    GRAY: "Šedá", // Šedá
    BROWN: "Hnedá", // Hnedá
    PURPLE: "Purpurová", // Purpurová
    VIOLET: "Fialová", // Fialová
    BLUE: "Modrá", // Modrá
    DARKBLUE: "Tmavomodrá", // Tmavomodrá
    LIGHTBLUE: "Svetlomodrá", // Svetlomodrá
    SKYBLUE: "Strednemodrá", // Strednemodrá
    RED: "Červená", // Červená
    ORANGE: "Oranžová", //  Oranžová
    DARKORANGE: "Tmavooranžová", // Tmavooranžová
    YELLOW: "Žltá", // Žltá
    GREEN: "Zelená", // Zelená
    DARKGREEN: "Tmavozelená", // Tmavozelená
    LIGHTGREEN: "Svetlozelená", // Svetlozelená
    PINK: "Ružová", // Ružová
    BEIGE: "Béžová", // Béžová
    GOLD: "Zlatá", // Zlatá
  },
  vehicleColorArray:
    [
      {
        "text": "Biela",
        "value": "WHITE",
      }, {
        "text": "Čierna",
        "value": "BLACK",
      }, {
        "text": "Strieborná",
        "value": "SILVER",
      }, {
        "text": "Šedá",
        "value": "GRAY",
      }, {
        "text": "Hnedá",
        "value": "BROWN",
      }, {
        "text": "Purpurová",
        "value": "PURPLE",
      }, {
        "text": "Fialová",
        "value": "VIOLET",
      }, {
        "text": "Modrá",
        "value": "BLUE",
      }, {
        "text": "Tmavomodrá",
        "value": "DARKBLUE",
      }, {
        "text": "Svetlomodrá",
        "value": "LIGHTBLUE",
      }, {
        "text": "Strednemodrá",
        "value": "SKYBLUE",
      }, {
        "text": "Červená",
        "value": "RED",
      }, {
        "text": "Oranžová",
        "value": "ORANGE",
      }, {
        "text": "Tmavooranžová",
        "value": "DARKORANGE",
      }, {
        "text": "Žltá",
        "value": "YELLOW",
      }, {
        "text": "Zelená",
        "value": "GREEN",
      }, {
        "text": "Tmavozelená",
        "value": "DARKGREEN",
      }, {
        "text": "Svetlozelená",
        "value": "LIGHTGREEN",
      }, {
        "text": "Ružová",
        "value": "PINK",
      }, {
        "text": "Béžová",
        "value": "BEIGE",
      }, {
        "text": "Zlatá",
        "value": "GOLD",
      },
    ],

  mapShapeArray:
    [
      {
        "text": "Kruh",
        "value": "CIRCLE",
      }, {
        "text": "Polygón",
        "value": "POLYGON",
      },
    ],
  mapShape: {
    CIRCLE: "Kruh",
    POLYGON: "Polygón",
  },
  /* ReportStatusMonthView: {
     page_title: "Zoznam objednávok",
     Id: "Č. objednávky",
     Id_tooltip: 'Technické číslo objednávky {msg}',
     Status: "Stav",
     Type: "Typ",
     Source: "Zdroj",
     PaymentType: "Platba",
     Note: "Poznámka",
     CreatedAt: "Vytvorené",
     AcceptedAt: "Akceptované",
     DriverId: "Č. vodiča",
     boardAddress: "Vyzdvihnutie",
     destinationAddress: "Cieľ",
     EstimatedPrice: "Predpokladaná cena",
     FinalPrice: "Finálna cena",
     filterLabel: {
       Id: "č. objednávky",
       Status: "Stav",
       Type: "Typ",
       Source: "Zdroj",
       PaymentType: "Platba",
       Note: "Poznámka",
       CreatedAt: "Vytvorené",
       AcceptedAt: "Akceptované",
       DriverId: "Č. vodiča",
       boardAddress: "Vyzdvihnutie",
       destinationAddress: "Cieľ",
       EstimatedPrice: "Predpokladaná cena",
       FinalPrice: "Finálna cena",
     },
     RefreshData: "Aktualizovať",
   },*/
  messageDispatchList: {
    page_title: "Zoznam oznámení v dispečingu",
    toolbarActions: "Aktivity",
    Id: "ID",
    Id_tooltip: 'Technické číslo {msg}',
    Status: "Stav",
    Type: "Typ",
    Taxiservice: "Taxislužba",
    TaxiserviceId: "Č. taxislužby",
    CreatedAt: "Vytvorené",
    UpdatedAt: "Upravené",
    ValidFrom: "Platné od",
    ValidTo: "Platné do",
    Title: "Názov",
    Subtitle: "Podnázov",
    Message: "Text",
    filterLabel: {
      Id: "č. oznámenia",
      Status: "Stav",
      Type: "Typ",
      Taxiservice: "Taxislužba",
      CreatedAt: "Vytvorené",
      UpdatedAt: "Upravené",
      ValidFrom: "Platné od",
      ValidTo: "Platné do",
      Title: "Názov",
    },
    RefreshData: "Aktualizovať",
    showCreateDialog: "Nové Oznámenie",
    showEditDialog: "Úprava Oznámenia",
    btnSetActualDate: "Nastaviť platnosť do na aktuálny dátum",
    msgValidMaxDate: "Platnosť do musí byť do 60 dní od dnešného dátumu",
    msgGetTaxiservicListForPickerError: "Nepodarilo sa načítať zoznam taxislužieb pre picker",
  },
  messageDispatchTypeArray:
    [
      {
        "text": "Zmuvné vzťahy",
        "value": "CONTRACT",
      }, {
        "text": "Údržba",
        "value": "MAINTENANCE",
      }, {
        "text": "Platby",
        "value": "PAYMENT",
      }, {
        "text": "Release",
        "value": "RELEASE",
      }, {
        "text": "Stav systému",
        "value": "SYSTEM_STATUS",
      }, {
        "text": "Iné",
        "value": "OTHER",
      },
    ],
  messageDispatchType: {
    CONTRACT: "Zmluvné vzťahy",
    MAINTENANCE: "Údržba",
    PAYMENT: "Platby",
    RELEASE: "Release",
    SYSTEM_STATUS: "Stav systému",
    OTHER: "Iné",
  },
  messageDispatchStatusArray:
    [
      {
        "text": "Expirované",
        "value": "EXPIRED",
      }, {
        "text": "Plánované",
        "value": "PLANNED",
      }, {
        "text": "Aktívne",
        "value": "ACTIVE",
      },
    ],
  messageDispatchStatus: {
    EXPIRED: "Expirované",
    PLANNED: "Plánované",
    ACTIVE: "Aktívne",
    OTHER: "Iné",
  },

  smsTemplateTypeArray:
    [
      {
        "text": "Akceptovanie objednávky vodičom",
        "value": "ACCEPTED",
      },
      /*{
        "text": "Priblíženie vozidla",
        "value": "APPROACHING",
      },*/
      {
        "text": "Pristavenie vozidla",
        "value": "WAITING",
      },
      /* {
        "text": "Priblíženie alebo pristavenie vozidla",
        "value": "WAITING_APPROACHING",
      },*/
      {
        "text": "Ukončenie jazdy",
        "value": "FINISHED",
      }, {
        "text": "Vyúčtovanie",
        "value": "SETTLED",
      },
      {
        "text": "Zrušenie objednávky",
        "value": "CANCELLED",
      },
    ],
  smsTemplateType: {
    ACCEPTED: "Akceptovanie objednávky",
    APPROACHING: "Priblíženie vozidlo",
    WAITING: "Pristavenie vozidla",
    WAITING_APPROACHING: "Priblíženie alebo pristavenie vozidla",
    FINISHED: "Ukončenie jazdy",
    SETTLED: "Vyúčtovanie",
    CANCELLED: "Zrušenie",
  },

  smsTemplateLanguageArray:
    [
      { "text": "Slovensky", "value": "SK" },
      { "text": "Česky", "value": "CS" },
      { "text": "Anglicky", "value": "EN" },
    ],
  smsTemplateLanguage: {
    SK: "Slovensky",
    CS: "Česky",
    EN: "Anglicky",
  },

  AccountEnums: {
    Status: {
      ACTIVE: "Aktívny",
      DISABLED: "Neaktívny",
      BLOCKED: "Zablokovaný",
      ARCHIVED: "Archivovaný",
    },
    StatusArray:
      [
        { "text": "Aktívny", "value": "ACTIVE", },
        { "text": "Neaktívny", "value": "DISABLED", },
        { "text": "Zablokovaný", "value": "BLOCKED", },
        { "text": "Archivovaný", "value": "ARCHIVED", },
      ],
    CompanyVatPayer:
      [
        { "text": "Nie je platiteľ DPH", "value": "NO_VAT", },
        { "text": "Platiteľ DPH", "value": "VAT", },
        { "text": "Oslobodený od DPH", "value": "VAT_FREE", },
        { "text": "Zahraničná osoba bez DPH", "value": "FOREIGN", },
      ],
    ClientStatus: {
      ACTIVE: "Aktívny",
      DISABLED: "Neaktívny",
      PENDING: "Na schválenie",
      BLOCKED: "Zablokovaný",
      ARCHIVED: "Archivovaný",
    },
    ClientStatusArray:
      [
        { "text": "Aktívny", "value": "ACTIVE", },
        { "text": "Neaktívny", "value": "DISABLED", },
        { "text": "Na schválenie", "value": "PENDING", },// Čakajúci na schválenie (2)
        { "text": "Zablokovaný", "value": "BLOCKED", },
        { "text": "Archivovaný", "value": "ARCHIVED", },
      ],
  },

  ModulePricelistType: {
    SIMPLE: "Defaultné ceny",
    LOCALITY: "Ceny pre lokality",
    EXTENDED: "Rozšírené",
  },
  ModulePricelistTypeArray:
    [
      { "text": "Defaultné ceny", "value": "SIMPLE", },
      { "text": "Ceny pre lokality", "value": "LOCALITY", },
      { "text": "Rozšírené", "value": "EXTENDED", },
    ],

  ModuleAddressSearch: {
    DB_GOOGLE: "Privátna DB (Google)",
    DB_OSM: "Privátna DB (OSM)",
    DB_GEOCODE: "Privátna DB (Geocode)",
    GOOGLE: "Google",
    OSM: "OSM",
    GEOCODE: "Geocode",
  },
  ModuleAddressSearchArray:
    [
      { "text": "Privátna DB (Google)", "value": "DB_GOOGLE", },
      { "text": "Privátna DB (OSM)", "value": "DB_OSM", },
      { "text": "Privátna DB (Geocode)", "value": "DB_GEOCODE", },
      { "text": "Google", "value": "GOOGLE", },
      { "text": "OSM", "value": "OSM", },
      { "text": "Geocode", "value": "GEOCODE", },
    ],

  AccountList: {
    page_title: "Firemní klienti",
    dialogTitle: "Nový firemný klient",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    status: "Stav",
    accountStatus: "Stav firmy",
    name: "Názov v zozname",
    name_hint: "Názov používaný pri bežnej práci v systéme. Použite čo najkratší.",
    companyIdNumber: "IČO",
    companyIdNumber_hint: "IČO nebude možné zmeniť po vytvorení. V každom štáte musí byť číslo jedinečné.",
    companyTaxId: "DIČ",
    companyVatPayer: "Platiteľ DPH",
    companyVatId: "IČ DPH",
    btnOrsr: "OR SR",
    btnNextNoCIN: "Ďalej bez IČO",
    validTo: "Platnosť do",
    country: "Krajina",
    country_hint: "Štát nebude možné zmeniť po vytvorení",
    companyName: "Názov spoločnosti",
    companyName_hint: "Názov určený pre faktúry",
    email: "Email",
    companyAddress1: "Adresa spoločnosti (1. riadok)",
    companyAddress2: "Adresa spoločnosti (2. riadok)",
    companyAddress3: "Adresa spoločnosti (3. riadok)",
    tableHeaders: {
      actions: "Akcie",
      accountId: "ID",
      status: "Stav",
      accountStatus: "Stav firmy",
      validFrom: "Platnosť od",
      validTo: "Platnosť do",
      name: "Názov v zozname",
      companyIdNumber: "IČO",
      country: "Krajina",
      companyName: "Názov spoločnosti",
      email: "Email",
    },
    filterLabel: {
      actions: "Akcie",
      accountId: "ID",
      status: "Stav",
      accountStatus: "Stav firmy",
      validFrom: "Platnosť od",
      validTo: "Platnosť do",
      name: "Názov v zozname",
      companyIdNumber: "IČO",
      country: "Krajina",
      companyName: "Názov spoločnosti",
      email: "Email",
    },
    msgAccountAlreadyExists: "Účet s týmto IČO už existuje", //Company Id Number already exists
  },

  Account: {
    page_title: "Firemný klient",
    tabInfo: "Informácie",
    tabUsers: "Používatelia",
    status: "Stav",
    status_hint: "Dispečerovi sa zobrazí iba aktívny účet",
    accountStatus: "Stav firmy",
    accountStatus_hint: "Stav nie je možné upravovať",
    name: "Názov v zozname",
    companyIdNumber: "IČO",
    companyIdNumber_hint: "IČO nie je možné upravovať",
    companyTaxId: "DIČ",
    companyVatPayer: "Platiteľ DPH",
    companyVatId: "IČ DPH",
    validFrom: "Platnosť od",
    validTo: "Platnosť do",
    country: "Krajina",
    country_hint: "Štát nie je možné upravovať",
    companyName: "Názov spoločnosti",
    email: "Email",
    companyAddress1: "Adresa spoločnosti (1. riadok)",
    companyAddress2: "Adresa spoločnosti (2. riadok)",
    companyAddress3: "Adresa spoločnosti (3. riadok)",
  },
  AccountClient: {
    clientPhone: "Telefón",
    msgPhoneValidation: "Telefónne číslo musí byť v tvare +421123456789",
    btnAddCustomer: "Prideliť zákazníka",
    dialogAssignTitle: "Pridanie zákazníka Firemnému klientovi",
    clientId: "ID zákazníka",
    phone: "Telefón",
    nickname: "Meno",
    mark: "Označenie",
    note: "Poznámka",
    info: "Informácie",
    dialogEditTitle: "Upraviť priradenie zákazníka",
    accountId: "ID firemného klienta",
    status: "Stav",
    validFrom: "Platnosť od",
    validTo: "Platnosť do",
    tableHeaders: {
      actions: "Akcie",
      clientId: "ID",
      phone: "Telefón",
      nickname: "Meno",
      mark: "Označenie",
      status: "Stav",
      validFrom: "Platnosť od",
      validTo: "Platnosť do",
    },
    msgPhoneMissing: "Telefónne číslo musí byť vyplnené",
    msgPhoneInvalid: "Telefónne číslo musí byť v tvare +421123456789",
    msgPhoneShort: "Telefónne číslo musí mať aspoň 11 číslic",
    msgPhoneOnlyNumbers: "Telefónne číslo musí obsahovať iba číslice a znak +",
    msgPhoneSecondCharIsNotNumber: "Druhý znak telefónneho čísla musí byť číslica",
    msgCustomerAlreadyAssigned: "Zákazník je už priradený k tomuto firemnému klientovi",
    msgCustomerNotExists: "Zákazník s týmto telefónnym číslom neexistuje",
    msgCustomerNotAddedNoCustomer: "Zákazník nebol pridaný. Taxislužba o zákazníkovi s takýmto telefónom nemá záznam.",
    msgCustomerNotAddedAlreadyAdded: "Zákazník nebol pridaný. Zákazník bol už pridaný. Ak sa nezobrazuje, skontrolujete jeho stav a čas platnosti",
    msgCustomerSuccessfullyAdded: "Zákazník bol úspešne pridaný",
  },

  DriverVehicleList: {
    page_title: "Pridelené vozidlá",
    enabledDriversSwitch: "Povolení vodiči",
    btnAssignVehicleTitle: "Prideliť vozidlo",
    dialogAssignTitle: "Prajete si prideliť vodičovi vozidlo?",
    dialogEndWorkshiftTitle: "Prajete si ukončiť pracovnú zmenu vodiča?",
    driverId: "ID",
    firstName: "Meno",
    lastName: "Priezvisko",
    fullNameVehicle: "Vozidlo",
    vehicleId: "ID",
    mileages: "Stav tachometra",
    listAssigned: "Pridelené",
    listUsed: "Používané",
    listRemoveVehicle: "- Odobrať pridelené vozidlo - ",
    msgAssigmentSuccesufull: "Vozidlo bolo úspešne pridelené",
    btnEndShift: "Ukončiť pracovnú zmenu",
    msgEndWorkshiftSuccesufull: "Pracovná zmena bola úspešne ukončená",
    msgEndWorkshiftErrorDriverHasOrder: "Pracovná zmena nemôže byť ukončená, pretože vodič má aktívne objednávky",
    tableHeaders: {
      actions: "Akcie",
      driverId: "ID",
      internalName: "Interný názov",
      firstName: "Meno",
      lastName: "Priezvisko",
      active: "Voľba",
      workStatus: "Prac. zmena",
      silence: "Tichý režim",
      vehicleAssignedInternalNameLpn: "Pridelené",
      vehicleAssignedBrandModel: "Model",
      vehicleSelectedInternalNameLpn: "Používané",
      vehicleSelectedBrandModel: "Model",
    },
  },

  VehicleDriverList: {
    page_title: "Zoznam vozidiel a vodičov",
    dialogDetailTitle: "Detail vozidla a vodiča",
    dialogCreateMaintenanceTitle: "Vytvoriť údržbu vozidla",
    dialogUpdateLastMaintenanceTitle: "Nastavenie poslednej údržby vozidla",
    dialogMaintenanceListTitle: "Zoznam údržieb a opráv vozidla",
    tableHeaders: {
      actions: "Akcie",
      internalName: "Interný názov",
      lpn: "ŠPZ",
      brand: "Značka",
      model: "Model",
      active: "Aktívne",
      status: "Stav",
      mileages: "Stav tachometra",
      driverId: "ID",
      driverInternalName: "Interný názov vodiča",
      driverFirstName: "Meno",
      driverLastName: "Priezvisko",
      driverActive: "Voľba",
      driverWorkStatus: "Prac. zmena",
      driverSilence: "Tichý režim",
      driverPhone: "Telefón",
    },
    filterLabel: {
      internalName: "Interný názov",
      lpn: "ŠPZ",
      brand: "Značka",
      model: "Model",
      active: "Aktívne",
      status: "Stav",
      mileages: "Stav tachometra",
      driverId: "ID",
      driverInternalName: "Interný názov vodiča",
      driverFirstName: "Meno",
      driverLastName: "Priezvisko",
      driverActive: "Voľba",
      driverWorkStatus: "Prac. zmena",
      driverSilence: "Tichý režim",
      driverPhone: "Telefón",
    },
    vehicleMaintenance: {
      page_title: "Údržba vozidiel",
      vehicleId: "ID vozidla",
      internalName: "Interný názov",
      lpn: "ŠPZ",
      brand: "Značka",
      model: "Model",
      active: "Aktívne",
      mileages: "Stav tachometra",
      lastMaintenanceAt: "Posledná údržba",
      lastMaintenanceMileage: "Stav kilometrov pri poslednej údržbe",
      maintenanceIntervalDay: "Interval údržby (dni)",
      maintenanceIntervalMileage: "Interval údržby (km)",
      maintenanceNotificationDay: "Upozornenie na údržbu (dni)",
      maintenanceNotificationMileage: "Upozornenie na údržbu (km)",
      plannedAt: "Plánované",
      startedAt: "Začaté",
      startedMileage: "Stav kilometrov na začiatku",
      resolvedAt: "Dokončené",
      resolvedMileage: "Stav kilometrov na konci",
      title: "Názov",
      type: "Typ",
      status: "Stav",
      note: "Poznámka",
      price: "Cena",
      fluidCheck: "Kontrola hladiny kvapalín",
      oilTopUp: "Doliatie oleja",
      oilChange: "Výmena oleja",
      coolantChange: "Výmena chladiva",
      brakeFluidChange: "Výmena brzdovej kvapaliny",
      oilFilterReplacement: "Výmena olejového filtra",
      airFilterReplacement: "Výmena vzduchového filtra",
      airConFilterReplacement: "Výmena filtra klimatizácie",
      sparkOrGlowPlugReplacement: "Výmena sviečok",
      tireReplacement: "Výmena pneumatík",
      drivetrainCheck: "Kontrola pohonu",
      serpentineBeltReplacement: "Výmena remeňa",
      otherFilterReplacement: "Výmena iného filtra",
      otherFluidTopUp: "Doliatie inej kvapaliny",
      lightRepair: "Oprava svetiel",
      brakeRepair: "Oprava brzd",
      bodyRepair: "Oprava karosérie",
      engineRepair: "Oprava motora",
      electronicsRepair: "Oprava elektroniky",
      drivetrainRepair: "Oprava pohonu",
      batteryReplacement: "Výmena batérie",
      otherRepair: "Iná oprava",
      cleaning: "Čistenie",
      btnSetLastMaintenanceAtFromVehicle: "Nastaviť údaje poslednej údržby vozidla",
      btnSetLastMaintenanceAtToNow: "Nastaviť dnešný dátum a posledný stav tachometra",
      btnSetUpdateMaintenanceMileageAtFromVehicle: "Nastaviť kilometre z údajov vozidla (tachometer)",
      btnSetUpdateMaintenanceDatesAtToNow: "Nastaviť dnešný dátum",
    },
    tableMaintenanceHeaders: {
      actions: "Akcie",
      mileages: "Tachometer",
      plannedAt: "Plánované",
      startedAt: "Začaté",
      startedMileage: "Štart KM",
      resolvedAt: "Dokončené",
      resolvedMileage: "Koniec KM",
      title: "Názov",
      type: "Typ",
      status: "Stav",
      note: "Poznámka",
      price: "Cena",
      fluidCheck: "Kontr. kvap.",
      oilTopUp: "Dopl. oleja",
      oilChange: "Vým. oleja",
      coolantChange: "Vým. chladiva",
      brakeFluidChange: "Vým. brzd. kvap.",
      oilFilterReplacement: "Vým. olej. filtra",
      airFilterReplacement: "Vým. vzduch. filtra",
      airConFilterReplacement: "Vým. filtra klim.",
      sparkOrGlowPlugReplacement: "Vým. sviečok",
      tireReplacement: "Vým. pneum.",
      drivetrainCheck: "Kontr. pohonu",
      serpentineBeltReplacement: "Vým. remeňa",
      otherFilterReplacement: "Vým. iného filtra",
      otherFluidTopUp: "Dopl. inej kvap.",
      lightRepair: "Opr. svetiel",
      brakeRepair: "Opr. brzd",
      bodyRepair: "Opr. karos.",
      engineRepair: "Opr. motora",
      electronicsRepair: "Opr. elektr.",
      drivetrainRepair: "Opr. pohonu",
      batteryReplacement: "Vým. batérie",
      otherRepair: "Iná opr.",
      cleaning: "Čistenie",
    },
  },
  VehicleMaintenanceList: {
    page_title: "Údržba vozidiel",
    dialogDetailTitle: "Detail údržby vozidla",
    vehicleId: "ID vozidla",
    internalName: "Interný názov",
    lpn: "ŠPZ",
    brand: "Značka",
    model: "Model",
    active: "Aktívne",
    mileages: "Stav tachometra",
    lastMaintenanceAt: "Posledná údržba",
    lastMaintenanceMileage: "Stav kilometrov pri poslednej údržbe",
    maintenanceIntervalDay: "Interval údržby (dni)",
    maintenanceIntervalMileage: "Interval údržby (km)",
    maintenanceNotificationDay: "Upozornenie na údržbu (dni)",
    maintenanceNotificationMileage: "Upozornenie na údržbu (km)",
    plannedAt: "Plánované",
    startedAt: "Začaté",
    startedMileage: "Stav kilometrov na začiatku",
    resolvedAt: "Dokončené",
    resolvedMileage: "Stav kilometrov na konci",
    btnSetUpdateMaintenanceMileageAtFromVehicle: "Nastaviť kilometre z údajov vozidla (tachometer)",
    btnSetUpdateMaintenanceDatesAtToNow: "Nastaviť dnešný dátum",
    title: "Názov",
    type: "Typ",
    status: "Stav",
    note: "Poznámka",
    price: "Cena",
    fluidCheck: "Kontrola hladiny kvapalín",
    oilTopUp: "Doliatie oleja",
    oilChange: "Výmena oleja",
    coolantChange: "Výmena chladiva",
    brakeFluidChange: "Výmena brzdovej kvapaliny",
    oilFilterReplacement: "Výmena olejového filtra",
    airFilterReplacement: "Výmena vzduchového filtra",
    airConFilterReplacement: "Výmena filtra klimatizácie",
    sparkOrGlowPlugReplacement: "Výmena sviečok",
    tireReplacement: "Výmena pneumatík",
    drivetrainCheck: "Kontrola pohonu",
    serpentineBeltReplacement: "Výmena remeňa",
    otherFilterReplacement: "Výmena iného filtra",
    otherFluidTopUp: "Doliatie inej kvapaliny",
    lightRepair: "Oprava svetiel",
    brakeRepair: "Oprava brzd",
    bodyRepair: "Oprava karosérie",
    engineRepair: "Oprava motora",
    electronicsRepair: "Oprava elektroniky",
    drivetrainRepair: "Oprava pohonu",
    batteryReplacement: "Výmena batérie",
    otherRepair: "Iná oprava",
    cleaning: "Čistenie",
    tableHeaders: {
      actions: "Akcie",
      vehicleInternalName: "Interný názov",
      vehicleLpn: "ŠPZ",
      vehicleBrand: "Značka",
      vehicleModel: "Model",
      vehicleActive: "Aktívne",
      vehicleStatus: "Stav",
      vehicleMileages: "Stav tachometra",
      type: "Typ",
      status: "Stav",
      plannedAt: "Plánované",
      startedAt: "Začaté",
      resolvedAt: "Dokončené",
      oilChange: "Výmena oleja",
      price: "Cena",
    },
    filterLabel: {
      actions: "Akcie",
      vehicleInternalName: "Interný názov",
      vehicleLpn: "ŠPZ",
      vehicleBrand: "Značka",
      vehicleModel: "Model",
      vehicleActive: "Aktívne",
      vehicleStatus: "Stav",
      vehicleMileages: "Stav tachometra",
      type: "Typ",
      status: "Stav",
    },
  },
  MaintenanceType: {
    REPAIR: "Oprava",
    MAINTENANCE: "Údržba",
    CHECKUP: "Kontrola",
    SHORT_TERM_INSPECTION: "Krátkodobá kontrola",
    LONG_TERM_INSPECTION: "Dlhodobá kontrola",
    SEASONAL_INSPECTION: "Sezónna kontrola",
    CLEANING: "Čistenie",
  },
  MaintenanceTypeArray:
    [
      { "text": "Oprava", "value": "REPAIR", },
      { "text": "Údržba", "value": "MAINTENANCE", },
      { "text": "Kontrola", "value": "CHECKUP", },
      { "text": "Krátkodobá kontrola", "value": "SHORT_TERM_INSPECTION", },
      { "text": "Dlhodobá kontrola", "value": "LONG_TERM_INSPECTION", },
      { "text": "Sezónna kontrola", "value": "SEASONAL_INSPECTION", },
      { "text": "Čistenie", "value": "CLEANING", },
    ],
  MaintenanceStatus: {
    PLANNED: "Plánované",
    DELAYED: "Omeškané",
    ONGOING: "Prebieha",
    PAUSED: "Pozastavené",
    COMPLETED: "Dokončené",
    CANCELLED: "Zrušené",
    UNFINISHED: "Nedokončené",
  },
  MaintenanceStatusArray:
    [
      { "text": "Plánované", "value": "PLANNED", },
      { "text": "Omeškané", "value": "DELAYED", },
      { "text": "Prebieha", "value": "ONGOING", },
      { "text": "Pozastavené", "value": "PAUSED", },
      { "text": "Dokončené", "value": "COMPLETED", },
      { "text": "Zrušené", "value": "CANCELLED", },
      { "text": "Nedokončené", "value": "UNFINISHED", },
    ],


  PlaceList: {
    page_title: "Zoznam miest",
    tabList: "Zoznam",
    tabMap: "Mapa",
    //Tab List

    //Tab Map
    toolbar_icon_title: "Prepnúť zobrazenie okna",
    toolbarList_title: "Adresy",
    searchField: "Hľadať",
    searchField_title: "Vyhľadávanie v zozname Adries potvrďte stlačením klávesy Enter.",
    showOnlyNotApprovedAddressesSwitch_title: "Zobraziť iba Adresy určené na schválenie",
    toolbarNew_title: "Vytvoriť nové miesto",
    searchNewGoogle: "Hľadať novú adresu GOOGLE",
    searchNewGeocode: "Hľadať novú adresu OSM",
    toolbarDetails_title: "Detail miesta",
    addressListItem_title: "Adresa zobrazovaná používateľovi",
    addressListItemSubtitle1_title: "GPS poloha miesta",
    addressListItemSubtitle1_text: "GPS súradnice",
    addressListItemSubtitle2_title: "Číslo domu, Cesta/Ulica", //houseNumber, road
    addressListItemSubtitle3_title: "Mesto, PSČ, Štát", //city, country
    addressListItemShowAddressBtn: "Zobraziť adresu na mape",
    displayName: "Názov",
    active: "Aktívny",
    latitude: "Zem. šírka",
    longitude: "Zem. dĺžka",
    houseNumber: "Číslo domu",
    road: "Cesta/Ulica",
    city: "Mesto",
    state: "Kraj",
    postcode: "PSČ",
    country: "Štát",
    countryCode: "Kód štátu",
    more: "Viac...",
    amenityName: "Popis miesta",
    amenityType: "Typ miesta",
    neighbourhood: "Lokalita mesta",
    suburb: "Štvrť mesta",
    cityDistrict: "Mestská časť",
    county: "Okres",
    ordering: "Poradie",
    priority: "Priorita",
    dialogTitle: "Miesto",
    tableHeaders: {
      actions: "Akcie",
      displayName: "Názov",
      active: "Aktívny",
      categoryName: "Kategória",
      city: "Mesto",
      countryCode: "Kód štátu",
      ordering: "Poradie",
      priority: "Priorita",
    },
  },

  DriverList: {
    page_title: "Zoznam vodičov",
    enabledDriversSwitch: "Povolení vodiči",
    dialogCreateTitle: "Nový vodič",
    dialogEditTitle: "Úprava vodiča",
    dialogEditStatusTitle: "Prajete si zmeniť stav vodiča?",
    dialogPasswordChangeTitle: "Prajete si zmeniť heslo vodičovi?",
    btnEdit: "Upraviť",
    btnEditStatus: "Zmeniť stav",
    btnPasswordChange: "Zmeniť heslo",
    btnDisable: "Zablokovať",
    btnEnable: "Povoliť",
    silenceDisabledTitle: "Tichý režim",
    driverId: "ID",
    id: "ID",
    email: "Email / Login",
    password: "Heslo",
    activeAcc: "Aktívny",
    firstName: "Meno",
    lastName: "Priezvisko",
    internalName: "Interné označenie",
    phone: "Telefón",
    phoneExt: "Telefónna klapka",
    gender: "Pohlavie",
    dispatcher: "Dispečer",
    tag: "Iné",
    driverLicenceNumber: "Číslo vodičského preukazu",
    driverLicenceExpiration: "Platnosť vodičského preukazu",
    driverTaxiLicenceNumber: "Číslo preukazu vodiča taxislužby",
    driverTaxiLicenceExpiration: "Platnosť preukazu vodiča taxislužby",
    language1: "Materinský jazyk",
    language2: "Jazyk 2",
    language3: "Jazyk 3",
    language4: "Jazyk 4",
    msgPasswordChanged: "Heslo bolo úspešne zmenené",
    msgInvalidDataInForm: "Formulár obsahuje neplatné údaje",
    msgDataMissing: "Formulár obsahuje nevyplnené údaje",
    msgDriverCreated: "Vodič bol úspešne vytvorený",
    msgDriverUpdated: "Vodič bol úspešne upravený",
    msgDriverStatusUpdated: "Stav vodiča bol úspešne zmenený",
    tableHeaders: {
      actions: "Akcie",
      email: "Email",
      internalName: "Interný názov",
      firstName: "Meno",
      lastName: "Priezvisko",
      active: "Voľba",
      workStatus: "Prac. zmena",
      silence: "Tichý režim",
      status: "Stav",
      phone: "Telefón",
      phoneExt: "Klapka",
      password: "Heslo",
      language1: "Jazyk 1",
      gender: "Pohlavie",
      dispatcher: "Dispečer",
      licenceStatus: "Stav licencie",
    },
    filterLabel: {
      email: "Email",
      internalName: "Interný názov",
      firstName: "Meno",
      lastName: "Priezvisko",
      active: "Voľba",
      workStatus: "Prac. zmena",
      silence: "Tichý režim",
      status: "Stav",
      phone: "Telefón",
      phoneExt: "Klapka",
    },
  },

  VehicleList: {
    page_title: "Zoznam vozidiel",
    dialogCreateTitle: "Nové vozidlo",
    dialogUpdateLastMaintenanceTitle: "Nastavenie poslednej údržby vozidla",
    btnSetLastMaintenanceAtFromVehicle: "Nastaviť údaje poslednej údržby vozidla",
    btnSetLastMaintenanceAtToNow: "Nastaviť dnešný dátum posledný stav tachometra",
    vehicleId: "ID",
    internalName: "Interné označenie",
    brand: "Značka",
    model: "Model",
    type: "Typ",
    lpn: "ŠPZ",
    color: "Farba",
    mileages: "Prejazdené km",
    lastMaintenanceAt: "Posledná údržba",
    lastMaintenanceMileage: "Posledná údržba (km)",
    tableHeaders: {
      actions: "Akcie",
      internalName: "Interné označenie",
      brand: "Značka",
      model: "Model",
      type: "Typ",
      lpn: "ŠPZ",
      active: "Aktívny",
      status: "Stav",
      color: "Farba",
      phoneExt: "Klapka",
      phone: "Telefón",
      mileages: "Prejazdené km",
      lastMaintenanceAt: "Posl. údržba",
      lastMaintenanceMileage: "Posl. údržba (km)",
      maintenanceIntervalDay: "Int. údržby (dni)",
      maintenanceIntervalMileage: "Int. údržby (km)",
      maintenanceNotificationDay: "Upoz. údržby (dni)",
      maintenanceNotificationMileage: "Upoz. údržby (km)",
    },
    filterLabel: {
      search: "Hľadať",
      internalName: "Interné označenie",
      brand: "Značka",
      model: "Model",
      type: "Typ",
      lpn: "ŠPZ",
      active: "Aktívny",
      status: "Stav",
      phoneExt: "Klapka",
      phone: "Telefón",
      lastMaintenanceAt: "Posledná údržba",
      lastMaintenanceMileage: "Posledná údržba (km)",
      maintenanceIntervalDay: "Interval údržby (dni)",
      maintenanceIntervalMileage: "Interval údržby (km)",
      maintenanceNotificationDay: "Upozornenie údržby (dni)",
      maintenanceNotificationMileage: "Upozornenie údržby (km)",
    },
  },

  Vehicle: {
    page_title: "Vozidlo",
    tabInfo: "Informácie",
    tabFilter: "Filtre",
    tabPhoto: "Fotka",
    internalName: "Interné označenie",
    brand: "Značka",
    model: "Model",
    type: "Typ",
    lpn: "ŠPZ",
    active: "Aktívny",
    status: "Stav",
    color: "Farba",
    phoneExt: "Telefónna klapka",
    phone: "Telefón",
    mileages: "Prejazdené km",
    lastMaintenanceAt: "Posledná údržba",
    lastMaintenanceMileage: "Posledná údržba (km)",
    maintenanceIntervalDay: "Interval údržby (dni)",
    maintenanceIntervalMileage: "Interval údržby (km)",
    maintenanceNotificationDay: "Upozornenie údržby (dni)",
    maintenanceNotificationMileage: "Upozornenie údržby (km)",
    msgInternalNameRule: "Nesmie byť dlhšie ako 3 znaky",  //Name must be no more than 3 characters
    mgsInternalNameHint: "Maximálne 3 znaky, odporúčané max. 2 znaky", //Maximum 3 characters (recommended: max 2)
    maxPerson: "Maximálny počet cestujúcich",
    airconditioning: "Klimatizácia",
    animal: "Zviera",
    autonomous: "Autonómne vozidlo",
    childrenChair: "Preprava dieťaťa",
    driverRating: "Hodnotenie vodiča",
    eco: "Ekologický",
    luggage: "Batožina",
    paymentTerminal: "Platobný terminál",
    smoking: "Fajčenie vo vozidle",
    specialTransport: "Špeciálna preprava",
    actualPicture: "Aktuálna fotka",
    newPicture: "Nová fotka",
    btnSelectPicture: "Vybrať fotku",
    btnSavePicture: "Uložiť fotku",
    examplePicture: "Príklad fotky",
    pictureHint: "Fotka musí byť vo formáte .jpg, .jpeg alebo .png a nesmie byť väčšia ako 2MB. " +
      "Fotka vozidla musí zachytávať pohľad na celé vozidlo z pravej prednej strany (trojštvrtinový pohľad na vozidlo). " +
      "Vozidlo na fotke pôsobí akoby prichádzalo k zákazníkovi z ľavej strany po ceste, a zákazník môže ľahko rozoznať prednú masku vozidla a zároveň pri pohľade z boku aký je to typ vozidla. " +
      "Výsledná fotka musí obsahovať vozidlo, ktoré vidno celé, neorezané. Ideálne so svetlým jednotným pozadím. ",
    uploadPlugin: {
      hint: "Kliknite alebo potiahnite súbor sem, aby ste ho nahrali",
      loading: "Nahrávanie…",
      noSupported:
        "Prehliadač nie je podporovaný, použite IE10+ alebo iné prehliadače",
      success: "Nahrávanie sa podarilo",
      fail: "Nahrávanie sa nepodarilo",
      preview: "Náhľad",
      btn: {
        off: "Zrušiť",
        close: "Zavrieť",
        back: "Späť",
        save: "Uložiť",
      },
      error: {
        onlyImg: "Iba obrázok",
        outOfSize: "Veľkosť obrázka prekročila limit: ",
        lowestPx: "Veľkosť obrázka je príliš malá. Očakáva sa aspoň: ",
      },
    },
  },
  VehicleEnums: {
    Status: {
      ACTIVE: "Aktívny",
      DISABLED: "Neaktívny",
      BLOCKED: "Zablokovaný",
      ARCHIVED: "Archivovaný",
    },
    StatusArray:
      [
        { "text": "Aktívny", "value": "ACTIVE", },
        { "text": "Neaktívny", "value": "DISABLED", },
        { "text": "Zablokovaný", "value": "BLOCKED", },
        { "text": "Archivovaný", "value": "ARCHIVED", },
      ],
    Type: {
      SEDAN: "Sedan",
      COMBI: "Combi",
      CABRIO: "Cabrio",
      COUPE: "Coupé",
      HATCHBACK: "Hatchback",
      VAN: "Van",
      MICROBUS: "Microbus",
      BUS: "Bus",
      VAN_CARGO: "Dodávka",
      PICKUP: "Pickup",
      SUV: "SUV",
    },
    TypeArray:
      [
        { "text": "Sedan", "value": "SEDAN", },
        { "text": "Combi", "value": "COMBI", },
        { "text": "Cabrio", "value": "CABRIO", },
        { "text": "Coupé", "value": "COUPE", },
        { "text": "Hatchback", "value": "HATCHBACK", },
        { "text": "Van", "value": "VAN", },
        { "text": "Microbus", "value": "MICROBUS", },
        { "text": "Bus", "value": "BUS", },
        { "text": "Dodávka", "value": "VAN_CARGO", },
        { "text": "Pickup", "value": "PICKUP", },
        { "text": "SUV", "value": "SUV", },
      ],
  },

  DispatcherList: {
    page_title: "Zoznam dispečerov",
    editInformation: "Upraviť informácie o dispečerovi",
    changePassword: "Zmeniť heslo dispečerovi",
    changeRole: "Zmeniť rolu dispečerovi",
    dialogCreateTitle: "Vytvoriť dispečera",
    dialogEditTitle: "Upraviť dispečera",
    dialogChangePasswordTitle: "Prajete si zmeniť heslo dispečerovi?",
    dialogChangePasswordText: "Oznámte prosím nové heslo dispečerovi. Dispečerovi nebude heslo zaslané.",
    dialogEditRoleTitle: "Prajete si zmeniť rolu dispečerovi?",
    msgPasswordChanged: "Heslo bolo úspešne zmenené",
    msgInvalidDataInForm: "Formulár obsahuje neplatné údaje",
    msgDispatcherRoleUpdated: "Stav vodiča bol úspešne zmenený",
    dispatcherId: "ID",
    id: "ID",
    email: "Email / Login",
    password: "Heslo",
    activeAcc: "Aktívny",
    firstName: "Meno",
    lastName: "Priezvisko",
    phone: "Telefón",
    role: "Rola",
    tableHeaders: {
      actions: "Akcie",
      email: "Email / Login",
      activeAcc: "Aktívny",
      firstName: "Meno",
      lastName: "Priezvisko",
      role: "Rola",
      phone: "Telefón",
    },
    filterLabel: {
      email: "Email",
      firstName: "Meno",
      lastName: "Priezvisko",
      phone: "Telefón",
    },
  },
  DispatcherRole: {
    ADMIN: "Admin",
    SUPPORT: "Podpora",
    OWNER: "Vlastník",
    MANAGER: "Manažér",
    MAINTAINER: "Údržbár",
    DISPATCHER: "Dispečer",
  },
  DispatcherRoleArray: [
    {
      "text": "Admin",
      "value": "ADMIN",
    },
    {
      "text": "Podpora",
      "value": "SUPPORT",
    },
    {
      "text": "Vlastník",
      "value": "OWNER",
    },
    {
      "text": "Manažér",
      "value": "MANAGER",
    },
    {
      "text": "Údržbár vozidiel",
      "value": "MAINTAINER",
    },
    {
      "text": "Dispečer",
      "value": "DISPATCHER",
    },
  ],
  DispatcherRoleForOwnerArray: [
    {
      "text": "Manažér",
      "value": "MANAGER",
    },
    {
      "text": "Údržbár",
      "value": "MAINTAINER",
    },
    {
      "text": "Dispečer",
      "value": "DISPATCHER",
    },
  ],

  LocalityList: {
    page_title: "Zoznam lokalít",
    tabLocalityPickup: "Vyzdvihnutie",
    tabLocalityPrice: "Cenové",
    tabLocalityCheckin: "Prihlasovacie",
    tabStands: "Stanovištia",
    activeOnlySwitch: "Zobraziť len aktívne",
    btnCreateCircle: "Vytvoriť kruh",
    btnCreatePolygon: "Vytvoriť polygón",
    dialogTitle: "Lokalita",
    id: "ID",
    priority: "Priorita",
    name: "Názov",
    active: "Aktívny",
    type: "Typ",
    latitude: "Zemepisná šírka",
    longitude: "Zemepisná dĺžka",
    radius: "Polomer",
    path: "Trasa",
    capacity: "Kapacita",
    defaultStand: "Hlavné stanovište",
    showPhone: "Vodičovi zobraziť telefónne číslo zákazníka",
    showDriverPhoneButton: "Vodičovi zobraziť tlačidlo telefonovania",
    showAllAddresses: "Vodičovi zobraziť všetky adresy",
    onlyManualSending: "Iba manuálne zaslanie objednávky",
    forcedPickupRadius: "Vynútenie vyzdvihnutia v okruhu (0 = vypnuté)",
    standId: "Stanovište",
    internalName: "Interný názov",
    city: "Mesto",
    country: "Krajina",
    street1: "Ulica",
    street2: "Ulica (2)",
    msgMax10Points: "Maximálny počet bodov v polygóne je 10. Prosím, nepridávajte viac bodov, inak systém nebude fungovať správne.",
    msgMax10PointsOver: "Príliš veľa bodov!!! Maximálny počet bodov v polygóne je 10. Prosím, odstráňte pomocou pravého tlačidla nepotrebné body, inak systém nebude fungovať správne.",
    editPolygonHelpText: "V polygóne je možné mať maximálne 10 bodov. Ak chcete pridať nový bod, kliknite a presuňte manipulačný bod, ktorý sa nachádza v strede medzi existujúcimi bodmi. Ak chcete odstrániť nepotrebný bod, kliknite naňho pravým tlačidlom myši.",
    msgErrorToEditLocality: "Chyba pri editácii lokality",
    msgDataMissing: "Chýbajúce údaje.",
    msgDefaultStandNotSet: "Hlavné stanovište nie je nastavené!",
    msgDefaultStandMoreThenOne: "Hlavné stanovište je viac ako jedno!",
    tableHeaders: {
      actions: "Akcie",
      id: "ID",
      priority: "Priorita",
      name: "Názov",
      active: "Aktívny",
      type: "Typ",
      latitude: "Zem. šírka",
      longitude: "Zem. dĺžka",
      radius: "Polomer",
      path: "Trasa",
      showDriverPhoneButton: "Tl. telefon",
      showPhone: "Telefón",
      showAllAddresses: "Adresy",
      onlyManualSending: "Manuálne zaslanie",
      forcedPickupRadius: "Vynútený polomer",
      standId: "Stanovište",
      capacity: "Kapacita",
      defaultStand: "Hlavné",
    },
    showMarkers: "Zobraziť značky",
    editPolygonPointsTitle: "Upraviť body polygónu",
    pathLatitude: "Zemepisná šírka",
    pathLongitude: "Zemepisná dĺžka",
    closePathChange: "Upraviť bod podľa blízkeho bodu iného polygónu",
  },

  PricelistList: {
    page_title: "Zoznam cenníkov",
    newPricelistDialogTitle: "Nový cenník",
    name: "Názov",
    validFrom: "Platný od",
    service: "Služba",
    tableHeaders: {
      actions: "Akcie",
      name: "Názov",
      active: "Aktívny",
      validFrom: "Platný od",
      service: "Služba",
      currency: "Mena",
    },
  },
  Pricelist: {
    page_title: "Cenník",
    tabBasic: "Základné informácie",
    tabPriceSettings: "Nastavenia cien",
    tabPrices: "Cenové položky",
    tabExtras: "Príplatky",
    //TAB BASIC
    id: "ID",
    name: "Názov",
    note: "Poznámka",
    active: "Aktívny",
    validFrom: "Platný od",
    service: "Služba",
    showPrice: "Zobraziť cenu",
    currency: "Mena",
    currencySymbol: "Symbol meny",
    priceRound: "Zaokrúhlovanie ceny",
    //TAB PRICE SETTINGS
    fareType: "Typ sadzby",
    fareChangeToDefault: "Zmena sadzby na Default",
    fixation: "Fixácia",
    waypointInProgressRecalcPrice: "Medzizastávka - prepočet ceny počas jazdy",
    fareStartSubheader: "Cena za vyzdvihnutie zákazníka",
    fareStartType: "Typ sadzby za vyzdvihnutie zákazníka",
    fareStart: "Sazba za vyzdvihnutie zákazníka",
    fareWaitingSubheader: "Čakanie na zákazníka pred začatím prepravy",
    fareWaitingLimit: "Časový limit čakania (min.)",
    fareWaiting: "Sadzba za čakanie",
    fareDriveSubheader: "Cena za prepravu",
    fareDriveType: "Typ sadzby za prepravu",
    fareDriveDistance: "Sadzba za vzdialenosť prepravy",
    fareDriveTime: "Sadzba za čas prepravy",
    fareJourneyMinimumSubheader: "Minimálna cena za jazdu",
    fareJourneyMinimumType: "Typ minimálnej ceny za jazdu",
    fareJourneyMinimum: "Minimálna cena za jazdu",
  },
  PricelistEnums: {
    showPrice: {
      ALL: "Zobraziť všetkým",
      ONLY_DRIVER: "Zobraziť iba vodičovi",
      HIDDEN: "Nezobraziť nikomu",
    },
    showPriceArray: [
      { "text": "Zobraziť všetkým", "value": "ALL", },
      { "text": "Zobraziť iba vodičovi", "value": "ONLY_DRIVER", },
      { "text": "Nezobraziť nikomu", "value": "HIDDEN", },
    ],

    FareType: {
      DEFAULT: "Defaultné sadzby",
      LOCALITY: "Sadzby podľa lokality",
    },
    FareTypeArray:
      [
        { "text": "Defaultné sadzby", "value": "DEFAULT", },
        { "text": "Sadzby podľa lokality", "value": "LOCALITY", },
      ],

    FareJourneyMinimumType: {
      DEFAULT: "Defaultná minimálna cena",
      LOCALITY: "Minimálna cena podľa lokality",
    },
    FareJourneyMinimumTypeArray:
      [
        { "text": "Defaultná minimálna cena", "value": "DEFAULT", },
        { "text": "Minimálna cena podľa lokality", "value": "LOCALITY", },
      ],

    FareDriveType: {
      FIXED: "Pevná cena",
      DISTANCE: "Vzdialenosť a čas",
    },
    FareDriveTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Vzdialenosť a čas", "value": "DISTANCE", },
      ],

    FareStartType: {
      FIXED: "FIXED",
      FROM_STAND: "FROM_STAND",
      TO_STAND: "TO_STAND",
      BETWEEN_STAND: "BETWEEN_STAND",
    },
    FareStartTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Stanovište->Vyzdvihnutie", "value": "FROM_STAND", },
        { "text": "Cieľ->Stanovište", "value": "TO_STAND", },
        { "text": "Stanovište->Vyzdvihnutie, Cieľ->Stanovište", "value": "BETWEEN_STAND", },
      ],

    FareChangeToDefault: {
      NONE: "NONE",
      WAITING: "Čakačka",
      WAYPOINT: "Medzizastávka",
      ACCOUNT: "Firemná jazda",
      WAITING_WAYPOINT: "Čakačka a Medzizastávka",
      WAITING_ACCOUNT: "Čakačka a firemná jazda",
      WAYPOINT_ACCOUNT: "Medzizastávka a firemná jazda",
      WAITING_WAYPOINT_ACCOUNT: "Čakačka, Medzizastávka a firemná jazda",
    },
    FareChangeToDefaultArray:
      [
        { "text": "Žiadna", "value": "NONE", },
        { "text": "Čakačka", "value": "WAITING", },
        { "text": "Medzizastávka", "value": "WAYPOINT", },
        { "text": "Firemná jazda", "value": "ACCOUNT", },
        { "text": "Čakačka a Medzizastávka", "value": "WAITING_WAYPOINT", },
        { "text": "Čakačka a firemná jazda", "value": "WAITING_ACCOUNT", },
        { "text": "Medzizastávka a firemná jazda", "value": "WAYPOINT_ACCOUNT", },
        { "text": "Čakačka, Medzizastávka a firemná jazda", "value": "WAITING_WAYPOINT_ACCOUNT", },
      ],

    Service: {
      PERSONAL: "Osobná preprava",
      LIMO: "Limuzínová preprava",
      CARGO: "Nákladná preprava",
      DRINK: "Drinktaxi služba",
      IMMOBILE: "Preprava imobilných osôb",
      COURIER: "Kuriérska služba",
      DELIVERY: "Doručovacia služba",
      AIRPORT: "Letiskové taxi",
      SOCIAL: "Sociálne taxi",
    },
    ServiceArray:
      [
        { "text": "Osobná preprava", "value": "PERSONAL", },
        { "text": "Limuzínová preprava", "value": "LIMO", },
        { "text": "Nákladná preprava", "value": "CARGO", },
        { "text": "Drinktaxi služba", "value": "DRINK", },
        { "text": "Preprava imobilných osôb", "value": "IMMOBILE", },
        { "text": "Kuriérska služba", "value": "COURIER", },
        { "text": "Doručovacia služba", "value": "DELIVERY", },
        { "text": "Letiskové taxi", "value": "AIRPORT", },
        { "text": "Sociálne taxi", "value": "SOCIAL", },
      ],
  },
  Prices: {
    btnGenerate: "Generovať ceny",
    btnRegenerate: "Pregenerovať ceny",
    btnSave: "Uložiť",
    btnCancel: "Zrušiť",
    btnEdit: "Upraviť",
    activeOnlySwitch: "Iba aktívne",
    generatePricesDialogTitle: "Prajete si pregenerovať ceny?",
    generatePricesDialogText: "Pri pregenerovaní cien sa vytvoria cenové položky zo všetkých kombinácií Cenových lokalít. Ak bola niektorá lokalita deaktivovaná alebo pridaná, adekvátne som sa zablokujú alebo pridajú cenové položky. Odporúčame pregenerovať ceny pri každej zmene v Cenových lokalitách.",
    msgGeneratePricesSuccess: "Ceny boli úspešne pregenerované.",
    msgGeneratePricesError: "Pri pregenerovaní cien nastala chyba.",
    msgUpdatePricesSuccess: "Ceny boli úspešne aktualizované.",
    msgUpdatePricesError: "Pri aktualizovaní cien nastala chyba.",
    tableHeaders: {
      name: "Názov",
      active: "Aktívny",
      fareStartType: "Typ ceny za vyzdvihnutie",
      fareStart: "Cena za vyzdvihnutie",
      fareWaitingLimit: "Limit čakania pred prepravou",
      fareWaiting: "Cena za čakanie pred prepravou",
      fareDriveType: "Typ ceny za prepravu",
      fareDriveDistance: "Cena za vzdialenosť",
      fareDriveTime: "Cena za čas",
      fareJourneyMinimum: "Minimálna cena za jazdu",
      note: "Poznámka",
      localityPriceFromId: "Z",
      localityPriceToId: "Do",
    },
  },
  PricesEnums: {
    FareStartType: {
      FIXED: "Pevná cena", // Pevná suma
      FROM_STAND: "Cena za vzdialenosť Stanovište->Vyzdvihnutie", // PRÍSTAVNÉ obsahuje jazdu zo Stanovišťa
      TO_STAND: "Cena za vzdialenosť Cieľ->Stanovište", // PRÍSTAVNÉ obsahuje jazdu na Stanovište
      BETWEEN_STAND: "Cena za vzdialenosť Stanovište->Vyzdvihnutie, Cieľ->Stanovište", // PRÍSTAVNÉ obsahuje jazdu z aj na Stanovište
    },
    FareStartTypeShort: {
      FIXED: "Pevná", // Pevná suma
      FROM_STAND: "S>V", // PRÍSTAVNÉ obsahuje jazdu zo Stanovišťa
      TO_STAND: "C>S", // PRÍSTAVNÉ obsahuje jazdu na Stanovište
      BETWEEN_STAND: "S>V|C>S", // PRÍSTAVNÉ obsahuje jazdu z aj na Stanovište
    },
    FareStartTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Stanovište->Vyzdvihnutie", "value": "FROM_STAND", },
        { "text": "Cieľ->Stanovište", "value": "TO_STAND", },
        { "text": "Stanovište->Vyzdvihnutie, Cieľ->Stanovište", "value": "BETWEEN_STAND", },
      ],
    FareDriveType: {
      FIXED: "Pevná cena",
      DISTANCE: "Cena za vzdialenosť a čas prepravy",
    },
    FareDriveTypeShort: {
      FIXED: "Pevná",
      DISTANCE: "Vzdialenosť",
    },
    FareDriveTypeArray:
      [
        { "text": "Pevná cena", "value": "FIXED", },
        { "text": "Vzdialenosť a čas", "value": "DISTANCE", },
      ],
  },
  PriceExtra: {
    btnCreate: "Vytvoriť",
    btnCreateObligatory: "Vytvoriť povinnú položku",
    priceExtraDialogTitle: "Príplatok",
    priceExtraObligatoryDialogTitle: "Povinná položka cenníka",
    name: "Názov",
    driverSummary: "Zobraziť v prehľade vodiča",
    automatic: "Automaticky vytváraný",
    active: "Aktívny",
    type: "Typ",
    price: "Cena/hodnota",
    operation: "Spôsob výpočtu ceny príplatku",
    resetToDefaultPrice: "Resetuje na defaultnú cenu",
    cancelPriceFixation: "Zruší fixáciu ceny",
    editable: "Editovateľný",
    occurenceLimit: "Maximálny počet vytvorených príplatkov",
    ordering: "Poradie v prehľade vodiča",
    closeBtn: "Zavrieť",
    saveBtn: "Uložiť",
    msgSaveSuccess: "Príplatok bol úspešne uložený",
    msgSaveError: "Príplatok sa nepodarilo uložiť",
    msgGetDataError: "Och, chyba.... Niečo sa pokazilo pri načítaní dát",
    msgObligatoryPriceExtraWarning: "Vytvor najprv povinné položky 'Cena za jazdu' (PRICE_JOURNEY) a 'Cena za čakanie pred jazdou' (PRICE_WAITING)!",
    tableHeaders: {
      actions: "Akcie",
      name: "Názov",
      active: "Aktívny",
      type: "Typ",
      price: "Cena",
      resetToDefaultPrice: "Resetuje na default",
      cancelPriceFixation: "Zruší fixáciu",
      operation: "Výpočet",
      automatic: "Automaticky",
      driverSummary: "Sumarizácia",
      editable: "Editovateľný",
      occurenceLimit: "Max. výskytov",
      ordering: "Poradie",
    },
  },
  PriceExtraEnums: {
    Operation: {
      NONE: "Nezadaný", 	// Nezadaný (0)
      ONE_OFF: "Jednorázový", 	// Jednorázový (1)
      PERC_PRICE_DRIVE: "Percento k cene prepravy", 	//Percento k cene prepravy (2)  ==> premenovať z PERC_TRANSFER na PERC_PRICE_DRIVE
      PERC_PRICE_JOURNEY: "Percento k sumárnej cene", 	// Percento k sumárnej cene (3) ==> premenovať z PERC_SUMMARY na PERC_PRICE_JOURNEY
      DRIVE_TIME: "Za minútu jazdy", 	// Za minútu jazdy (4)
      DRIVE_DISTANCE: "Za kilometer jazdy", 	// Za kilometer jazdy (5)
      WAITING_TIME: "Za min. čakania pri preprave", 	// Za minútu čakania v stave 6 - za kazdu zacatu minutu (6)
      WAITING_TIME_EXACT: "Za min. čakania pri preprave po sek.", 	// Za minútu čakania v stave 6 presne na sekundy, resp. min 3 desatinne miesta(6)
      //WAITING_PICKUP_TIME: "WAITING_PICKUP_TIME", 	// Za minútu čakania v stave 5 (5)
    },
    OperationArray:
      [
        { "text": "Nezadaný", "value": "NONE", },
        { "text": "Jednorázový", "value": "ONE_OFF", },
        { "text": "Percento k cene prepravy", "value": "PERC_PRICE_DRIVE", },
        { "text": "Percento k sumárnej cene", "value": "PERC_PRICE_JOURNEY", },
        { "text": "Za minútu jazdy", "value": "DRIVE_TIME", },
        { "text": "Za kilometer jazdy", "value": "DRIVE_DISTANCE", },
        { "text": "Za minútu čakania počas prepravy", "value": "WAITING_TIME", },
        { "text": "Za minútu čakania počas prepravy presne na sekundy", "value": "WAITING_TIME_EXACT", },
        //{ "text": "Za minútu čakania v stave 5", "value": "WAITING_PICKUP_TIME", },
      ],
    Type: {
      //Prve 2 su obligatorne, a v arrany sa nachadzaju samostatne, lebo iné dialogove okno
      PRICE_WAITING: "Cena za čakanie pred jazdou (P)",
      PRICE_JOURNEY: "Cena za jazdu (P)",
      NONE: "Nezadaný", 	// Nezadaný (0)
      GENERAL: "Všeobecný poplatok", 	// Všeobecný poplatok (1)
      TYPE_INSTANT: "Objednávka okamžite (A)", 	// Objednávka okamžite (2)
      TYPE_TIME: "Objednávka na čas (A)",
      TYPE_STREET: "Objednávka z ulice (A)",
      //TYPE_DIRECT: "Objednávka priama (A)",
      //TYPE_REPEATED: "Objednávka opakovaná (A)",
      //TYPE_AIRPORT: "Objednávka letisko (A)",
      WAITING: "Čakačka (A)", 	// Poplatok za čakanie (2)
      PREORDER: "Predobjednávka", 	// Objednávka na čas (3)
      NIGHT: "Nočný príplatok", 	// Nočný príplatok (4)
      WEEKEND: "Víkendový príplatok", 	// Víkendový príplatok (5)
      OFF_TIME: "Nočný/víkendový príplatok", 	// Nočný/víkendový príplatok (6)
      AIRPORT_TRANSPORT: "Letiskový príplatok", 	// Letiskový príplatok (7)
      STATION_TRANSPORT: "Staničný príplatok", 	// Staničný príplatok (8)
      ABROAD_TRANSPORT: "Zahraničný príplatok", 	// Zahraničný príplatok (9)
      PICKUP: "Poplatok za vyzdvihnutie", 	// Poplatok za vyzdvihnutie 
      PICKUP_CITY: "Vyzdvihnutie v meste", 	// Vyzdvihnutie v meste (10)
      PICKUP_OUT_CITY: "Vyzdvihnutie mimo mesta", 	// Vyzdvihnutie mimo mesta (11)
      DRINK_TRANSPORT: "Príplatok za prepravu vozidla zákazníka", 	// Príplatok za prepravu vozidla zákazníka
      LUGGAGE_SMALL: "Batožina malá (A)", 	// Malá batožina (12)
      LUGGAGE_MEDIUM: "Batožina stredná (A)", 	// Stredná batožina (12)
      LUGGAGE_BIG: "Batožina veľká (A)", 	// Veľká batožina (12)
      PAYMENT_TERMINAL: "Platobný terminál (A)", 	// Platobný terminál vo vozidle
      SPECIAL_TRANSPORT_OLD: "Preprava starších ľudí (A)", 	// Preprava starších ľudí
      SPECIAL_TRANSPORT_CHAIR: "Preprava ľudí na vozíčku (A)", 	// Preprava ľudí na vozíčku 
      AIRCONDITION: "Klimatizácia", 	// Klimatizácia (14)
      BOOSTER: "Detský podsedák (A)", 	// Detský podsedák (15)
      SEAT: "Detská sedačka (A)", 	// Detská sedačka (16)
      ANIMAL_SMALL: "Zviera malé (A)", 	// Preprava zvieraťa (17)
      ANIMAL_MEDIUM: "Zviera stredné (A)", 	// Preprava zvieraťa (17)
      ANIMAL_BIG: "Zviera veľké (A)", 	// Preprava zvieraťa (17)
      PREFERRED_DRIVER: "Preferovaný šofér (A)", 	// Preferovaný šofér (18)
      FOULING: "Znečistenie", 	// Znečistenie (18)
      STAINING: "Veľké znečistenie", 	// Veľké znečistenie (19)
      /*VEHICLE_SEDAN: "Sedan", 	// Sedan (21)
      VEHICLE_COMBI: "Combi", 	// Combi (22)
      VEHICLE_CABRIO: "Cabrio", 	// Cabrio (23)
      VEHICLE_COUPE: "Coupé", 	// Coupé (24)
      VEHICLE_HATCHBACK: "Hatchback", 	// Hatchback (25)
      VEHICLE_VAN: "Van", 	// Van (26)
      VEHICLE_MICROBUS: "Microbus", 	// Microbus (27)
      VEHICLE_BUS: "Bus", 	// Bus (28)
      VEHICLE_VAN_CARGO: "Dodávka", 	// Dodávka (29)
      VEHICLE_PICKUP: "Pickup", 	// Pickup (30)
      VEHICLE_SUV: "SUV", 	// SUV (31)
      */
      PERSONS_2: "Požadovaný počet osôb 2 (A)", 	// Maximálny požadovaný počet osôb 2 (32)
      PERSONS_3: "Požadovaný počet osôb 3 (A)", 	// Maximálny požadovaný počet osôb 3 (32)
      PERSONS_4: "Požadovaný počet osôb 4 (A)", 	// Maximálny požadovaný počet osôb 4 (32)
      PERSONS_5: "Požadovaný počet osôb 5 (A)", 	// Maximálny požadovaný počet osôb 5 (32)
      PERSONS_6: "Požadovaný počet osôb 6 (A)", 	// Maximálny požadovaný počet osôb 6 (32)
      PERSONS_7: "Požadovaný počet osôb 7 (A)", 	// Maximálny požadovaný počet osôb 7 (32)
      PERSONS_8: "Požadovaný počet osôb 8 (A)", 	// Maximálny požadovaný počet osôb 8 (32)
      SERVICE_PERSONAL: "Služba osobnej prepravy (A)", 	// Osobná preprava (41)
      //SERVICE_LIMO: "Služba limuzínovej prepravy (A)", 	// Limuzínová preprava (42)
      //SERVICE_CARGO: "Služba nákladnej prepravy (A)", 	// Nákladná preprava (43)
      //SERVICE_DRINK: "Služba Drink taxi (A)", 	// Drink taxi (44)
      //SERVICE_IMMOBILE: "Služba prepravy imobilných (A)", 	// Preprava imobilných (45)
      //SERVICE_COURIER: "Kuriérska služba (A)", 	// Kurierská služba (46)
      //SERVICE_DELIVERY: "Donášková služba (A)", 	// Donášková služba (47)
      //SERVICE_AIRPORT: "Služba prepravy na letisko (A)", 	// Letiskové taxi (48)
      //SERVICE_SOCIAL: "Služba sociálneho taxi (A)", 	// Sociálne taxi (49)
      PAYMENT_CASH: "Platba v hotovosti (A)", 	// Hotovosť (51)
      PAYMENT_CARD: "Platba kartou vo vozidle (A)", 	// Karta vo vozidle (52)
      //PAYMENT_VIAMO: "Platba cez VIAMO (A)", 	// Viamo (53)
      //PAYMENT_COUPON: "Platba kupónom (A)", 	// Kupón (54)
      PAYMENT_CARD_ONLINE: "Platba kartou online (A)", 	// Karta online (55)
      //PAYMENT_PREPAID: "Platba z kreditu (A)", 	// Preplatená jazda (56)
      PAYMENT_POSTPAID: "Platba po jazde/postpaid/faktúra (A)", 	// Platba po jazde/postpaid (57)
      //PAYMENT_INVOICE: "Platba na faktúru (A)", 	// Platba na faktúru (58)
      PICKUP_PLACE_CHANGED: "Zmena miesta vyzdvihnutia",
      DESTINATION_PLACE_CHANGED: "Zmena cieľa cesty",
      WAYPOINT: "Medzizastávka/Prejazdový bod (A)", //AUTOMATICALLY ADDED
      WAYPOINT_STOP: "Medzizastávka", 	// Zastávka 
      //WAYPOINT_INFO: "WAYPOINT_INFO", 	// Informácia o cene za medzizastávky (0)
      WAYPOINT_DISTRICT: "Medzizastávka vo štvrti", 	// Extra zastávka vo štvrti (61)
      WAYPOINT_CITY: "Medzizastávka v meste", 	// Extra zastávka v meste (62)
      BATTERY: "Nabitie batérie", 	// Nabitie batérie (67)
      FUEL: "Poskytnutie paliva", 	// Poskytnutie paliva (68)
      DRAG_VEHICLE: "Odtiahnutie vozidla", 	// Odtiahnutie vozidla (69)
      REGISTRATION: "Registrácia", 	// Registrácia (71)
      BLOCKING: "Zablokovanie", 	// Zablokovanie (72)
      UNBLOCKING: "Odblokovanie", 	// Odblokovanie (73)
      SOURCE_DISPATCH_TAXISERVICE: "Zdroj dispečing (A)", 	// Objednávka z dispečingu (74)
      //SOURCE_DISPATCH_BROKER: "Zdroj dispečing brokera (A)", 	// Objednávka z dispečingu (74)
      //SOURCE_DISPATCH_TAXXIMO: "Zdroj dispečing taxximo (A)", 	// Objednávka z dispečingu (74)
      SOURCE_DRIVER: "Zdroj vodič (A)", 	// Objednávka od vodiča z ulice (75)
      SOURCE_APP: "Zdroj appka zákazníka (A)", 	// Objednávka z aplikácie zákazníka (76)
      SOURCE_BRANDAPP: "Zdroj brandovaná appka zákazníka (A)", 	// Objednávka od brandovanej aplikácie zákazníka (77)
      ACCOUNT: "Firemná jazda", 	// Zmluvná/firemný jazda (78)
      //STORNO_PREREGISTERED: "Storno predregistrovanej objednávky", 	 	// STORNO PREREGISTERED -> Odregistrovaná/Unregistered (88)
      //STORNO_NEW: "Storno novej objednávky", 	 	// STORNO Nová -> Nerealizovaná/Expired  (81)
      //STORNO_PLANNED: "Storno plánovanej objednávky", 	 	// STORNO Plánovaná -> Odvolaná/Revoked  (82)
      //STORNO_PENDING: "Storno čakajúcej objednávky", 	 	// STORNO Čakajúca -> Odmietnutá/Declined  (83)
      //STORNO_ACCEPTED: "Storno akceptovanej objednávky", 	 	// STORNO Akcpetovaná -> Zrušená/Canceled  (84)
      //STORNO_WAITING: "Storno objednávky na mieste", 	 	// STORNO Na mieste -> Zamietnutá/Dismissed  (85)
      //STORNO_IN_PROGRESS: "Storno prepravovanej objednávky", 	 	// STORNO Realizovaná -> Nedokončená/Unfinished  (86)
      //STORNO_FINISHED: "Storno ukončenej objednávky", 	 	// STORNO Ukončená -> Nenaúčtovaná/Uncharged  (87)
      CUSTOMER_NEW: "Zákazník nový (A)", 	// Nový zákazník
      CUSTOMER_REGULAR: "Zákazník stály (A)", 	// Stály zákazník
      CUSTOMER_PRIORITY: "Zákazník prioritný (A)", 	// Prioritný zákazník
      CUSTOMER_FAMOUS: "Zákazník známa osobnosť (A)", 	// Známa osobnosť
      CUSTOMER_VIP: "Zákazník VIP (A)", 	// VIP klient
      CUSTOMER_COLLEAGUE: "Zákazník je kolega (A)", 	// Kolega z taxislužby
      CUSTOMER_STUDENT: "Zákazník študent (A)", 	// Študent
      CUSTOMER_CHILD: "Zákazník dieťa (A)", 	// Dieťa
      CUSTOMER_JUNIOR: "Zákazník junior (A)", 	// Junior
      CUSTOMER_SENIOR: "Zákazník Senior (A)", 	// Senior
      CUSTOMER_DEBTOR: "Zákazník je dlžník (A)", 	// Dlžník
      CUSTOMER_BLOCKED: "Zákazník je blokovaný (A)", 	// Na zozname zakázaných zákazníkov
      SOURCE_BRANDAPP_FIRST_TWO_ORDERS: "Zdroj appka zákazníka max.2 obj (A)", 	// Objednávka od brandovanej aplikácie zákazníka (77)
      SOURCE_BRANDAPP_EVERY_FIVE_ORDERS: "Zdroj appka zákazníka každých 5 obj (A)" 	// Objednávka od brandovanej aplikácie zákazníka (77)
    },
    TypeArray: [
      { "text": "Nezadaný", "value": "NONE", },
      { "text": "Všeobecný poplatok", "value": "GENERAL", },
      { "text": "Objednávka okamžite (A)", "value": "TYPE_INSTANT", },
      { "text": "Objednávka na čas (A)", "value": "TYPE_TIME", },
      { "text": "Objednávka z ulice (A)", "value": "TYPE_STREET", },
      //{ "text": "Objednávka priama (A)", "value": "TYPE_DIRECT", },
      //{ "text": "Objednávka opakovaná (A)", "value": "TYPE_REPEATED", },
      //{ "text": "Objednávka letisko (A)", "value": "TYPE_AIRPORT", },
      { "text": "Čakačka (A)", "value": "WAITING", },
      { "text": "Predobjednávka", "value": "PREORDER", },
      { "text": "Nočný príplatok", "value": "NIGHT", },
      { "text": "Víkendový príplatok", "value": "WEEKEND", },
      { "text": "Nočný/víkendový príplatok", "value": "OFF_TIME", },
      { "text": "Letiskový príplatok", "value": "AIRPORT_TRANSPORT", },
      { "text": "Staničný príplatok", "value": "STATION_TRANSPORT", },
      { "text": "Zahraničný príplatok", "value": "ABROAD_TRANSPORT", },
      { "text": "Poplatok za vyzdvihnutie", "value": "PICKUP", },
      { "text": "Vyzdvihnutie v meste", "value": "PICKUP_CITY", },
      { "text": "Vyzdvihnutie mimo mesta", "value": "PICKUP_OUT_CITY", },
      { "text": "Príplatok za prepravu vozidla zákazníka (Drink taxi)", "value": "DRINK_TRANSPORT", },
      { "text": "Batožina malá (A)", "value": "LUGGAGE_SMALL", },
      { "text": "Batožina stredná (A)", "value": "LUGGAGE_MEDIUM", },
      { "text": "Batožina veľká (A)", "value": "LUGGAGE_BIG", },
      { "text": "Platobný terminál (A)", "value": "PAYMENT_TERMINAL", },
      { "text": "Preprava starších ľudí (A)", "value": "SPECIAL_TRANSPORT_OLD", },
      { "text": "Preprava ľudí na vozíčku (A)", "value": "SPECIAL_TRANSPORT_CHAIR", },
      { "text": "Klimatizácia", "value": "AIRCONDITION", },
      { "text": "Detský podsedák (A)", "value": "BOOSTER", },
      { "text": "Detská sedačka (A)", "value": "SEAT", },
      { "text": "Zviera malé (A)", "value": "ANIMAL_SMALL", },
      { "text": "Zviera stredné (A)", "value": "ANIMAL_MEDIUM", },
      { "text": "Zviera veľké (A)", "value": "ANIMAL_BIG", },
      { "text": "Preferovaný šofér (A)", "value": "PREFERRED_DRIVER", },
      { "text": "Znečistenie", "value": "FOULING", },
      { "text": "Veľké znečistenie", "value": "STAINING", },
      /*{ "text": "Sedan", "value": "VEHICLE_SEDAN", }, 	//  (21)
      { "text": "Combi", "value": "VEHICLE_COMBI", }, 	//  (22)
      { "text": "Cabrio", "value": "VEHICLE_CABRIO", }, 	//  (23)
      { "text": "Coupé", "value": "VEHICLE_COUPE", }, 	//  (24)
      { "text": "Hatchback", "value": "VEHICLE_HATCHBACK", }, 	//  (25)
      { "text": "Van", "value": "VEHICLE_VAN", }, 	//  (26)
      { "text": "Microbus", "value": "VEHICLE_MICROBUS", }, 	//  (27)
      { "text": "Bus", "value": "VEHICLE_BUS", }, 	//  (28)
      { "text": "Dodávka", "value": "VEHICLE_VAN_CARGO", }, 	//  (29)
      { "text": "Pickup", "value": "VEHICLE_PICKUP", }, 	//  (30)
      { "text": "SUV", "value": "VEHICLE_SUV", }, 	//  (31)
      */
      { "text": "Požadovaný počet osôb 2 (A)", "value": "PERSONS_2", },
      { "text": "Požadovaný počet osôb 3 (A)", "value": "PERSONS_3", },
      { "text": "Požadovaný počet osôb 4 (A)", "value": "PERSONS_4", },
      { "text": "Požadovaný počet osôb 5 (A)", "value": "PERSONS_5", },
      { "text": "Požadovaný počet osôb 6 (A)", "value": "PERSONS_6", },
      { "text": "Požadovaný počet osôb 7 (A)", "value": "PERSONS_7", },
      { "text": "Požadovaný počet osôb 8 (A)", "value": "PERSONS_8", },
      { "text": "Služba osobnej prepravy (A)", "value": "SERVICE_PERSONAL", },
      //{ "text": "Služba limuzínovej prepravy (A)", "value": "SERVICE_LIMO", }, 	
      //{ "text": "Služba nákladnej prepravy (A)", "value": "SERVICE_CARGO", }, 
      //{ "text": "Služba Drink taxi (A)", "value": "SERVICE_DRINK", }, 	
      //{ "text": "Služba prepravy imobilných (A)", "value": "SERVICE_IMMOBILE", }, 	
      //{ "text": "Kuriérska služba (A)", "value": "SERVICE_COURIER", }, 
      //{ "text": "Donášková služba (A)", "value": "SERVICE_DELIVERY", }, 
      //{ "text": "Služba prepravy na letisko (A)", "value": "SERVICE_AIRPORT", }, 
      //{ "text": "Služba sociálneho taxi (A)", "value": "SERVICE_SOCIAL", }, 
      { "text": "Platba v hotovosti (A)", "value": "PAYMENT_CASH", },
      { "text": "Platba kartou vo vozidle (A)", "value": "PAYMENT_CARD", },
      //{ "text": "Platba cez VIAMO (A)", "value": "PAYMENT_VIAMO", },
      //{ "text": "Platba kupónom (A)", "value": "PAYMENT_COUPON", }, 
      { "text": "Platba kartou online (A)", "value": "PAYMENT_CARD_ONLINE", },
      //{ "text": "Platba z kreditu (A)", "value": "PAYMENT_PREPAID", }, 
      { "text": "Platba po jazde/postpaid/faktúra (A)", "value": "PAYMENT_POSTPAID", },
      //{ "text": "Platba na faktúru (A)", "value": "PAYMENT_INVOICE", }, 	
      { "text": "Zmena miesta vyzdvihnutia", "value": "PICKUP_PLACE_CHANGED", },
      { "text": "Zmena cieľa cesty", "value": "DESTINATION_PLACE_CHANGED", },
      { "text": "Medzizastávka/Prejazdový bod (A)", "value": "WAYPOINT", },
      { "text": "Medzizastávka", "value": "WAYPOINT_STOP", }, 	// Zastávka 
      //{ "text": "WAYPOINT_INFO", "value": "WAYPOINT_INFO", }, 	
      { "text": "Medzizastávka vo štvrti", "value": "WAYPOINT_DISTRICT", },
      { "text": "Medzizastávka v meste", "value": "WAYPOINT_CITY", },
      { "text": "Nabitie batérie", "value": "BATTERY", },
      { "text": "Poskytnutie paliva", "value": "FUEL", },
      { "text": "Odtiahnutie vozidla", "value": "DRAG_VEHICLE", },
      { "text": "Registrácia", "value": "REGISTRATION", },
      { "text": "Zablokovanie", "value": "BLOCKING", },
      { "text": "Odblokovanie", "value": "UNBLOCKING", },
      { "text": "Zdroj dispečing (A)", "value": "SOURCE_DISPATCH_TAXISERVICE", },
      //{ "text": "Zdroj dispečing brokera (A)", "value": "SOURCE_DISPATCH_BROKER", }, 
      //{ "text": "Zdroj dispečing taxximo (A)", "value": "SOURCE_DISPATCH_TAXXIMO", }, 
      { "text": "Zdroj vodič (A)", "value": "SOURCE_DRIVER", },
      { "text": "Zdroj appka zákazníka (A)", "value": "SOURCE_APP", },
      { "text": "Zdroj brandovaná appka zákazníka (A)", "value": "SOURCE_BRANDAPP", },
      { "text": "Firemná jazda", "value": "ACCOUNT", },
      //{ "text": "Storno predregistrovanej objednávky", "value": "STORNO_PREREGISTERED", }, 	 
      //{ "text": "Storno novej objednávky", "value": "STORNO_NEW", }, 	 	
      //{ "text": "Storno plánovanej objednávky", "value": "STORNO_PLANNED", }, 
      //{ "text": "Storno čakajúcej objednávky", "value": "STORNO_PENDING", }, 
      //{ "text": "Storno akceptovanej objednávky", "value": "STORNO_ACCEPTED", }, 	
      //{ "text": "Storno objednávky na mieste", "value": "STORNO_WAITING", }, 	 
      //{ "text": "Storno prepravovanej objednávky", "value": "STORNO_IN_PROGRESS", }, 	 	
      //{ "text": "Storno ukončenej objednávky", "value": "STORNO_FINISHED", }, 	 	
      { "text": "Zákazník nový (A)", "value": "CUSTOMER_NEW", },
      { "text": "Zákazník stály (A)", "value": "CUSTOMER_REGULAR", },
      { "text": "Zákazník prioritný (A)", "value": "CUSTOMER_PRIORITY", },
      { "text": "Zákazník známa osobnosť (A)", "value": "CUSTOMER_FAMOUS", },
      { "text": "Zákazník VIP (A)", "value": "CUSTOMER_VIP", },
      { "text": "Zákazník je kolega (A)", "value": "CUSTOMER_COLLEAGUE", },
      { "text": "Zákazník študent (A)", "value": "CUSTOMER_STUDENT", },
      { "text": "Zákazník dieťa (A)", "value": "CUSTOMER_CHILD", },
      { "text": "Zákazník junior (A)", "value": "CUSTOMER_JUNIOR", },
      { "text": "Zákazník Senior (A)", "value": "CUSTOMER_SENIOR", },
      { "text": "Zákazník je dlžník (A)", "value": "CUSTOMER_DEBTOR", },
      { "text": "Zákazník je blokovaný (A)", "value": "CUSTOMER_BLOCKED", },
      { "text": "Zdroj appka zákazníka max.2 obj (A)", "value": "SOURCE_BRANDAPP_FIRST_TWO_ORDERS", },
      { "text": "Zdroj appka zákazníka každých 5 obj (A)", "value": "SOURCE_BRANDAPP_EVERY_FIVE_ORDERS", },
    ],
    ObligatoryTypeArray: [
      { "text": "Cena za jazdu (P)", "value": "PRICE_JOURNEY", },
      { "text": "Cena za čakanie pred jazdou (P)", "value": "PRICE_WAITING", },
    ],
  },

  Settings: {
    page_title: "Nastavenia taxislužby",
    tabInfo: "Info",
    tabAutomat: "Automat",
    tabSettings: "Nastavenia",
    tabContacts: "Kontakty",
    tabGateway: "Gateway",
    tabOrderTypes: "Typy objednávok",
    tabPaymentOptions: "Platobné možnosti",
    TabSms: "SMS",
    TabModules: "Moduly",
    //tabInfo
    id: "ID",
    brokerId: "ID brokera",
    name: "Názov",
    company: "Názov spoločnosti",
    country: "Krajina",
    city: "Mesto",
    taxiLicenceNumber: "Číslo koncesie",
    taxiLicenceExpiration: "Dátum expirácie koncesie",
    type: "Typ",
    status: "Stav",
    descriptionShort: "Krátky popis",
    description: "Popis",
    //tabAutomat
    subheaderActivationTimeOrder: " Aktivácia časovej objednávky na odosielanie",
    activationTimeOrderDescription: "Čas kedy sa má aktivovať objednávka na odoslanie sa zapíše individuálne do každej časovej objednávky pri jej vytvorení. "
      + "Samotné aktivovanie automatom je možné globálne povolit alebo zakázať podľa typu objednávky (pozri záložku Typy objednavok). ",
    activeOrderBeforeSend: "Posielať vodičovi s 1 aktívnou objednávkou",
    activeOrderBeforeSendTitle: "Posielať objednávky aj vodičovi, ktorý má 1 objednávku v stave VO_VOZIDLE",
    priorityZero: "Prednostne posielať preferovanému vodičovi",
    priorityZeroTitle: "Objednávky s preferovaným vodičom posielať prednostne a garantovať ich doručenie",
    defaultPresendTime: "Prednastavený čas odoslania",
    defaultPresendTimeTitle: "Čas kedy sa majú začať vopred odosielať objednávky na vodičov - pri časových objednávkach",
    defaultPresendTime_hint: "Odporúčaný čas je 15 minút pred odoslaním objednávky",
    subheaderDistanceAutomat: "Nastavenia jednoduchého automatu (DISTANCE)",
    radiusDirect: "Radius u priamo zasielanej objednávky",
    radiusDirectTitle: "Pri priamo zasielanej objednávke sa vodiči dostanú do radiusu",
    delay: "Oneskorenie",
    delayTitle: "Časový interval v sekundách, kedy sa má opakovať odosielanie konkrétnej objednávky",
    subheaderPriorityAutomat: "Nastavenia pokročilého automatu (PRIORITY)",
    priorityAutomatDescription: "Objednávka prechádza prioritami od 5 do 1. Priorita 1 a 2 je určená pre časové objednávky. Priorita 3, 4 a 5 je určená pre okamžité objednávky. Najprv automat rozdeľuje objednávky priority 1, potom 2 až 5. "
      + "Objednávka postupuje spravidla z priority 5 do 4 a do 3, ak nie je najdený vhodný vodič. Resp. od 2 do 1. Priorita 5 a 2 by mala mať najmenší rádius (spravidla do 3km podľa veľkosti mesta). "
      + "Priorita 3 a 1 by mala mať čo najširší rádius (viac ako 10 km). Rádius je určený v metroch a čas v minútach (od času vytvorenia objednávky alebo požadovaného času).",
    automat: "Zapnutý automat",
    radius1: "Radius 1",
    radius1_hint: "Odporúčaná hodnota väčšia ako 50000m",
    automatPriorityTime1: "Čas 2",
    automatPriorityTime1_hint: "Odporúčaná hodnota 5min",
    radius2: "Radius 2",
    radius2_hint: "Odporúčaná hodnota 10000m",
    radius3: "Radius 3",
    radius3_hint: "Odporúčaná hodnota väčšia ako 50000m",
    automatPriorityTime2: "Čas 4",
    automatPriorityTime2_hint: "Odporúčaná hodnota 5min",
    automatPriorityTime2_validation: "Čas 4 musí byť väčší ako Čas 5",
    radius4: "Radius 4",
    radius4_hint: "Odporúčaná hodnota 20000m",
    automatPriorityTime3: "Čas 5",
    automatPriorityTime3_hint: "Odporúčaná hodnota 3min",
    automatPriorityTime3_validation: "Čas 5 musí byť menší ako Čas 4",
    radius5: "Radius 5",
    radius5_hint: "Odporúčaná hodnota menšia ako 2000m",
    automatBonusMaxMinuteDescription: "Automat začne prioritizovať vodičov, ktorí nemajú žiadnu objednávku viac ako 10 minút.  "
      + "Hodnota prioritizácie sa zvyšuje s každou minútou, po ktorú vodič nemá objednávku, po maximálnu hodnotu v minútach. "
      + "Vodiča to virtuálne priblíži o počet metrov k objednávke, ktoré sú vypočítané na základe vzorca Vzdialenosť_zvýhodnenia / (Čas_zvýhodnenia - 10). "
      + "Ak zvýhodňovanie nepožadujete, nastavenie obidve hodnoty na 1 minútu. "
      + "Zvýhodňovanie je vhodné najmä v noci, keď majú vodiči menej objednávok a stáva sa, že vzhľadom na polohu, kde stoja bez objednávky, niektorí vodiči nemusia dostávať objednávky."
      + "Toto nastavenie umožňuje rovnomernejšie rozdelenie objednávok medzi vodičov pri malom zvýšení prejazdov.",
    automatBonusMaxMinute: "Čas zvýhodnenia bez objednávky",
    automatBonusMaxMinute_hint: "Odporúčaná hodnota 40min",
    automatBonusMaxRadius: "Vzdialenosť zvýhodnenia bez objednávky",
    automatBonusMaxRadius_hint: "Odporúčaná hodnota 300m",
    //tabSettings
    subheaderDriverApp: "Nastavenia aplikácie pre vodičov",
    allowSilence: "Povoliť tichý režim",
    allowSilenceTitle: "Povoliť tlačidlo na tichý režim v aplikácii pre vodičov",
    allowStreetOrder: "Povoliť objednávky na ulici",
    allowStreetOrderTitle: "Povolenie tlačidla objednávky z ulice v aplikácii vodiča, ktorým si môže vodič vytvoriť sám objednávku z ulice",
    showClientPhoneDriverDispatch: "Zobraziť telefónne číslo zákazníka pre dispečovanie",
    showClientPhoneDriverDispatchTitle: "Zobraziť telefónne číslo zákazníka v objednávke z ulice pre dispečovanie objednávok",
    showRouteInfoNotif: "Zobraziť v notifikácii vzdialenosť vyzdvihnutia",
    showRouteInfoNotifTitle: "Zobrazenie odhadovanej vzdialenosti vodiča k miestu vyzdvihnutia v notifikácii o novej objednávke",
    showClientMarkInNotif: "Zobraziť v notifikácii označenie zákazníka",
    showClientMarkInNotifTitle: "Zobrazenie označenia zákazníka v notifikácii o novej objednávke",
    showClientNicknameInNotif: "Zobraziť v notifikácii prezývku zákazníka",
    showClientNicknameInNotifTitle: "Zobrazenie prezývky zákazníka v notifikácii o novej objednávke",
    showFiltersInNotif: "Zobraziť v notifikácii filtre",
    showFiltersInNotifTitle: "Zobrazenie filtrov/požiadaviek na jazdu v notifikácii o novej objednávke",
    allowOrderCancel: "Povoliť zrušenie objednávky",
    allowOrderCancelTitle: "Povolenie tlačidla zrušenia a vrátenia objednávky v aplikácii vodiča",
    popupTime: "Čas zobrazovania notifikácie",
    popupTimeTitle: "Čas v sekundách, ako dlho sa zobrazuje notifikácia vodičovi o novej objednávke",
    subheaderWaiting: "Čakanie na zákazníka počas jazdy",
    allowWaiting: "Povoliť čakanie",
    allowWaitingTitle: "Povolenie tlačidla Čakačky na zákazníka počas jazdy",
    waitingAfterStartLimit: "Časový limit na čakačku po štarte jazdy",
    waitingAfterStartLimitTitle: "Čas v sekundách, po ktorom môže vodič zapnúť tlačidlo na čakanie počas jazdy",
    waitingAfterStartLimit_hint: "Odporúčaná hodnota 120 sekúnd",
    waitingAfterLastLimit: "Časový limit medzi čakačkami",
    waitingAfterLastLimitTitle: "Čas v sekundách, po ktorom môže vodič opätovne zapnúť tlačidlo na čakanie počas jazdy",
    waitingAfterLastLimit_hint: "Odporúčaná hodnota 60 sekúnd",
    subheaderWorkshift: "Pracovná smena",
    vehicleAllocation: "Prideľovanie vozidiel",
    approvalWorklog: "Schvaľovanie pracovnej zmeny",
    approvalWorklogTitle: "Ukončenie pracovnej zmeny a prestávku schvaľuje dispečer",
    validateMileages: "Validácia kilometrov",
    validateMileagesTitle: "Validácia kilometrov v aplikácii vodiča pri začiatku a konci jazdy podľa pôvodného kilometražného stavu",
    subheaderPbxCallRouting: "Bezdispečerský režim",
    pbxCallRouting: "Smerovanie hovorov na vodičov",
    pbxCallRoutingTitle: "Spôsob smerovania hovorov zákazníkov na vodičov v bezdispečerskom režime",
    driverPhoneExt: "Klapka telefónu vodiča",
    driverPhoneExtTitle: "Telefónnej ústredni zasielaná klapka na vodiča",
    driverPhone: "Telefónu vodiča",
    driverPhoneTitle: "Telefónnej ústredni zasielané telefónne číslo na vodiča",
    showDriversDriverMap: "Zobraziť vodičov na mape vodiča",
    showDriversDriverMapTitle: "Zobrazenie všetkých vodičov na mape v aplikácii vodiča",
    showDriverCalllog: "Zobraziť záznamy hovorov vodiča",
    showDriverCalllogTitle: "Zobrazenie záznamov hovorov vodiča/vodičov v aplikácii vodiča",
    showStreetOrderCreateBtn: "Zobraziť tlačidlo vytvorenia objednávky",
    showStreetOrderCreateBtnTitle: "Zobrazenie tlačidla vytvorenia objednávky z ulice v aplikácii vodiča",
    subheaderOther: "Iné",
    smsCancellation: "SMS storno",
    smsCancellationTitle: "Začatím jazdy zruší staršie SMS vo fronte (negarantované)",
    realDistanceCorrection: "Korekcia reálnej vzdialenosti",
    realDistanceCorrectionTitle: "Korekcia reálnej vzdialenosti prepravy podľa GPS polohy vodiča",
    realDistanceCorrection_hint: "Odporúčaná hodnota 2.0%",
    msgRealDistanceCorrectionValueOutOfRange: "Hodnota korekcie reálnej vzdialenosti musí byť v rozsahu od -15.0 do 15.0",
    msgOneDecimalAllowed: "Povolené je len jedno desatinné miesto",
    subheaderPayment: "Platby",
    paymentProfileId: "Profil pre vystavovanie účtenky",
    profileId: "Fakturačný profil",
    profileIdTitle: "Profil, na ktorý vystavuje faktúru taxximo za služby pre taxislužbu",
    subheaderLocalityCheck: "Zaznamenávanie lokalít",
    localityCheck: "Zaznamenávanie lokalít",
    standCheck: "Zaznamenávanie na stanovišti",
    //tabContacts
    subheaderContacts: "Kontaktné údaje",
    email: "Kontaktný email",
    emailTitle: "Kontaktný email, na ktorý môžu písať zákazníci",
    problemEmail: "Email pre problémy",
    problemEmailTitle: "Kontaktný email, na ktorý budú prichádzať notifikácie o zaevidovaných problémoch",
    problemPhone: "Telefón pre problémy",
    problemPhoneTitle: "Kontaktný telefón, na ktorý budú prichádzať sms notifikácie o zaevidovaných problémoch",
    maintenanceVehicleEmail: "Email pre údržbu vozidiel",
    maintenanceVehicleEmailTitle: "Kontaktný email, na ktorý budú prichádzať notifikácie o blížiacich sa údržbách vozidiel",
    subheaderDispatchPhones: "Telefónne čísla na dispečing",
    phone1: "Telefón 1",
    phone2: "Telefón 2",
    phone3: "Telefón 3",
    phone4: "Telefón 4",
    phone5: "Telefón 5",
    phone6: "Telefón 6",
    phone7: "Telefón 7",
    phone8: "Telefón 8",
    phone9: "Telefón 9",
    phone10: "Telefón 10",
    phone11: "Telefón 11",
    phone12: "Telefón 12",
    //tabGateway
    subheaderSmsGateway: "Nastavenia SMS brány",
    smsGate: "SMS brána",
    smsIdentity: "Identita SMS brány",
    smsLogin: "Prihlasovacie meno",
    smsPassword: "Heslo",
    subheaderPhoneGateway: "Nastavenia telefónnej ústredne",
    phoneGate: "Telefónna ústredňa",
    phoneGateLogin: "Prihlasovacie meno",
    phoneGatePassword: "Heslo",
    subheaderPhoneExtensions: "Klapky pre telefónnu ústredňu",
    phoneExt1: "Klapka 1",
    phoneExt2: "Klapka 2",
    phoneExt3: "Klapka 3",
    phoneExt4: "Klapka 4",
    phoneExt5: "Klapka 5",
    phoneExt6: "Klapka 6",
    phoneExt7: "Klapka 7",
    phoneExt8: "Klapka 8",
    phoneExt9: "Klapka 9",
    phoneExt10: "Klapka 10",
    phoneExt11: "Klapka 11",
    phoneExt12: "Klapka 12",
    //tabOrderTypes
    tableHeadersSupportOrderTypes: {
      actions: "Akcie",
      id: "ID",
      type: "Typ",
      accept: "Prijímať",
      send: "Odoslať",
      sendType: "Typ odosielania",
      moveAutomat: "Aktivovať na odoslanie automaticky",
    },
    dialogEditSuportOrderTitle: "Upraviť typ objednávky",
    dialogCreateSuportOrderTitle: "Vytvoriť nový typ objednávky",
    dialogDeleteSuportOrderTitle: "Vymazať typ objednávky",
    supportOrderType: "Typ objednávky",
    supportOrderAccept: "Prijímať",
    supportOrderSend: "Odoslať",
    supportOrderSendType: "Typ odosielania",
    supportOrderMoveAutomat: "Aktivovať na odoslanie automaticky",
    //There can be inpact for functioning other system components, in case you delete this object. Please consider your action!
    supportOrderDeleteWarning: "V prípade, že vymažete typ objednávky, môže mať vplyv na fungovanie ostatných systémových komponentov. Prosím, zvážte svoju akciu!",
    supportOrderdeleteSubheader: "Posuňte slider doprava, ak chcete vymazať typ objednávky", // Move slider to right if you want to delete object.
    //tabPaymentOptions - importované
    //TabSms - importované
    //TabModules
    servicePlan_page_title: "Program služieb",
    servicePlan_totalPrice: "Celková cena",
    tableHeadersSupportModules: {
      actions: "Akcie",
      icon: "",
      name: "Názov modulu",
      desctription: "Popis",
      status: "Stav",
      unitPrice: "Jednotková cena",
      actualPrice: "Aktuálna cena",
      changeValue: "Požadovaná hodnota",
      newPrice: "Nová cena",
    },
    tableRowsSupportModules: {
      limitWorkshift: "Vodiči na pracovnej zmene",
      limitWorkshiftDescription: "Maximálny počet súbežne prihlásených vodičov na pracovnej zmene",
      cardPayments: "Platobná brána",
      cardPaymentsDescription: " Realizácia platieb kartou online",
      discount: "Zľavy",
      discountDescription: "Vytváranie zľavových kupónov",
      dispatchingChat: "Dispečing chat",
      dispatchingChatDescription: "Chat komunikácia iba navzájom medzi dispečermi",
      limitLocalityPrice: "Počet cenových lokalít",
      limitLocalityPriceDescription: "Maximálny počet lokalít určených pre cenníkove ceny medzi jednotlivými lokalitami",
      limitStand: "Počet stanovišť",
      limitStandDescription: "Maximálny počet stanovíšť pre vozidlá",
      pricelistType: "Typ cenníka",
      pricelistTypeDescription: "Typ cenníka umožňujúci rôzne spôsoby výpočtu ceny za objednávku",
      report: "Reporty",
      reportDescription: "Zobrazovanie reportov",
      timeOrder: "Časové objednávky",
      timeOrderDescription: "Možnosť vytvoriť objednávku na konkrétny čas",
      account: "Firemné jazdy",
      accountDescription: "Možnosť vytvoriť objednávku s fakturáciou pre firmy",
      worklog: "Pracovné zmeny vodičov",
      worklogDescription: "Záznam pracovných zmien vodičov",
      addressSearch: "Adresy v dispečingu",
      addressSearchDescription: "Zdroj adresných údajov",
      brandApp: "Brandovaná aplikácia",
      brandAppDescription: "Vlastná aplikácia pre zákazníkov",
      commercialAds: "Reklamný modul",
      commercialAdsDescription: "Zobrazovanie vlastných reklám",
    },
    limitWorkshiftRules1: "This field is required",
    limitWorkshiftRules2: "Value should be above 3",
    limitWorkshiftRules3: "Max should not be above 500",

    limitStandRules1: "This field is required",
    limitStandRules2: "Value should be above 0",
    limitStandRules3: "Value should be above your current limit",
    limitStandRules4: "Max should not be above 20",

    limitLocalityPriceRules1: "This field is required",
    limitLocalityPriceRules2: "Value should be above 0",
    limitLocalityPriceRules3: "Value should be above your current limit",
    limitLocalityPriceRules4: "Max should not be above 20",

    AllowOrderCancelArray: [
      {
        "text": "Povoliť",
        "value": "ENABLED",
      },
      {
        "text": "Zakázať",
        "value": "REQUEST",
      },
    ],
    VehicleAllocationTypeArray: [
      {
        "text": "Zobrazené iba priradené vozidlo",
        "value": "ASSIGNED",
      },
      {
        "text": "Všetky vozidlá na výber",
        "value": "ALL",
      },
    ],
    AcceptSupportOrderType: {
      AUTO: "Automaticky",
      MANUAL: "Manuálne",
      UNDEFINED: "",
    },
    AcceptSupportOrderTypeArray:
      [
        {
          "text": "Automaticky",
          "value": "AUTO",
        },
        {
          "text": "Manuálne",
          "value": "MANUAL",
        },
      ],

    SendSupportOrderType: {
      AUTOMAT: "Automatom",
      MANUAL: "Manuálne",
      UNDEFINED: "",
    },
    SendSupportOrderTypeArray:
      [
        {
          "text": "Automatom",
          "value": "AUTOMAT",
        },
        {
          "text": "Manuálne",
          "value": "MANUAL",
        },
      ],

    PbxCallRoutingType: {
      SLOWEST: "Na najpomalšieho voľného vodiča",
      FREE_EARLIEST_DRIVE: "Na voľného vodiča najdlhšie bez objednávky",
      EARLIEST_CALL: "Rovnomerné rozdeľovanie (na vodiča najdlhšie bez telefonátu)",
      EARLIEST_CALL_PREF_FREE: "Rovnomerné rozdeľovanie, preferovný voľný",
      EARLIEST_CALL_PREF_UNOCCUPIED: "Rovnomerné rozdeľovanie, preferovaný voľný a neobsadený",
      UNDEFINED: "",
    },

    PbxCallRoutingTypeArray: [
      {
        "text": "Na najpomalšieho voľného vodiča",
        "value": "SLOWEST",
      },
      {
        "text": "Na voľného vodiča najdlhšie bez objednávky",
        "value": "FREE_EARLIEST_DRIVE",
      },
      {
        "text": "Rovnomerné rozdeľovanie (na vodiča najdlhšie bez telefonátu)",
        "value": "EARLIEST_CALL",
      },
      {
        "text": "Rovnomerné rozdeľovanie, preferovný voľný",
        "value": "EARLIEST_CALL_PREF_FREE",
      },
      {
        "text": "Rovnomerné rozdeľovanie, preferovaný voľný a neobsadený",
        "value": "EARLIEST_CALL_PREF_UNOCCUPIED",
      },
    ],

    DriverPhoneExtType: {
      VEHICLE: "Vozidlo",
      DRIVER: "Vodič",
      DRIVER_SELECT: "Výber vodičom",
    },
    DriverPhoneExtTypeArray: [
      {
        "text": "Vozidlo",
        "value": "VEHICLE",
      },
      {
        "text": "Vodič",
        "value": "DRIVER",
      },
      /* {
        "text": "Výber vodičom",
        "value": "DRIVER_SELECT",
      }, */
    ],

    DriverPhoneType: {
      VEHICLE: "Vozidlo",
      DRIVER: "Vodič",
      DRIVER_SELECT: "Výber vodičom",
    },
    DriverPhoneTypeArray: [
      {
        "text": "Vozidlo",
        "value": "VEHICLE",
      },
      {
        "text": "Vodič",
        "value": "DRIVER",
      },
      /*  {
         "text": "Výber vodičom",
         "value": "DRIVER_SELECT",
       }, */
    ],

    ShowDriversDriverMapType: {
      NONE: "Nikdy",
      ALWAYS: "Vždy",
      DISPATCH_ONLY: "Iba pre dispečera",
      AUTO_DISPATCH_ONLY: "Iba pre dispečera v bezdispečingu",
    },
    ShowDriversDriverMapTypeArray: [
      {
        "text": "Nikdy",
        "value": "NONE",
      },
      {
        "text": "Vždy",
        "value": "ALWAYS",
      },
      {
        "text": "Iba pre dispečera",
        "value": "DISPATCH_ONLY",
      },
      {
        "text": "Iba pre dispečera v bezdispečingu",
        "value": "AUTO_DISPATCH_ONLY",
      },
    ],

    ShowDriverCalllogType: {
      NONE: "Žiadne",
      ALL: "Všetky",
      OWN: "Iba vlastné",
    },
    ShowDriverCalllogTypeArray: [
      {
        "text": "Nikdy",
        "value": "NONE",
      },
      {
        "text": "Všetky",
        "value": "ALL",
      },
      {
        "text": "Iba vlastné",
        "value": "OWN",
      },
    ],

    ShowStreetOrderCreateBtnType: {
      STREET: "Iba Z ulice",
      MY: "Iba Moja",
      OTHER: "Iba Prerozdeliť",
      STREET_OTHER: "Z ulice a Prerozdeliť",
      MY_OTHER: "Moja a Prerozdeliť",
      STREET_MY: "Z ulice a Moja",
      ALL: "Všetky",
    },
    ShowStreetOrderCreateBtnTypeArray: [
      {
        "text": "Iba Z ulice",
        "value": "STREET",
      },
      {
        "text": "Iba Moja",
        "value": "MY",
      },
      {
        "text": "Iba Prerozdeliť",
        "value": "OTHER",
      },
      {
        "text": "Z ulice a Prerozdeliť",
        "value": "STREET_OTHER",
      },
      {
        "text": "Moja a Prerozdeliť",
        "value": "MY_OTHER",
      },
      {
        "text": "Z ulice a Moja",
        "value": "STREET_MY",
      },
      {
        "text": "Všetky",
        "value": "ALL",
      },
    ],

    SendtypeSupportOrderType: {
      DIRECT_DRIVER: "Priamo na vodiča",
      DIRECT_LOCALITY: "Priamo na lokalitu",
      DIRECT_STAND: "Priamo na stanovište",
      DISTANCE: "Najbližšia vzdialenosť",
      PRIORITY: "Prioritizovaný",
      RADIUS: "Po rádiusoch",
      UNOCCUPIED: "Na neobsadených",
      UNDEFINED: "",
    },
    SendtypeSupportOrderTypeArray:
      [
        /*{
          "text": "Priamo na vodiča",
          "value": "DIRECT_DRIVER",
        },
        {
          "text": "Priamo na lokalitu",
          "value": "DIRECT_LOCALITY",
        },
        {
          "text": "Priamo na stanovište",
          "value": "DIRECT_STAND",
        },
        {
          "text": "Najbližšia vzdialenosť",
          "value": "DISTANCE",
        },*/
        {
          "text": "Prioritizovaný (odporúčaný)",
          "value": "PRIORITY",
        },
        /*{
          "text": "Po rádiusoch",
          "value": "RADIUS",
        },
        {
          "text": "Na neobsadených",
          "value": "UNOCCUPIED",
        },*/
      ],
  },
  SmsTemplateList: {
    tableHeaders: {
      actions: "Akcie",
      enabled: "Povolené",
      template: "Šablóna",
      language: "jazyk",
      type: "Typ",
    },
    dialogTitle: "SMS šablóna",
    enabled: "Povolené",
    template: "Šablóna",
    language: "Jazyk",
    type: "Typ",
    blockPaymentInvoice: "Neposielať pre platby faktúrou",
    templateExample: "Príklad šablóny",
    dialogText1: "Odporúčame používať SMS bez diakritiky a špeciálnych znakov, pretože môžu skrátiť dĺžku jednej SMS (jeden znak zaberie miesto viacerých znakov v správe). Ak správa prekročí limit 160 znakov, môže byť rozdelená do viacerých SMS, čo zvýši finančné náklady na zasielanie. Ak používate premenné, uistite sa, že zahrniete aj ich dĺžku v skutočnej podobe, aby ste predišli problémom s limitom znakov.",
    dialogText2: "Ak chcete vložiť do textu informácie z objednávky, použite nasledujúce premenné:",
    VAR_clientPhone: "Telefónne číslo klienta",
    VAR_driverNickname: "Prezývka vodiča",
    VAR_driverFirstName: "Meno vodiča",
    VAR_driverLastName: "Priezvisko vodiča",
    VAR_driverPhone: "Telefónne číslo vodiča",
    VAR_vehicleBrand: "Značka vozidla",
    VAR_vehicleModel: "Model vozidla",
    VAR_vehicleLpn: "ŠPZ vozidla",
    VAR_vehicleColor: "Farba vozidla",
    VAR_requestedTime: "Požadovaný čas",
    VAR_distance: "Vzdialenosť",
    VAR_currency: "Mena",
    VAR_estimatedPriceTotal: "Odhadovaná cena",
    VAR_finalPriceTotal: "Konečná cena",
    VAR_finalPriceExtra: "Konečný príplatok",
    VAR_finalPriceDiscount: "Konečná zľava",
    VAR_sms_url_id_short: "Link na online sledovanie objednávky",
    msgErrorSelectType: "Vyberte typ šablóny",
    msgErrorAlreadyUsedType: "Tento typ šablóny už existuje",
    exampleAccepted1: "Vasa objednávka bola prijata. Vozidlo ",
    exampleAccepted2: " pride po vas o niekolko minut.",
    exammpleApproaching1: "Vozidlo ",
    exammpleApproaching2: " je o chvilu na pozadovanom mieste. Cakame na Vas.",
    exampleWaiting1: "Vozidlo ",
    exampleWaiting2: " dorazilo na pozadovane miesto. Cakame na Vas.",
    exampleWaitingApproaching1: "Vozidlo ",
    exampleWaitingApproaching2: " dorazilo na pozadovane miesto. Cakame na Vas.",
    exampleFinished1: "Jazda bola ukoncena. Dakujeme za pouzitie nasich sluzieb.",
    exampleSettled1: "Jazda bola ukoncena. Cena za jazdu je ",
    exampleSettled2: ". Dakujeme za pouzitie nasich sluzieb.",
    exampleCancelled1: "Jazda bola zrusena. V pripade otazok nas prosim kontaktujte na cisle dispecingu +4219XXXXXXXX.",
  },
  PaymentOptionList: {
    tableHeaders: {
      actions: "Akcie",
      active: "Aktívne",
      paymentType: "Typ platby",
      receiptType: "Typ dokladu",
      receiptStatus: "Stav dokladu",
      note: "Poznámka",
    },
    dialogTitle: "Platobná možnosť",
    active: "Aktívne",
    paymentType: "Typ platby",
    receiptType: "Typ dokladu",
    receiptStatus: "Stav dokladu",
    note: "Poznámka",
    msgErrorAlreadyUsedType: "Tento typ platobnej možnosti už existuje",
  },

  receiptType: {
    DISABLED: "Bez dokladu", 	// Neaktívny, negenerovať doklad (0)
    INVOICE: "Faktúra", 	// Faktúra daňový doklad (1)
    RECEIPT: "Pokladničný blok", 	// Cash Receipt / Pokladničný blok, daňový doklad (2)
    CREDIT_NOTE: "Dobropis", 	// Dobropis, daňový doklad (3)
    NF_INVOICE: "Nedaňová faktúra", 	// Non Fiscal Invoice / Faktúra NEdaňový doklad (1)
    NF_RECEIPT: "Nedaňový pokladničný blok", 	// Non Fiscal Cash Receipt / Pokladničný blok, NEdaňový doklad (2)
    NF_CREDIT_NOTE: "Nedaňový dobropis", 	// Non Fiscal Credit Note / Dobropis, NEdaňový doklad (3)
    PRO_FORMA: "Zálohová faktúra" 	// Zálohová faktúra, NEdaňový doklad (14+4)
  },
  receiptTypeArray:
    [
      { "text": "Bez dokladu", "value": "DISABLED", },
      { "text": "Faktúra", "value": "INVOICE", },
      { "text": "Pokladničný blok", "value": "RECEIPT", },
      { "text": "Dobropis", "value": "CREDIT_NOTE", },
      { "text": "Nedaňová faktúra", "value": "NF_INVOICE", },
      { "text": "Nedaňový pokladničný blok", "value": "NF_RECEIPT", },
      { "text": "Nedaňový dobropis", "value": "NF_CREDIT_NOTE", },
      { "text": "Zálohová faktúra", "value": "PRO_FORMA", },
    ],

  receiptStatus: {
    CANNED: "Predpripravená", 	// Predpripravená (1)
    ISSUED: "Vystavená", 	// Vystavená (2)
    POSTED: "Vyúčtovaná", 	// Vyúčtovaná (3)
    PAID: "Zaplatená", 	// Zaplatená (4)
    ACCOUNTED: "Zaúčtovaná", 	// Zaúčtovaná (5)
    CREDIT: "Dobropisovaná", 	// Dobropisovaná (6)
    RECLAIMED: "Reklamovaná", 	// Reklamovaná (7)
    DELAYED: "V omeškaní", 	// V omeškaní (8)
    CLAIMED: "Vymáhaná", 	// Vymáhaná (8)
    CANCELLED: "Zrusena" 	// Zrusena (10)
  },
  receiptStatusArray:
    [
      { "text": "Predpripravená", "value": "CANNED", },
      { "text": "Vystavená", "value": "ISSUED", },
      { "text": "Vyúčtovaná", "value": "POSTED", },
      { "text": "Zaplatená", "value": "PAID", },
      { "text": "Zaúčtovaná", "value": "ACCOUNTED", },
      { "text": "Dobropisovaná", "value": "CREDIT", },
      { "text": "Reklamovaná", "value": "RECLAIMED", },
      { "text": "V omeškaní", "value": "DELAYED", },
      { "text": "Vymáhaná", "value": "CLAIMED", },
      { "text": "Zrusena", "value": "CANCELLED", },
    ],

  TaxiserviceList: {
    page_title: "Zozname taxislužieb",
    dialogCreateTitle: "Nová taxislužba",
    name: "Názov",
    country: "Krajina",
    city: "Mesto",
    tableHeaders: {
      actions: "Akcie",
      id: "ID",
      name: "Názov",
      country: "Krajina",
      city: "Mesto",
      status: "Stav",
      type: "Typ",
    },
  },
  Taxiservice: {
    page_title: "Taxislužba",
    //Ostatne zo "Settings" 
  },
  //FEEDBACK
  FeedbackList: {
    page_title: "Zoznamy feedbackov",
    id: "ID",
    checked: "Vyriešené",
    createdAt: "Vytvorené",
    fromEmail: "Email",
    message: "Správa",
    dialogTitle: "Úprava feedbacku",
    tableHeaders: {
      actions: "Akcie",
      id: "ID",
      checked: "Vyriešené",
      createdAt: "Vytvorené",
      fromEmail: "Email",
      message: "Správa",
    },

  },
  //REPORTS
  ReportDispatcherAccessLog: {
    page_title: "Záznamy o prihlásení dispečerov",
    actions: "Akcie",
    createdAt: "Vytvorené",
    ipAddress: "IP adresa",
    role: "Rola",
    firstName: "Meno",
    lastName: "Priezvisko",
    info: "Info",
    btnFindIpAddress: "Nájsť IP adresu v mapovej službe",
  },
  ReportOverview: {
    page_title: "Prehľad",
    allRealized: "Zrealizované",
    allCanceledRealized: "Zrušené počas realizácie",
    allCanceledUnrealized: "Zrušené pred realizáciou",
    sourceStatistic: "Počty objednávok podľa zdroja vytvorenia v aktuálnom mesiaci",
  },
  ReportSalesCompany: {
    page_title: "Firemné jazdy",
    actions: "Akcie",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    id: "ID",
  },
  ReportSalesDriver: {
    page_title: "Tržby vodičov",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    startTime: "Začiatok pracovnej zmeny",
    id: "ID",
  },
  ReportStatusDay: {
    page_title: "Stavy objednávok podľa dní",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    startTime: "Začiatok pracovnej zmeny",
    dateRange: "Obdobie",
    settled: "Zúčtované",
    canceled: "Zrušené",
  },
  ReportStatusHour: {
    page_title: "Stavy objednávok podľa hodín",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    dateRange: "Obdobie",
  },
  ReportStatusMonth: {
    page_title: "Stavy objednávok podľa mesiacov",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    dateRange: "Obdobie",
  },
  ReportStatusMonthPerSource: {
    page_title: "Stavy objednávok podľa zdroja",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    dateRange: "Obdobie",
  },
  ReportStatusDailyVehicles: {
    page_title: "Počty vozidiel podľa dní",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    onlyRealized_label: "Iba realizované",
    startTime: "Začiatok pracovnej zmeny",
    dateRange: "Obdobie",
    year: "Rok",
    month: "Mesiac",
    day: "Deň",
    monthName: "Názov mesiaca",
    dayName: "Názov dňa",
    total: "Počet vozidiel",
  },
  ReportWorkshift: {
    page_title: "Pracovné zmeny",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    startTime: "Začiatok pracovnej zmeny",
    id: "ID",
    driverId: "ID vodiča",
    worklogDetailDialog_title: "Detail pracovnej zmeny",
    worklogDetailDialogItemList_title: "Zoznam zmien počas pracovnej zmeny",
    worklogDetailDialogRequestList_title: "Zoznam požiadaviek vodiča",
    worklogOrdersDialog_title: "Zoznam objednávok počas pracovnej zmeny",
    worklogId: "ID pracovnej zmeny",
    createdAt: "Vytvorené",
    processedAt: "Spracované",
    updatedAt: "Zmenené",
    breakCounter: "Počet prestávok",
    breakDuration: "Trvanie prestávok",
    itemDuration: "Trvanie",
    status: "Stav",
    type: "Typ",
    firstName: "Meno",
    lastName: "Priezvisko",
    tableHeaders: {
      id: "ID",
      status: "Stav",
      source: "Zdroj",
      type: "Typ",
      paymentType: "Typ platby",
      acceptedAt: "Akceptované",
      finalPriceTotal: "Celková cena",
      driverId: "ID vodiča",
    },
  },
  ReportWorkshiftSummary: {
    page_title: "Sumarizácia pracovných zmien",
    mobileBreakpointSwitch_label: "Tabuľkové zobrazenie",
    selectedHeaders_label: "Vyberte stĺpce",
    startTime: "Začiatok pracovnej zmeny",
    driverId: "ID vodiča",
  },
  OrdersGoogleMap: {
    enableBoardMarkers: "Značky vyzdvihnutia",
    enableDestinationMarkers: "Značky cieľov",
    enableOrderPath: "Trasa",
    enableBoardHeatmap: "Heatmap vyzdvihnutia",
    enableDestinationHeatmap: "Heatmap cieľov",
    heatmapSettings: "Nastavenia heatmapy",
    heatmapOpacity: "Priehľadnosť",
    heatmapRadius: "Polomer",
    heatmapBoardGradient: "Farba vyzdvihnutia",
    heatmapDestinationGradient: "Farba cieľov",
  },
};