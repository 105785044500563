import en from "vuetify/lib/locale/en";

export default {

  $vuetify: {
    ...en,
  },

  menu: {
    dashboard: "Dashboard",
    console: "Console",
    consoleOsm: "Console OSM",
    subheaderOperational: "Operational data",
    order: "Orders",
    account: "Companies",
    driverVehicle: "Assigned Vehicles",
    vehicleDriver: "Vehicle List",
    vehicleMaintenance: "Vehicle Maintenance",
    place: "Places",
    reports: "Reports",
    reportOverview: "Overview",
    reportSubheaderStatus: "Status and counts",
    reportStatusMonth: "Status by month",
    reportStatusDay: "Status by day",
    reportStatusHours: "Status by hours",
    reportStatusMonthPerSource: "Status by source",
    reportDailyVehicles: "Vehicle counts by days",
    reportSubheaderSales: "Sales and company rides",
    reportSalesDriver: "Sales by driver",
    reportSalesCompany: "Company rides",
    reportSubheaderWorkshift: "Workshifts",
    reportWorkshift: "Workshifts",
    reportWorkshiftSummary: "Workshifts summary",
    reportSubheaderUsers: "Users",
    reportDispatcherAccessLog: "Access log",
    subheaderUsers: "Users and vehicles",
    driver: "Drivers",
    vehicle: "Vehicles",
    dispatcher: "Dispatchers",
    subheaderSettings: "Settings",
    locality: "Localities",
    pricelist: "Pricelist",
    settings: "Settings",
    taxiserviceStatus: "Taxiservice Status",
    taxiservice: "Taxiservices",
    subheaderOther: "Other",
    roadmap: "Roadmap",
    servicePlan: "Service Plan",
    system: "System",
    messageDispatch: "Dispatch messages",
    feedback: "Feedback",
    about: "About",
    signout: "Sign out",
    versionChange: "Change version",
    versionBeta: "Try Beta ",
    versionProd: "Back to Prod",
  },

  page_title: {
    dashboard: "Dashboard",
    console: "Dispatching Console",
    consoleOsm: "Dispatching Console OSM",
    orderList: "Order List",
    orderDetail: "Order Detail",
    accountList: "Companies",
    accountDetail: "Company Detail",
    driverVehicleList: "Assigned Vehicles",
    vehicleDriverList: "Vehicle List",
    vehicleMaintenanceList: "Vehicle Maintenance",
    placeList: "Places",
    reportOverview: "Overview",
    reportStatusMonth: "Order Status by Month",
    reportStatusDay: "Order Status by Day",
    reportStatusMonthPerSource: "Order Status by Month per Source",
    reportDailyVehicles: "Vehicle counts by days",
    reportSalesDriver: "Driver Sales",
    reportSalesCompany: "Company Sales",
    reportWorkshift: "Workshifts",
    reportWorkshiftSummary: "Workshifts Summary",
    reportDispatcherAccessLog: "Dispatcher access log",
    driverList: "Driver List",
    vehicleList: "Vehicle List",
    vehicleDetail: "Vehicle Detail",
    dispatcherList: "Dispatcher List",
    localityList: "Locality List",
    pricelistList: "Pricelist List",
    priceList: "Pricelist",
    settings: "Settings",
    taxiserviceList: "Taxiservice List",
    taxiserviceDetail: "Taxiservice Detail",
    taxiserviceStatus: "Taxiservice Status",
    roadmap: "Roadmap",
    servicePlan: "Service Plan",
    messageDispatchList: "Notifications",
    feedbackList: "Feedback",
    about: "About",
    personalSetting: "Personal Settings",
  },

  // Common
  btnCreate: "Create",
  btnSave: "Save",
  btnSubmit: "Submit",
  btnCancel: "Cancel",
  btnClose: "Close",
  btnEdit: "Edit",
  btnAdd: "Add",
  btnAssign: "Assign",
  btnReload: "Reload",
  btnUpdate: "Update",
  btnRemove: "Remove",
  btnDelete: "Delete",
  btnChange: "Change",
  btnConfirm: "Confirm",
  btnDisable: "Disable",
  btnEnable: "Enable",
  btnExportData: "Export data",
  btnImportData: "Import Data",
  btnRedirectHttps: "Redirect to HTTPS",
  DateRangeSelector: "Select date range",
  //POST request
  msgCreateSuccess: "The record was successfully created",
  msgCreateError: "Record could not be created",
  //PUT request
  msgUpdateSuccess: "The record was successfully updated",
  msgUpdateError: "Record could not be updated",
  //GET request
  msgGetDataError: "Ooooops, error.... Something went wrong while retrieving the data",
  //DELETE request
  msgDeleteSuccess: "Record was deleted",
  msgDeleteError: "Record was not deleted",
  //General error response
  msgResponseError: "Ooooops, there was an error...",
  msgDataMissing: "Missing data",
  msgDataValidation: "Data validation error",
  msgConfirmSlider: "Confirm with slider (move right)",
  //Validation rules
  msgRequiredField: "This field is required",
  phone_hint: "Format: +421123456789",
  phone_placeholder: "+421123456789",
  msgPasswordMinLength: "Password must be at least 8 characters long",
  msgPasswordLowerCase: "Password must contain at least one lowercase letter",
  msgPasswordNumber: "Password must contain at least one number",
  //modules
  msgGetModulesError: "Error while retrieving modules",
  msgModuleWorklogNotEnabled: "Module Worklog is not enabled",
  msgModuleReportNotEnabled: "Module Report is not enabled",
  //HTTPS
  msgHttps: "For security reasons, we recommend using HTTPS",

  Main: {
    showBetaDialog: {
      title: "Beta version",
      text1: "Do you want to switch to the beta version of the application?",
      text2: "Application is tested, but may contain errors. It has the latest functionality. On the other hand, the beta version may already contain a fix for a bug that may have occurred in the production version.",
      text3: "By testing the beta version, you will help to speed up the debugging of the functionality and its production deployment.",
      text4: "We are also interested in your feedback on new features so that future versions will meet your needs, as well as those of other users.",
      text5: "You can always decide to switch back to the production version.",
      switchButton: "Switch to Beta version",
    },

    showProdDialog: {
      title: "Production version of the application", // Produkčná verzia aplikácie
      text1: "Do you want to switch back to the production version of the application?", // Prajete si prepnúť sa späť na produkčnú verziu aplikácie?
      text2: "If there was an error in the beta version of the application, or if you have any feedback regarding the new functionality, please let us know at info@taxximo.com", // Ak bola v beta verzii aplikácie chyba, alebo máte pripomienky k novej funkčnosti, dajte nám prosím vedieť na adresu info@taxximo.com
      switchButton: "Switch to Production version", // Prepnúť na Produkčnú verziu
    },
    language: {
      SK: "Slovensky (Slovak)",
      CS: "Česky (Czech)",
      EN: "English",
    },
    showLogoutDialog: {
      title: "End of work and log out",
      text1: "Do you want log out of the application?",
      //text1: "Do you want to end your work shift and log out of the application?",
      logoutButton: "Log out",
      signoutButton: "End work and log out",
    },
    msgGetTaxiservicListForPickerError: "Failed to load list of taxi services for picker",
    msgChangeTaxiserviceError: "Failed to change taxi service",
    msgChangeTaxiserviceSuccess: "Taxi service has been successfully changed. Please update the loaded data.",
    msgSignOutError: "Could not log out of work shift!",
  },
  AutocompleteDriverRemote: {
    prependIconTitle: "Update list of drivers",
    iconAppendOuterTitle: "Toggle sorting of list by Vehicle ID | Driver ID | Last Name",
  },
  AutocompleteAccountRemote: {
    placeholder: "Start typing company name...",
  },
  AutocompleteAddressOsm: {
    placeholder: "Start typing address...",
    //title: "To add a new address to the database, click on the icon in front of the field."
    title: "Search for address in Open Street Map service",
  },
  AutocompleteAddressGeocode: {
    placeholder: "Start typing address...", // Začnite písať adresu...
    //title: "Pre pridanie novej adresy do databázy kliknite na ikonu pred políčkom.",
    title: "Search for address in Google Maps service", // Vyhľadať adresu v službe Google Maps
    iconAppendOuterTitle: "Switch filter search by: Nearby addresses | Only on visible map",
    iconAppendOuterTitleBoundary: "Switch filter search by: Nearby addresses | >Only on visible map<",
    iconAppendOuterTitleFocus: "Switch filter search by: >Nearby addresses< | Only on visible map",
  },
  AutocompleteAddressRemote: {
    placeholder: "Start typing the address...", // Začnite písať adresu...
    title: "To add a new address to the database, click on the icon in front of the field.", // Pre pridanie novej adresy do databázy kliknite na ikonu pred políčkom.
    prependIconTitle: "Search and add a new address to the database", // Vyhľadať a pridať novú adresu do databázy
    dialogTitle: "Add a new address to the database", // Pridanie novej adresy do databázy
    dialogInputPlaceholder: "Enter the address...", // Zadajte adresu...
    btnClose: "Close", // Zavrieť
    btnAdd: "Add", // Pridať
    msgAddSuccess: "The address was successfully added to the database", // Adresa bola úspešne pridaná do databázy
    msgAddErrorDuplicated: "The address could not be added to the database", // Adresu sa nepodarilo pridať do databázy
    msgAddErrorDuplicatedAlert: "Duplicated record. Please search address regulary, do not add address via functionality to add address.", // Duplicitný záznam. Prosím vyhľadajte adresu normálnym spôsobom, nevyhľadávajte ju cez Pridanie adresy.
    msgAddErrorDuplicatedBan: "You often search for addresses that are already in the database. You have exceeded the maximum number of such addresses. The function of adding new addresses is temporarily blocked for you.",
    msgNoAddress: "No details available for input", // Nie je dostupný detail adresy
    msgAddAddressNotSearched: "The address has not been searched yet. Please search for the address first.", // Adresa ešte nebola vyhľadaná. Prosím najprv vyhľadajte adresu.
  },
  AutocompleteAddressRemoteGeocode: {
    placeholder: "Start typing address...", // Začnite písať adresu...
    title: "To add a new address to the database, click on the icon in front of the field.", // Pre pridanie novej adresy do databázy kliknite na ikonu pred políčkom.
    prependIconTitle: "Search and add a new address to the database via Geocode Earth service", // Vyhľadať a pridať novú adresu do databázy cez službu Geocode Earth
    dialogTitle: "Add a new address to the database", // Pridanie novej adresy do databázy
    autocompleteAddLabel: "New address", // Nová adresa
    btnClose: "Close", // Zavrieť
    btnAdd: "Add", // Pridať
    msgAddSuccess: "The address was successfully added to the database", // Adresa bola úspešne pridaná do databázy
    msgAddErrorEmpty: "The address has not been searched yet or selected. Please search for the address first and select it from the options.",
    msgAddErrorDuplicated: "The address could not be added to the database", // Adresu sa nepodarilo pridať do databázy
    msgAddErrorDuplicatedAlert: "Duplicated record. Please search address regulary, do not add address via functionality to add address.", // Duplicitný záznam. Prosím vyhľadajte adresu normálnym spôsobom, nevyhľadávajte ju cez Pridanie adresy.
    msgAddErrorDuplicatedBan: "You often search for addresses that are already in the database. You have exceeded the maximum number of such addresses. The function of adding new addresses is temporarily blocked for you.",
    msgNoAddress: "No details available for input", // Nie je dostupný detail adresy
    msgAddAddressNotSearched: "The address has not been searched yet. Please search for the address first.", // Adresu je potrebné pokúsiť sa najprv vyhľadať
  },
  AutocompleteCityRemote: {
    placeholder: "Start typing the city...", // Začnite písať mesto...
    msgSelectCountry: "To search for a city, you must first select a country.", // Pre vyhľadanie mesta je potrebné najprv vybrať Krajinu.
  },
  WeatherForecast: {
    title: "Weather forecast", // Počasie
    msgGetWeatherError: "Failed to load weather data", // Nepodarilo sa načítať počasie
    temperature: "Temperature", // Teplota
    wind: "Wind", // Vietor
    humidity: "Humidity", // Vlhkosť
    pressure: "Pressure", // Tlak
    feelsLike: "Feels like", // Pocitová teplota
    probabilityOfPrecipitation: "Probability of precipitation", // Pravdepodobnosť zrážok
  },
  //https://openweathermap.org/weather-conditions
  WeatherEvent: {
    200: "Thunderstorm", //Búrka
    300: "Drizzle", //Mrholenie
    500: "Rain",//Dážď
    600: "Snow",//Sneh
    700: "Atmosphere", //Atmosferické podmienky
    800: "Clear",//Jasno
    801: "Few clouds",// Mierne zamračené 
    802: "Scattered clouds",// Rozptýlené oblaky / Polojasno
    803: "Broken clouds",// Rozbité oblaky / Zamračené
    804: " Overcast clouds",// Zamračené /
  },

  ExportData: {
    title: "Export data", // Exportovať údaje
    formatDatetime_label: "Date and time format", // Formát dátumu a času
    formatDecimal_label: "Decimal separator", // Oddeľovač desatinných čísel
    btnExcel: "Excel", // Excel
    btnCsv: "CSV", // CSV
    btnRawData: "Raw data", // Raw data
  },

  Login: {
    page_title: "Login to Taxximo",
    email: "Email",
    password: "Password",
    btnLogin: "Login",
    btnForgottenPassword: "Forgotten password?",
    btnDriverApp: "Download Driver App",
    btnDriverAppBeta: "Join the beta testing of the Driver App",
    btnSimpleLogin: "Simple login",
    dialogTitle: "Forgotten password",
    msgWrongFormatEmail: "Wrong format of email address. Please try again.",
    msgWrongCredentials: "Wrong email or password. Please try again.",
    msgWrongCredentials2: "Wrong credentials. Please try again.",
    msgModuleError: "Failed to load module settings... Contact your system administrator or Taxximo sales representative.",
    msgResetSuccess: "Reset password link has been sent to your email.",
    msgRecommendedChrome: "For the best application performance, we recommend using the Google Chrome browser.",
    msgRecommendedChromeUnsupported: "Your browser is not supported. For the best application performance, we recommend using the Google Chrome browser.",
    //displaying orders from device memory in case of connection failure
    dialogOrderListTitle: "Outdated order list from device memory - technical specification",
    btnCheckConnectivity: "Check connectivity",
    btnOrderList: "Show order list",
    msgOrderList: "Displaying the latest data from device memory, not from the server. They are probably outdated. Orders cannot be updated or canceled. The list is for information only.",
    msgConnectivity404: "Connection to the server failed.",
    msgConnectivity200: "Connection to the server is OK.",
    msgConnectivity302: "Connection to the server is probably OK.",
    msgConnectivityOffline: "The device is not connected to the internet. The latest data is displayed. Orders cannot be updated or canceled. Try to connect or wait until an internet connection is available.",
    msgConnectivityServerDown: "Data from the server is not available. There may be an internet outage or a data center outage. If you display list of the orders, it will only contains the latest known data. Orders cannot be updated or canceled. Wait until the server is available again or until an internet connection is available.",
    msgConnectivityNoInternet: "You do not have internet access. There may be an outage with your internet service provider or a problem with your router. If you display list of the orders, it will only contains the latest known data. Orders cannot be updated or canceled.",
    tableHeaders: {
      id: "ID",
      status: "Status",
      type: "Type",
      createdAt: "Creation time",
      requestedTime: "Requested time",
      address1Type: "Type 1",
      address1: "Address 1",
      address2Type: "Type 2",
      address2: "Address 2",
      driverFirstname: "Driver name",
      driverLastname: "Driver surname",
      vehicleInternalName: "Vehicle name",
      preferredDriverLastname: "Preferred driver",
      note: "Note",
    },
  },

  PersonalSetting: {
    page_title: "Personal settings",
    passwordTitle: "Password",
    passwordSubtitle: "Password settings",
    btnLogout: "Logout",
    dashboardSelectedItems: "Items in dashboard",
    dialogLogoutTitle: "Logout from the app",
    logoutText: "Do you want to log out of the application?",
    DialogPasswordChangeTitle: "Change password",
    passwordOld: "Current password",
    passwordNew: "New password",
    passwordNew2: "Confirmation of new password",
    msgPasswordsNotMatch: "Passwords do not match",
    msgDashboardSelectedItemsSuccesfullySaved: "Items in dashboard have been successfully saved",
  },

  Dashboard: {
    page_title: "Dashboard",
    cardMessageDispatch: "Notifications",
    cardDdriverOnBreak: "Drivers on break",
    cardDdriverOnBreakSubtitle: "List of drivers on break",
    cardWorklogRequest: "Driver worklog requests",
    cardWorklogRequestSubtitle: "List of driver requests for a break and end of work shift",
    cardDriverOnline: "Active drivers",
    cardDriverOnlineSubtitle: "List of drivers who are available or driving",
    cardPriceLocality: "Price localities",
    cardPriceLocalitySubtitle: "Map of areas used for price calculation",
    cardWeatherForecast: "Weather forecast",
    cardWeatherForecastSubtitle: "Weather forecast for the next hours",
    cardPaymentDue: "Payment due",
    service: "Service",
    tableHeadersWorklog: {
      type: "Type",
      status: "Status",
      driverFirstName: "First name",
      driverLastName: "Last name",
      createdAt: "Created",
    },
    tableHeadersDriverOnBreak: {
      id: "ID",
      firstName: "First name",
      lastName: "Last name",
    },
    tableHeadersDriverOnline: {
      id: "ID",
      firstName: "First name",
      lastName: "Last name",
      silence: "Silent mode",
    },
    silenceEnabledTitle: "Silent mode enabled",
    silenceDisabledTitle: "Silent mode disabled",
    dashcardUtilizationRatio: {
      title: "Driver utilization",
      actionText: "Percentage of busy drivers: Percentage of busy drivers compared to active drivers. " +
        "\nIdeal value for flexible customer service is up to 80%." +
        "\nOptimal for taxi service efficiency is 100%",
    },
    dashcardLoadRatio: {
      title: "Taxi service load",
      actionText: "Taxi service load index: Ratio of Active orders (completed + waiting) / Active drivers." +
        "\nIdeal value for customer service is 1 or less." +
        "\nA value above 1 causes customers to wait longer for a vehicle to be assigned." +
        "\nOptimal for efficient (fast driver utilization) taxi service is from 1 to 1.4. " +
        "\nOver 2 is an overloaded taxi service. Then it is appropriate to consider rejecting new orders.",
    },
    dashcardImmediateAvailabilityRatio: {
      title: "Immediate availability",
      actionText: "Immediate availability index: Ratio of orders to be assigned and available drivers." +
        "\nIdeal value for customer service is 1 or less.",
    },
    dashcardCounterPENDING: {
      title: "Unassigned orders",
      actionText: "Orders waiting to be assigned to drivers.",
    },
    dashcardSumPerformedOrders: {
      title: "Completed orders",
      actionText: "Orders assigned to drivers and currently being completed.",
    },
    dashcardSumActiveDrivers: {
      title: "Active drivers",
      actionText: "Available and busy drivers.",
    },
    dashcardSumBusyDrivers: {
      title: "Busy Drivers",
      actionText: "Drivers with assigned orders.",
    },
    dashcardCounterOnlineFREE: {
      title: "Available Drivers",
      actionText: "Drivers who are available without orders.",
    },
    dashcardCounterONBREAK: {
      title: "Drivers on Break",
      actionText: "Drivers who are currently on a break.",
    },
  },

  orderStatus: {
    PREREGISTERED: "Preregistered",
    TRANSFERED: "Transferred",
    NEW: "New",
    PLANNED: "Planned",
    PENDING: "Pending",
    ACCEPTED: "Accepted",
    WAITING: "Waiting",
    IN_PROGRESS: "In progress",
    FINISHED: "Finished",
    CHARGED: "Charged",
    SETTLED: "Settled",
    ARCHIVED: "Archived",
    CANCELLED_PREREGISTERED: "Cancelled - Preregistered",
    CANCELLED_NEW: "Cancelled - New",
    CANCELLED_PLANNED: "Cancelled - Planned",
    CANCELLED_PENDING: "Cancelled - Pending",
    CANCELLED_ACCEPTED: "Cancelled - Accepted",
    CANCELLED_WAITING: "Cancelled - Waiting",
    CANCELLED_IN_PROGRESS: "Cancelled - In progress",
    CANCELLED_FINISHED: "Cancelled - Finished",
    CANCELLED_CHARGED: "Cancelled - Charged",
    CANCELLED_SETTLED: "Cancelled - Settled",
    CANCELLED_ALL: "All CANCELLED",
    UNDEFINED: "",
  },

  orderStatusSimple: {
    PREREGISTERED: "Preregistered",
    TRANSFERED: "Transferred",
    NEW: "New",
    PLANNED: "Planned",
    PENDING: "Pending",
    ACCEPTED: "Accepted",
    WAITING: "Waiting",
    IN_PROGRESS: "In progress",
    FINISHED: "Finished",
    CHARGED: "Charged",
    SETTLED: "Settled",
    ARCHIVED: "Archived",
    CANCELLED_PREREGISTERED: "Cancelled",
    CANCELLED_NEW: "Cancelled",
    CANCELLED_PLANNED: "Cancelled",
    CANCELLED_PENDING: "Cancelled",
    CANCELLED_ACCEPTED: "Cancelled",
    CANCELLED_WAITING: "Cancelled",
    CANCELLED_IN_PROGRESS: "Cancelled",
    CANCELLED_FINISHED: "Cancelled",
    CANCELLED_CHARGED: "Cancelled",
    CANCELLED_SETTLED: "Cancelled",
    UNDEFINED: "",
  },
  orderStatusExt: {
    UNDEFINED: "",
    CANCELLED_SUM: "Cancelled",
  },
  orderStatus_tooltip: {
    PREREGISTERED: "Preregistered",
    TRANSFERED: "Transferred",
    NEW: "New",
    PLANNED: "Planned",
    PENDING: "Pending",
    ACCEPTED: "Accepted",
    WAITING: "Waiting",
    IN_PROGRESS: "In progress",
    FINISHED: "Finished",
    CHARGED: "Charged",
    SETTLED: "Settled",
    ARCHIVED: "Archived",
    CANCELLED_PREREGISTERED: "Cancelled - Preregistered",
    CANCELLED_NEW: "Cancelled - New",
    CANCELLED_PLANNED: "Cancelled - Planned",
    CANCELLED_PENDING: "Cancelled - Pending",
    CANCELLED_ACCEPTED: "Cancelled - Accepted",
    CANCELLED_WAITING: "Cancelled - Waiting",
    CANCELLED_IN_PROGRESS: "Cancelled - In progress",
    CANCELLED_FINISHED: "Cancelled - Finished",
    CANCELLED_CHARGED: "Cancelled - Charged",
    CANCELLED_SETTLED: "Cancelled - Settled",
    UNDEFINED: "",
  },
  orderStatus_help: {
    PREREGISTERED: "Pre-registered order",
    TRANSFERED: "Transferred order",
    NEW: "Order was created by the customer and is waiting for acceptance by the taxi service.",
    PLANNED: "The order is not intended for immediate assignment to a driver and is not currently being assigned by an automated system. It was probably created in advance for a specific time.",
    PENDING: "The order is waiting for immediate assignment to a driver. If the automated system is on, the order will be assigned automatically.",
    ACCEPTED: "The order has been assigned to a driver who has accepted it. If the driver has only this order, they are likely on their way to the customer.",
    WAITING: "The driver is waiting for the customer at the requested pick-up location",
    IN_PROGRESS: "The driver is transporting the customer along the requested route",
    FINISHED: "The order has been completed, the customer has been transported to the requested destination. The driver is preparing the invoice.",
    CHARGED: "The order has been charged, payment is being made by card, credit, or will be paid by invoice for corporate rides.",
    SETTLED: "The order has been paid and the invoice closed.",
    ARCHIVED: "Archived order",
    CANCELLED_PREREGISTERED: "Cancelled pre-registered order",
    CANCELLED_NEW: "Cancelled new order",
    CANCELLED_PLANNED: "Cancelled planned order",
    CANCELLED_PENDING: "Cancelled pending order",
    CANCELLED_ACCEPTED: "Cancelled accepted order",
    CANCELLED_WAITING: "Cancelled waiting order",
    CANCELLED_IN_PROGRESS: "Cancelled in-progress order",
    CANCELLED_FINISHED: "Cancelled finished order",
    CANCELLED_CHARGED: "Cancelled charged order",
    CANCELLED_SETTLED: "Cancelled settled order",
    UNDEFINED: "",
  },
  orderStatusArray:
    [
      {
        "text": "Pre-registered",
        "value": "PREREGISTERED",
      },
      {
        "text": "Transferred",
        "value": "TRANSFERED",
      },
      {
        "text": "New",
        "value": "NEW",
      },
      {
        "text": "Planned",
        "value": "PLANNED",
      },
      {
        "text": "Pending",
        "value": "PENDING",
      },
      {
        "text": "Accepted",
        "value": "ACCEPTED",
      },
      {
        "text": "Waiting",
        "value": "WAITING",
      },
      {
        "text": "In progress",
        "value": "IN_PROGRESS",
      },
      {
        "text": "Finished",
        "value": "FINISHED",
      },
      {
        "text": "Charged",
        "value": "CHARGED",
      },
      {
        "text": "Settled",
        "value": "SETTLED",
      },
      {
        "text": "Archived",
        "value": "ARCHIVED",
      },
      {
        "text": "Cancelled pre-registered",
        "value": "CANCELLED_PREREGISTERED",
      },
      {
        "text": "Cancelled new",
        "value": "CANCELLED_NEW",
      },
      {
        "text": "Cancelled planned",
        "value": "CANCELLED_PLANNED",
      },
      {
        "text": "Cancelled pending",
        "value": "CANCELLED_PENDING",
      },
      {
        "text": "Cancelled accepted",
        "value": "CANCELLED_ACCEPTED",
      },
      {
        "text": "Cancelled waiting",
        "value": "CANCELLED_WAITING",
      },
      {
        "text": "Cancelled in progress",
        "value": "CANCELLED_IN_PROGRESS",
      },
      {
        "text": "Cancelled unfinished",
        "value": "CANCELLED_FINISHED",
      },
      {
        "text": "Cancelled uncharged",
        "value": "CANCELLED_CHARGED",
      },
      {
        "text": "Cancelled unpaid",
        "value": "CANCELLED_SETTLED",
      },
      {
        "text": "",
        "value": "UNDEFINED",
      }
    ],

  orderType: {
    INSTANT: "Instant",
    TIME: "Time",
    STREET: "From Street",
    DIRECT: "Direct",
    REPEATED: "Repeated",
    AIRPORT: "Airport",
    UNDEFINED: "",
  },

  orderType_tooltip: {
    INSTANT: "Order to instant drive",
    TIME: "Time order",
    STREET: "Order from street",
    DIRECT: "Direct order",
    REPEATED: "Repeated order",
    AIRPORT: "Order with pickup on airport",
    UNDEFINED: "",
  },

  orderTypeArray:
    [
      {
        "text": "Instant",
        "value": "INSTANT",
      },
      {
        "text": "Time",
        "value": "TIME",
      },
      {
        "text": "From Street",
        "value": "STREET",
      },
      {
        "text": "Direct",
        "value": "DIRECT",
      },
      {
        "text": "Repeated",
        "value": "REPEATED",
      },
      {
        "text": "Airport",
        "value": "AIRPORT",
      },
      {
        "text": "",
        "value": "UNDEFINED",
      }
    ],

  source: {
    DISPATCH_TAXXIMO: "Dispatch Txx.",
    DISPATCH_TAXISERVICE: "Dispatch Tx.",
    DISPATCH_BROKER: "Dispatch B.",
    CUSTOMER_ANDROID: "Customer A.",
    CUSTOMER_IOS: "Customer iOS",
    BRAND_ANDROID: "Brand A.",
    BRAND_IOS: "Brand iOS",
    DRIVER_ANDROID: "Driver",
    PBX: "PBX",
    UNDEFINED: "",
    undefined: "",
  },
  source_short: {
    DISPATCH_TAXXIMO: "DSPXX",
    DISPATCH_TAXISERVICE: "DSP",
    DISPATCH_BROKER: "DSPB",
    CUSTOMER_ANDROID: "CUSA",
    CUSTOMER_IOS: "CUSI",
    BRAND_ANDROID: "CUSBA",
    BRAND_IOS: "CUSBI",
    DRIVER_ANDROID: "DRV",
    PBX: "PBX",
    undefined: "",
  },
  source_tooltip: {
    DISPATCH_TAXXIMO: "Taxximo Dispatch",
    DISPATCH_TAXISERVICE: "Taxi Service Dispatch",
    DISPATCH_BROKER: "Broker Shared Dispatch",
    CUSTOMER_ANDROID: "Taxximo Customer App, Android system",
    CUSTOMER_IOS: "Taxximo Customer App, iOS system",
    BRAND_ANDROID: "Broker Branded Customer App, Android system",
    BRAND_IOS: "Broker Branded Customer App, iOS system",
    DRIVER_ANDROID: "Driver App, Android system",
    PBX: "Private Phone Branch eXchange system",
    undefined: "",
    UNDEFINED: "",
  },
  sourceArray:
    [
      { "text": "Dispatch Txx.", "value": "DISPATCH_TAXXIMO" },
      { "text": "Dispatch", "value": "DISPATCH_TAXISERVICE" },
      { "text": "Dispatch B.", "value": "DISPATCH_BROKER" },
      { "text": "Customer AD", "value": "CUSTOMER_ANDROID" },
      { "text": "Customer iOS", "value": "CUSTOMER_IOS" },
      { "text": "Customer AD B.", "value": "BRAND_ANDROID" },
      { "text": "Customer iOS B.", "value": "BRAND_IOS" },
      { "text": "Driver", "value": "DRIVER_ANDROID" },
      { "text": "PBX", "value": "PBX" },
      { "text": "", "value": "UNDEFINED" }
    ],
  paymentType: {
    CASH: "Cash",
    CARD: "EFTPOS",
    VIAMO: "VIAMO",
    COUPON: "Coupon",
    CARD_ONLINE: "Card Online",
    PREPAID: "Credit",
    POSTPAID: "Invoice",
    INVOICE: "Invoice Broker",
    UNDEFINED: "",
    undefined: "",
  },
  paymentType_tooltip: {
    CASH: "Cash payment",
    CARD: "Payment by card from the EFT POS terminal in the vehicle",
    VIAMO: "Payment via VIAMO",
    COUPON: "Payment by voucher",
    CARD_ONLINE: "Payment via online payment gateway",
    PREPAID: "Payment from prepaid credit",
    POSTPAID: "Payment on invoice charged by the Taxi company",
    INVOICE: "Payment on invoice charged via Broker",
    UNDEFINED: "",
  },
  paymentTypeArray:
    [
      { "text": "Cash", "value": "CASH" },
      { "text": "EFTPOS", "value": "CARD" },
      { "text": "VIAMO", "value": "VIAMO" },
      { "text": "Coupon", "value": "COUPON" },
      { "text": "Card Online", "value": "CARD_ONLINE" },
      { "text": "Credit", "value": "PREPAID" },
      { "text": "Invoice", "value": "POSTPAID" },
      { "text": "Invoice Broker", "value": "INVOICE" },
      { "text": "", "value": "undefined" }
    ],
  workStatus: {
    OFFLINE: "Off-duty",
    ONLINE: "On-duty",
    ON_BREAK: "On break",
    NONE: "",
    UNDEFINED: "",
  },
  activeTaxiserviceSelection: {
    ACTIVE: "Selected",
    DISABLED: "Not selected",
    UNDEFINED: "",
  },

  worklogRequestType: {
    OFFLINE: "End of shift",
    ONLINE: "Start of shift",
    ON_BREAK: "Break",
    NONE: "",
    UNDEFINED: "",
  },
  worklogRequestStatus: {
    NEW: "New",
    CONFIRMED: "Confirmed",
    DECLINED: "Declined",
    DONE: "Completed",
    CANCELLED: "Cancelled",
    UNDEFINED: "",
  },
  dashboardItems: [
    { "text": "Driver utilization", "value": "utilizationRatio" },
    { "text": "Taxi service load", "value": "loadRatio" },
    { "text": "Immediate availability", "value": "immediateAvailabilityRatio" },
    { "text": "Unassigned orders", "value": "counterPENDING" },
    { "text": "Performed orders", "value": "sumPerformedOrders" },
    { "text": "Active drivers", "value": "sumActiveDrivers" },
    { "text": "Busy drivers", "value": "sumBusyDrivers" },
    { "text": "Free drivers", "value": "counterOnlineFREE" },
    { "text": "Drivers on break", "value": "counterON_BREAK" },
    { "text": "Price areas - map", "value": "priceLocalityList" },
    { "text": "Active drivers - list", "value": "driverOnlineItems" },
    { "text": "Drivers on break - list", "value": "driverOnBreakItems" },
    { "text": "Worklog request approval - list", "value": "worklogRequestItems" },
  ],
  OrderListView: {
    page_title: "Order list",
    mobileBreakpointSwitch_label: "Table view",
    selectedHeaders_label: "Selected columns",
    onlyRealized_label: "Only realized",
    actions: "Actions",
    Id: "ID",
    Id_tooltip: 'Technical number of Order {msg}',
    Status: "Status",
    Type: "Typy",
    Source: "Source",
    PaymentType: "Payment Type",
    Note: "Note",
    CreatedAt: "Created at",
    AcceptedAt: "Akcepted at",
    StartedAt: "Started at",
    FinishedAt: "Finished at",
    DriverId: "Driver ID",
    DriverName: "Driver name",
    DriverFirstName: "Driver first name",
    DriverLastName: "Driver last name",
    ClientId: "Client ID",
    ClientNickname: "Client nickname",
    ClientPhone: "Client phone",
    VehicleLpn: "Vehicle license plate number",
    VehicleBrand: "Vehicle brand",
    VehicleModel: "Vehicle model",
    VehicleInternalName: "Vehicle internal name",
    boardAddress: "Pickup address",
    destinationAddress: "Destination address",
    EstimatedDistance: "Estimated distance",
    EstimatedTime: "Estimated time",
    FinalDistance: "Final distance",
    FinalTime: "Final time",
    InfoDistance: "Informative distance",
    InfoTime: "Informative time",
    InfoPriceTotal: "Informative total price",
    EstimatedPriceTotal: "Estimated total price",
    FinalPriceStart: "Final pickup price",
    FinalPriceDrive: "Final drive price",
    FinalPriceWaiting: "Final waiting price",
    FinalPriceJourney: "Final journey price",
    FinalPriceExtra: "Final extra price",
    FinalPriceDiscount: "Final discount",
    FinalPriceTotal: "Final total price",
    OrdersCount: "Number of orders",
    OrderCounter: "Number of orders",
    BreakCounter: "Number of breaks",
    BreakDuration: "Break duration",
    MileagesStart: "Starting mileage",
    MileagesFinish: "Ending mileage",
    Mileages: "Mileage",
    Duration: "Duration",
    Distance: "Distance",
    NetDuration: "Net duration",
    AccountId: "Corporate client ID",
    AccountName: "Corporate client name",
    filterLabel: {
      actions: "Actions",
      Id: "ID",
      Status: "Status",
      Type: "Type",
      Source: "Source",
      PaymentType: "Payment",
      Note: "Note",
      CreatedAt: "Created at",
      AcceptedAt: "Accepted at",
      DriverId: "Driver ID",
      DriverName: "Driver name",
      DriverFirstName: "Driver first name",
      DriverLastName: "Driver last name",
      VehicleLpn: "LPN",
      boardAddress: "Pick-up address",
      destinationAddress: "Destination address",
      AccountName: "Corporate client Name",
      AccountId: "Corporate client ID",
    },
    RefreshData: "Update",
    vizualizeAddressOnMapDialog_title: "Show address on map",
    btnVizualizeAddresses: "Show adress on map",
    btnVizualizeAddresses_title: "Show pick-up and destination address on map via heatmap display",
    btnVizualizeBoardAddresses: "Show pick-ups",
    btnVizualizeBoardAddresses_title: "Show pick-up addresses on the map via heatmap display",
    btnVizualizeDestinationAddresses: "Show destinations",
    btnVizualizeDestinationAddresses_title: "Show destination addresses on the map via heatmap display",
    detailDialog_title: "Order detail",
  },

  OrderView: {
    page_title: "Order details",
    reducePositions_title: "Reduce the number of markers if there are too many on the map",
    tabInfo: "Info",
    tabRoute: "Route",
    tabTimeline: "Records",
    tabPrice: "Price",
    //Tab Info
    headerBasicData: "Basic order information",
    id: "Order number",
    status: "Status",
    source: "Source",
    type: "Type",
    paymentType: "Payment type",
    preferredDriverFirstName: "Preferred driver first name",
    preferredDriverLastName: "Preferred driver last name",
    requestedTime: "Requested time",
    presendTime: "Send time in advance (minutes)",
    preferredDriverId: "Preferred driver ID",
    note: "Note",
    headerClient: "Client",
    nickname: "Name",
    clientPhone: "Phone",
    mark: "Type",
    clientNote: "Note",
    clientRating: "Rating",
    clientDriveCounter: "Number of rides",
    clientRateCount: "Number of ratings",
    clientRateSum: "Sum of rating points",
    headerAccount: "Corporate ride",
    accountName: "Company name",
    accountStatus: "Company status",
    accountId: "Company ID",
    accountNone: "None", //No data
    headerDriver: "Driver",
    driverFirstName: "First name",
    driverLastName: "Last name",
    driverPhone: "Phone",
    driverNickname: "Nickname",
    driverRating: "Rating",
    driverDriveCounter: "Number of rides",
    driverRateCount: "Number of ratings",
    driverRateSum: "Sum of rating points",
    driverNone: "None", //No data
    driverId: "Driver ID",
    orderDriverRating: "Rating from client",
    orderClientRating: "Rating from driver",
    headerOrderFilter: "Ride requirements",
    headerDisplayAndSend: "Display and send order",
    showPhone_short: "Client phone",
    showDriverPhoneButton_short: "Call button",
    showAllAddresses_short: "All addresses",
    onlyManualSending_short: "Manual sending",
    forcedPickupRadius_short: "Pickup radius",
    showPrice: "Display price to driver and client",
    showPhone: "Display client phone to driver",
    showDriverPhoneButton: "Display call button to driver",
    showAllAddresses: "Display all addresses to driver",
    onlyManualSending: "Only manually send order",
    forcedPickupRadius: "Forced pickup radius (0 = off)",
    headerWaypointList: "Route",
    headerChat: "Chat",
    headerOrderSentLogs: "Logs of order sent to drivers",
    cardFinalPriceWaiting: "Waiting",
    cardFinalPriceJourney: "Journey",
    cardFinalPriceExtra: "Extra",
    cardFinalPriceDiscount: "Discount",
    cardFinalPriceTotal: "Total",
    subheaderPrice: "Price analysis",
    displayCalculationSwitchHide: "Hide calculation",
    displayCalculationSwitchShow: "Show calculation",
    tblHeaderPriceName: "Name",
    tblHeaderPriceEstimated: "Estimated",
    tblHeaderPriceFinal: "Final",
    tblHeaderPriceInfo: "Informational",
    tblPrice: {
      locFrom: "Pick-up location",
      locTo: "Destination location",
      distance: "Distance of transport",
      time: "Transport time",
      priceWaiting: "Price for waiting before starting customer transport",
      priceStart: "Price for pick-up",
      priceDriveDistance: "Price for distance of transport",
      priceDriveTime: "Price for transport time",
      priceDrive: "Price for transport",
      priceJourney: "Price for journey",
      priceExtra: "Extras",
      priceDiscount: "Discounts",
      priceTotal: "Total order price",
    },
    subheaderPriceItems: "Price items",
    tblPriceItems: {
      type: "Type",
      name: "Name",
      active: "Active",
      cancelPriceFixation: "Cancel fixation",
      resetToDefualtPrice: "Reset to default price",
      unitPrice: "Unit price",
      quantity: "Quantity",
      unit: "Unit",
      price: "Total price",
    },
    addressMarker_BOARD: "Customer pick-up location",
    addressMarker_DESTINATION: "Customer destination location",
    addressMarker_TRANSIT1: "1st transit point",
    addressMarker_TRANSIT2: "2nd transit point",
    addressMarker_TRANSIT3: "3rd transit point",
    addressMarker_TRANSIT4: "4th transit point",
    addressMarker_TRANSIT: "Transit point",
    //position marker title
    markerTitleId: "Point ID",
    markerTitleOrderStatus: "Order status",
    markerTitleDriverId: "Driver",
    markerTitleSpeed: "Speed",
    markerTitleCreatedAt: "Created (UTC)",
    markerTitleRecordedAt: "Recorded (UTC)",
    timeline: {
      //ORDER CREATION
      createdAt_title: "Order created",
      createdAt_text: "Order created in the system",
      //ORDER STATUS CHANGES
      plannedAt_title: "Order planned",
      plannedAt_text: "Order planned",
      pendingAt_title: "Order ready for driver assignment",
      pendingAt_text: "Order",
      acceptedAt_title: "Order accepted by driver",
      acceptedAt_text: "Driver accepted the order. If they do not have another ongoing ride, they should be on their way to the customer.",
      waitingAt_title: "Driver waiting for customer",
      waitingAt_text: "Driver arrived at the customer pick-up location",
      progressAt_title: "Driver transporting customer",
      progressAt_text: "Driver started the customer transport",
      finishedAt_title: "Driver completed ride",
      finishedAt_text: "Driver delivered the customer to their desired destination.",
      chargedAt_title: "Driver prepared bill",
      chargedAt_text: "Driver processed the final bill. If they have permission, they could adjust the ride price or surcharges.",
      settledAt_title: "Ride settlement completed",
      settledAt_text: "The order was paid.",
      interruptedAt_title: "Canceled order",
      interruptedAt_text: "The order was canceled.",
      //REQUESTED PICKUP TIME
      requestedTimeInstant_title: "Requested pick-up time - immediate",
      requestedTimeInstant_text: "The pick-up time is at the time of creation for immediate rides or for future time for scheduled rides.",
      orderSentLogsTime_title: "Requested pick-up time",
      orderSentLogsTime_text: "The pick-up time for a scheduled ride",
      //CHAT
      chatList_title: "Messages / Chat",
      //WAYPOINT
      waypointIssue_title: "Late arrival at customer pick-up location",
      waypointIssue1_text: "Driver arrived at the location ",
      waypointIssue2_text: " minutes later than the requested pick-up time.",
      waypointIssue_detail: "Requested time: ",
      waypointListBoard_title: "Pick-up location",
      waypointListDestination_title: "Destination",
      waypointListTransit_title: "Waypoint - transit point",
      //WAITING
      waitingListStartedAt_title: "Waiting time started",
      waitingListStartedAt_text: "Driver started the waiting time",
      waitingListFinishedAt_title: "Waiting time finished",
      waitingListFinishedAt1_text: "Driver stopped the waiting time after ",
      waitingListFinishedAt2_text: "min. (exact value: ",
      //ORDER LOGS
      orderLogList_title: "Log records",
      //ORDER SEND LOGS
      orderSentLogs_title: "Order sent",
      orderSentLogs1_text: "Order sent (",
      orderSentLogs2_text: ") to the driver ",
      orderSentLogs3_text: " who was ",
      orderSentLogs4_text: " away from the pick-up location.",
    },
  },

  Console: {
    panelActions: "Activity",
    panelActions_title: "Minimalize/maximalize Activity panel",
    tabNew: "New",
    tabDetail: "Detail",
    tabMessages: "Messages",
    tabSettings: "Settings",
    tabNew_title: "New order",
    tabDetail_title: "Detail order",
    tabMessages_title: "Chat messages",
    tabSettings_title: "Settings",
    panelInfo: "Information",
    infoUtilizationRatio_title: "Driver utilization",
    infoLoadRatio_title: "Taxi service load index",
    infoImmediateAvailabilityRatio_title: "Immediate availability index",
    infoCountDriversInStand_title: "Number of free taxis at stands",
    infoListOfDriversPerStand_title: "List of free drivers at stands",
    infoListOfDriversWorkRequests_title: "List of drivers with work requests",
    infoCounterPENDING_title: "Orders to be assigned",
    infoSumPerformedOrders_title: "Performed orders",
    infoSumBusyDrivers_title: "Number of busy drivers / Drivers with more than one order",
    infoCounterOnlineFREE_title: "Number of online free drivers",
    infoCounterON_BREAK_title: "Number of drivers on break",
    msgTestTimeProblem: "Wrong time on PC. Check if you have the correct time and time zone on your PC, or summer/winter time.",
    msgInternetProblemDevice: "The device is not connected to the Internet. Only the last data found are displayed. Orders cannot be updated or canceled. Try to connect or wait until the Internet connection is available.",
    msgInternetProblemServer: "Data from the server are not available. You may have an Internet outage or a data center outage. Only the last data found are displayed. Orders cannot be updated or canceled. Wait until the server is available again or the Internet connection is available.",
    msgInternetProblemConnection: "You do not have Internet access. You may have an outage of Internet service providers or a problem in the router. Only the last data found are displayed. Orders cannot be updated or canceled.",
    msgInternetBackOnline: "The Internet connection is available again.",
    msgNoWorklogRequests: "No work requests found.",
    msgWorklogRequestNotConfirmedTooLong: "The work request of Driver was not confirmed for too long time.",
    btnChatOk: "OK",
    btnChatCalling: "Calling",

    //Tab Settings
    settings: {
      onlineStatus_subtitle: "Online status of taxi service",
      utilizationRatio: "Driver utilization",
      utilizationRatio_title: "Percentage of busy drivers to active drivers.",
      utilizationRatio_sumActiveDrivers: "All",
      utilizationRatio_sumBusyDrivers: "Busy",
      utilizationRatio_counterOnlineFREE: "Free",
      utilizationRatio_counterON_BREAK: "on break",
      loadRatio: "Load index of taxi service",
      loadRatio_title: "Ratio of Active Orders (realized + pending) / Active Drivers.",
      loadRatio_sumActiveOrders: "All orders",
      loadRatio_counterPENDING: "Pending",
      loadRatio_sumPerformedOrders: "Performed",
      immediateAvailabilityRatio: "Immediate availability index",
      immediateAvailabilityRatio_title: "Ratio of orders to be assigned and free drivers.",
      immediateAvailabilityRatio_counterOnlineFREE: "Free drivers",
      immediateAvailabilityRatio_counterON_BREAK: "On break",
      immediateAvailabilityRatio_counterDriversMoreThanOneOrder: "Busy more orders",
      settings_subtitle: "Settings",
      darkGui: "Dark GUI mode",
      darkMap: "Dark map mode",
      playNotificationSound: "Chat notifications",
      playNotificationSoundFile: "Sound of notifications",
      ignoreSystemMessages: "Do not show system messages",
      showInfoPanel: "Information panel",
      driverSpeed: "Vehicle speed",
      showPendingOrders: "Pick-up locations of pending orders",
      pathDriver: "Driver route lines",
      iconDriver: "Driver icons",
      iconDriverLabel: "Driver labels",
      btnAutomatSwitch: "Automat",
      btnAutomatSwitch_title: "Switch Automat",
      btnPbxSwitch: "PBX",
      btnPbxSwitch_title: "Switch Phone Private Branch eXchange",
      btnDatacenterStatus: "Cloud",
      btnDatacenterStatus_title: "Datacenter status",
      btnHelp_title: "Help",
      msgGetAutomatStatusError: "Error loading automat settings",
      msgChangeAutomatSuccess: "Automat settings changed successfully",
      msgChangePbxSuccess: "PBX mode changed successfully",
      msgChangePbxUnchanged: "PBX mode remain unchanged",
      msgChangePbxError: "Error changing PBX mode",
      msgChangePbxModuleDisabled: "Non dispatching PBX module is disabled",
      pathDriverSettings: [
        { "text": "Do not display", "value": "NEVER" },
        { "text": "Last 5 min. of transport", "value": "INPROGRESS05MIN" },
        { "text": "Last 10 min. of transport", "value": "INPROGRESS10MIN" },
        { "text": "During transport", "value": "INPROGRESS" },
        { "text": "During waiting and transport", "value": "WAITING" },
        { "text": "Always display", "value": "ALWAYS" },
      ],
      showPendingOrdersSettings: [
        { "text": "Do not display", "value": "NEVER" },
        { "text": "Pin", "value": "PIN" },
        { "text": "Pin and line", "value": "PINLINE" },
        { "text": "Pin and red line", "value": "PINLINERED" },
      ],
      iconDriverSettings: [
        { "text": "Pin", "value": "PIN" },
        {
          "text": "Car",
          "value": "SIMPLECAR",
        },
        {
          "text": "Circle",
          "value": "CIRCLE",
        },
      ],
      iconDriverSettingsOSM: [
        { "text": "Tag", "value": "TAG" },
        //{text: "3D autíčko",value: "SVGCAR",},
      ],
      iconDriverLabelSettings: [
        { "text": "Internal vehicle", "value": "VEHICLEINTERNAL" },
        {
          "text": "Internal driver",
          "value": "DRIVERINTERNAL",
        },
        {
          "text": "Driver initials",
          "value": "DRIVERNAME"
        },
      ],
      notificationSoundSettings: [
        { "text": "Sound 1 (default)", "value": "got-it-done.mp3" },
        { "text": "Sound 2", "value": "imo_sms_tone_2016.mp3" },
        { "text": "Sound 3", "value": "message_sound.mp3" },
        { "text": "Sound 4", "value": "nice_sms.mp3" },
        { "text": "Sound 5", "value": "short_alert.mp3" },
      ],
    },
    automatEnableDialog: {
      title: "Do you want to turn on the automat?",
      text: "Orders will be automatically sent to drivers according to the rules set by the automat.",
      btnYes: "Turn on automat",
    },
    automatDisableDialog: {
      title: "Do you want to turn off the automat?",
      text: "Orders will only be able to be sent to drivers manually.",
      btnYes: "Turn off automat",
    },
    pbxDialog: {
      title: "Do you want to change the Phone PBX mode?",
      text: "Changing the PBX mode may affect call reception.",
      btnPbxDispatching: "Dispatching",
      btnPbxNonDispatching: "Drivers",
      btnPbxOffline: "Offline",
    },
  },

  ConsoleMap: {
    markerTitle_2Active: "2 active orders",
    markerTitle_3Active: "3 active orders",
    markerTitle_4Active: "4 active orders",
    markerTitle_timeTypeWarning: "Next ride is Time type",
    markerTitle_silenceEnabled: "Silent mode enabled",
    markerTitle_preferredDriverInconsistencyActual: "Actual drive is assigned to different driver than preferred driver",
    markerTitle_preferredDriverInconsistencyOther: "Other drive was assigned to different driver than preferred driver",
    markerTitle_worklogRequestOnbreakNew: "New break request",
    markerTitle_worklogRequestOnbreakConfirmed: "Break request confirmed",
    markerTitle_worklogRequestOfflineNew: "New offline request",
    markerTitle_worklogRequestOfflineConfirmed: "Offline request confirmed",
    markerTitle_outdatedPosition: "Longer time we don't have current position",
    markerTitle_driverSpeed: "Vehicle speed",
    markerTitle_timeToDestination: "Delivery of customer for",
    markerTitle_timeFromLastOrder: "Time from the end of the last ride",
    markerTitle_streetOrder: "Ride from the street",
    markerTitlePlace_board: "Place of customer pick-up",
    markerTitlePlace_destination: "Destination of the journey",
    markerTitlePlace_transit1: "1. transit point",
    markerTitlePlace_transit2: "2. transit point",
    markerTitlePlace_transit3: "3. transit point",
    markerTitlePlace_transit4: "4. transit point",
    infoWindow_btnSetDriver: "Set this driver in the field on the DETAIL tab of the order",
    infoWindow_btnMessageDriver: "Send message to driver",
    infoWindow_btnDetail: "Show order detail",
    infoWindow_btnMessageDriverClient: "Send message to Customer and Driver to order",
    msgFindDriverOnMap1: "Driver with ID",
    msgFindDriverOnMap2: "is not on the map.",
    msgSetDriverToDetailFromMapSuccess: "Driver added to order detail...",
    msgSetDriverToDetailFromMapError: "No suitable free nearby driver found...",
    markerOrderTitle_durationSinceTryingAssign: "Length of assignment",
    markerOrderTitle_addresses: "Addresses",
    markerOrderTitle_customer: "Customer",
    markerOrderTitle_driver: "Driver",
    markerOrderTitle_preferredDriver: "Preferred driver",
    markerOrderTitle_dispatch: "Dispatch",
    markerOrderTitle_time: "Timing",
    markerOrderTitle_instant: "Instant",
    defaultStand: "Default stand",
    stand: "Stand",
    capacity: "Capacity",
    radius: "Radius",
  },

  ConsoleOrderNew: {
    //niektor tlacidla su duplicitne oproti standardnym tlacidlam, lebo konzola môže mať iné popisky a by boli priestorovo efektivnejsie
    btnCreate: "Create",
    btnClear: "Clear",
    phone: "Phone",
    phoneRules: "Phone number has to be numbers only with + prefix", //Phone number has to be numbers only with + prefix
    btnGetClientByPhone_title: "Search by phone number",
    btnGetClientByExtension_title: "Search by current call on the PBX",
    extension: "Extension",
    extensionExpand: "Expand extension to buttons",
    extensionCollapse: "Collapse buttons with extension",
    nickname: "Customer name",
    btnOpenEditClientDialog_title: "Edit customer information",
    btnOpenInfoClientDialog_title: "Customer information",
    btnOpenHistoryClientDialog: "Customer order history",
    clientInfo: "Information about customer",
    clientMoreAccounts: "more", //more
    clientNote: "Note about customer",
    countryCode: "Country",
    countryCode_title: "Country",
    countryCode_prepend_title: "Clear fields country and city",
    city: "City",
    city_title: "City",
    boardAddress: "Pick-up address",
    transitAddresses: "Transit addresses",
    transit1Address: "1. Transit address",
    transit2Address: "2. Transit address",
    transit3Address: "3. Transit address",
    transit4Address: "4. Transit address",
    destinationAddress: "Destination address",
    radioBtnInstant: "Instant",
    radioBtnTime: "Scheduled",
    preferredDriverId: "Preferred driver",
    preferredDriverId_title: "Driver who will have priority when assigning an order, but it is not guaranteed",
    radioBtnClient: "Private",
    radioBtnAccount: "Company",
    accountId: "Company",
    paymentType: "Payment type",
    requestedTime: "Requested time",
    btnAddRequestedTimeToNote: "Add requested time to note",
    btnAddClientPhoneToNote: "Add client phone to order note",
    presendTime: "Presend time",
    note: "Note",
    noteInsertTime: "Time",
    noteInsertAccount: "Company",
    noteInsertClientPhone: "Phone",
    orderFilterHeader: "Ride requests",
    orderFilter: {
      animal: "Animal",
      airconditioning: "Airconditioning",
      luggage: "Luggage",
      autonomous: "Autonomous",
      paymentTerminal: "Payment terminal",
      childrenChair: "Children chair",
      driverRating: "Driver rating",
      economy: "Economy",
      maxPerson: "Max. persons",
      specialTransport: "Special transport",
    },
    onlyManualSending: "Assigning order to driver",
    createdClientDialog_title: "New customer",
    editClientDialog_title: "Edit customer",
    infoClientDialog_title: "Customer information",
    historyClientDialog_title: "Customer order history",
    createOrderDialog_title: "Create new order",
    successCreateOrderDialog_title: "Successfully created new order",
    setAccountIdFromClientDialog_title: "Set client company",
    showOrderDetailDialog_title: "Order detail",
    showPhoneHistoryDialog_title: "History of last phone numbers",
    mark: "Mark",
    notification: "Notification",
    virtualAssistant: "Virtual assistant",
    rating: "Rating",
    driveCounter: "Drive counter",
    accounts: "Companies",
    accountName: "Company name",
    accountClientStatus: "User status in company",
    msgPhoneCrountryAlert1: "The phone number is probably from another country (",
    msgPhoneCrountryAlert2: "). Please set notification if you want to send SMS.",
    msgPhoneLandlineAlert: "The phone number is probably a LANDLINE. Please set notification if you want to send SMS.",
    msgOrderCreateSuccess: "Order was successfully created.",
    msgOrderCreateText: "Do you want to leave form data (Close), create a new clean order or load a new call for a new clean order?",
    autoHideSuccessCreateOrderDialogSwitch: "Automatically hide and clear the form for a new order after 2 seconds",
    btnNewOrder: "New order",
    btnGetClientByExtension: "Load call",
    btnShowOrderDetail_title: "Detail of newly created new order",
    msgSetAccountIdText: "The client has allowed company rides! Do you want to create a client order with the default company?",
    alwaysShowAccountAlertDialog_label: "Show alert",
    alwaysShowAccountAlertDialog_title: "Show popup window with a warning that the client has allowed company rides",
    btnPaymentPrivate: "Private order",
    btnPaymentAccount: "Company order",
    msgShowOrderDetailText: "The client has an ongoing order! Do you want to display the order detail?",
    alwaysShowOrderDetailAlertDialog_label: "Show alert",
    alwaysShowOrderDetailAlertDialog_title: "Show popup window with a warning that the client has an ongoing order",
    btnShowOrderDetail: "Order detail",
    rulesPresendTime1: "Min. 1min", //Time should be above 1 minute
    rulesPresendTime2: "Max. 240min", //Max should not be above 240 minutes (4 hours)
    presendTimeChip_title: "Click to set presend",
    rulesNote: "Note can have maximum 80 characters", //Max 80 characters
    btnActionHideText: "Hide",
    btnActionShowText: "Show",
    msgActionShowFullForm: "Do you want to try the EXTENDED OPTIONS of the New Order window?",
    msgReverseGeoAlertNoResults: "No results found",
    msgNoExtensionSelected: "No extension selected",
    msgNoCallOnExtension: "No call on extension",
    msgCreateNewCustomer: "Register a new client!",
    msgPhoneGateError: "Error communicating with the telephone exchange!",
    msgPhoneGateErrorOther: "Error communicating with the exchange!",
    msgPhoneNumberMissing: "Phone number missing!",
    msgPhoneNumberMissingPlus: "The phone number must start with the + sign",
    msgPhoneNumberShort: "The phone number is too short, for example, use the international format and at least 8 digits!",
    msgPhoneNumberNotNumbers: "The phone number can only contain numbers after the + sign!",
    msgPhoneNumberSecondNotNumber: "The phone number is invalid!",
    msgDisabledModuelDispatchingCreateOrder: "The module for creating orders is disabled!",
    msgMissingClientOrNumber: "No client found or the phone number is invalid.",
    msgClientPhoneNotMatch: "The phone number does not match the client!",
    msgMissingClient: "No client found.",
    msgClientNoteNotChanged: "The note has not changed.",
    msgMissingBoardDestinAddress: "You do not have a pick-up or destination address.",
    msgGetPaymentListError: "An error occurred while loading payment options. Check if payment methods are configured.",
    msgFilterPaymentTerminalYes: "Request for a payment terminal in the vehicle added",
    msgFilterPaymentTerminalAny: "Request for a payment terminal in the vehicle canceled",
    msgClientPendingOnAccount: "The company was added, but the customer has not yet been approved in the company.",
    msgDefaultAccountIsNotValid: "The default company is not valid. Please select a company from the menu and notify the taxi service manager to check the corporate customer.",
    msgAccountNotSelected: "You have selected payment to the company, but you have not selected the company.",
    msgShortDistanceAddresses: "The distance between the points is too small! The distance must be at least 10m.",
    msgTimeInPastError: "The pick-up time cannot be in the past!",
    msgTimeTooSoonError: "The pick-up time is too soon! Enter a later time (at least 5 minutes)!",
    msgTimeTooLateError: "The time entered is more than 2 weeks in the future!",
    msgCreateOrderError: "An error occurred while creating the order!",
    msgDuplicateOrder: "Order for the customer already exists!",
    msgAddReverseGeoLimitBan: "You often search for addresses directly on the map. You have exceeded the maximum number of such addresses. The function of adding new addresses is temporarily blocked for you.",
    msgClientBlocked: "Client is blocked!",
    msgClientDebtor: "Client is debtor!",
    msgClientVip: "Client is VIP!",
    msgUpdateClientSuccess: "Update successful!",
    msgUpdateClientError: "Update failed!",
    msgPresendTimeNotSufficient: "The recommended presend time is longer than the default presend time. Adjust presend time!",
    msgBoardAddressFarFromStand: "The pick-up address is further than usual!",
  },

  onlyManualSendingArray: [
    {
      "text": "Default",
      "value": null,
    }, {
      "text": "Manual sending only",
      "value": true,
    }, {
      "text": "Sending by automat (if is ON)",
      "value": false,
    },

  ],

  ConsoleOrderList: {
    panelOrderList: "Orders",
    panelOrderList_title: "Minimize / maximize the Order Panel",
    searchField: "Search",
    searchField_title: "Search in the list of orders by pressing the Enter key.",
    showOnlyActiveOrder_title: "Show only Active orders",
    showPlannedOrder_title: "Show all Planned orders",
    hidePlannedOrder_title: "Hide Planned orders (only timeorders assigned to 30 minutes will be displayed)",
    onlyNotApprovedSwitch_title: "Show only Addresses for approval",
  },

  ConsoleOrderListItem: {
    preferredDriver: "Preferred driver",
    requestedTime: "Requested time",
    onlyManualSending_true: "Show order detail and manually assign to driver",
    onlyManualSending_false: "Show order detail",
    changeStatusDialog_title: "Change order status",
    changeStatusDialog_text: "You can only change the status of New orders.",
    btnAccept: "Accept order",
    btnAbort: "Cancel order",
  },

  ConsoleOrderWaypointEdit: {
    btnEditWaypoint: "Addresses",
    btnEditWaypoint_title: "Edit addresses and waypoints of the order",
    dialogTitle: "Edit addresses",
    id_short: "ID",
    status: "Status",
    createdAt: "Created",
    boardAddress: "Pick-up address",
    transit1Address: "Waypoint 1 address",
    transit2Address: "Waypoint 2 address",
    transit3Address: "Waypoint 3 address",
    transit4Address: "Waypoint 4 address",
    destAddress: "Destination address",
    msgPriceUpdated: "The price was successfully recalculated.",
    msgPriceNotUpdated: "The price could not be recalculated.",
  },

  ConsoleOrderEdit: {
    btnEditOrder: "Edit",
    btnEditOrder_title: "Edit order",
    dialogTitle: "Edit order",
    id_short: "ID",
    status: "Status",
    createdAt: "Created",
    msgSuccess: "Order was successfully updated",
    msgError: "Order update failed!",
    msgUnavailable: "This type or status of the order cannot be edited!",
    msgInvalidDataInForm: "Data in the form are invalid!",
    list: {
      subheaderTime: "Time",
      updateToInstantDialog: "Change to instant order",
      updateRequestedTimeDialog: "Change pick-up time",
      updateRequestedTimeDialog_toTime: "Change to time order",
      updatePresendTimeDialog: "Change presend time",
      subheaderStatus: "Status",
      abortOrderDialog: "Cancel order",
      updateSendingActivateDialog: "Activate sending",
      updateSendingDeactivateDialog: "Deactivate sending",
      clearDriverDialog: "Clear driver",
      subheaderSettings: "Settings",
      updateNoteDialog: "Change note",
      updatePaymentDialog: "Change payment type or account",
      updateFilterDialog: "Change filters/requirements",
      updateSettingsDialog: "Change display settings and sending",
      updatePriceDialog: "Recalculate price",
      confirmNewDialog: "Confirm new order",
    },
    //zrusenie
    abortOrderDialog_title: "Do you want to cancel the order?",
    abortOrderDialog_text: "When you cancel the order, the order will not be delivered to drivers. If the customer has not requested a ride, notify them of the cancellation of their order.",
    abortOrderDialog_btnAbort: "Cancel order",
    abortedReason: "Reason for cancellation",
    abortNote: "Note",
    //aktivovanie
    updateSendingActivateDialog_title: "Do you want to activate the order for sending?",
    updateSendingActivateDialog_text: "When you activate the order, the order will be delivered to drivers. (automatically or manually according to settings)",
    updateSendingActivateDialog_btnActivate: "Activate for sending",
    //deaktivovanie
    updateSendingDeactivateDialog_title: "Do you want to deactivate the order for sending?",
    updateSendingDeactivateDialog_text: "When you deactivate the order, the order will not be delivered to drivers.",
    updateSendingDeactivateDialog_btnDeactivate: "Deactivate for sending",
    //na okamzitu
    updateToInstantDialog_title: "Do you want to change the order to an instant ride?",
    updateToInstantDialog_text: "When you change the order to an instant ride, the order will be delivered to drivers IMMEDIATELY.",
    updateToInstantDialog_btnToInstant: "Change to instant ride",
    //na casovku
    updateRequestedTimeDialog_title: "Do you want to change the order to a time order or change the pick-up time?",
    updateRequestedTimeDialog_text: "When you change the order to a time order, the order will be delivered to drivers at the time you entered. For sending in advance, also set the time to send in advance in minutes.",
    updateRequestedTimeDialog_btnToTime: "Change",
    requestedTime: "Pick-up time",
    msgRequestedTimePastError: "The pick-up time must be in the future!",
    msgRequestedTimeFarFutureError: "The pick-up time must be within 14 days!",
    //cas zaslanie vopred
    updatePresendTimeDialog_title: "Do you want to change the time when the order should start sending in advance?",
    updatePresendTimeDialog_text: "The order will be delivered the selected number of minutes in advance.",
    updatePresendTimeDialog_btnUpdate: "Change",
    presendTime: "Time to send in advance",
    rulesPresendTime1: "Time must be more than 1 minute",
    rulesPresendTime2: "Time must be less than 240 minutes (4 hours)",
    msgPresendTimeNullError: "Presend time must be filled!",
    //Odobratie objednavky od vodica
    clearDriverDialog_title: "Do you want to remove the order from the driver?",
    clearDriverDialog_text: "When you remove the order from the driver, the order will return to the sending state.",
    clearDriverDialog_btnClear: "Remove order",
    //zmena poznamky
    updateNoteDialog_title: "Do you want to change the note?",
    updateNoteDialog_text: "When you change the note, the note in the order will change. The change will not be reflected immediately in the driver's application, but for example when the order status changes or the application is restarted.",
    updateNoteDialog_btnUpdate: "Change note",
    note: "Note",
    rulesNote: "The note can have a maximum of 80 characters", //Max 80 characters
    //zmena sposobu platby
    updatePaymentDialog_title: "Do you want to change the payment method or account?",
    updatePaymentDialog_text: "When you change the payment method or account, the payment method and company account in the order will change. The change will not be reflected immediately in the driver's application, but for example when the order status changes or the application is restarted.",
    updatePaymentDialog_btnUpdate: "Change payment",
    paymentType: "Payment type",
    accountId: "Company account",
    //poziadavky na jazdu
    updateFilterDialog_title: "Do you want to change the ride requirements?",
    updateFilterDialog_text: "When you change the ride requirements, the ride requirements in the order will change. The change will not be reflected immediately in the driver's application, but for example when the order status changes or the application is restarted.",
    updateFilterDialog_btnUpdate: "Change requirements",
    animal: "Animal",
    airconditioning: "Air conditioning",
    luggage: "Luggage",
    autonomous: "Autonomous ride",
    paymentTerminal: "Payment terminal",
    childrenChair: "Child seat",
    driverRating: "Driver rating",
    economy: "Eco ride",
    maxPerson: "Number of passengers",
    specialTransport: "Special transport",
    //nastavenia
    updateSettingsDialog_title: "Do you want to change the settings for sending an order and displaying information in the driver's application?",
    updateSettingsDialog_text: "When changing the settings, the setting in the order will change. The change will not be reflected immediately in the driver's application, but for example when the order status changes or the application is restarted.",
    updateSettingsDialog_btnUpdate: "Change settings",
    showPhone: "Show customer phone number to driver",
    showDriverPhoneButton: "Show driver call button",
    showAllAddresses: "Show all addresses to driver",
    onlyManualSending: "Only manual sending",
    forcedPickupRadius: "Forced pickup radius (0 = off)",
    showPhone_title: "The driver needs to restart the application or change the order status to see the phone number.",
    showDriverPhoneButton_title: "Show driver call button",
    showAllAddresses_title: "The driver needs to restart the application or change the order status to see the phone number.",
    onlyManualSending_title: "Used for the automat to not send the order automatically",
    forcedPickupRadius_title: "If 0, the driver can change the order status anywhere that he is at the pickup location. If, for example, 200, the driver must be a maximum of 200 meters from the pickup address location upon arrival.",
    rulesForcedPickupRadius1: "Must be 0 or more",
    rulesForcedPickupRadius2: "Max 1000m",
    //cena
    updatePriceDialog_title: "Do you want to recalculate the order price?",
    updatePriceDialog_text: "When recalculating the price, negative impacts on the customer may arise. Therefore, consider carefully whether you want to recalculate the price of the order. After changing the price, it is necessary to notify the customer directly whether he agrees with the new price.",
    updatePriceDialog_btnUpdate: "Recalculate price",
    msgPriceUpdated: "The price was successfully recalculated.",
    msgPriceNotUpdated: "The price could not be recalculated.",
    //potvrdenie Novej objednavky
    confirmNewDialog_title: "Do you want to confirm the new order?",
    confirmNewDialog_text: "When creating an order, usually from the Customer application, if automatic approval is not set, it is necessary to Approve the order. If you do not approve or reject the order, it will not be sent to the drivers.",
    confirmNewDialog_btnConfirmNew: "Approve",
    confirmNewDialog_btnAbortNew: "Reject and cancel",
  },

  ConsoleOrderDetail: {
    id: "Order ID",
    id_short: "ID",
    status: "Status",
    presendTime: "Presend time:",
    requestedTime: "",
    clientNone: " - no client - ",
    clientId: "Customer ID:",
    nicknameClient: "",
    phoneClient: "",
    mark: "",
    ratingClient: "",
    noteClient: "",
    driverNone: " - no driver - ",
    driverId: "Driver ID:",
    firstName: "",
    lastName: "",
    nicknameDriver: "",
    phoneDriver: "",
    ratingDriver: "",
    driveCounter: "",
    vehicleId: "Vehicle ID:",
    brand: "",
    model: "",
    lpn: "",
    color: "",
    vehicleType: "",
    internalName: "",
    preferredDriverFirstName: "Pref: ",
    preferredDriverLastName: "",
    paymentType: "Payment:",
    account: "Account:",
    note: "Note:",
    orderFilter: "Ride requirements",
    displayAndSend: "Display and send settings",
    timeChanged: "Time of last change", //Čas zmeny stavu objednávky
    createdAt: "Created At",
    plannedAt: "Planned At",
    pendingAt: "Pending At",
    acceptedAt: "Accepted At",
    waitingAt: "Waiting At",
    progressAt: "Progress At",
    finishedAt: "Finished At",
    settledAt: "Settled At",
    chargedAt: "Charged At",
    interruptedAt: "Interrupted At",
    archivedAt: "Archived At",
    showPrice: "Show price to driver and customer",
    showPhone: "Show customer phone number to driver",
    showDriverPhoneButton: "Show driver call button",
    showAllAddresses: "Show all addresses to driver",
    onlyManualSending: "Only manual sending",
    forcedPickupRadius: "Forced pickup radius (0 = off)",
    btnSendOrderToDriver: "Send",
    btnUpdatePreferreDriver: "Prefer",
    btnFindDriverOnMap: "Find",
    btnShowEditDriverWorkShiftDialog: "Work shift",
    btnShowMessage: "Chat",
    btnDetailRefresh: "Refresh",
    btnAutocompleteDriverRemote: "Driver",
    btnAutocompleteDriverRemote_placeholder: "Start typing...",
    //btnEditOrder: "Edit",
    //btnEditOrderWaypoint: "Edit waypoints",
    price: "Price",
    priceName: "Name",
    priceEstimated: "Estimated",
    priceFinal: "Final",
    priceInfo: "Info",
    distance: "Distance",
    time: "Time",
    priceStart: "For pickup",
    priceWaiting: "For waiting before driving",
    priceDriveDistance: "For driving distance",
    priceDriveTime: "For driving time",
    priceDrive: "For driving",
    priceJourney: "For journey",
    priceExtra: "Extra charges",
    priceDiscount: "Discount",
    priceTotal: "Total price",
    sentOrderLogList: "Sending orders to drivers",
    tbl_driver: "Driver",
    tbl_sent: "Sent",
    tbl_sent_short: "Sent",
    tbl_distance: "Distance",
    tbl_distance_short: "Dist.",
    tbl_time: "Time",
    waitingList: "Waiting for customer",
    tbl_startedAt: "Started at",
    tbl_finishedAt: "Finished at",
    tbl_timeMin: "Time (min)",
    tbl_timeSeconds: "Time (s)",
    tbl_distanceMeters: "Distance (m)",
    editDriverWorkShiftDialog_title: "Edit work shift states",
    workBreak: "Break in work",
    workBreakApprove: "Approve break",
    workBreakReject: "Reject break",
    workEndShift: "End of work shift",
    workEndShiftApprove: "Approve end of work shift",
    workEndShiftReject: "Reject end of work shift",
    workShiftRequestListDialog_title: "List of open work shift change requests",
    msgUpdateWorkShiftMissingData: "Missing data for changing workshift.",
    msgUpdateWorkShiftMissingRequest: "No driver request for break or end of work shift was found.",
    msgUpdateStatusError: "Error updating order status",
    msgUpdateStatusSuccess: "Order status was updated successfully",
    msgDriverNotSelected: "Driver is not selected",
    msgWorkShiftDriverNoRequest: "Driver has no work shift change request!",
    msgGetOrderDetailError: "Error getting order detail",
    msgCopyText: "Copied to clipboard",
    msgPbxCallNumberMissing: "Phone number is not available. Check whether the order detail is loaded or whether the customer number is entered in the order",
    msgPbxCallLineMissing: "No flap was selected on the New Order tab!",
    msgPbxCallSuccess: "The call was successfully initiated",
    msgPbxCallError: "The call was not initiated!",
    msgSendOrderDriverNotSelected: "The driver is not selected",
    msgSendOrderOrderNotSelected: "The order is not selected",
    msgSendOrderSuccess: "The order was successfully sent to the driver.",
    msgSendOrderError: "The order was not sent to the driver!",
    msgSendOrderDriverNotPreferred: "You're about to send an order to a non-preferred driver. Press OK to send him the Order anyway.",
    msgPreferredDriverSetSuccess: "The preferred driver is set",
    msgPreferredDriverSetError: "The preferred driver is not set",
    msgFindDriverNotSet: "To display a specific driver on the map, first select a driver from the list ...",
    msgFindPositionError: "The location is without coordinates ...",
    msgMessageStatusCannotSend: "The message cannot be sent because its status is closed",
    confirmPbxCallDialog_title: "Calls to the customer and the driver within the order",
    confirmPbxCallDialog_text: "Some functions depend on your exchange or device used, may not be available.",
    subheaderPbxCall: "Calls via the exchange",
    btnPbxCallClient: "Create a customer call from the flap",
    btnPbxCallDriver: "Create a driver call from the flap",
    btnPbxCallBetweenDriverClient: "Create a call from the driver to the customer",
    subheaderPhoneCopy: "Copy phone number",
    btnPhoneClientCopy: "Copy the phone number 'Customer'",
    btnPhoneDriverCopy: "Copy the driver's phone number",
    subheaderPhoneHref: "Open phone link in phone app",
    btnPhoneClientHref: "Open link to Customer's phone",
    btnPhoneDriverHref: "Open link to Driver's phone",
    msgPbxCallOrderMissing: "No order is selected!",
    chatMsgSuccessPbxDriverClientCall: "The dispatcher initiated a call between the driver and the customer",
    editDriverSilenceDialog_title: "Change driver's Silent mode",
    editDriverSilenceDialog_text: "Would you like to switch driver's Silent mode?",
    btnSilenceOn: "Turn On Silent mode",
    btnSilenceOff: "Turn Off Silent mode",
    msgSilenceOnSuccess: "Silent mode is On",
    msgSilenceOffSuccess: "Silent mode is Off",
    msgSilenceError: "Error to switch driver's Silent mode",
    historyClientDialog_title: "History of customer orders",
    btnOpenHistoryClientDialog: "Order History",
    btnOpenHistoryClientDialog_title: "Display a list of orders from the customer's history",
    msgMissingClient: "Client is not available!",
    msgClientHistoryEmpty: "Order history of client is empty."
  },

  ConsoleOrderDetail_tooltip: {
    copyToClipboard: "Copy to clipboard",
    createdAtChip: "Date and time of order creation (UTC)",
    changeStatusAtChip: "Click to change the status",
    presendTime: "When sending by automaton, send the order at the preset time in advance",
    requestedTime: "When timed ordering, the requested pickup time",
    paymentType: "Payment method",
    account: "Account",
    note: "Note",
    client: "Customer",
    clientId: "Customer ID",
    nicknameClient: "Customer Name",
    phoneClient: "Phone Number to Customer",
    phoneClientHref: "Open link to Customer's phone in phone app",
    phoneClientCopy: "Copy the customer's phone number",
    phonePbxCall: "Call the customer via the call center",
    mark: "Customer designation or type",
    ratingClient: "Customer rating",
    noteClient: "Customer note",
    driver: "Driver",
    driverId: "Driver Identification Number",
    firstName: "First Name",
    lastName: "Last Name",
    nicknameDriver: "Designation/nickname",
    phoneDriver: "Phone Number to driver",
    phoneDriverHref: "Open link to Driver's phone in phone app",
    phoneDriverCopy: "Copy the driver's phone number",
    ratingDriver: "Driver rating",
    driveCounter: "Total number of trips by the driver",
    vehicleId: "Vehicle identification number:",
    brand: "Vehicle brand",
    model: "Vehicle model",
    lpn: "License plate number",
    color: "Color",
    vehicleType: "Vehicle type",
    internalName: "Internal name",
    preferredDriverFirstName: "First name of preferred driver",
    preferredDriverLastName: "Last name of preferred driver",
    orderFilter: "Ride requirements",
    displayAndSend: "Display and send",
    btnSendOrderToDriver: "Send order to driver",
    btnUpdatePreferreDriver: "Set preferred driver",
    btnFindDriverOnMap: "Find driver on map",
    btnShowEditDriverWorkShiftDialog: "Approval of work shift states",
    btnShowEditDriverWorkShiftDialog: "Schvaľovanie stavov pracovnej zmeny",
    btnShowMessage: "Display chat messages for order",
    btnDetailRefresh: "Refresh order detail information",
    btnAutocompleteDriverRemote: "Select driver",
    price: "Price",
    //btnEditOrder: "Edit order",
    //btnEditOrderWaypoint: "Edit order addresses and waypoints",
    timeChanged: "Time of last change", //Čas zmeny stavu objednávky
    createdAt: "Order created",
    plannedAt: "The ride was planned, in case of timed order at the requested time",
    pendingAt: "The order is waiting for assignment to the driver",
    acceptedAt: "The driver accepted the order and is moving to the customer's pick-up location",
    waitingAt: "The driver arrived at the customer's pick-up location and is waiting for the customer",
    progressAt: "The driver has started transporting the customer",
    finishedAt: "The driver delivered the customer to the destination and prepares the account",
    settledAt: "The order was paid and closed",
    chargedAt: "The order was invoiced, if card payment was chosen, the payment will be made by the payment gateway",
    interruptedAt: "The order was canceled or its sending to the driver was suspended",
    archivedAt: "The order is archived",
    sentOrderLogList: "Sending orders to drivers",
    waitingList: "Waiting for customer",
  },

  ConsoleOrderMessage: {
    tabOrder: "Order",
    tabDriver: "Driver",
    tabDispatch: "Dispatch",
    tabPublic_title: "Messages to all drivers",
    orderMessageField: "Message to driver and customer",
    dispDriverMessageField: "Message to driver",
    btnWorkShift: "Approve work shift states (break, end shift)",
    dispatchingMessageField: "Message to all dispatchers",
    publicMessageField: "Message to all drivers",
    btnAutocompleteDriverRemote: "Driver",
    btnAutocompleteDriverRemote_placeholder: "Start typing...",
    btnAutocompleteDriverRemote_title: "Select a driver. Alternatively, click on the left icon to update the list of drivers",
    copyToTextField: "Click to copy text to message field",
    editDriverWorkShiftDialog_title: "Edit work shift states",
    workBreak: "Break at work",
    workBreakApprove: "Approve break",
    workBreakReject: "Reject break",
    workEndShift: "End work shift",
    workEndShiftApprove: "Approve end work shift",
    workEndShiftReject: "Reject end work shift",
    msgWorkShiftDriverNoRequest: "Driver has no work shift request!",
    msgWorkShiftDriverNotSelected: "Driver is not selected!",
    msgWorkShiftMissingData: "Missing data for changing workshift.",
    msgWorkShiftMissingRequest: "Driver's request for break or end of shift was not found.",
    msgWorkShiftUpdateError: "Error updating state",
    msgWorkShiftUpdateSuccess: "State was successfully updated",
    msgGetOrderDetailError: "Error getting order detail",
    msgOrderMessageCountSystem: "There are several messages with warnings in the order. It is possible that too many drivers ignored this order. Assign it manually or take appropriate action.",
    sendMessageSuccess: "Message was successfully sent",
    getPublicMessagesError: "Error loading public messages for all drivers",
    sendOrderMessageError: "Order is not selected or message text is missing!",
    sendDispDriverMessageError: "Driver is not selected or message text is missing!",
    sendDispatchingMessageError: "Message text is missing!",
    sendPublicMessageError: "Message text is missing!",
    dispDriverMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Good",
      good_text: "Good",
      thankyou: "Thank you",
      thankyou_text: "Thank you",
      calling: "Calling",
      calling_text: "Calling",
      going: "Going",
      going_text: "Customer is already going",
      whereareyou: "Where are you?",
      whereareyou_text: "Where are you please?",
      cancelled: "Cancelled",
      cancelled_text: "Order is cancelled",
      "1min": "1",
      "1min_text": "Customer will come in 1 minute",
      "2min": "2",
      "2min_text": "Customer will come in 2 minutes",
      "5min": "5",
      "5min_text": "Customer will come in 5 minutes",
    },
    orderMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Good",
      good_text: "Good",
      thankyou: "Thank you",
      thankyou_text: "Thank you",
      calling: "Calling",
      calling_text: "Calling",
      going: "Going",
      going_text: "Customer is already going",
      cancelled: "Cancelled",
      cancelled_text: "Order is cancelled",
      "1min": "1",
      "1min_text": "Customer will come in 1 minute",
      "2min": "2",
      "2min_text": "Customer will come in 2 minutes",
      "5min": "5",
      "5min_text": "Customer will come in 5 minutes",
      clientPhone: "Phone",
      clientPhone_text: "Customer's phone: ",
    },
    dispatchingMessageText: {
      ok: "OK",
      ok_text: "OK",
      good: "Good",
      good_text: "Good",
      thankyou: "Thank you",
      thankyou_text: "Thank you",
    },
  },

  showDriverPhoneButton: {
    NONE: "NONE",
    CUSTOMER: "Customer phone",
    DISPATCH: "Dispatch phone",
    CUSTOMER_DISPATCH: "Customer and Dispatch phone",
    PBX: "Customer phone via PBX",
    PBX_DISPATCH: "Customer phone via PBX and Dispatch phone",
  },
  showDriverPhoneButtonShort: {
    NONE: "-",
    CUSTOMER: "C",
    DISPATCH: "D",
    CUSTOMER_DISPATCH: "C&D",
    PBX: "P",
    PBX_DISPATCH: "P&D",
  },
  showDriverPhoneButtonArray: [
    {
      "text": "None",
      "value": "NONE",
    }, {
      "text": "Customer phone",
      "value": "CUSTOMER",
    }, {
      "text": "Dispatch phone",
      "value": "DISPATCH",
    }, {
      "text": "Customer and Dispatch phone",
      "value": "CUSTOMER_DISPATCH",
    }, {
      "text": "Customer phone via PBX",
      "value": "PBX",
    }, {
      "text": "Customer phone via PBX and Dispatch phone",
      "value": "PBX_DISPATCH",
    },
  ],
  showPrice: {
    ALL: "Show to all",
    ONLY_DRIVER: "Show only to driver",
    HIDDEN: "Hide",
  },
  showPriceShort: {
    ALL: "All",
    ONLY_DRIVER: "Only driver",
    HIDDEN: "Hidden",
  },
  showPriceArray: [
    {
      "text": "Show to all",
      "value": "ALL",
    }, {
      "text": "Show only to driver",
      "value": "ONLY_DRIVER",
    }, {
      "text": "Hide",
      "value": "HIDDEN",
    },
  ],

  orderFilter: {
    airconditioning: "Air conditioning",
    animal: "Animal",
    autonomous: "Autonomous vehicle",
    childrenChair: "Child transport",
    driverRating: "Driver rating",
    eco: "Ecological",
    luggage: "Luggage",
    maxPerson: "Number of passengers",
    paymentTerminal: "Payment terminal",
    smoking: "Smoking in the vehicle",
    specialTransport: "Special transport",
  },

  orderFilterSelect: {
    airconditioning: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Yes",
        "value": "YES",
      },
    ],
    animal: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Small up to 10kg",
        "value": "SMALL",
      }, {
        "text": "Medium up to 20kg",
        "value": "MEDIUM",
      }, {
        "text": "Large up to 50kg",
        "value": "BIG",
      }, {
        "text": "Only without animals",
        "value": "NONE",
      },
    ],
    autonomous: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Autonomous vehicle",
        "value": "AUTONOMOUS",
      }, {
        "text": "Non-autonomous vehicle",
        "value": "NONAUTONOMOUS",
      },
    ],
    paymentTerminal: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Required",
        "value": "YES",
      },
    ],
    childrenChair: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Booster seat",
        "value": "BOOSTER",
      }, {
        "text": "Child seat",
        "value": "SEAT",
      },
    ],
    driverRating: [
      {
        "text": "Not required",
        "value": 0,
      }, {
        "text": "1",
        "value": 1,
      }, {
        "text": "2",
        "value": 2,
      }, {
        "text": "3",
        "value": 3,
      }, {
        "text": "4",
        "value": 4,
      }, {
        "text": "5",
        "value": 5,
      },
    ],
    eco: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Ecological vehicle",
        "value": "ECO",
      }, {
        "text": "Electric vehicle",
        "value": "ELECTRIC",
      },
    ],
    luggage: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Small luggage",
        "value": "SMALL",
      }, {
        "text": "Medium luggage",
        "value": "MEDIUM",
      }, {
        "text": "Large luggage",
        "value": "BIG",
      },
    ],
    maxPerson: [
      {
        "text": "Not required",
        "value": 0,
      }, {
        "text": "1",
        "value": 1,
      }, {
        "text": "2",
        "value": 2,
      }, {
        "text": "3",
        "value": 3,
      }, {
        "text": "4",
        "value": 4,
      }, {
        "text": "5",
        "value": 5,
      }, {
        "text": "6",
        "value": 6,
      }, {
        "text": "7",
        "value": 7,
      }, {
        "text": "8",
        "value": 8,
      }, {
        "text": "9",
        "value": 9,
      },
    ],
    smoking: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Smoking vehicle",
        "value": "SMOKING",
      },
    ],
    specialTransport: [
      {
        "text": "Not required",
        "value": "ANY",
      }, {
        "text": "Wheelchair",
        "value": "CHAIR",
      }, {
        "text": "Elderly",
        "value": "OLD",
      },
    ],
  },

  vehicleSelect: {
    airconditioning: [
      {
        "text": "No",
        "value": "NO",
      }, {
        "text": "Yes",
        "value": "YES",
      },
    ],
    animal: [
      {
        "text": "Small",
        "value": "SMALL",
      }, {
        "text": "Medium",
        "value": "MEDIUM",
      }, {
        "text": "Big",
        "value": "BIG",
      }, {
        "text": "None",
        "value": "NONE",
      },
    ],
    autonomous: [
      {
        "text": "No autonomous functions",
        "value": "L0",
      }, {
        "text": "Level 1 - Adaptive cruise control",
        "value": "L1",
      }, {
        "text": "Level 2 - Lane keeping assist",
        "value": "L2",
      }, {
        "text": "Level 3 - Partial autonomous driving",
        "value": "L3",
      }, {
        "text": "Level 4 - Almost autonomous driving",
        "value": "L4",
      }, {
        "text": "Level 5 - Fully autonomous driving",
        "value": "L5",
      },
    ],
    paymentTerminal: [
      {
        "text": "No",
        "value": "NO",
      }, {
        "text": "Yes",
        "value": "YES",
      },
    ],
    childrenChair: [
      {
        "text": "No children chair",
        "value": "NONE",
      }, {
        "text": "Booster",
        "value": "BOOSTER",
      }, {
        "text": "Seat",
        "value": "SEAT",
      }, {
        "text": "Seat and booster",
        "value": "ALL",
      },
    ],
    eco: [
      {
        "text": "Diesel",
        "value": "DIESEL",
      }, {
        "text": "Gasoline",
        "value": "GASOLINE",
      }, {
        "text": "Hybrid Electric",
        "value": "HYBRID",
      }, {
        "text": "Plug-in Hybrid Electric",
        "value": "PLUGIN",
      }, {
        "text": "CNG",
        "value": "CNG",
      }, {
        "text": "LPG",
        "value": "LPG",
      }, {
        "text": "Electric",
        "value": "ELECTRIC",
      }, {
        "text": "Hydrogen",
        "value": "HYDROGEN",
      },
    ],
    luggage: [
      {
        "text": "Small",
        "value": "SMALL",
      }, {
        "text": "Medium",
        "value": "MEDIUM",
      }, {
        "text": "Big",
        "value": "BIG",
      },
    ],
    smoking: [
      {
        "text": "No",
        "value": "NO",
      }, {
        "text": "Yes",
        "value": "YES",
      },
    ],
    specialTransport: [
      {
        "text": "No",
        "value": "NONE",
      }, {
        "text": "Transport of people in a wheelchair",
        "value": "CHAIR",
      }, {
        "text": "Transport of elderly people and people requiring assistance",
        "value": "OLD",
      }, {
        "text": "Elderly and immobile people in a wheelchair",
        "value": "ALL",
      },
    ],
  },

  DateRangeSelect:
    [
      {
        "text": "Today",
        "value": "today",
      }, {
        "text": "Yesterday",
        "value": "yesterday",
      }, {
        "text": "Last 2 days",
        "value": "last_2_days",
      }, {
        "text": "This week",
        "value": "this_week",
      }, {
        "text": "This month",
        "value": "this_month",
      }, {
        "text": "Last month",
        "value": "last_month",
      },
      /*{
        "text": "Last 3 months",
        "value": "last_3_months",
      }, {
        "text": "Last 6 months",
        "value": "last_6_months",
      },*/
      {
        "text": "Custom range",
        "value": "custom",
      },
    ],

  clientMark: {
    NONE: "New client",
    NEW: "New client",
    REGULAR: "Regular client",
    PRIORITY: "Priority client",
    FAMOUS: "Famous person",
    VIP: "VIP",
    COLLEAGUE: "Colleague",
    STUDENT: "Student",
    CHILD: "Child",
    JUNIOR: "Junior",
    SENIOR: "Senior",
    DEBTOR: "Debtor",
    BLOCKED: "Blocked",
  },

  clientMarkSelect:
    [
      {
        "text": "New",
        "value": "NEW",
      }, {
        "text": "Regular",
        "value": "REGULAR",
      }, {
        "text": "Priority",
        "value": "PRIORITY",
      }, {
        "text": "Famous",
        "value": "FAMOUS",
      }, {
        "text": "VIP",
        "value": "VIP",
      }, {
        "text": "Colleague",
        "value": "COLLEAGUE",
      }, {
        "text": "Student",
        "value": "STUDENT",
      }, {
        "text": "Child",
        "value": "CHILD",
      }, {
        "text": "Junior",
        "value": "JUNIOR",
      }, {
        "text": "Senior",
        "value": "SENIOR",
      }, {
        "text": "Debtor",
        "value": "DEBTOR",
      }, {
        "text": "Blocked",
        "value": "BLOCKED",
      },
    ],

  clientNotificationSelect:
    [
      {
        "text": " - none - ",
        "value": "NONE",
      },
      /*{
        "text": "PUSH messages",
        "value": "PUSH",
      },*/
      {
        "text": "SMS",
        "value": "SMS",
      },
      /* {
        "text": "Email",
        "value": "EMAIL",
      }, {
        "text": "Call",
        "value": "CALL",
      }, {
        "text": "Chat",
        "value": "CHAT",
      }, {
        "text": "Viver",
        "value": "VIBER",
      }, {
        "text": "WhatsApp",
        "value": "WHATSAPP",
      },*/
    ],
  CountryCodeConsoleShortList:
    [
      { "text": "All countries", "value": null, },
      { "value": "SK", "text": "Slovakia" },
      { "value": "CZ", "text": "Czechia" },
      { "value": "AT", "text": "Austria" },
      { "value": "HU", "text": "Hungary" },
      { "value": "PL", "text": "Poland" },
      { "value": "UA", "text": "Ukraine" },
    ],
  CountryCodeShortList:
    [
      { "value": "SK", "text": "Slovakia" },
      { "value": "CZ", "text": "Czechia" },
      { "value": "AT", "text": "Austria" },
      { "value": "HU", "text": "Hungary" },
      { "value": "PL", "text": "Poland" },
      { "value": "UA", "text": "Ukraine" },
    ],
  CountryCodeList:
    [
      { "value": "SK", "text": "Slovakia" },
      { "value": "CZ", "text": "Czechia" },
      { "value": "AT", "text": "Austria" },
      { "value": "HU", "text": "Hungary" },
      { "value": "PL", "text": "Poland" },
      { "value": "UA", "text": "Ukraine" },
      { "value": "AD", "text": "Andorra" },
      { "value": "AE", "text": "United Arab Emirates" },
      { "value": "AF", "text": "Afghanistan" },
      { "value": "AG", "text": "Antigua and Barbuda" },
      { "value": "AI", "text": "Anguilla" },
      { "value": "AL", "text": "Albania" },
      { "value": "AM", "text": "Armenia" },
      { "value": "AO", "text": "Angola" },
      { "value": "AQ", "text": "Antarctica" },
      { "value": "AR", "text": "Argentina" },
      { "value": "AS", "text": "American Samoa" },
      { "value": "AU", "text": "Australia" },
      { "value": "AW", "text": "Aruba" },
      { "value": "AX", "text": "Åland Islands" },
      { "value": "AZ", "text": "Azerbaijan" },
      { "value": "BA", "text": "Bosnia and Herzegovina" },
      { "value": "BB", "text": "Barbados" },
      { "value": "BD", "text": "Bangladesh" },
      { "value": "BE", "text": "Belgium" },
      { "value": "BF", "text": "Burkina Faso" },
      { "value": "BG", "text": "Bulgaria" },
      { "value": "BH", "text": "Bahrain" },
      { "value": "BI", "text": "Burundi" },
      { "value": "BJ", "text": "Benin" },
      { "value": "BL", "text": "Saint Barthélemy" },
      { "value": "BM", "text": "Bermuda" },
      { "value": "BN", "text": "Brunei Darussalam" },
      { "value": "BO", "text": "Bolivia, Plurinational State of" },
      { "value": "BQ", "text": "Bonaire, Sint Eustatius and Saba" },
      { "value": "BR", "text": "Brazil" },
      { "value": "BS", "text": "Bahamas" },
      { "value": "BT", "text": "Bhutan" },
      { "value": "BV", "text": "Bouvet Island" },
      { "value": "BW", "text": "Botswana" },
      { "value": "BY", "text": "Belarus" },
      { "value": "BZ", "text": "Belize" },
      { "value": "CA", "text": "Canada" },
      { "value": "CC", "text": "Cocos (Keeling) Islands" },
      { "value": "CD", "text": "Congo, Democratic Republic of the" },
      { "value": "CF", "text": "Central African Republic" },
      { "value": "CG", "text": "Congo" },
      { "value": "CH", "text": "Switzerland" },
      { "value": "CI", "text": "Côte d'Ivoire" },
      { "value": "CK", "text": "Cook Islands" },
      { "value": "CL", "text": "Chile" },
      { "value": "CM", "text": "Cameroon" },
      { "value": "CN", "text": "China" },
      { "value": "CO", "text": "Colombia" },
      { "value": "CR", "text": "Costa Rica" },
      { "value": "CU", "text": "Cuba" },
      { "value": "CV", "text": "Cabo Verde" },
      { "value": "CW", "text": "Curaçao" },
      { "value": "CX", "text": "Christmas Island" },
      { "value": "CY", "text": "Cyprus" },
      { "value": "DE", "text": "Germany" },
      { "value": "DJ", "text": "Djibouti" },
      { "value": "DK", "text": "Denmark" },
      { "value": "DM", "text": "Dominica" },
      { "value": "DO", "text": "Dominican Republic" },
      { "value": "DZ", "text": "Algeria" },
      { "value": "EC", "text": "Ecuador" },
      { "value": "EE", "text": "Estonia" },
      { "value": "EG", "text": "Egypt" },
      { "value": "EH", "text": "Western Sahara" },
      { "value": "ER", "text": "Eritrea" },
      { "value": "ES", "text": "Spain" },
      { "value": "ET", "text": "Ethiopia" },
      { "value": "FI", "text": "Finland" },
      { "value": "FJ", "text": "Fiji" },
      { "value": "FK", "text": "Falkland Islands (Malvinas)" },
      { "value": "FM", "text": "Micronesia, Federated States of" },
      { "value": "FO", "text": "Faroe Islands" },
      { "value": "FR", "text": "France" },
      { "value": "GA", "text": "Gabon" },
      { "value": "GB", "text": "United Kingdom of Great Britain and Northern Ireland" },
      { "value": "GD", "text": "Grenada" },
      { "value": "GE", "text": "Georgia" },
      { "value": "GF", "text": "French Guiana" },
      { "value": "GG", "text": "Guernsey" },
      { "value": "GH", "text": "Ghana" },
      { "value": "GI", "text": "Gibraltar" },
      { "value": "GL", "text": "Greenland" },
      { "value": "GM", "text": "Gambia" },
      { "value": "GN", "text": "Guinea" },
      { "value": "GP", "text": "Guadeloupe" },
      { "value": "GQ", "text": "Equatorial Guinea" },
      { "value": "GR", "text": "Greece" },
      { "value": "GS", "text": "South Georgia and the South Sandwich Islands" },
      { "value": "GT", "text": "Guatemala" },
      { "value": "GU", "text": "Guam" },
      { "value": "GW", "text": "Guinea-Bissau" },
      { "value": "GY", "text": "Guyana" },
      { "value": "HK", "text": "Hong Kong" },
      { "value": "HM", "text": "Heard Island and McDonald Islands" },
      { "value": "HN", "text": "Honduras" },
      { "value": "HR", "text": "Croatia" },
      { "value": "HT", "text": "Haiti" },
      { "value": "ID", "text": "Indonesia" },
      { "value": "IE", "text": "Ireland" },
      { "value": "IL", "text": "Israel" },
      { "value": "IM", "text": "Isle of Man" },
      { "value": "IN", "text": "India" },
      { "value": "IO", "text": "British Indian Ocean Territory" },
      { "value": "IQ", "text": "Iraq" },
      { "value": "IR", "text": "Iran, Islamic Republic of" },
      { "value": "IS", "text": "Iceland" },
      { "value": "IT", "text": "Italy" },
      { "value": "JE", "text": "Jersey" },
      { "value": "JM", "text": "Jamaica" },
      { "value": "JO", "text": "Jordan" },
      { "value": "JP", "text": "Japan" },
      { "value": "KE", "text": "Kenya" },
      { "value": "KG", "text": "Kyrgyzstan" },
      { "value": "KH", "text": "Cambodia" },
      { "value": "KI", "text": "Kiribati" },
      { "value": "KM", "text": "Comoros" },
      { "value": "KN", "text": "Saint Kitts and Nevis" },
      { "value": "KP", "text": "Korea, Democratic People's Republic of" },
      { "value": "KR", "text": "Korea, Republic of" },
      { "value": "KW", "text": "Kuwait" },
      { "value": "KY", "text": "Cayman Islands" },
      { "value": "KZ", "text": "Kazakhstan" },
      { "value": "LA", "text": "Lao People's Democratic Republic" },
      { "value": "LB", "text": "Lebanon" },
      { "value": "LC", "text": "Saint Lucia" },
      { "value": "LI", "text": "Liechtenstein" },
      { "value": "LK", "text": "Sri Lanka" },
      { "value": "LR", "text": "Liberia" },
      { "value": "LS", "text": "Lesotho" },
      { "value": "LT", "text": "Lithuania" },
      { "value": "LU", "text": "Luxembourg" },
      { "value": "LV", "text": "Latvia" },
      { "value": "LY", "text": "Libya" },
      { "value": "MA", "text": "Morocco" },
      { "value": "MC", "text": "Monaco" },
      { "value": "MD", "text": "Moldova, Republic of" },
      { "value": "ME", "text": "Montenegro" },
      { "value": "MF", "text": "Saint Martin, (French part)" },
      { "value": "MG", "text": "Madagascar" },
      { "value": "MH", "text": "Marshall Islands" },
      { "value": "MK", "text": "North Macedonia" },
      { "value": "ML", "text": "Mali" },
      { "value": "MM", "text": "Myanmar" },
      { "value": "MN", "text": "Mongolia" },
      { "value": "MO", "text": "Macao" },
      { "value": "MP", "text": "Northern Mariana Islands" },
      { "value": "MQ", "text": "Martinique" },
      { "value": "MR", "text": "Mauritania" },
      { "value": "MS", "text": "Montserrat" },
      { "value": "MT", "text": "Malta" },
      { "value": "MU", "text": "Mauritius" },
      { "value": "MV", "text": "Maldives" },
      { "value": "MW", "text": "Malawi" },
      { "value": "MX", "text": "Mexico" },
      { "value": "MY", "text": "Malaysia" },
      { "value": "MZ", "text": "Mozambique" },
      { "value": "NA", "text": "Namibia" },
      { "value": "NC", "text": "New Caledonia" },
      { "value": "NE", "text": "Niger" },
      { "value": "NF", "text": "Norfolk Island" },
      { "value": "NG", "text": "Nigeria" },
      { "value": "NI", "text": "Nicaragua" },
      { "value": "NL", "text": "Netherlands" },
      { "value": "NO", "text": "Norway" },
      { "value": "NP", "text": "Nepal" },
      { "value": "NR", "text": "Nauru" },
      { "value": "NU", "text": "Niue" },
      { "value": "NZ", "text": "New Zealand" },
      { "value": "OM", "text": "Oman" },
      { "value": "PA", "text": "Panama" },
      { "value": "PE", "text": "Peru" },
      { "value": "PF", "text": "French Polynesia" },
      { "value": "PG", "text": "Papua New Guinea" },
      { "value": "PH", "text": "Philippines" },
      { "value": "PK", "text": "Pakistan" },
      { "value": "PM", "text": "Saint Pierre and Miquelon" },
      { "value": "PN", "text": "Pitcairn" },
      { "value": "PR", "text": "Puerto Rico" },
      { "value": "PS", "text": "Palestine, State of" },
      { "value": "PT", "text": "Portugal" },
      { "value": "PW", "text": "Palau" },
      { "value": "PY", "text": "Paraguay" },
      { "value": "QA", "text": "Qatar" },
      { "value": "RE", "text": "Réunion" },
      { "value": "RO", "text": "Romania" },
      { "value": "RS", "text": "Serbia" },
      { "value": "RU", "text": "Russian Federation" },
      { "value": "RW", "text": "Rwanda" },
      { "value": "SA", "text": "Saudi Arabia" },
      { "value": "SB", "text": "Solomon Islands" },
      { "value": "SC", "text": "Seychelles" },
      { "value": "SD", "text": "Sudan" },
      { "value": "SE", "text": "Sweden" },
      { "value": "SG", "text": "Singapore" },
      { "value": "SH", "text": "Saint Helena, Ascension and Tristan da Cunha" },
      { "value": "SI", "text": "Slovenia" },
      { "value": "SJ", "text": "Svalbard and Jan Mayen" },
      { "value": "SL", "text": "Sierra Leone" },
      { "value": "SM", "text": "San Marino" },
      { "value": "SN", "text": "Senegal" },
      { "value": "SO", "text": "Somalia" },
      { "value": "SR", "text": "Suriname" },
      { "value": "SS", "text": "South Sudan" },
      { "value": "ST", "text": "Sao Tome and Principe" },
      { "value": "SV", "text": "El Salvador" },
      { "value": "SX", "text": "Sint Maarten, (Dutch part)" },
      { "value": "SY", "text": "Syrian Arab Republic" },
      { "value": "SZ", "text": "Eswatini" },
      { "value": "TC", "text": "Turks and Caicos Islands" },
      { "value": "TD", "text": "Chad" },
      { "value": "TF", "text": "French Southern Territories" },
      { "value": "TG", "text": "Togo" },
      { "value": "TH", "text": "Thailand" },
      { "value": "TJ", "text": "Tajikistan" },
      { "value": "TK", "text": "Tokelau" },
      { "value": "TL", "text": "Timor-Leste" },
      { "value": "TM", "text": "Turkmenistan" },
      { "value": "TN", "text": "Tunisia" },
      { "value": "TO", "text": "Tonga" },
      { "value": "TR", "text": "Türkiye" },
      { "value": "TT", "text": "Trinidad and Tobago" },
      { "value": "TV", "text": "Tuvalu" },
      { "value": "TW", "text": "Taiwan, Province of China" },
      { "value": "TZ", "text": "Tanzania, United Republic of" },
      { "value": "UG", "text": "Uganda" },
      { "value": "UM", "text": "United States Minor Outlying Islands" },
      { "value": "US", "text": "United States of America" },
      { "value": "UY", "text": "Uruguay" },
      { "value": "UZ", "text": "Uzbekistan" },
      { "value": "VA", "text": "Holy See" },
      { "value": "VC", "text": "Saint Vincent and the Grenadines" },
      { "value": "VE", "text": "Venezuela, Bolivarian Republic of" },
      { "value": "VG", "text": "Virgin Islands, British" },
      { "value": "VI", "text": "Virgin Islands, U.S." },
      { "value": "VN", "text": "Viet Nam" },
      { "value": "VU", "text": "Vanuatu" },
      { "value": "WF", "text": "Wallis and Futuna" },
      { "value": "WS", "text": "Samoa" },
      { "value": "YE", "text": "Yemen" },
      { "value": "YT", "text": "Mayotte" },
      { "value": "ZA", "text": "South Africa" },
      { "value": "ZM", "text": "Zambia" },
      { "value": "ZW", "text": "Zimbabwe" }
    ],

  orderAbortedReasonArray:
    [
      { "text": "Customer cancelled order", "value": "CLIENT_CANCELLED_ORDER" },
      { "text": "Customer did not come", "value": "CLIENT_NOT_COME" },
      { "text": "Duplicated order", "value": "DUPLICATED" },
      { "text": "Incorrect order", "value": "INCORRECT_ORDER" },
      { "text": "Other", "value": "OTHER" },
    ],
  messageType: {
    SYSTEM: "System",
    PUBLIC: "Public",
    DISP_DRIVER: "With driver",
    ORDER: "Order",
    DISPATCHING: "Between dispatchers",
    DIRECT: "Direct",
  },
  messageType_short: {
    SYSTEM: "SYS",
    PUBLIC: "ALL",
    DISP_DRIVER: "DRV",
    ORDER: "ORD",
    DISPATCHING: "DISP",
    DIRECT: "DIR",
  },
  messageType_tooltip: {
    SYSTEM: "System generated message",
    PUBLIC: "Message distributed to all drivers without possibility to reply",
    DISP_DRIVER: "Message between driver and dispatchers",
    ORDER: "Message within order",
    DISPATCHING: "Message between dispatchers",
    DIRECT: "Direct message between two users",
  },
  messageFromToType: {
    SYSTEM: "System",
    PUBLIC: "Public",
    DRIVER: "Driver",
    CLIENT: "Client",
    DISPATCHER: "Dispatcher",
  },
  messageFromToType_short: {
    SYSTEM: "System",
    PUBLIC: "Public",
    DRIVER: "Driver",
    CLIENT: "Client",
    DISPATCHER: "Dispatcher",
  },
  messageFromToType_tooltip: {
    SYSTEM: "System",
    PUBLIC: "Public",
    DRIVER: "Driver",
    CLIENT: "Client",
    DISPATCHER: "Dispatcher",
  },
  gender:
    [
      {
        "text": "Male",
        "value": "MALE",
      }, {
        "text": "Female",
        "value": "FEMALE",
      }, {
        "text": "Other",
        "value": "OTHER",
      },
    ],
  driverTag:
    [
      {
        "text": "-",
        "value": null,
      }, {
        "text": "Deaf or hard of hearing",
        "value": "DEAF",
      }, {
        "text": "Mobility impaired",
        "value": "IMMOBILE",
      },
    ],
  language: [
    { "text": "Slovak", "value": "sk" },
    { "text": "Czech", "value": "cs" },
    { "text": "English", "value": "en" },
    { "text": "Ukrainian", "value": "uk" },
    { "text": "Hungarian", "value": "hu" },
    { "text": "German", "value": "de" },
    { "text": "French", "value": "fr" },
    { "text": "Spanish", "value": "es" },
    { "text": "Russian", "value": "ru" },
    { "text": "Turkish", "value": "tr" },
    { "text": "Polish", "value": "pl" },
    { "text": "Chinese", "value": "zh" },
    { "text": "Croatian", "value": "hr" },
    { "text": "Slovenian", "value": "sl" },
    { "text": "Italian", "value": "tl" },
    { "text": "Serbian", "value": "sr" },
  ],

  vehicleColor_short: {
    WHITE: "White", // biela
    BLACK: "Black", // Čierna
    SILVER: "Silver", // Strieborná
    GRAY: "Gray", // Šedá
    BROWN: "Brown", // Hnedá
    PURPLE: "Purple", // Purpurová
    VIOLET: "Violet", // Fialová
    BLUE: "Blue", // Modrá
    DARKBLUE: "Dark blue", // Tmavomodrá
    LIGHTBLUE: "Light blue", // Svetlomodrá
    SKYBLUE: "Sky blue", // Strednemodrá
    RED: "Red", // Červená
    ORANGE: "Orange", //  Oranžová
    DARKORANGE: "Dark orange", // Tmavooranžová
    YELLOW: "Yellow", // Žltá
    GREEN: "Green", // Zelená
    DARKGREEN: "Dark green", // Tmavozelená
    LIGHTGREEN: "Light green", // Svetlozelená
    PINK: "Pink", // Ružová
    BEIGE: "Beige", // Béžová
    GOLD: "Gold", // Zlatá
  },
  vehicleColorArray:
    [
      {
        "text": "White",
        "value": "WHITE",
      }, {
        "text": "Black",
        "value": "BLACK",
      }, {
        "text": "Silver",
        "value": "SILVER",
      }, {
        "text": "Gray",
        "value": "GRAY",
      }, {
        "text": "Brown",
        "value": "BROWN",
      }, {
        "text": "Purple",
        "value": "PURPLE",
      }, {
        "text": "Violet",
        "value": "VIOLET",
      }, {
        "text": "Blue",
        "value": "BLUE",
      }, {
        "text": "Dark blue",
        "value": "DARKBLUE",
      }, {
        "text": "Light blue",
        "value": "LIGHTBLUE",
      }, {
        "text": "Sky blue",
        "value": "SKYBLUE",
      }, {
        "text": "Red",
        "value": "RED",
      }, {
        "text": "Orange",
        "value": "ORANGE",
      }, {
        "text": "Dark orange",
        "value": "DARKORANGE",
      }, {
        "text": "Yellow",
        "value": "YELLOW",
      }, {
        "text": "Green",
        "value": "GREEN",
      }, {
        "text": "Dark green",
        "value": "DARKGREEN",
      }, {
        "text": "Light green",
        "value": "LIGHTGREEN",
      }, {
        "text": "Pink",
        "value": "PINK",
      }, {
        "text": "Beige",
        "value": "BEIGE",
      }, {
        "text": "Gold",
        "value": "GOLD",
      },
    ],

  mapShapeArray:
    [
      {
        "text": "Circle",
        "value": "CIRCLE",
      }, {
        "text": "Polygon",
        "value": "POLYGON",
      },
    ],
  mapShape: {
    CIRCLE: "Circle",
    POLYGON: "Polygon",
  },
  /* ReportStatusMonthView: {
     page_title: "Zoznam objednávok",
     Id: "Č. objednávky",
     Id_tooltip: 'Technické číslo objednávky {msg}',
     Status: "Stav",
     Type: "Typ",
     Source: "Zdroj",
     PaymentType: "Platba",
     Note: "Poznámka",
     CreatedAt: "Vytvorené",
     AcceptedAt: "Akceptované",
     DriverId: "Č. vodiča",
     boardAddress: "Vyzdvihnutie",
     destinationAddress: "Cieľ",
     EstimatedPrice: "Predpokladaná cena",
     FinalPrice: "Finálna cena",
     filterLabel: {
       Id: "č. objednávky",
       Status: "Stav",
       Type: "Typ",
       Source: "Zdroj",
       PaymentType: "Platba",
       Note: "Poznámka",
       CreatedAt: "Vytvorené",
       AcceptedAt: "Akceptované",
       DriverId: "Č. vodiča",
       boardAddress: "Vyzdvihnutie",
       destinationAddress: "Cieľ",
       EstimatedPrice: "Predpokladaná cena",
       FinalPrice: "Finálna cena",
     },
     RefreshData: "Aktualizovať",
   },*/
  messageDispatchList: {
    page_title: "Dispatch anouncements list",
    toolbarActions: "Actions",
    Id: "ID",
    Id_tooltip: 'Technical number {msg}',
    Status: "Status",
    Type: "Type",
    Taxiservice: "Taxiservice",
    TaxiserviceId: "Taxiservice Id",
    CreatedAt: "Created",
    UpdatedAt: "Updated",
    ValidFrom: "Valid from",
    ValidTo: "Valid to",
    Title: "Title",
    Subtitle: "Subtitle",
    Message: "Message",
    filterLabel: {
      Id: "Id",
      Status: "Status",
      Type: "Type",
      Taxiservice: "Taxiservice",
      CreatedAt: "Created",
      UpdatedAt: "Updated",
      ValidFrom: "Valid from",
      ValidTo: "Valid to",
      Title: "Title",
    },
    RefreshData: "Refresh",
    showCreateDialog: "Create",
    showEditDialog: "Edit",
    btnSetActualDate: "Set valid to to actual date",
    msgValidMaxDate: "Valid to must be up to 60 days from today",
    msgGetTaxiservicListForPickerError: "Cannot get taxiservice list for picker",
  },
  messageDispatchTypeArray:
    [
      {
        "text": "Contract",
        "value": "CONTRACT",
      }, {
        "text": "Maintenance",
        "value": "MAINTENANCE",
      }, {
        "text": "Payment",
        "value": "PAYMENT",
      }, {
        "text": "Release",
        "value": "RELEASE",
      }, {
        "text": "System status",
        "value": "SYSTEM_STATUS",
      }, {
        "text": "Other",
        "value": "OTHER",
      },
    ],
  messageDispatchType: {
    CONTRACT: "Contract",
    MAINTENANCE: "Maintenance",
    PAYMENT: "Payment",
    RELEASE: "Release",
    SYSTEM_STATUS: "System status",
    OTHER: "Other",
  },
  messageDispatchStatusArray:
    [
      {
        "text": "Expired",
        "value": "EXPIRED",
      }, {
        "text": "Planned",
        "value": "PLANNED",
      }, {
        "text": "Active",
        "value": "ACTIVE",
      },
    ],
  messageDispatchStatus: {
    EXPIRED: "Expired",
    PLANNED: "Planned",
    ACTIVE: "Active",
    OTHER: "Other",
  },

  smsTemplateTypeArray:
    [
      {
        "text": "Acceptance of the order by the driver",
        "value": "ACCEPTED",
      },
      /*{
        "text": "Approaching of the vehicle",
        "value": "APPROACHING",
      },*/
      {
        "text": "Arrival of the vehicle",
        "value": "WAITING",
      },
      /* {
          "text": "Approaching or arrival of the vehicle",
          "value": "WAITING_APPROACHING",
        },*/
      {
        "text": "End of the ride",
        "value": "FINISHED",
      }, {
        "text": "Settlement",
        "value": "SETTLED",
      },
      {
        "text": "Cancellation of the order",
        "value": "CANCELLED",
      },
    ],
  smsTemplateType: {
    ACCEPTED: "Acceptance of the order by the driver",
    APPROACHING: "Approaching of the vehicle",
    WAITING: "Arrival of the vehicle",
    WAITING_APPROACHING: "Approaching or arrival of the vehicle",
    FINISHED: "End of the ride",
    SETTLED: "Settlement",
    CANCELLED: "Cancellation of the order",
  },

  smsTemplateLanguageArray:
    [
      { "text": "Slovak", "value": "SK" },
      { "text": "Czech", "value": "CS" },
      { "text": "English", "value": "EN" },
    ],
  smsTemplateLanguage: {
    SK: "Slovak",
    CS: "Czech",
    EN: "English",
  },

  AccountEnums: {
    Status: {
      ACTIVE: "Active",
      DISABLED: "Disabled",
      BLOCKED: "Blocked",
      ARCHIVED: "Archived",
    },
    StatusArray:
      [
        { "text": "Active", "value": "ACTIVE", },
        { "text": "Disabled", "value": "DISABLED", },
        { "text": "Blocked", "value": "BLOCKED", },
        { "text": "Archived", "value": "ARCHIVED", },
      ],
    CompanyVatPayer:
      [
        { "text": "Not VAT payer", "value": "NO_VAT", },
        { "text": "VAT payer", "value": "VAT", },
        { "text": "VAT free", "value": "VAT_FREE", },
        { "text": "Foreign person without VAT", "value": "FOREIGN", },
      ],
    ClientStatus: {
      ACTIVE: "Active",
      DISABLED: "Disabled",
      PENDING: "Pending",
      BLOCKED: "Blocked",
      ARCHIVED: "Archived",
    },
    ClientStatusArray:
      [
        { "text": "Active", "value": "ACTIVE", },
        { "text": "Disabled", "value": "DISABLED", },
        { "text": "Pending", "value": "PENDING", },// Čakajúci na schválenie (2)
        { "text": "Blocked", "value": "BLOCKED", },
        { "text": "Archived", "value": "ARCHIVED", },
      ],
  },

  ModulePricelistType: {
    SIMPLE: "Default prices",
    LOCALITY: "Prices for localities",
    EXTENDED: "Extended",
  },
  ModulePricelistTypeArray:
    [
      { "text": "Default prices", "value": "SIMPLE", },
      { "text": "Prices for localities", "value": "LOCALITY", },
      { "text": "Extended", "value": "EXTENDED", },
    ],

  ModuleAddressSearch: {
    DB_GOOGLE: "Private DB (Google)",
    DB_OSM: "Private DB (OSM)",
    DB_GEOCODE: "Private DB (Geocode)",
    GOOGLE: "Google",
    OSM: "OSM",
    GEOCODE: "Geocode",
  },
  ModuleAddressSearchArray:
    [
      { "text": "Private DB (Google)", "value": "DB_GOOGLE", },
      { "text": "Private DB (OSM)", "value": "DB_OSM", },
      { "text": "Private DB (Geocode)", "value": "DB_GEOCODE", },
      { "text": "Google", "value": "GOOGLE", },
      { "text": "OSM", "value": "OSM", },
      { "text": "Geocode", "value": "GEOCODE", },
    ],

  AccountList: {
    page_title: "Corporate clients",
    dialogTitle: "New corporate client",
    mobileBreakpointSwitch_label: "Table View",
    status: "Status",
    accountStatus: "Company status",
    name: "Name in the list",
    name_hint: "Name used for everyday work in the system. Use the shortest possible.",
    companyIdNumber: "Company ID",
    companyIdNumber_hint: "Company ID will not be changed after creation. In each state, the number must be unique.",
    companyTaxId: "Tax ID",
    companyVatPayer: "VAT payer",
    companyVatId: "VAT ID",
    btnOrsr: "OR SR",
    btnNextNoCIN: "Continue without ID",
    validTo: "Valid to",
    country: "Country",
    country_hint: "State will not be changed after creation",
    companyName: "Company name",
    companyName_hint: "Name for invoices",
    email: "Email",
    companyAddress1: "Company address (1st line)",
    companyAddress2: "Company address (2nd line)",
    companyAddress3: "Company address (3rd line)",
    tableHeaders: {
      actions: "Actions",
      accountId: "ID",
      status: "Status",
      accountStatus: "Company status",
      validFrom: "Valid from",
      validTo: "Valid to",
      name: "Name in the list",
      companyIdNumber: "Company ID",
      country: "Country",
      companyName: "Company name",
      email: "Email",
    },
    filterLabel: {
      actions: "Actions",
      accountId: "ID",
      status: "Status",
      accountStatus: "Company status",
      validFrom: "Valid from",
      validTo: "Valid to",
      name: "Name in the list",
      companyIdNumber: "Company ID",
      country: "Country",
      companyName: "Company name",
      email: "Email",
    },
    msgAccountAlreadyExists: "Account with this Company ID already exists",
  },

  Account: {
    page_title: "Corporate client",
    tabInfo: "Information",
    tabUsers: "Users",
    status: "User status",
    status_hint: "Only active account will be displayed to dispatcher.",
    accountStatus: "Status of Corporate client",
    accountStatus_hint: "Status cannot be changed",
    name: "Name in the list",
    companyIdNumber: "Company ID",
    companyIdNumber_hint: "Company ID cannot be changed",
    companyTaxId: "Tax ID",
    companyVatPayer: "VAT payer",
    companyVatId: "VAT ID",
    validFrom: "Valid from",
    validTo: "Valid to",
    country: "Country",
    country_hint: "Country cannot be changed",
    companyName: "Company name",
    email: "Email",
    companyAddress1: "Address of the company (1st line)",
    companyAddress2: "Address of the company (2nd line)",
    companyAddress3: "Address of the company (3rd line)",
  },
  AccountClient: {
    clientPhone: "Phone",
    msgPhoneValidation: "Phone number is not valid. Should be in format +421123456789",
    btnAddCustomer: "Add customer",
    dialogAssignTitle: "Assign customer to corporate client",
    clientId: "Customer ID",
    phone: "Phone",
    nickname: "Nickname",
    mark: "Mark",
    note: "Note",
    info: "Information",
    dialogEditTitle: "Edit customer assigment to corporate client",
    accountId: "Corporate client ID",
    status: "Status",
    validFrom: "Valid from",
    validTo: "Valid to",
    tableHeaders: {
      actions: "Actions",
      clientId: "ID",
      phone: "Phone",
      nickname: "Name",
      mark: "Mark",
      status: "Status",
      validFrom: "Valid from",
      validTo: "Valid to",
    },
    msgPhoneMissing: "Phone number is missing",
    msgPhoneInvalid: "Phone number must be in the format +421123456789",
    msgPhoneShort: "Phone number is too short, minimum is 11 digits",
    msgPhoneOnlyNumbers: "Phone number must contain only numbers and the + sign",
    msgPhoneSecondCharIsNotNumber: "The second character of the phone number must be a digit",
    msgCustomerAlreadyAssigned: "Customer is already assigned to this corporate client",
    msgCustomerNotExists: "Customer with this phone number does not exist",
    msgCustomerNotAddedNoCustomer: "Customer was not added. The taxi service has no record of a customer with this phone number.",
    msgCustomerNotAddedAlreadyAdded: "Customer was not added. The customer has already been added. If it is not displayed, check its status and validity period.",
    msgCustomerSuccessfullyAdded: "Customer was successfully added",
  },

  DriverVehicleList: {
    page_title: "Assigned Vehicles",
    enabledDriversSwitch: "Enabled Drivers",
    btnAssignVehicleTitle: "Assign Vehicle",
    dialogAssignTitle: "Do you want to assign a vehicle to the driver?",
    dialogEndWorkshiftTitle: "Do you want to end the driver's workshift?",
    driverId: "ID",
    firstName: "First Name",
    lastName: "Last Name",
    fullNameVehicle: "Vehicle",
    vehicleId: "ID",
    mileages: "Mileage",
    listAssigned: "Assigned",
    listUsed: "Used",
    listRemoveVehicle: "- Remove Assigned Vehicle -",
    msgAssigmentSuccesufull: "Vehicle assigned successfully",
    btnEndShift: "End Workshift",
    msgEndWorkshiftSuccesufull: "Workshift ended successfully",
    msgEndWorkshiftErrorDriverHasOrder: "Workshift cannot be ended as the driver has active orders",
    tableHeaders: {
      actions: "Actions",
      driverId: "ID",
      internalName: "Internal Name",
      firstName: "First Name",
      lastName: "Last Name",
      active: "Active",
      workStatus: "Workshift Status",
      silence: "Silent Mode",
      vehicleAssignedInternalNameLpn: "Assigned",
      vehicleAssignedBrandModel: "Model",
      vehicleSelectedInternalNameLpn: "Used",
      vehicleSelectedBrandModel: "Model",
    },
  },

  VehicleDriverList: {
    page_title: "Vehicle List and Drivers",
    dialogDetailTitle: "Vehicle and Driver Detail",
    dialogCreateMaintenanceTitle: "Create Maintenance",
    dialogUpdateLastMaintenanceTitle: "Update last vehicle maintenance",
    tableHeaders: {
      actions: "Actions",
      internalName: "Internal Name",
      lpn: "LPN",
      brand: "Brand",
      model: "Model",
      active: "Active",
      status: "Status",
      mileages: "Mileage",
      driverId: "ID",
      driverInternalName: "Driver Internal Name",
      driverFirstName: "First Name",
      driverLastName: "Last Name",
      driverActive: "Active",
      driverWorkStatus: "Workshift Status",
      driverSilence: "Silent Mode",
      driverPhone: "Phone",
    },
    filterLabel: {
      internalName: "Internal Name",
      lpn: "LPN",
      brand: "Brand",
      model: "Model",
      active: "Active",
      status: "Status",
      mileages: "Mileage",
      driverId: "ID",
      driverInternalName: "Driver Internal Name",
      driverFirstName: "First Name",
      driverLastName: "Last Name",
      driverActive: "Active",
      driverWorkStatus: "Workshift Status",
      driverSilence: "Silent Mode",
      driverPhone: "Phone",
    },
    vehicleMaintenance: {
      page_title: "Vehicle Maintenance",
      vehicleId: "Vehicle ID",
      internalName: "Internal Name",
      lpn: "LPN",
      brand: "Brand",
      model: "Model",
      active: "Active",
      mileages: "Mileage",
      lastMaintenanceAt: "Last Maintenance",
      lastMaintenanceMileage: "Last Maintenance Mileage",
      maintenanceIntervalDay: "Maintenance Interval (days)",
      maintenanceIntervalMileage: "Maintenance Interval (KM)",
      maintenanceNotificationDay: "Maintenance Notification (days)",
      maintenanceNotificationMileage: "Maintenance Notification (KM)",
      plannedAt: "Planned",
      startedAt: "Started",
      startedMileage: "Mileage at start",
      resolvedAt: "Resolved",
      resolvedMileage: "Mileage at end",
      title: "Title",
      type: "Typw",
      status: "Status",
      note: "Note",
      price: "Price",
      fluidCheck: "Fluid check",
      oilTopUp: "Oil top up",
      oilChange: "Oil change",
      coolantChange: "Coolant change",
      brakeFluidChange: "Brake fluid change",
      oilFilterReplacement: "Oil filter replacement",
      airFilterReplacement: "Air filter replacement",
      airConFilterReplacement: "Air conditioning filter replacement",
      sparkOrGlowPlugReplacement: "Spark or glow plug replacement",
      tireReplacement: "Tire replacement",
      drivetrainCheck: "Drivetrain check",
      serpentineBeltReplacement: "Serpentine belt replacement",
      otherFilterReplacement: "Other filter replacement",
      otherFluidTopUp: "Other fluid top up",
      lightRepair: "Light repair",
      brakeRepair: "Brake repair",
      bodyRepair: "Body repair",
      engineRepair: "Engine repair",
      electronicsRepair: "Electronics repair",
      drivetrainRepair: "Drivetrain repair",
      batteryReplacement: "Battery replacement",
      otherRepair: "Other repair",
      cleaning: "Cleaning",
      btnSetLastMaintenanceAtFromVehicle: "Set last maintenance at from vehicle",
      btnSetLastMaintenanceAtToNow: "Set to now and vehicle mileage",
      btnSetUpdateMaintenanceMileageAtFromVehicle: "Set update maintenance mileage at from vehicle",
      btnSetUpdateMaintenanceMileageAtToNow: "Set update maintenance mileage at to now",
    },
    tableMaintenanceHeaders: {
      actions: "Akcie",
      mileages: "Mileage",
      plannedAt: "Planned",
      startedAt: "Started",
      startedMileage: "Mileage at start",
      resolvedAt: "Resolved",
      resolvedMileage: "Mileage at end",
      title: "Title",
      type: "Typw",
      status: "Status",
      note: "Note",
      price: "Price",
      fluidCheck: "Fluid check",
      oilTopUp: "Oil top up",
      oilChange: "Oil change",
      coolantChange: "Coolant change",
      brakeFluidChange: "Brake fluid change",
      oilFilterReplacement: "Oil filter replacement",
      airFilterReplacement: "Air filter replacement",
      airConFilterReplacement: "Air conditioning filter replacement",
      sparkOrGlowPlugReplacement: "Spark or glow plug replacement",
      tireReplacement: "Tire replacement",
      drivetrainCheck: "Drivetrain check",
      serpentineBeltReplacement: "Serpentine belt replacement",
      otherFilterReplacement: "Other filter replacement",
      otherFluidTopUp: "Other fluid top up",
      lightRepair: "Light repair",
      brakeRepair: "Brake repair",
      bodyRepair: "Body repair",
      engineRepair: "Engine repair",
      electronicsRepair: "Electronics repair",
      drivetrainRepair: "Drivetrain repair",
      batteryReplacement: "Battery replacement",
      otherRepair: "Other repair",
      cleaning: "Cleaning",
    },
  },
  VehicleMaintenanceList: {
    page_title: "Vehicle Maintenance",
    dialogDetailTitle: "Vehicle Maintenance Detail",
    vehicleId: "Vehicle ID",
    internalName: "Internal Name",
    lpn: "LPN",
    brand: "Brand",
    model: "Model",
    active: "Active",
    mileages: "Mileage",
    lastMaintenanceAt: "Last Maintenance",
    lastMaintenanceMileage: "Last Maintenance Mileage",
    maintenanceIntervalDay: "Maintenance Interval (days)",
    maintenanceIntervalMileage: "Maintenance Interval (KM)",
    maintenanceNotificationDay: "Maintenance Notification (days)",
    maintenanceNotificationMileage: "Maintenance Notification (KM)",
    plannedAt: "Planned",
    startedAt: "Started",
    startedMileage: "Mileage at start",
    resolvedAt: "Resolved",
    resolvedMileage: "Mileage at end",
    btnSetUpdateMaintenanceMileageAtFromVehicle: "Set update maintenance mileage at from vehicle",
    btnSetUpdateMaintenanceMileageAtToNow: "Set update maintenance mileage at to now",
    title: "Title",
    type: "Typw",
    status: "Status",
    note: "Note",
    price: "Price",
    fluidCheck: "Fluid check",
    oilTopUp: "Oil top up",
    oilChange: "Oil change",
    coolantChange: "Coolant change",
    brakeFluidChange: "Brake fluid change",
    oilFilterReplacement: "Oil filter replacement",
    airFilterReplacement: "Air filter replacement",
    airConFilterReplacement: "Air conditioning filter replacement",
    sparkOrGlowPlugReplacement: "Spark or glow plug replacement",
    tireReplacement: "Tire replacement",
    drivetrainCheck: "Drivetrain check",
    serpentineBeltReplacement: "Serpentine belt replacement",
    otherFilterReplacement: "Other filter replacement",
    otherFluidTopUp: "Other fluid top up",
    lightRepair: "Light repair",
    brakeRepair: "Brake repair",
    bodyRepair: "Body repair",
    engineRepair: "Engine repair",
    electronicsRepair: "Electronics repair",
    drivetrainRepair: "Drivetrain repair",
    batteryReplacement: "Battery replacement",
    otherRepair: "Other repair",
    cleaning: "Cleaning",

    tableHeaders: {
      actions: "Actions",
      vehicleInternalName: "Internal Name",
      vehicleLpn: "LPN",
      vehicleBrand: "Brand",
      vehicleModel: "Model",
      vehicleActive: "Active",
      vehicleStatus: "Status",
      vehicleMileages: "Mileage",
      type: "Type",
      status: "Status",
      plannedAt: "Planned At",
      startedAt: "Started At",
      resolvedAt: "Resolved At",
      oilChange: "Oil Change",
      price: "Price",
    },
    filterLabel: {
      actions: "Actions",
      vehicleInternalName: "Internal Name",
      vehicleLpn: "LPN",
      vehicleBrand: "Brand",
      vehicleModel: "Model",
      vehicleActive: "Active",
      vehicleStatus: "Status",
      vehicleMileages: "Mileage",
      type: "Type",
      status: "Status",
    },
  },
  MaintenanceType: {
    REPAIR: "Repair",
    MAINTENANCE: "Maintenance",
    CHECKUP: "Checkup",
    SHORT_TERM_INSPECTION: "Short-term inspection",
    LONG_TERM_INSPECTION: "Long-term inspection",
    SEASONAL_INSPECTION: "Seasonal inspection",
    CLEANING: "Cleaning",
  },
  MaintenanceTypeArray: [
    { "text": "Repair", "value": "REPAIR", },
    { "text": "Maintenance", "value": "MAINTENANCE", },
    { "text": "Checkup", "value": "CHECKUP", },
    { "text": "Short-term inspection", "value": "SHORT_TERM_INSPECTION", },
    { "text": "Long-term inspection", "value": "LONG_TERM_INSPECTION", },
    { "text": "Seasonal inspection", "value": "SEASONAL_INSPECTION", },
    { "text": "Cleaning", "value": "CLEANING", },
  ],
  MaintenanceStatus: {
    PLANNED: "Planned",
    DELAYED: "Delayed",
    ONGOING: "Ongoing",
    PAUSED: "Paused",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
    UNFINISHED: "Unfinished",
  },
  MaintenanceStatusArray: [
    { "text": "Planned", "value": "PLANNED", },
    { "text": "Delayed", "value": "DELAYED", },
    { "text": "Ongoing", "value": "ONGOING", },
    { "text": "Paused", "value": "PAUSED", },
    { "text": "Completed", "value": "COMPLETED", },
    { "text": "Cancelled", "value": "CANCELLED", },
    { "text": "Unfinished", "value": "UNFINISHED", },
  ],

  PlaceList: {
    page_title: "Places",
    tabList: "Places list",
    tabMap: "Map",
    //Tab List

    //Tab Map
    toolbar_icon_title: "Toggle window view",
    toolbarList_title: "Addresses",
    searchField: "Search",
    searchField_title: "Search in the list of addresses, confirm with Enter key",
    showOnlyNotApprovedAddressesSwitch_title: "Show only addresses pending approval",
    toolbarNew_title: "New address",
    searchNewGoogle: "Search new address GOOGLE",
    searchNewGeocode: "Search new address OSM",
    toolbarDetails_title: "Place Details",
    addressListItem_title: "Address displayed to the user",
    addressListItemSubtitle1_title: "GPS location of the place",
    addressListItemSubtitle1_text: "GPS coordinates",
    addressListItemSubtitle2_title: "House Number, Road",
    addressListItemSubtitle3_title: "City, Postal Code, State",
    addressListItemShowAddressBtn: "Show address on the map",
    displayName: "Name",
    active: "Active",
    latitude: "Latitude",
    longitude: "Longitude",
    houseNumber: "House Number",
    road: "Road",
    city: "City",
    state: "State",
    postcode: "Postal Code",
    country: "Country",
    countryCode: "Country Code",
    more: "More...",
    amenityName: "Place Description",
    amenityType: "Place Type",
    neighbourhood: "City Locality",
    suburb: "City Suburb",
    cityDistrict: "City District",
    county: "County",
    ordering: "Ordering",
    priority: "Priority",
    dialogTitle: "Place",
    tableHeaders: {
      actions: "Actions",
      displayName: "Name",
      active: "Active",
      categoryName: "Category",
      city: "City",
      countryCode: "Country Code",
      ordering: "Ordering",
      priority: "Priority",
    },
  },

  DriverList: {
    page_title: "Driver List",
    enabledDriversSwitch: "Enabled Drivers",
    dialogCreateTitle: "New Driver",
    dialogEditTitle: "Edit Driver",
    dialogEditStatusTitle: "Do you want to change the driver's status?",
    dialogPasswordChangeTitle: "Do you want to change the driver's password?",
    btnEdit: "Edit",
    btnEditStatus: "Change Status",
    btnPasswordChange: "Change Password",
    btnDisable: "Disable",
    btnEnable: "Enable",
    silenceDisabledTitle: "Silent Mode",
    driverId: "ID",
    id: "ID",
    email: "Email / Login",
    password: "Password",
    activeAcc: "Active",
    firstName: "First Name",
    lastName: "Last Name",
    internalName: "Internal Name",
    phone: "Phone",
    phoneExt: "Phone Extention",
    gender: "Gender",
    dispatcher: "Dispatcher",
    tag: "Other",
    driverLicenceNumber: "Driver's Licence Number",
    driverLicenceExpiration: "Driver's Licence Expiration Date",
    driverTaxiLicenceNumber: "Driver's Taxi Licence Number",
    driverTaxiLicenceExpiration: "Driver's Taxi Licence Expiration Date",
    language1: "Native Language",
    language2: "Language 2",
    language3: "Language 3",
    language4: "Language 4",
    msgPasswordChanged: "Password changed successfully",
    msgInvalidDataInForm: "The form contains invalid data",
    msgDataMissing: "The form has missing data",
    msgDriverCreated: "Driver created successfully",
    msgDriverUpdated: "Driver updated successfully",
    msgDriverStatusUpdated: "Driver's status changed successfully",
    tableHeaders: {
      actions: "Actions",
      email: "Email",
      internalName: "Internal Name",
      firstName: "First Name",
      lastName: "Last Name",
      active: "Active",
      workStatus: "Workshift Status",
      silence: "Silent Mode",
      status: "Status",
      phone: "Phone",
      phoneExt: "Extention",
      password: "Password",
      language1: "Language 1",
      gender: "Gender",
      dispatcher: "Dispatcher",
      licenceStatus: "Licence Status",
    },
    filterLabel: {
      email: "Email",
      internalName: "Internal Name",
      firstName: "First Name",
      lastName: "Last Name",
      active: "Active",
      workStatus: "Workshift Status",
      silence: "Silent Mode",
      status: "Status",
      phone: "Phone",
      phoneExt: "Extention",
    },
  },

  VehicleList: {
    page_title: "Vehicle List",
    dialogCreateTitle: "New Vehicle",
    dialogUpdateLastMaintenanceTitle: "Update last vehicle maintenance",
    btnSetLastMaintenanceAtFromVehicle: "Set last maintenance from vehicle",
    btnSetLastMaintenanceAtToNow: "Set to now and vehicle mileage",
    internalName: "Internal Name",
    brand: "Brand",
    model: "Model",
    type: "Type",
    lpn: "License Plate Number",
    color: "Color",
    mileages: "Mileage (km)",
    lastMaintenanceAt: "Last Maintenance",
    lastMaintenanceMileage: "Last Maintenance Mileage",
    tableHeaders: {
      actions: "Actions",
      internalName: "Internal Name",
      brand: "Brand",
      model: "Model",
      type: "Type",
      lpn: "License Plate Number",
      active: "Active",
      status: "Status",
      color: "Color",
      phoneExt: "Extension",
      phone: "Phone",
      mileages: "Mileage (km)",
      lastMaintenanceAt: "Last Maintenance",
      lastMaintenanceMileage: "Last Maintenance Mileage",
      maintenanceIntervalDay: "Maintenance Interval (days)",
      maintenanceIntervalMileage: "Maintenance Interval (KM)",
      maintenanceNotificationDay: "Maintenance Notification (days)",
      maintenanceNotificationMileage: "Maintenance Notification (KM)",
    },
    filterLabel: {
      search: "Search",
      internalName: "Internal Name",
      brand: "Brand",
      model: "Model",
      type: "Type",
      lpn: "License Plate Number",
      active: "Active",
      status: "Status",
      phoneExt: "Extension",
      phone: "Phone",
      lastMaintenanceAt: "Last Maintenance",
      lastMaintenanceMileage: "Last Maintenance Mileage",
      maintenanceIntervalDay: "Maintenance Interval (days)",
      maintenanceIntervalMileage: "Maintenance Interval (KM)",
      maintenanceNotificationDay: "Maintenance Notification (days)",
      maintenanceNotificationMileage: "Maintenance Notification (KM)",
    },
  },

  Vehicle: {
    page_title: "Vehicle",
    tabInfo: "Information",
    tabFilter: "Filters",
    tabPhoto: "Photo",
    internalName: "Internal Name",
    brand: "Brand",
    model: "Model",
    type: "Type",
    lpn: "License Plate Number",
    active: "Active",
    status: "Status",
    color: "Color",
    phoneExt: "Phone Extension",
    phone: "Phone",
    mileages: "Mileage (km)",
    lastMaintenanceAt: "Last Maintenance",
    lastMaintenanceMileage: "Last Maintenance Mileage",
    maintenanceIntervalDay: "Maintenance Interval (days)",
    maintenanceIntervalMileage: "Maintenance Interval (KM)",
    maintenanceNotificationDay: "Maintenance Notification (days)",
    maintenanceNotificationMileage: "Maintenance Notification (KM)",
    msgInternalNameRule: "Must not be longer than 3 characters",
    mgsInternalNameHint: "Maximum 3 characters, recommended max. 2 characters",
    maxPerson: "Maximum Number of Passengers",
    airconditioning: "Air Conditioning",
    animal: "Pet Friendly",
    autonomous: "Autonomous Vehicle",
    childrenChair: "Child Seat",
    driverRating: "Driver Rating",
    eco: "Eco-friendly",
    luggage: "Luggage",
    paymentTerminal: "Payment Terminal",
    smoking: "Smoking Allowed",
    specialTransport: "Special Transport",
    actualPicture: "Current Photo",
    newPicture: "New Photo",
    btnSelectPicture: "Select Photo",
    btnSavePicture: "Save Photo",
    examplePicture: "Example Photo",
    pictureHint:
      "The photo must be in .jpg, .jpeg, or .png format and should not exceed 2MB. " +
      "The vehicle photo should capture the view of the entire vehicle from the front right side (three-quarter view of the vehicle). " +
      "The vehicle in the photo should appear as if it is approaching the customer from the left side of the road, and the customer can easily identify the front mask of the vehicle while also seeing the side profile to determine the vehicle type. " +
      "The resulting photo must include the entire, uncut vehicle, ideally with a light and uniform background.",
    uploadPlugin: {
      hint: "Click or drag a file here to upload",
      loading: "Uploading...",
      noSupported: "Browser not supported, please use IE10+ or other browsers",
      success: "Upload successful",
      fail: "Upload failed",
      preview: "Preview",
      btn: {
        off: "Cancel",
        close: "Close",
        back: "Back",
        save: "Save",
      },
      error: {
        onlyImg: "Only images are allowed",
        outOfSize: "Image size exceeds limit: ",
        lowestPx: "Image size is too small. Expected at least: ",
      },
    },
  },
  VehicleEnums: {
    Status: {
      ACTIVE: "Active",
      DISABLED: "Disabled",
      BLOCKED: "Blocked",
      ARCHIVED: "Archived",
    },
    StatusArray:
      [
        { "text": "Active", "value": "ACTIVE" },
        { "text": "Disabled", "value": "DISABLED" },
        { "text": "Blocked", "value": "BLOCKED" },
        { "text": "Archived", "value": "ARCHIVED" },
      ],
    Type: {
      SEDAN: "Sedan",
      COMBI: "Combi",
      CABRIO: "Cabrio",
      COUPE: "Coupé",
      HATCHBACK: "Hatchback",
      VAN: "Van",
      MICROBUS: "Microbus",
      BUS: "Bus",
      VAN_CARGO: "Cargo Van",
      PICKUP: "Pickup",
      SUV: "SUV",
    },
    TypeArray:
      [
        { "text": "Sedan", "value": "SEDAN" },
        { "text": "Combi", "value": "COMBI" },
        { "text": "Cabrio", "value": "CABRIO" },
        { "text": "Coupé", "value": "COUPE" },
        { "text": "Hatchback", "value": "HATCHBACK" },
        { "text": "Van", "value": "VAN" },
        { "text": "Microbus", "value": "MICROBUS" },
        { "text": "Bus", "value": "BUS" },
        { "text": "Cargo Van", "value": "VAN_CARGO" },
        { "text": "Pickup", "value": "PICKUP" },
        { "text": "SUV", "value": "SUV" },
      ],
  },

  DispatcherList: {
    page_title: "Dispatcher List",
    editInformation: "Edit Dispatcher Information",
    changePassword: "Change Dispatcher Password",
    changeRole: "Change Dispatcher Role",
    dialogCreateTitle: "Create Dispatcher",
    dialogEditTitle: "Edit Dispatcher",
    dialogChangePasswordTitle: "Do you want to change the password for the dispatcher?",
    dialogChangePasswordText: "Please provide the new password for the dispatcher. The password will not be sent to the dispatcher.",
    dialogEditRoleTitle: "Do you want to change the role for the dispatcher?",
    msgPasswordChanged: "Password changed successfully",
    msgInvalidDataInForm: "The form contains invalid data",
    msgDispatcherRoleUpdated: "Dispatcher role updated successfully",
    dispatcherId: "ID",
    id: "ID",
    email: "Email / Login",
    password: "Password",
    activeAcc: "Active",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    role: "Role",
    tableHeaders: {
      actions: "Actions",
      email: "Email / Login",
      activeAcc: "Active",
      firstName: "First Name",
      lastName: "Last Name",
      role: "Role",
      phone: "Phone",
    },
    filterLabel: {
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone",
    },
  },
  DispatcherRole: {
    ADMIN: "Administrator",
    SUPPORT: "Support",
    OWNER: "Owner",
    MANAGER: "Manager",
    MAINTAINER: "Maintainer",
    DISPATCHER: "Dispatcher",
  },
  DispatcherRoleArray: [
    {
      "text": "Administrator",
      "value": "ADMIN",
    },
    {
      "text": "Support",
      "value": "SUPPORT",
    },
    {
      "text": "Owner",
      "value": "OWNER",
    },
    {
      "text": "Manager",
      "value": "MANAGER",
    },
    {
      "text": "Vehicle Maintainer",
      "value": "MAINTAINER",
    },
    {
      "text": "Dispatcher",
      "value": "DISPATCHER",
    },
  ],
  DispatcherRoleForOwnerArray: [
    {
      "text": "Manager",
      "value": "MANAGER",
    },
    {
      "text": "Maintainer",
      "value": "MAINTAINER",
    },
    {
      "text": "Dispatcher",
      "value": "DISPATCHER",
    },
  ],

  LocalityList: {
    page_title: "Locality List",
    tabLocalityPickup: "Pickup",
    tabLocalityPrice: "Pricing",
    tabLocalityCheckin: "Check-in",
    tabStands: "Stands",
    activeOnlySwitch: "Show only active",
    btnCreateCircle: "Create Circle",
    btnCreatePolygon: "Create Polygon",
    dialogTitle: "Locality",
    id: "ID",
    priority: "Priority",
    name: "Name",
    active: "Active",
    type: "Type",
    latitude: "Latitude",
    longitude: "Longitude",
    radius: "Radius",
    path: "Path",
    capacity: "Capacity",
    defaultStand: "Default Stand",
    showPhone: "Show Customer's Phone to Driver",
    showDriverPhoneButton: "Show Driver Phone Button",
    showAllAddresses: "Show All Addresses to Driver",
    onlyManualSending: "Only Manual Order Sending",
    forcedPickupRadius: "Forced Pickup Radius (0 = disabled)",
    internalName: "Internal Name",
    city: "City",
    country: "Country",
    street1: "Street",
    street2: "Street (2)",
    msgMax10Points: "Maximum of 10 points are allowed in the polygon. Please do not add more points, or the system will not work correctly.",
    msgMax10PointsOver: "Too many points! Maximum of 10 points are allowed in the polygon. Please remove unwanted points by RIGHT CLICK, or the system will not work correctly.",
    editPolygonHelpText: "A polygon can have a maximum of 10 points. To add a new point, click and drag the control point located in the middle of existing points. To remove an unnecessary point, right-click on it.",
    msgErrorToEditLocality: "Error editing locality",
    msgDataMissing: "Data are missing.",
    msgDefaultStandNotSet: "Default stand is not set!",
    msgDefaultStandMoreThenOne: "Default stand is more than one!",
    tableHeaders: {
      actions: "Actions",
      id: "ID",
      priority: "Priority",
      name: "Name",
      active: "Active",
      type: "Type",
      latitude: "Latitude",
      longitude: "Longitude",
      radius: "Radius",
      path: "Path",
      showDriverPhoneButton: "Phone Btn",
      showPhone: "Show Phone",
      showAllAddresses: "All Address",
      onlyManualSending: "Manual Sending",
      forcedPickupRadius: "Pickup Radius",
      capacity: "Capacity",
      defaultStand: "Default",
    },
    showMarkers: "Show Markers",
    editPolygonPointsTitle: "Edit Polygon Points",
    pathLatitude: "Latitude",
    pathLongitude: "Longitude",
    closePathChange: "Edit the point according to the nearby point of another polygon",
  },

  PricelistList: {
    page_title: "Pricelist Overview",
    newPricelistDialogTitle: "New Pricelist",
    name: "Name",
    validFrom: "Valid From",
    service: "Service",
    tableHeaders: {
      actions: "Actions",
      name: "Name",
      active: "Active",
      validFrom: "Valid From",
      service: "Service",
      currency: "Currency",
    },
  },
  Pricelist: {
    page_title: "Pricelist",
    tabBasic: "Basic Information",
    tabPriceSettings: "Price Settings",
    tabPrices: "Price Items",
    tabExtras: "Extras",
    // TAB BASIC
    id: "ID",
    name: "Name",
    note: "Note",
    active: "Active",
    validFrom: "Valid From",
    service: "Service",
    showPrice: "Show Price",
    currency: "Currency",
    currencySymbol: "Currency Symbol",
    priceRound: "Price Rounding",
    // TAB PRICE SETTINGS
    fareType: "Fare Type",
    fareChangeToDefault: "Change Fare to Default",
    fixation: "Fixation",
    waypointInProgressRecalcPrice: "Waypoint in Progress Recalculation",
    fareStartSubheader: "Price for Customer Pickup",
    fareStartType: "Fare Type for Customer Pickup",
    fareStart: "Fare for Customer Pickup",
    fareWaitingSubheader: "Waiting for Customer Before Start of Transport",
    fareWaitingLimit: "Waiting Time Limit (min.)",
    fareWaiting: "Fare for Waiting",
    fareDriveSubheader: "Price for Transport",
    fareDriveType: "Fare Type for Transport",
    fareDriveDistance: "Fare for Transport Distance",
    fareDriveTime: "Fare for Transport Time",
    fareJourneyMinimumSubheader: "Minimum Fare for Journey",
    fareJourneyMinimumType: "Fare Type for Minimum Journey Fare",
    fareJourneyMinimum: "Minimum Fare for Journey",
  },
  PricelistEnums: {
    showPrice: {
      ALL: "Show to Everyone",
      ONLY_DRIVER: "Show Only to Driver",
      HIDDEN: "Do Not Show to Anyone",
    },
    showPriceArray: [
      { "text": "Show to Everyone", "value": "ALL" },
      { "text": "Show Only to Driver", "value": "ONLY_DRIVER" },
      { "text": "Do Not Show to Anyone", "value": "HIDDEN" },
    ],

    FareType: {
      DEFAULT: "Default Rates",
      LOCALITY: "Rates by Locality",
    },
    FareTypeArray:
      [
        { "text": "Default Rates", "value": "DEFAULT" },
        { "text": "Rates by Locality", "value": "LOCALITY" },
      ],

    FareJourneyMinimumType: {
      DEFAULT: "Default Minimum Fare",
      LOCALITY: "Minimum Fare by Locality",
    },
    FareJourneyMinimumTypeArray:
      [
        { "text": "Default Minimum Fare", "value": "DEFAULT" },
        { "text": "Minimum Fare by Locality", "value": "LOCALITY" },
      ],

    FareDriveType: {
      FIXED: "Fixed Fare",
      DISTANCE: "Distance and Time",
    },
    FareDriveTypeArray:
      [
        { "text": "Fixed Fare", "value": "FIXED" },
        { "text": "Distance and Time", "value": "DISTANCE" },
      ],

    FareStartType: {
      FIXED: "Fixed Fare",
      FROM_STAND: "Stand to Pickup",
      TO_STAND: "Destination to Stand",
      BETWEEN_STAND: "Stand to Pickup and Destination to Stand",
    },
    FareStartTypeArray:
      [
        { "text": "Fixed Fare", "value": "FIXED" },
        { "text": "Stand to Pickup", "value": "FROM_STAND" },
        { "text": "Destination to Stand", "value": "TO_STAND" },
        { "text": "Stand to Pickup and Destination to Stand", "value": "BETWEEN_STAND" },
      ],

    FareChangeToDefault: {
      NONE: "None",
      WAITING: "Waiting",
      WAYPOINT: "Waypoint",
      ACCOUNT: "Account",
      WAITING_WAYPOINT: "Waiting and Waypoint",
      WAITING_ACCOUNT: "Waiting and Account",
      WAYPOINT_ACCOUNT: "Waypoint and Account",
      WAITING_WAYPOINT_ACCOUNT: "Waiting, Waypoint, and Account",
    },
    FareChangeToDefaultArray:
      [
        { "text": "None", "value": "NONE" },
        { "text": "Waiting", "value": "WAITING" },
        { "text": "Waypoint", "value": "WAYPOINT" },
        { "text": "Account", "value": "ACCOUNT" },
        { "text": "Waiting and Waypoint", "value": "WAITING_WAYPOINT" },
        { "text": "Waiting and Account", "value": "WAITING_ACCOUNT" },
        { "text": "Waypoint and Account", "value": "WAYPOINT_ACCOUNT" },
        { "text": "Waiting, Waypoint, and Account", "value": "WAITING_WAYPOINT_ACCOUNT" },
      ],

    Service: {
      PERSONAL: "Personal Transport",
      LIMO: "Limousine Service",
      CARGO: "Cargo Transport",
      DRINK: "Drink Taxi Service",
      IMMOBILE: "Transport of Immobilized Persons",
      COURIER: "Courier Service",
      DELIVERY: "Delivery Service",
      AIRPORT: "Airport Taxi",
      SOCIAL: "Social Taxi",
    },
    ServiceArray:
      [
        { "text": "Personal Transport", "value": "PERSONAL" },
        { "text": "Limousine Service", "value": "LIMO" },
        { "text": "Cargo Transport", "value": "CARGO" },
        { "text": "Drink Taxi Service", "value": "DRINK" },
        { "text": "Transport of Immobilized Persons", "value": "IMMOBILE" },
        { "text": "Courier Service", "value": "COURIER" },
        { "text": "Delivery Service", "value": "DELIVERY" },
        { "text": "Airport Taxi", "value": "AIRPORT" },
        { "text": "Social Taxi", "value": "SOCIAL" },
      ],
  },
  Prices: {
    btnGenerate: "Generate Prices",
    btnRegenerate: "Regenerate Prices",
    btnSave: "Save",
    btnCancel: "Cancel",
    btnEdit: "Edit",
    activeOnlySwitch: "Active Only",
    generatePricesDialogTitle: "Do you want to regenerate prices?",
    generatePricesDialogText: "When regenerating prices, price items will be created from all combinations of Locality Prices. If any locality is deactivated or added, the corresponding price items will be blocked or added accordingly. We recommend regenerating prices whenever there is a change in Locality Prices.",
    msgGeneratePricesSuccess: "Prices have been successfully regenerated.",
    msgGeneratePricesError: "An error occurred during price regeneration.",
    msgUpdatePricesSuccess: "Prices have been successfully updated.",
    msgUpdatePricesError: "An error occurred during price update.",
    tableHeaders: {
      name: "Name",
      active: "Active",
      fareStartType: "Fare Start Type",
      fareStart: "Fare Start Price",
      fareWaitingLimit: "Waiting Limit Before Transport",
      fareWaiting: "Fare for Waiting Before Transport",
      fareDriveType: "Fare Drive Type",
      fareDriveDistance: "Fare for Distance",
      fareDriveTime: "Fare for Time",
      fareJourneyMinimum: "Minimum Fare for Journey",
      note: "Note",
      localityPriceFromId: "From",
      localityPriceToId: "To",
    },
  },
  PricesEnums: {
    FareStartType: {
      FIXED: "Fixed Fare", // Fixed amount
      FROM_STAND: "Fare for Distance Stand->Pickup", // STAND-BASED includes journey from the Stand
      TO_STAND: "Fare for Distance Drop-off->Stand", // STAND-BASED includes journey to the Stand
      BETWEEN_STAND: "Fare for Distance Stand->Pickup, Drop-off->Stand", // STAND-BASED includes journey from and to the Stand
    },
    FareStartTypeShort: {
      FIXED: "Fixed", // Fixed amount
      FROM_STAND: "S>P", // STAND-BASED includes journey from the Stand
      TO_STAND: "D>S", // STAND-BASED includes journey to the Stand
      BETWEEN_STAND: "S>P|D>S", // STAND-BASED includes journey from and to the Stand
    },
    FareStartTypeArray:
      [
        { "text": "Fixed Fare", "value": "FIXED" },
        { "text": "Stand->Pickup", "value": "FROM_STAND" },
        { "text": "Drop-off->Stand", "value": "TO_STAND" },
        { "text": "Stand->Pickup, Drop-off->Stand", "value": "BETWEEN_STAND" },
      ],
    FareDriveType: {
      FIXED: "Fixed Fare",
      DISTANCE: "Fare for Distance and Time of Travel",
    },
    FareDriveTypeShort: {
      FIXED: "Fixed",
      DISTANCE: "Distance",
    },
    FareDriveTypeArray:
      [
        { "text": "Fixed Fare", "value": "FIXED" },
        { "text": "Distance and Time", "value": "DISTANCE" },
      ],
  },
  PriceExtra: {
    btnCreate: "Create",
    btnCreateObligatory: "Create Mandatory Item",
    priceExtraDialogTitle: "Surcharge",
    priceExtraObligatoryDialogTitle: "Mandatory Pricing Item",
    name: "Name",
    driverSummary: "Show in Driver Overview",
    automatic: "Automatically Created",
    active: "Active",
    type: "Type",
    price: "Price/Value",
    operation: "Price Surcharge Calculation Method",
    resetToDefaultPrice: "Reset to Default Price",
    cancelPriceFixation: "Cancel Price Fixation",
    editable: "Editable",
    occurenceLimit: "Maximum Number of Created Surcharges",
    ordering: "Order in Driver Overview",
    closeBtn: "Close",
    saveBtn: "Save",
    msgSaveSuccess: "Surcharge saved successfully",
    msgSaveError: "Failed to save surcharge",
    msgGetDataError: "Oh, an error occurred.... Something went wrong while loading data",
    msgObligatoryPriceExtraWarning: "Create mandatory items 'Fare Price' (PRICE_JOURNEY) and 'Waiting Fare Price' (PRICE_WAITING) first!",
    tableHeaders: {
      actions: "Actions",
      name: "Name",
      active: "Active",
      type: "Type",
      price: "Price",
      resetToDefaultPrice: "Reset to Default",
      cancelPriceFixation: "Cancel Fixation",
      operation: "Calculation",
      automatic: "Automatically",
      driverSummary: "Summary",
      editable: "Editable",
      occurenceLimit: "Max Occurrences",
      ordering: "Order",
    },
  },
  PriceExtraEnums: {
    Operation: {
      NONE: "Not Specified",  // Not Specified (0)
      ONE_OFF: "One-time",  // One-time (1)
      PERC_PRICE_DRIVE: "Percentage of Fare Price",  // Percentage of Fare Price (2) ==> Rename from PERC_TRANSFER to PERC_PRICE_DRIVE
      PERC_PRICE_JOURNEY: "Percentage of Total Fare Price",  // Percentage of Total Fare Price (3) ==> Rename from PERC_SUMMARY to PERC_PRICE_JOURNEY
      DRIVE_TIME: "Per Minute of Drive Time",  // Per minute of drive time (4)
      DRIVE_DISTANCE: "Per Kilometer of Drive Distance",  // Per kilometer of drive distance (5)
      WAITING_TIME: "Per Minute of Waiting Time During Transportation",  // Per minute of waiting time in state 6 - for each started minute (6)
      WAITING_TIME_EXACT: "Per Minute of Waiting Time During Transportation to Seconds",  // Per minute of waiting time in state 6 exactly to seconds, or min 3 decimal places (6)
      //WAITING_PICKUP_TIME: "WAITING_PICKUP_TIME",  // Per minute of waiting time in state 5 (5)
    },
    OperationArray:
      [
        { "text": "Not Specified", "value": "NONE", },
        { "text": "One-time", "value": "ONE_OFF", },
        { "text": "Percentage of Fare Price", "value": "PERC_PRICE_DRIVE", },
        { "text": "Percentage of Total Fare Price", "value": "PERC_PRICE_JOURNEY", },
        { "text": "Per Minute of Drive Time", "value": "DRIVE_TIME", },
        { "text": "Per Kilometer of Drive Distance", "value": "DRIVE_DISTANCE", },
        { "text": "Per Minute of Waiting Time During Transportation", "value": "WAITING_TIME", },
        { "text": "Per Minute of Waiting Time During Transportation to Seconds", "value": "WAITING_TIME_EXACT", },
        //{ "text": "Per Minute of Waiting Time in State 5", "value": "WAITING_PICKUP_TIME", },
      ],
    Type: {
      //Prve 2 su obligatorne, a v arrany sa nachadzaju samostatne, lebo iné dialogove okno
      PRICE_WAITING: "Waiting Fee Before Ride (P)",
      PRICE_JOURNEY: "Ride Fee (P)",
      NONE: "Not Specified", // Not Specified (0)
      GENERAL: "General Fee", // General Fee (1)
      TYPE_INSTANT: "Instant Order (A)", // Instant Order (2)
      TYPE_TIME: "Scheduled Order (A)",
      TYPE_STREET: "Street Order (A)",
      //TYPE_DIRECT: "Objednávka priama (A)",
      //TYPE_REPEATED: "Objednávka opakovaná (A)",
      //TYPE_AIRPORT: "Objednávka letisko (A)",
      WAITING: "Waiting Fee (A)", // Waiting Fee (2)
      PREORDER: "Pre-order", // Scheduled Order (3)
      NIGHT: "Night Surcharge", // Night Surcharge (4)
      WEEKEND: "Weekend Surcharge", // Weekend Surcharge (5)
      OFF_TIME: "Night/Weekend Surcharge", // Night/Weekend Surcharge (6)
      AIRPORT_TRANSPORT: "Airport Surcharge", // Airport Surcharge (7)
      STATION_TRANSPORT: "Station Surcharge", // Station Surcharge (8)
      ABROAD_TRANSPORT: "Abroad Surcharge", // Abroad Surcharge (9)
      PICKUP: "Pickup Fee", // Pickup Fee
      PICKUP_CITY: "Pickup in City", // Pickup in City (10)
      PICKUP_OUT_CITY: "Pickup Outside City", // Pickup Outside City (11)
      DRINK_TRANSPORT: "Customer's Vehicle Transport Surcharge", // Customer's Vehicle Transport Surcharge
      LUGGAGE_SMALL: "Small Luggage (A)", // Small Luggage (12)
      LUGGAGE_MEDIUM: "Medium Luggage (A)", // Medium Luggage (12)
      LUGGAGE_BIG: "Large Luggage (A)", // Large Luggage (12)
      PAYMENT_TERMINAL: "Payment Terminal in Vehicle (A)", // Payment Terminal in Vehicle
      SPECIAL_TRANSPORT_OLD: "Transport of Elderly People (A)", // Transport of Elderly People
      SPECIAL_TRANSPORT_CHAIR: "Transport of People in Wheelchairs (A)", // Transport of People in Wheelchairs
      AIRCONDITION: "Air Conditioning", // Air Conditioning (14)
      BOOSTER: "Child Booster (A)", // Child Booster (15)
      SEAT: "Child Seat (A)", // Child Seat (16)
      ANIMAL_SMALL: "Small Animal Transport (A)", // Small Animal Transport (17)
      ANIMAL_MEDIUM: "Medium Animal Transport (A)", // Medium Animal Transport (17)
      ANIMAL_BIG: "Large Animal Transport (A)", // Large Animal Transport (17)
      PREFERRED_DRIVER: "Preferred Driver (A)", // Preferred Driver (18)
      FOULING: "Soiling", // Soiling (18)
      STAINING: "Heavy Soiling", // Heavy Soiling (19)
      /*VEHICLE_SEDAN: "Sedan", 	// Sedan (21)
VEHICLE_COMBI: "Combi", 	// Combi (22)
VEHICLE_CABRIO: "Cabrio", 	// Cabrio (23)
VEHICLE_COUPE: "Coupé", 	// Coupé (24)
VEHICLE_HATCHBACK: "Hatchback", 	// Hatchback (25)
VEHICLE_VAN: "Van", 	// Van (26)
VEHICLE_MICROBUS: "Microbus", 	// Microbus (27)
VEHICLE_BUS: "Bus", 	// Bus (28)
VEHICLE_VAN_CARGO: "Dodávka", 	// Dodávka (29)
VEHICLE_PICKUP: "Pickup", 	// Pickup (30)
VEHICLE_SUV: "SUV", 	// SUV (31)
*/
      PERSONS_2: "Required Number of Persons 2 (A)", // Maximum required number of persons 2 (32)
      PERSONS_3: "Required Number of Persons 3 (A)", // Maximum required number of persons 3 (32)
      PERSONS_4: "Required Number of Persons 4 (A)", // Maximum required number of persons 4 (32)
      PERSONS_5: "Required Number of Persons 5 (A)", // Maximum required number of persons 5 (32)
      PERSONS_6: "Required Number of Persons 6 (A)", // Maximum required number of persons 6 (32)
      PERSONS_7: "Required Number of Persons 7 (A)", // Maximum required number of persons 7 (32)
      PERSONS_8: "Required Number of Persons 8 (A)", // Maximum required number of persons 8 (32)
      SERVICE_PERSONAL: "Personal Transport Service (A)", // Personal Transport (41)
      //SERVICE_LIMO: "Služba limuzínovej prepravy (A)", 	// Limuzínová preprava (42)
      //SERVICE_CARGO: "Služba nákladnej prepravy (A)", 	// Nákladná preprava (43)
      //SERVICE_DRINK: "Služba Drink taxi (A)", 	// Drink taxi (44)
      //SERVICE_IMMOBILE: "Služba prepravy imobilných (A)", 	// Preprava imobilných (45)
      //SERVICE_COURIER: "Kuriérska služba (A)", 	// Kurierská služba (46)
      //SERVICE_DELIVERY: "Donášková služba (A)", 	// Donášková služba (47)
      //SERVICE_AIRPORT: "Služba prepravy na letisko (A)", 	// Letiskové taxi (48)
      //SERVICE_SOCIAL: "Služba sociálneho taxi (A)", 	// Sociálne taxi (49)
      PAYMENT_CASH: "Cash Payment (A)", // Cash (51)
      PAYMENT_CARD: "Card Payment in Vehicle (A)", // Card in Vehicle (52)
      //PAYMENT_VIAMO: "Platba cez VIAMO (A)", 	// Viamo (53)
      //PAYMENT_COUPON: "Platba kupónom (A)", 	// Kupón (54)
      PAYMENT_CARD_ONLINE: "Online Card Payment (A)", // Card online (55)
      //PAYMENT_PREPAID: "Platba z kreditu (A)", 	// Preplatená jazda (56)
      PAYMENT_POSTPAID: "Post-Ride Payment/Postpaid/Invoice (A)", // Post-Ride/Postpaid (57)
      //PAYMENT_INVOICE: "Platba na faktúru (A)", 	// Platba na faktúru (58)
      PICKUP_PLACE_CHANGED: "Change in Pickup Location",
      DESTINATION_PLACE_CHANGED: "Change in Destination Location",
      WAYPOINT: "Intermediate Stop/Waypoint (A)", // AUTOMATICALLY ADDED
      WAYPOINT_STOP: "Intermediate Stop", // Stop
      //WAYPOINT_INFO: "WAYPOINT_INFO", 	// Informácia o cene za medzizastávky (0)
      WAYPOINT_DISTRICT: "Intermediate Stop in District", // Extra stop in district (61)
      WAYPOINT_CITY: "Intermediate Stop in City", // Extra stop in city (62)
      BATTERY: "Battery Charging", // Battery charging (67)
      FUEL: "Fuel Provision", // Fuel provision (68)
      DRAG_VEHICLE: "Vehicle Towing", // Vehicle towing (69)
      REGISTRATION: "Registration", // Registration (71)
      BLOCKING: "Blocking", // Blocking (72)
      UNBLOCKING: "Unblocking", // Unblocking (73)
      SOURCE_DISPATCH_TAXISERVICE: "Dispatch Source (A)", // Order from dispatch (74)
      //SOURCE_DISPATCH_BROKER: "Zdroj dispečing brokera (A)", 	// Objednávka z dispečingu (74)
      //SOURCE_DISPATCH_TAXXIMO: "Zdroj dispečing taxximo (A)", 	// Objednávka z dispečingu (74)
      SOURCE_DRIVER: "Driver Source (A)", // Order from driver on the street (75)
      SOURCE_APP: "Customer App Source (A)", // Order from customer app (76)
      SOURCE_BRANDAPP: "Branded Customer App Source (A)", // Order from branded customer app (77)
      ACCOUNT: "Business Ride", // Contract/business ride (78)
      //STORNO_PREREGISTERED: "Storno predregistrovanej objednávky", 	 	// STORNO PREREGISTERED -> Odregistrovaná/Unregistered (88)
      //STORNO_NEW: "Storno novej objednávky", 	 	// STORNO Nová -> Nerealizovaná/Expired  (81)
      //STORNO_PLANNED: "Storno plánovanej objednávky", 	 	// STORNO Plánovaná -> Odvolaná/Revoked  (82)
      //STORNO_PENDING: "Storno čakajúcej objednávky", 	 	// STORNO Čakajúca -> Odmietnutá/Declined  (83)
      //STORNO_ACCEPTED: "Storno akceptovanej objednávky", 	 	// STORNO Akcpetovaná -> Zrušená/Canceled  (84)
      //STORNO_WAITING: "Storno objednávky na mieste", 	 	// STORNO Na mieste -> Zamietnutá/Dismissed  (85)
      //STORNO_IN_PROGRESS: "Storno prepravovanej objednávky", 	 	// STORNO Realizovaná -> Nedokončená/Unfinished  (86)
      //STORNO_FINISHED: "Storno ukončenej objednávky", 	 	// STORNO Ukončená -> Nenaúčtovaná/Uncharged  (87)
      CUSTOMER_NEW: "New Customer (A)", // New customer
      CUSTOMER_REGULAR: "Regular Customer (A)", // Regular customer
      CUSTOMER_PRIORITY: "Priority Customer (A)", // Priority customer
      CUSTOMER_FAMOUS: "Famous Customer (A)", // Famous personality
      CUSTOMER_VIP: "VIP Customer (A)", // VIP client
      CUSTOMER_COLLEAGUE: "Customer is Colleague (A)", // Colleague from taxi service
      CUSTOMER_STUDENT: "Student Customer (A)", // Student
      CUSTOMER_CHILD: "Child Customer (A)", // Child
      CUSTOMER_JUNIOR: "Junior Customer (A)", // Junior
      CUSTOMER_SENIOR: "Senior Customer (A)", // Senior
      CUSTOMER_DEBTOR: "Customer is Debtor (A)", // Debtor
      CUSTOMER_BLOCKED: "Customer is Blocked (A)", // On the list of banned customers
      SOURCE_BRANDAPP_FIRST_TWO_ORDERS: "Customer App Source (A) - max. two Orders", // Order from branded customer app (77)
      SOURCE_BRANDAPP_EVERY_FIVE_ORDERS: "Customer App Source (A) - every fifth Order" // Order from branded customer app (77)
    },
    TypeArray: [
      { "text": "Not specified", "value": "NONE" },
      { "text": "General fee", "value": "GENERAL" },
      { "text": "Order instantly (A)", "value": "TYPE_INSTANT" },
      { "text": "Scheduled order (A)", "value": "TYPE_TIME" },
      { "text": "Street pickup order (A)", "value": "TYPE_STREET" },
      //{ "text": "Direct order (A)", "value": "TYPE_DIRECT" },
      //{ "text": "Repeated order (A)", "value": "TYPE_REPEATED" },
      //{ "text": "Airport order (A)", "value": "TYPE_AIRPORT" },
      { "text": "Waiting (A)", "value": "WAITING" },
      { "text": "Pre-order", "value": "PREORDER" },
      { "text": "Night surcharge", "value": "NIGHT" },
      { "text": "Weekend surcharge", "value": "WEEKEND" },
      { "text": "Night/Weekend surcharge", "value": "OFF_TIME" },
      { "text": "Airport surcharge", "value": "AIRPORT_TRANSPORT" },
      { "text": "Station surcharge", "value": "STATION_TRANSPORT" },
      { "text": "Abroad surcharge", "value": "ABROAD_TRANSPORT" },
      { "text": "Pickup fee", "value": "PICKUP" },
      { "text": "Pickup in the city", "value": "PICKUP_CITY" },
      { "text": "Pickup outside the city", "value": "PICKUP_OUT_CITY" },
      { "text": "Customer's vehicle transport fee (Drink taxi)", "value": "DRINK_TRANSPORT" },
      { "text": "Small luggage (A)", "value": "LUGGAGE_SMALL" },
      { "text": "Medium luggage (A)", "value": "LUGGAGE_MEDIUM" },
      { "text": "Large luggage (A)", "value": "LUGGAGE_BIG" },
      { "text": "Payment terminal (A)", "value": "PAYMENT_TERMINAL" },
      { "text": "Transport of elderly people (A)", "value": "SPECIAL_TRANSPORT_OLD" },
      { "text": "Transport of people in wheelchairs (A)", "value": "SPECIAL_TRANSPORT_CHAIR" },
      { "text": "Air conditioning", "value": "AIRCONDITION" },
      { "text": "Child booster seat (A)", "value": "BOOSTER" },
      { "text": "Child seat (A)", "value": "SEAT" },
      { "text": "Small animal (A)", "value": "ANIMAL_SMALL" },
      { "text": "Medium animal (A)", "value": "ANIMAL_MEDIUM" },
      { "text": "Large animal (A)", "value": "ANIMAL_BIG" },
      { "text": "Preferred driver (A)", "value": "PREFERRED_DRIVER" },
      { "text": "Fouling", "value": "FOULING" },
      { "text": "Heavy fouling", "value": "STAINING" },
      /*{ "text": "Sedan", "value": "VEHICLE_SEDAN" },  // (21)
      { "text": "Combi", "value": "VEHICLE_COMBI" },  // (22)
      { "text": "Cabrio", "value": "VEHICLE_CABRIO" },  // (23)
      { "text": "Coupé", "value": "VEHICLE_COUPE" },  // (24)
      { "text": "Hatchback", "value": "VEHICLE_HATCHBACK" },  // (25)
      { "text": "Van", "value": "VEHICLE_VAN" },  // (26)
      { "text": "Microbus", "value": "VEHICLE_MICROBUS" },  // (27)
      { "text": "Bus", "value": "VEHICLE_BUS" },  // (28)
      { "text": "Delivery van", "value": "VEHICLE_VAN_CARGO" },  // (29)
      { "text": "Pickup truck", "value": "VEHICLE_PICKUP" },  // (30)
      { "text": "SUV", "value": "VEHICLE_SUV" },  // (31)
      */
      { "text": "Required number of persons 2 (A)", "value": "PERSONS_2" },
      { "text": "Required number of persons 3 (A)", "value": "PERSONS_3" },
      { "text": "Required number of persons 4 (A)", "value": "PERSONS_4" },
      { "text": "Required number of persons 5 (A)", "value": "PERSONS_5" },
      { "text": "Required number of persons 6 (A)", "value": "PERSONS_6" },
      { "text": "Required number of persons 7 (A)", "value": "PERSONS_7" },
      { "text": "Required number of persons 8 (A)", "value": "PERSONS_8" },
      { "text": "Personal transport service (A)", "value": "SERVICE_PERSONAL" },
      //{ "text": "Limousine transport service (A)", "value": "SERVICE_LIMO" },
      //{ "text": "Cargo transport service (A)", "value": "SERVICE_CARGO" },
      //{ "text": "Drink taxi service (A)", "value": "SERVICE_DRINK" },
      //{ "text": "Transport of immobile persons service (A)", "value": "SERVICE_IMMOBILE" },
      //{ "text": "Courier service (A)", "value": "SERVICE_COURIER" },
      //{ "text": "Delivery service (A)", "value": "SERVICE_DELIVERY" },
      //{ "text": "Airport transport service (A)", "value": "SERVICE_AIRPORT" },
      //{ "text": "Social taxi service (A)", "value": "SERVICE_SOCIAL" },
      { "text": "Cash payment (A)", "value": "PAYMENT_CASH" },
      { "text": "Card payment in the vehicle (A)", "value": "PAYMENT_CARD" },
      //{ "text": "VIAMO payment (A)", "value": "PAYMENT_VIAMO" },
      //{ "text": "Coupon payment (A)", "value": "PAYMENT_COUPON" },
      { "text": "Online card payment (A)", "value": "PAYMENT_CARD_ONLINE" },
      //{ "text": "Credit card payment (A)", "value": "PAYMENT_PREPAID" },
      { "text": "Payment after the ride/postpaid/invoice (A)", "value": "PAYMENT_POSTPAID" },
      //{ "text": "Invoice payment (A)", "value": "PAYMENT_INVOICE" },
      { "text": "Pickup location change", "value": "PICKUP_PLACE_CHANGED" },
      { "text": "Destination change", "value": "DESTINATION_PLACE_CHANGED" },
      { "text": "Waypoint/Transfer point (A)", "value": "WAYPOINT" },
      { "text": "Waypoint", "value": "WAYPOINT_STOP" },  // Stop 
      //{ "text": "WAYPOINT_INFO", "value": "WAYPOINT_INFO" },
      { "text": "Waypoint in the neighborhood", "value": "WAYPOINT_DISTRICT" },
      { "text": "Waypoint in the city", "value": "WAYPOINT_CITY" },
      { "text": "Battery charging", "value": "BATTERY" },
      { "text": "Fuel provision", "value": "FUEL" },
      { "text": "Vehicle towing", "value": "DRAG_VEHICLE" },
      { "text": "Registration", "value": "REGISTRATION" },
      { "text": "Blocking", "value": "BLOCKING" },
      { "text": "Unblocking", "value": "UNBLOCKING" },
      { "text": "Dispatch source (A)", "value": "SOURCE_DISPATCH_TAXISERVICE" },
      //{ "text": "Dispatch source broker (A)", "value": "SOURCE_DISPATCH_BROKER" },
      //{ "text": "Dispatch source taxximo (A)", "value": "SOURCE_DISPATCH_TAXXIMO" },
      { "text": "Driver source (A)", "value": "SOURCE_DRIVER" },
      { "text": "Customer app source (A)", "value": "SOURCE_APP" },
      { "text": "Branded customer app source (A)", "value": "SOURCE_BRANDAPP" },
      { "text": "Company ride", "value": "ACCOUNT" },
      //{ "text": "Cancellation of pre-registered order", "value": "STORNO_PREREGISTERED" },
      //{ "text": "Cancellation of new order", "value": "STORNO_NEW" },
      //{ "text": "Cancellation of planned order", "value": "STORNO_PLANNED" },
      //{ "text": "Cancellation of pending order", "value": "STORNO_PENDING" },
      //{ "text": "Cancellation of accepted order", "value": "STORNO_ACCEPTED" },
      //{ "text": "Cancellation of on-site order", "value": "STORNO_WAITING" },
      //{ "text": "Cancellation of in-progress order", "value": "STORNO_IN_PROGRESS" },
      //{ "text": "Cancellation of completed order", "value": "STORNO_FINISHED" },
      { "text": "New customer (A)", "value": "CUSTOMER_NEW" },
      { "text": "Regular customer (A)", "value": "CUSTOMER_REGULAR" },
      { "text": "Priority customer (A)", "value": "CUSTOMER_PRIORITY" },
      { "text": "Known personality customer (A)", "value": "CUSTOMER_FAMOUS" },
      { "text": "VIP customer (A)", "value": "CUSTOMER_VIP" },
      { "text": "Customer is a colleague (A)", "value": "CUSTOMER_COLLEAGUE" },
      { "text": "Student customer (A)", "value": "CUSTOMER_STUDENT" },
      { "text": "Child customer (A)", "value": "CUSTOMER_CHILD" },
      { "text": "Junior customer (A)", "value": "CUSTOMER_JUNIOR" },
      { "text": "Senior customer (A)", "value": "CUSTOMER_SENIOR" },
      { "text": "Customer is a debtor (A)", "value": "CUSTOMER_DEBTOR" },
      { "text": "Customer is blocked (A)", "value": "CUSTOMER_BLOCKED" },
      { "text": "Customer App Source (A) - max. two Orders", "value": "SOURCE_BRANDAPP_FIRST_TWO_ORDERS" },
      { "text": "Customer App Source (A) - every fifth Order", "value": "SOURCE_BRANDAPP_EVERY_FIVE_ORDERS" },
    ],
    ObligatoryTypeArray: [
      { "text": "Price for the ride (P)", "value": "PRICE_JOURNEY" },
      { "text": "Price for waiting before the ride (P)", "value": "PRICE_WAITING" },
    ],
  },

  Settings: {
    page_title: "Taxi Service Settings",
    tabInfo: "Info",
    tabAutomat: "Automat",
    tabSettings: "Settings",
    tabContacts: "Contacts",
    tabGateway: "Gateway",
    tabOrderTypes: "Order Types",
    tabPaymentOptions: "Payment Options",
    TabSms: "SMS",
    TabModules: "Modules",
    //tabInfo
    id: "ID",
    brokerId: "Broker ID",
    name: "Name",
    company: "Company Name",
    country: "Country",
    city: "City",
    taxiLicenceNumber: "Taxi Licence Number",
    taxiLicenceExpiration: "Taxi Licence Expiration",
    type: "Type",
    status: "Status",
    descriptionShort: "Short Description",
    description: "Description",
    //tabAutomat
    subheaderActivationTimeOrder: "Activation Time for Time Orders",
    activationTimeOrderDescription: "The time when the order for sending should be activated is recorded individually for each time order when created. "
      + "Activation can be globally enabled or disabled based on the order type (see Order Types tab).",
    activeOrderBeforeSend: "Send to drivers with active order",
    activeOrderBeforeSendTitle: "Send order also to drivers with order in status IN_PROGRESS",
    priorityZero: "Priority send to preferred driver",
    priorityZeroTitle: "Send orders with preferred driver as priority and guarantee their delivery",
    defaultPresendTime: "Default Send Time",
    defaultPresendTimeTitle: "Time when orders should start being sent to drivers - for time orders",
    defaultPresendTime_hint: "Recommended time is 15 minutes before sending the order",
    subheaderDistanceAutomat: "Settings for Simple Automat (DISTANCE)",
    radiusDirect: "Radius for Directly Sent Order",
    radiusDirectTitle: "For directly sent orders, drivers will be in radius",
    delay: "Delay",
    delayTitle: "Time interval in seconds when a specific order should be resent",
    subheaderPriorityAutomat: "Settings for Advanced Automat (PRIORITY)",
    priorityAutomatDescription: "The order goes through priorities from 5 to 1. Priority 1 and 2 are for time orders. Priority 3, 4, and 5 are for immediate orders. The automat first divides orders by priority 1, then 2 to 5. "
      + "The order usually proceeds from priority 5 to 4 and to 3 if no suitable driver is found. Or from 2 to 1. Priorities 5 and 2 should have the smallest radius (usually up to 3km depending on the city size). "
      + "Priorities 3 and 1 should have the widest radius (more than 10 km). The radius is specified in meters, and the time in minutes (from created time or requested time).",
    automat: "Enable Automat",
    radius1: "Radius 1",
    radius1_hint: "Recommended value greater than 50000m",
    automatPriorityTime1: "Time 2",
    automatPriorityTime1_hint: "Recommended value 5min",
    radius2: "Radius 2",
    radius2_hint: "Recommended value 10000m",
    radius3: "Radius 3",
    radius3_hint: "Recommended value greater than 50000m",
    automatPriorityTime2: "Time 4",
    automatPriorityTime2_hint: "Recommended value 5min",
    automatPriorityTime2_validation: "Time 4 must be greater than Time 5",
    radius4: "Radius 4",
    radius4_hint: "Recommended value 20000m",
    automatPriorityTime3: "Time 5",
    automatPriorityTime3_hint: "Recommended value 3min",
    automatPriorityTime3_validation: "Time 5 must be smaller than Time 4",
    radius5: "Radius 5",
    radius5_hint: "Recommended value less than 2000m",
    automatBonusMaxMinuteDescription: "Automat will start prioritizing drivers who haven't had an order for more than 10 minutes. "
      + "The prioritization value increases with each minute a driver doesn't have an order, up to the maximum value in minutes. "
      + "This virtually brings the driver closer by the number of meters to the order, calculated based on the formula Bonus_Distance / (Bonus_Time - 10). "
      + "If you don't want prioritization, set both values to 1 minute."
      + "Prioritization is especially useful at night when drivers have fewer orders and it happens that due to their location, some drivers may not receive orders."
      + "This setting allows for a more even distribution of orders among drivers with a small increase in mileage.",

    automatBonusMaxMinute: "Bonus Time without Order",
    automatBonusMaxMinute_hint: "Recommended value 40min",
    automatBonusMaxRadius: "Bonus Distance without Order",
    automatBonusMaxRadius_hint: "Recommended value 300m",
    //tabSettings
    subheaderDriverApp: "Driver App Settings",
    allowSilence: "Allow Silent Mode",
    allowSilenceTitle: "Enable the silent mode button in the driver app",
    allowStreetOrder: "Allow Street Orders",
    allowStreetOrderTitle: "Allow the street order button in the driver app, allowing the driver to create an order from the street",
    showClientPhoneDriverDispatch: "Show Client Phone in Dispatch",
    showClientPhoneDriverDispatchTitle: "Show the client's phone number in the dispatch street order window",
    showRouteInfoNotif: "Show Distance to Pickup in Notification",
    showRouteInfoNotifTitle: "Show the estimated distance from the driver to the pickup location in the notification about a new order",
    showClientMarkInNotif: "Show Client Mark in Notification",
    showClientMarkInNotifTitle: "Show the client's mark in the notification about a new order",
    showClientNicknameInNotif: "Show Client Nickname in Notification",
    showClientNicknameInNotifTitle: "Show the client's nickname in the notification about a new order",
    showFiltersInNotif: "Show Filters in Notification",
    showFiltersInNotifTitle: "Show the filters/requirements in the notification about a new order",
    allowOrderCancel: "Allow Order Cancellation",
    allowOrderCancelTitle: "Enable the order cancellation and return button in the driver app",
    popupTime: "Notification Display Time",
    popupTimeTitle: "Time in seconds for how long the notification about a new order is displayed to the driver",
    subheaderWaiting: "Waiting for Customer During the Ride",
    allowWaiting: "Allow Waiting",
    allowWaitingTitle: "Enable the Waiting button for the driver during the ride",
    waitingAfterStartLimit: "Time Limit for Waiting After Ride Start",
    waitingAfterStartLimitTitle: "Time in seconds after which the driver can activate the waiting button during the ride",
    waitingAfterStartLimit_hint: "Recommended value 120 seconds",
    waitingAfterLastLimit: "Time Limit Between Waiting Intervals",
    waitingAfterLastLimitTitle: "Time in seconds after which the driver can again activate the waiting button during the ride",
    waitingAfterLastLimit_hint: "Recommended value 60 seconds",
    subheaderWorkshift: "Work Shift",
    vehicleAllocation: "Vehicle Allocation",
    approvalWorklog: "Approval of Workshift",
    approvalWorklogTitle: "Dispatcher approves the end of the workshift and breaks",
    validateMileages: "Mileage Validation",
    validateMileagesTitle: "Validation of mileage in the driver app at the start and end of the ride based on the original mileage",
    subheaderPbxCallRouting: "Non dispatcher mode of PBX",
    pbxCallRouting: "Routing calls type",
    pbxCallRoutingTitle: "Type of routing calls for non dispatcher mode of PBX",
    driverPhoneExt: "Driver Phone Extension",
    driverPhoneExtTitle: "Extension for driver phone",
    showDriversDriverMap: "Show Drivers on Driver Map",
    showDriversDriverMapTitle: "Show drivers on the driver map in the driver app",
    showDriverCalllog: "Show Driver Call Log",
    showDriverCalllogTitle: "Show the driver call log in the driver app",
    showStreetOrderCreateBtn: "Show Street order create button",
    showStreetOrderCreateBtnTitle: "Show the street order create button in the driver app",
    subheaderOther: "Other",
    smsCancellation: "SMS Cancellation",
    smsCancellationTitle: "By starting the ride, the system will cancel all older SMS in the queue (no guarantee)",
    realDistanceCorrection: "Real Distance Correction",
    realDistanceCorrectionTitle: "Real distance correction of transport based on the driver's GPS location",
    realDistanceCorrection_hint: "Recommended value 2.0%",
    msgRealDistanceCorrectionValueOutOfRange: "The real distance correction value must be in the range from -15.0 to 15.0",
    msgOneDecimalAllowed: "Only one decimal place is allowed",
    subheaderPayment: "Payments",
    paymentProfileId: "Profile for Invoice Generation",
    profileId: "Billing Profile",
    profileIdTitle: "Profile to which taxximo invoices for taxi service are issued",
    subheaderLocalityCheck: "Locality Recording",
    localityCheck: "Record Locality",
    standCheck: "Record at Stand",
    //tabContacts
    subheaderContacts: "Contact Information",
    email: "Contact Email",
    emailTitle: "Contact email for customer inquiries",
    problemEmail: "Problem Email",
    problemEmailTitle: "Contact email for notifications about registered issues",
    problemPhone: "Problem Phone",
    problemPhoneTitle: "Contact phone for SMS notifications about registered issues",
    maintenanceVehicleEmail: "Vehicle Maintenance Email",
    maintenanceVehicleEmailTitle: "Contact email for notifications about vehicle maintenance",
    subheaderDispatchPhones: "Dispatcher Phone Numbers",
    phone1: "Phone 1",
    phone2: "Phone 2",
    phone3: "Phone 3",
    phone4: "Phone 4",
    phone5: "Phone 5",
    phone6: "Phone 6",
    phone7: "Phone 7",
    phone8: "Phone 8",
    phone9: "Phone 9",
    phone10: "Phone 10",
    phone11: "Phone 11",
    phone12: "Phone 12",
    //tabGateway
    subheaderSmsGateway: "SMS Gateway Settings",
    smsGate: "SMS Gateway",
    smsIdentity: "SMS Gateway Identity",
    smsLogin: "Login",
    smsPassword: "Password",
    subheaderPhoneGateway: "Phone Exchange Settings",
    phoneGate: "Phone Exchange",
    phoneGateLogin: "Login",
    phoneGatePassword: "Password",
    subheaderPhoneExtensions: "Extensions for Phone Exchange",
    phoneExt1: "Extension 1",
    phoneExt2: "Extension 2",
    phoneExt3: "Extension 3",
    phoneExt4: "Extension 4",
    phoneExt5: "Extension 5",
    phoneExt6: "Extension 6",
    phoneExt7: "Extension 7",
    phoneExt8: "Extension 8",
    phoneExt9: "Extension 9",
    phoneExt10: "Extension 10",
    phoneExt11: "Extension 11",
    phoneExt12: "Extension 12",
    //tabOrderTypes
    tableHeadersSupportOrderTypes: {
      actions: "Actions",
      id: "ID",
      type: "Type",
      accept: "Accept",
      send: "Send",
      sendType: "Send Type",
      moveAutomat: "Activate for Automatic Sending",
    },
    dialogEditSuportOrderTitle: "Edit Order Type",
    dialogCreateSuportOrderTitle: "Create New Order Type",
    dialogDeleteSuportOrderTitle: "Delete Order Type",
    supportOrderType: "Order Type",
    supportOrderAccept: "Accept",
    supportOrderSend: "Send",
    supportOrderSendType: "Send Type",
    supportOrderMoveAutomat: "Activate for Automatic Sending",
    // There can be an impact on the functioning of other system components if you delete this object. Please consider your action!
    supportOrderDeleteWarning: "Deleting this order type may impact the functioning of other system components. Please consider your action!",
    supportOrderdeleteSubheader: "Move the slider to the right if you want to delete the order type.",
    //tabPaymentOptions - importované
    //TabSms - importované
    // TabModules
    servicePlan_page_title: "Service Plan",
    servicePlan_totalPrice: "Total Price",
    tableHeadersSupportModules: {
      actions: "Actions",
      icon: "",
      name: "Module Name",
      description: "Description",
      status: "Status",
      unitPrice: "Unit Price",
      actualPrice: "Actual Price",
      changeValue: "Desired Value",
      newPrice: "New Price",
    },
    tableRowsSupportModules: {
      limitWorkshift: "Drivers on Workshift",
      limitWorkshiftDescription: "Maximum number of concurrently logged-in drivers on a workshift",
      cardPayments: "Card Payments",
      cardPaymentsDescription: "Execution of online card payments",
      discount: "Discounts",
      discountDescription: "Creation of discount coupons",
      dispatchingChat: "Dispatching Chat",
      dispatchingChatDescription: "Chat communication exclusively between dispatchers",
      limitLocalityPrice: "Number of Locality Prices",
      limitLocalityPriceDescription: "Maximum number of localities designated for price lists among different localities",
      limitStand: "Number of Stands",
      limitStandDescription: "Maximum number of stands for vehicles",
      pricelistType: "Pricelist Type",
      pricelistTypeDescription: "Pricelist type allowing different ways of calculating the order price",
      report: "Reports",
      reportDescription: "Displaying reports",
      timeOrder: "Scheduled Orders",
      timeOrderDescription: "Ability to create orders for a specific time",
      account: "Corporate Rides",
      accountDescription: "Ability to create orders with billing for companies",
      worklog: "Driver Workshift Logs",
      worklogDescription: "Recording driver workshift logs",
      addressSearch: "Addresses in Dispatching",
      addressSearchDescription: "Source of address data",
      brandApp: "Branded Application",
      brandAppDescription: "Custom application for customers",
      commercialAds: "Advertising Module",
      commercialAdsDescription: "Displaying custom advertisements",
    },
    limitWorkshiftRules1: "This field is required",
    limitWorkshiftRules2: "Value should be above 3",
    limitWorkshiftRules3: "Max should not be above 500",

    limitStandRules1: "This field is required",
    limitStandRules2: "Value should be above 0",
    limitStandRules3: "Value should be above your current limit",
    limitStandRules4: "Max should not be above 20",

    limitLocalityPriceRules1: "This field is required",
    limitLocalityPriceRules2: "Value should be above 0",
    limitLocalityPriceRules3: "Value should be above your current limit",
    limitLocalityPriceRules4: "Max should not be above 20",

    AllowOrderCancelArray: [
      {
        "text": "Enabled",
        "value": "ENABLED",
      },
      {
        "text": "Disabled",
        "value": "REQUEST",
      },
    ],
    VehicleAllocationTypeArray: [
      {
        "text": "Assigned only",
        "value": "ASSIGNED",
      },
      {
        "text": "All vehicles",
        "value": "ALL",
      },
    ],
    AcceptSupportOrderType: {
      AUTO: "Automatic",
      MANUAL: "Manual",
      UNDEFINED: "",
    },
    AcceptSupportOrderTypeArray:
      [
        {
          "text": "Automatic",
          "value": "AUTO",
        },
        {
          "text": "Manual",
          "value": "MANUAL",
        },
      ],

    SendSupportOrderType: {
      AUTOMAT: "Automatically",
      MANUAL: "Manual",
      UNDEFINED: "",
    },
    SendSupportOrderTypeArray:
      [
        {
          "text": "Automatically",
          "value": "AUTOMAT",
        },
        {
          "text": "Manual",
          "value": "MANUAL",
        },
      ],

    PbxCallRoutingType: {
      SLOWEST: "To the slowest available driver",
      FREE_EARLIEST_DRIVE: "To the driver who has been free the longest",
      EARLIEST_CALL: "Even distribution (to the driver who has been without a call the longest)",
      EARLIEST_CALL_PREF_FREE: "Even distribution, prefer free",
      EARLIEST_CALL_PREF_UNOCCUPIED: "Even distribution, prefer free and unoccupied",
      UNDEFINED: "",
    },
    PbxCallRoutingTypeArray:
      [
        {
          "text": "To the slowest available driver",
          "value": "SLOWEST",
        },
        {
          "text": "To the driver who has been free the longest",
          "value": "FREE_EARLIEST_DRIVE",
        },
        {
          "text": "Even distribution (to the driver who has been without a call the longest)",
          "value": "EARLIEST_CALL",
        },
        {
          "text": "Even distribution, prefer free",
          "value": "EARLIEST_CALL_PREF_FREE",
        },
        {
          "text": "Even distribution, prefer free and unoccupied",
          "value": "EARLIEST_CALL_PREF_UNOCCUPIED",
        },
      ],
    DriverPhoneExtType: {
      VEHICLE: "Vehicle",
      DRIVER: "Driver",
      DRIVER_SELECT: "Selected by Driver",
    },
    DriverPhoneExtTypeArray:
      [
        {
          "text": "Vehicle",
          "value": "VEHICLE",
        },
        {
          "text": "Driver",
          "value": "DRIVER",
        },
        {
          "text": "Selected by Driver",
          "value": "DRIVER_SELECT",
        },
      ],
    DriverPhoneType: {
      VEHICLE: "Vehicle",
      DRIVER: "Driver",
      DRIVER_SELECT: "Selected by Driver",
    },
    DriverPhoneTypeArray:
      [
        {
          "text": "Vehicle",
          "value": "VEHICLE",
        },
        {
          "text": "Driver",
          "value": "DRIVER",
        },
        /* {
          "text": "Selected by Driver",
          "value": "DRIVER_SELECT",
        }, */
      ],

    ShowDriversDriverMapType: {
      NONE: "None",
      ALWAYS: "Always",
      DISPATCH_ONLY: "Dispatch Only",
      AUTO_DISPATCH_ONLY: "Auto Dispatch Only",
    },
    ShowDriversDriverMapTypeArray:
      [
        {
          "text": "None",
          "value": "NONE",
        },
        {
          "text": "Always",
          "value": "ALWAYS",
        },
        {
          "text": "Dispatcher Only",
          "value": "DISPATCH_ONLY",
        },
        {
          "text": "Dispatcher only in nondispatch mode",
          "value": "AUTO_DISPATCH_ONLY",
        },
      ],

    ShowDriverCalllogType: {
      NONE: "None",
      ALL: "All",
      OWN: "Own",
    },
    ShowDriverCalllogTypeArray:
      [
        {
          "text": "None",
          "value": "NONE",
        },
        {
          "text": "All",
          "value": "ALL",
        },
        {
          "text": "Own",
          "value": "OWN",
        },
      ],

    ShowStreetOrderCreateBtnType: {
      STREET: "Only from the street",
      MY: "Only mine",
      OTHER: "Only redistribute",
      STREET_OTHER: "From the street and redistribute",
      MY_OTHER: "Mine and redistribute",
      STREET_MY: "From the street and mine",
      ALL: "All",
    },
    ShowStreetOrderCreateBtnTypeArray:
      [
        {
          "text": "Only from the street",
          "value": "STREET",
        },
        {
          "text": "Only mine",
          "value": "MY",
        },
        {
          "text": "Only redistribute",
          "value": "OTHER",
        },
        {
          "text": "From the street and redistribute",
          "value": "STREET_OTHER",
        },
        {
          "text": "Mine and redistribute",
          "value": "MY_OTHER",
        },
        {
          "text": "From the street and mine",
          "value": "STREET_MY",
        },
        {
          "text": "All",
          "value": "ALL",
        },
      ],

    SendtypeSupportOrderType: {
      DIRECT_DRIVER: "Direct to driver",
      DIRECT_LOCALITY: "Direct to locality",
      DIRECT_STAND: "Direct to stand",
      DISTANCE: "Closest distance",
      PRIORITY: "Prioritized",
      RADIUS: "By radius",
      UNOCCUPIED: "On unoccupied",
      UNDEFINED: "",
    },
    SendtypeSupportOrderTypeArray:
      [
        /*{
          "text": "Direct to driver",
          "value": "DIRECT_DRIVER",
        },
        {
          "text": "Direct to locality",
          "value": "DIRECT_LOCALITY",
        },
        {
          "text": "Direct to stand",
          "value": "DIRECT_STAND",
        },
        {
          "text": "Closest distance",
          "value": "DISTANCE",
        },*/
        {
          "text": "Prioritized (recommended)",
          "value": "PRIORITY",
        },
        /*{
          "text": "By radius",
          "value": "RADIUS",
        },
        {
          "text": "On unoccupied",
          "value": "UNOCCUPIED",
        },*/
      ],
  },
  SmsTemplateList: {
    tableHeaders: {
      actions: "Actions",
      enabled: "Enabled",
      template: "Template",
      language: "Language",
      type: "Type",
    },
    dialogTitle: "SMS Template",
    enabled: "Enabled",
    template: "Template",
    language: "Language",
    type: "Type",
    blockPaymentInvoice: "Do not send for invoice payment",
    templateExample: "Template Example",
    dialogText1: "We recommend using SMS without diacritics and special characters as they can shorten the length of one SMS (one character takes up space for multiple characters in the message). If the message exceeds the limit of 160 characters, it may be split into multiple SMS, increasing the financial cost of sending. If you use variables, make sure to include their length in the actual form to avoid character limit issues.",
    dialogText2: "To insert order information into the text, use the following variables:",
    VAR_clientPhone: "Client's Phone Number",
    VAR_driverNickname: "Driver's Nickname",
    VAR_driverFirstName: "Driver's First Name",
    VAR_driverLastName: "Driver's Last Name",
    VAR_driverPhone: "Driver's Phone Number",
    VAR_vehicleBrand: "Vehicle Brand",
    VAR_vehicleModel: "Vehicle Model",
    VAR_vehicleLpn: "Vehicle License Plate Number",
    VAR_vehicleColor: "Vehicle Color",
    VAR_requestedTime: "Requested Time",
    VAR_distance: "Distance",
    VAR_currency: "Currency",
    VAR_estimatedPriceTotal: "Estimated Total Price",
    VAR_finalPriceTotal: "Final Total Price",
    VAR_finalPriceExtra: "Final Extra Price",
    VAR_finalPriceDiscount: "Final Discount Price",
    VAR_sms_url_id_short: "Link to online order tracking",
    msgErrorSelectType: "Select template type",
    msgErrorAlreadyUsedType: "This template type already exists",
    exampleAccepted1: "Your order has been accepted. The vehicle ",
    exampleAccepted2: " will pick you up in a few minutes.",
    exammpleApproaching1: "The vehicle ",
    exammpleApproaching2: " is approaching the requested location. We are waiting for you.",
    exampleWaiting1: "The vehicle ",
    exampleWaiting2: " has arrived at the requested location. We are waiting for you.",
    exampleWaitingApproaching1: "The vehicle ",
    exampleWaitingApproaching2: " has arrived at the requested location. We are waiting for you.",
    exampleFinished1: "The ride has been completed. Thank you for using our services.",
    exampleSettled1: "The ride has been completed. The price for the ride is ",
    exampleSettled2: ". Thank you for using our services.",
    exampleCancelled1: "The ride has been canceled. If you have any questions, please contact our dispatch at +4219XXXXXXXX.",
  },
  PaymentOptionList: {
    tableHeaders: {
      actions: "Actions",
      active: "Active",
      paymentType: "Payment Type",
      receiptType: "Receipt Type",
      receiptStatus: "Receipt Status",
      note: "Note",
    },
    dialogTitle: "Payment Option",
    active: "Active",
    paymentType: "Payment Type",
    receiptType: "Receipt Type",
    receiptStatus: "Receipt Status",
    note: "Note",
    msgErrorAlreadyUsedType: "This payment option type already exists",
  },

  receiptType: {
    DISABLED: "No receipt",     // Inactive, do not generate a receipt (0)
    INVOICE: "Invoice",         // Invoice tax document (1)
    RECEIPT: "Cash receipt",    // Cash Receipt / Cash register block, tax document (2)
    CREDIT_NOTE: "Credit note", // Credit note, tax document (3)
    NF_INVOICE: "Non-fiscal invoice",     // Non-Fiscal Invoice / Non-fiscal tax document (1)
    NF_RECEIPT: "Non-fiscal cash receipt", // Non-Fiscal Cash Receipt / Cash register block, Non-fiscal tax document (2)
    NF_CREDIT_NOTE: "Non-fiscal credit note", // Non-Fiscal Credit Note / Credit note, Non-fiscal tax document (3)
    PRO_FORMA: "Pro forma invoice"  // Pro forma invoice, Non-fiscal tax document (14+4)
  },
  receiptTypeArray:
    [
      { "text": "No receipt", "value": "DISABLED", },
      { "text": "Invoice", "value": "INVOICE", },
      { "text": "Cash receipt", "value": "RECEIPT", },
      { "text": "Credit note", "value": "CREDIT_NOTE", },
      { "text": "Non-fiscal invoice", "value": "NF_INVOICE", },
      { "text": "Non-fiscal cash receipt", "value": "NF_RECEIPT", },
      { "text": "Non-fiscal credit note", "value": "NF_CREDIT_NOTE", },
      { "text": "Pro forma invoice", "value": "PRO_FORMA", },
    ],

  receiptStatus: {
    CANNED: "Prepared",        // Prepared (1)
    ISSUED: "Issued",          // Issued (2)
    POSTED: "Posted",          // Posted (3)
    PAID: "Paid",              // Paid (4)
    ACCOUNTED: "Accounted",    // Accounted (5)
    CREDIT: "Credited",        // Credited (6)
    RECLAIMED: "Reclaimed",    // Reclaimed (7)
    DELAYED: "Delayed",        // Delayed (8)
    CLAIMED: "Claimed",        // Claimed (8)
    CANCELLED: "Cancelled"     // Cancelled (10)
  },
  receiptStatusArray:
    [
      { "text": "Prepared", "value": "CANNED", },
      { "text": "Issued", "value": "ISSUED", },
      { "text": "Posted", "value": "POSTED", },
      { "text": "Paid", "value": "PAID", },
      { "text": "Accounted", "value": "ACCOUNTED", },
      { "text": "Credited", "value": "CREDIT", },
      { "text": "Reclaimed", "value": "RECLAIMED", },
      { "text": "Delayed", "value": "DELAYED", },
      { "text": "Claimed", "value": "CLAIMED", },
      { "text": "Cancelled", "value": "CANCELLED", },
    ],

  TaxiserviceList: {
    page_title: "List of taxi services",
    dialogCreateTitle: "New taxi service",
    name: "Name",
    country: "Country",
    city: "City",
    tableHeaders: {
      actions: "Actions",
      id: "ID",
      name: "Name",
      country: "Country",
      city: "City",
      status: "Status",
      type: "Type",
    },
  },
  Taxiservice: {
    page_title: "Taxi Service",
    // Other items from "Settings"
  },

  FeedbackList: {
    page_title: "List of feedback",
    id: "ID",
    checked: "Resolved",
    createdAt: "Created",
    fromEmail: "Email",
    message: "Message",
    dialogTitle: "Edit feedback",
    tableHeaders: {
      actions: "Actions",
      id: "ID",
      checked: "Resolved",
      createdAt: "Created",
      fromEmail: "Email",
      message: "Message",
    },

  },
  //REPORTS
  ReportDispatcherAccessLog: {
    page_title: "Dispatcher Access Log",
    actions: "Actions",
    createdAt: "Created",
    ipAddress: "IP Address",
    role: "Role",
    firstName: "First Name",
    lastName: "Last Name",
    info: "Info",
    btnFindIpAddress: "Find IP Address on Map Service",
  },
  ReportOverview: {
    page_title: "Overview",
    allRealized: "All Realized",
    allCanceledRealized: "Canceled During Realization",
    allCanceledUnrealized: "Canceled Before Realization",
    sourceStatistic: "Order Counts by Source in Current Month",
  },
  ReportSalesCompany: {
    page_title: "Corporate Rides",
    actions: "Actions",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    onlyRealized_label: "Only Realized",
    id: "ID",
  },
  ReportSalesDriver: {
    page_title: "Driver Revenues",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    onlyRealized_label: "Only Realized",
    startTime: "Start of Work Shift",
    id: "ID",
  },
  ReportStatusDay: {
    page_title: "Order Statuses by Day",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    onlyRealized_label: "Only Realized",
    startTime: "Start of Work Shift",
    dateRange: "Period",
    settled: "Settled",
    canceled: "Canceled",
  },
  ReportStatusHour: {
    page_title: "Order Statuses by Hour",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    onlyRealized_label: "Only Realized",
    dateRange: "Period",
  },
  ReportStatusMonth: {
    page_title: "Order Statuses by Month",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    onlyRealized_label: "Only Realized",
    dateRange: "Period",
  },
  ReportStatusMonthPerSource: {
    page_title: "Order Statuses by Source",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    onlyRealized_label: "Only Realized",
    dateRange: "Period",
  },
  ReportStatusDailyVehicles: {
    page_title: "Vehicle Counts by Days",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    onlyRealized_label: "Only Realized",
    startTime: "Start of Work Shift",
    dateRange: "Period",
    year: "Year",
    month: "Month",
    day: "Day",
    monthName: "Month Name",
    dayName: "Day Name",
    total: "Vehicle Count",
  },
  ReportWorkshift: {
    page_title: "Work Shifts",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    startTime: "Start of Work Shift",
    id: "ID",
    driverId: "Driver ID",
    worklogDetailDialog_title: "Work Shift Detail",
    worklogDetailDialogItemList_title: "List of Changes During Work Shift",
    worklogDetailDialogRequestList_title: "List of Driver Requests",
    worklogOrdersDialog_title: "List of Orders During Work Shift",
    worklogId: "Work Shift ID",
    createdAt: "Created",
    processedAt: "Processed",
    updatedAt: "Updated",
    breakCounter: "Break Count",
    breakDuration: "Break Duration",
    itemDuration: "Duration",
    status: "Status",
    type: "Type",
    firstName: "First Name",
    lastName: "Last Name",
    tableHeaders: {
      id: "ID",
      status: "Status",
      source: "Source",
      type: "Type",
      paymentType: "Payment Type",
      acceptedAt: "Accepted",
      finalPriceTotal: "Total Price",
      driverId: "Driver ID",
    },
  },
  ReportWorkshiftSummary: {
    page_title: "Work Shift Summary",
    mobileBreakpointSwitch_label: "Table View",
    selectedHeaders_label: "Select Columns",
    startTime: "Start of Work Shift",
    driverId: "Driver ID",
  },
  OrdersGoogleMap: {
    enableBoardMarkers: "Board Markers",
    enableDestinationMarkers: "Destination Markers",
    enableOrderPath: "Order Path",
    enableBoardHeatmap: "Board Heatmap",
    enableDestinationHeatmap: "Destination Heatmap",
    heatmapSettings: "Heatmap Settings",
    heatmapOpacity: "Opacity",
    heatmapRadius: "Radius",
    heatmapBoardGradient: "Board Gradient",
    heatmapDestinationGradient: "Destination Gradient",
  },
};